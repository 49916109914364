import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';


export const useReportsStore = defineStore('reports', {
    state: () => ({
        dataDebitors: null,
        dataCreditors: null,
        dataSummary: null
    }),

    actions: {
        async getDebitor(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['reportsDebitor'], { ...payload })
                .then((res) => {
                    this.dataDebitors = res;
                    return res;
                }).finally(() => {

                });
        },
        async getCreditor(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['reportsCreditor'], { ...payload })
                .then((res) => {
                    this.dataCreditors = res;
                    return res;
                }).finally(() => {

                });
        },
        async getSummary(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['reportsSummary'], { ...payload })
                .then((res) => {
                    this.dataSummary = res;
                    return res;
                }).finally(() => {

                });
        }
    },
    getters: {}
});
