<template>
  <!-- Add Pipeline -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_pipeline"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Pipeline</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/pipeline">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Pipeline Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="pipeline-modal-head d-flex justify-content-between mb-3">
                    <h4>Pipeline Stages</h4>
                    <a
                      href="javascript:void(0);"
                      class="add-stage"
                      data-bs-toggle="modal"
                      data-bs-target="#add_stage"
                      ><i class="la la-plus-square me-2"></i>Add New</a
                    >
                  </div>
                  <ul class="pipeline-stages">
                    <li>
                      <p>
                        <i class="la la-grip-vertical"></i>
                        Inpipeline
                      </p>
                      <div class="edit-delete-stage">
                        <a
                          href="javascript:void(0);"
                          class="edit-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_stage"
                          ><i class="la la-edit me-2"></i>Edit</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="delete-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_stage"
                          ><i class="la la-trash-alt me-2"></i>Delete</a
                        >
                      </div>
                    </li>
                    <li>
                      <p>
                        <i class="la la-grip-vertical"></i>
                        Follow Up
                      </p>
                      <div class="edit-delete-stage">
                        <a
                          href="javascript:void(0);"
                          class="edit-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_stage"
                          ><i class="la la-edit me-2"></i>Edit</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="delete-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_stage"
                          ><i class="la la-trash-alt me-2"></i>Delete</a
                        >
                      </div>
                    </li>
                    <li>
                      <p>
                        <i class="la la-grip-vertical"></i>
                        Schedule service
                      </p>
                      <div class="edit-delete-stage">
                        <a
                          href="javascript:void(0);"
                          class="edit-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_stage"
                          ><i class="la la-edit me-2"></i>Edit</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="delete-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_stage"
                          ><i class="la la-trash-alt me-2"></i>Delete</a
                        >
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-12">
                  <div class="pipeline-modal-head input-blocks add-products">
                    <h4 class="mb-3">Access</h4>
                    <div class="access-info-tab">
                      <ul class="nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0"
                            id="pills-public-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-public"
                            role="tab"
                            aria-controls="pills-public"
                            aria-selected="true"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="public"
                              checked
                            />
                            <span class="checkmark"></span> All</span
                          >
                        </li>
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0 active"
                            id="pills-select-people-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-select-people"
                            role="tab"
                            aria-controls="pills-select-people"
                            aria-selected="false"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="select-people"
                            />
                            <span class="checkmark"></span> Select People</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-content" id="pills-tabContent2">
                    <div
                      class="tab-pane fade"
                      id="pills-public"
                      role="tabpanel"
                      aria-labelledby="pills-public-tab"
                    ></div>
                    <div
                      class="tab-pane fade show active"
                      id="pills-select-people"
                      role="tabpanel"
                      aria-labelledby="pills-select-people-tab"
                    >
                      <ul class="person-selected">
                        <li>
                          <div class="person-profile">
                            <a href="javascript:void(0);" class="profile-pic">
                              <img src="@/assets/img/avatar/avatar-21.jpg" alt="Img" />
                              Vaughan
                            </a>
                          </div>
                          <a href="javascript:void(0);" class="remove-person">Remove</a>
                        </li>
                        <li>
                          <div class="person-profile">
                            <a href="javascript:void(0);" class="profile-pic">
                              <img src="@/assets/img/avatar/avatar-1.jpg" alt="Img" />
                              Jessica
                            </a>
                          </div>
                          <a href="javascript:void(0);" class="remove-person">Remove</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Pipeline</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Pipeline -->

  <!-- Edit Pipeline -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="edit_pipeline"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Edit Pipeline</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/pipeline">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Pipeline Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="Sales" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="pipeline-modal-head d-flex justify-content-between mb-3">
                    <h4>Pipeline Stages</h4>
                    <a
                      href="javascript:void(0);"
                      class="add-stage"
                      data-bs-toggle="modal"
                      data-bs-target="#add_stage"
                      ><i class="la la-plus-square me-2"></i>Add New</a
                    >
                  </div>
                  <ul class="pipeline-stages">
                    <li>
                      <p>
                        <i class="la la-grip-vertical"></i>
                        Inpipeline
                      </p>
                      <div class="edit-delete-stage">
                        <a
                          href="javascript:void(0);"
                          class="edit-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_stage"
                          ><i class="la la-edit me-2"></i>Edit</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="delete-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_stage"
                          ><i class="la la-trash-alt me-2"></i>Delete</a
                        >
                      </div>
                    </li>
                    <li>
                      <p>
                        <i class="la la-grip-vertical"></i>
                        Follow Up
                      </p>
                      <div class="edit-delete-stage">
                        <a
                          href="javascript:void(0);"
                          class="edit-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_stage"
                          ><i class="la la-edit me-2"></i>Edit</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="delete-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_stage"
                          ><i class="la la-trash-alt me-2"></i>Delete</a
                        >
                      </div>
                    </li>
                    <li>
                      <p>
                        <i class="la la-grip-vertical"></i>
                        Schedule service
                      </p>
                      <div class="edit-delete-stage">
                        <a
                          href="javascript:void(0);"
                          class="edit-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_stage"
                          ><i class="la la-edit me-2"></i>Edit</a
                        >
                        <a
                          href="javascript:void(0);"
                          class="delete-stage"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_stage"
                          ><i class="la la-trash-alt me-2"></i>Delete</a
                        >
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-12">
                  <div class="pipeline-modal-head input-blocks add-products">
                    <h4 class="mb-3">Access</h4>
                    <div class="access-info-tab">
                      <ul class="nav nav-pills" id="pills-tab2" role="tablist">
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0"
                            id="pills-public-tab2"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-public2"
                            role="tab"
                            aria-controls="pills-public2"
                            aria-selected="true"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="public"
                              checked
                            />
                            <span class="checkmark"></span> All</span
                          >
                        </li>
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0 active"
                            id="pills-select-people-tab2"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-select-people2"
                            role="tab"
                            aria-controls="pills-select-people2"
                            aria-selected="false"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="select-people"
                            />
                            <span class="checkmark"></span> Select People</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-content" id="pills-tabContent3">
                    <div
                      class="tab-pane fade"
                      id="pills-public2"
                      role="tabpanel"
                      aria-labelledby="pills-public-tab2"
                    ></div>
                    <div
                      class="tab-pane fade show active"
                      id="pills-select-people2"
                      role="tabpanel"
                      aria-labelledby="pills-select-people-tab2"
                    >
                      <ul class="person-selected">
                        <li>
                          <div class="person-profile">
                            <a href="javascript:void(0);" class="profile-pic">
                              <img src="@/assets/img/avatar/avatar-21.jpg" alt="Img" />
                              Vaughan
                            </a>
                          </div>
                          <a href="javascript:void(0);" class="remove-person">Remove</a>
                        </li>
                        <li>
                          <div class="person-profile">
                            <a href="javascript:void(0);" class="profile-pic">
                              <img src="@/assets/img/avatar/avatar-1.jpg" alt="Img" />
                              Jessica
                            </a>
                          </div>
                          <a href="javascript:void(0);" class="remove-person">Remove</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Pipeline</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Pipeline -->

  <!-- Add Stage -->
  <div class="modal custom-modal fade modal-padding" id="add_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Stage</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/pipeline">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Stage Name <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Save Stage</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Stage -->

  <!-- Edit Stage -->
  <div class="modal custom-modal fade modal-padding" id="edit_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Edit Stage</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/pipeline">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Stage Name <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" value="Win" />
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Save Stage</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Stage -->

  <!-- Delete Pipeline -->
  <div class="modal custom-modal fade" id="delete_pipeline" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Pipeline ”Sales” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/crm/pipeline" class="btn btn-primary">Okay</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Pipeline -->

  <!-- Delete Pipeline -->
  <div class="modal custom-modal fade" id="delete_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Stage ”Inpipeline” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/crm/pipeline" class="btn btn-primary">Okay</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Pipeline -->

  <!-- Export -->
  <div class="modal custom-modal fade modal-padding" id="export" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Export</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/pipeline">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <h5 class="mb-3">Export</h5>
                  <div class="status-radio-btns d-flex">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="pdf"
                        name="export-type"
                        checked
                      />
                      <label for="pdf">Person</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="excel"
                        name="export-type"
                      />
                      <label for="excel">Organization</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h4 class="mb-3">Filters</h4>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Fields <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="FieldsPip"
                    id="fieldspip"
                    placeholder="All Fields"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdateOne"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Export Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Export -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      FieldsPip: ["All Fields", "Contact", "Company"],
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      startdateOne: currentDateOne,
    };
  },
};
</script>
