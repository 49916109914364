
<template>
    <div class="toggle-switch">
      <input
        type="checkbox"
        class="custom_switch"
        :id="`custom_switch_checkbox4${data.id}`"
        :checked="!!data.status"
        @change="handleChangeStatus"
      />
      <label
        :for="`custom_switch_checkbox4${data.id}`"
        class="switch-label"
      ></label>
    </div>
  </template>

<script setup>
import {defineProps, defineEmits} from 'vue';
const props = defineProps(['data']);
const emit = defineEmits(['statusChange']);

const handleChangeStatus = () => {
    emit('statusChange', props.data.id);
};
</script>

<style scoped>
.toggle-switch {
  position: relative;
  width: 48px;  /* Width of the switch */
  height: 24px; /* Height of the switch */
  display: inline-block;
}

.custom_switch {
  opacity: 0; /* Hide the checkbox */
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10; /* Ensure it captures clicks */
}

.switch-label {
  background-color: #ebedf2; /* Default background color */
  border-radius: 50px; /* Rounded switch */
  display: block;
  height: 100%;
  position: relative; /* Position relative for before pseudo-element */
  transition: background-color 0.3s; /* Smooth transition for background */
}

.switch-label::before {
  content: '';
  position: absolute;
  left: 2px; /* Initial position of the knob */
  bottom: 2px; /* Position from the bottom */
  width: 20px; /* Width of the toggle knob */
  height: 20px; /* Height of the toggle knob */
  background-color: white; /* Color of the knob */
  border-radius: 50%; /* Round knob */
  transition: left 0.3s, background-color 0.3s; /* Smooth transition */
}

/* When checkbox is checked */
.custom_switch:checked + .switch-label {
  background-color: #FF902F; /* Change background when checked */
}

.custom_switch:checked + .switch-label::before {
  left: 26px; /* Move knob to the right */
}

/* Dark mode styles */
body.dark .switch-label {
  background-color: #333; /* Dark background */
}

body.dark .custom_switch:checked + .switch-label {
  background-color: #FF902F; /* Primary color when checked in dark mode */
}

body.dark .switch-label::before {
  background-color: white; /* Knob color in dark mode */
}
</style>
