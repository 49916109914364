import {createRouter, createWebHistory} from 'vue-router';
import { useAuthStore } from '@/stores/modules/auth';
import Dashboard from '@/views/pages/dashboard/dashboard.vue'
import AdminDashboard from '@/views/pages/dashboard/admin-dashboard.vue'
import EmployeeDashboard from '@/views/pages/employee-dashboard/employee-dashboard.vue'
import LeadsDashboard from '@/views/pages/leads-dashboard/leads-dashboard.vue'
import DealsDashboard from '@/views/pages/deals-dashboard/deals-dashboard.vue'

import Apps from '@/views/pages/apps/apps-index.vue'
import Chat from '@/views/pages/apps/chat/chat-index.vue'
import Events from '@/views/pages/apps/events/events-index.vue'
import Contacts from '@/views/pages/apps/contacts/contacts-index.vue'
import Inbox from '@/views/pages/apps/inbox-index.vue'
import Compose from '@/views/pages/apps/compose-index.vue'
import FileManager from '@/views/pages/apps/file-manager.vue'

import Calls from '@/views/pages/apps/calls/calls-index.vue'
import VoiceCall from '@/views/pages/apps/calls/voice-call.vue'
import VideoCall from '@/views/pages/apps/calls/video-call.vue'
import OutgoingCall from '@/views/pages/apps/calls/outgoing-call.vue'
import IncomingCall from '@/views/pages/apps/calls/incoming-call.vue'

import Employee from '@/views/pages/employees/employee-index.vue'
import Employees from '@/views/pages/employees/employees-index.vue'
import EmployeesList from '@/views/pages/employees/employees-list.vue'
import Holidays from '@/views/pages/employees/holidays-index.vue'
import Leaves  from '@/views/pages/employees/admin-leaves/leaves-index.vue'
import LeavesEmployee from '@/views/pages/employees/employee-leaves/leaves-employee.vue'
import LeaveSettings from '@/views/pages/employees/leave-settings/leave-settings.vue'
import Attendance from '@/views/pages/employees/attendance-index.vue'
import AttendanceEmployee from '@/views/pages/employees/attendance-employee.vue'
import Departments from '@/views/pages/employees/departments-index.vue'
import Designations from '@/views/pages/employees/designations-index.vue'
import Timesheet from '@/views/pages/employees/time-sheet.vue'
import ShiftList from '@/views/pages/employees/shift-list.vue'
import Overtime from '@/views/pages/employees/over-time.vue'
import Clients from '@/views/pages/clients/clients-index.vue'
import ShiftScheduling from '@/views/pages/employees/shift-scheduling.vue'
import Login from '@/views/pages/pages/authentications/login-index.vue'
import Register from '@/views/pages/pages/authentications/register-index.vue'
import Forgot_Password from '@/views/pages/pages/authentications/forgot-password.vue'
import OTP from '@/views/pages/pages/authentications/one-time-password.vue'
import Lock_Screen from '@/views/pages/pages/authentications/lock-screen.vue'
import Error_500 from '@/views/pages/pages/errors/error-500.vue'
import Error_404 from '@/views/pages/pages/errors/error-404.vue'
import Privacy_Policy from '@/views/pages/pages/pages/privacy-policy.vue'
import Faq from '@/views/pages/pages/pages/faq-index.vue'
import Blank_Page from '@/views/pages/pages/pages/blank-page.vue'
import Terms from '@/views/pages/pages/pages/terms-conditions.vue'
import Subscriptions_Company from '@/views/pages/pages/subscriptions/subscriptions-company/subscriptions-company.vue'
import Subscriptions from '@/views/pages/pages/subscriptions/sub/sub-scriptions.vue'
import Subscribedcompanies from '@/views/pages/pages/subscriptions/subscribedcompanies/sub-index.vue'
import Pages from '@/views/pages/pages/pages/pages-index.vue'
import Settings from '@/views/pages/administration/settings/settings-index.vue'
import Localization from '@/views/pages/administration/settings/localization-index.vue'
import Theme_Settings from '@/views/pages/administration/settings/theme-settings.vue'
import Email_Settings from '@/views/pages/administration/settings/email-settings.vue'
import Approval_Setting from '@/views/pages/administration/settings/approval-setting/approval-setting.vue'
import Roles_Permissions from '@/views/pages/administration/settings/roles-permissions/roles-permissions.vue'
import Invoice_Settings from '@/views/pages/administration/settings/invoice-settings.vue'
import Salary_Settings from '@/views/pages/administration/settings/salary-settings.vue'
import Notifications_Settings from '@/views/pages/administration/settings/notifications-settings.vue'
import Change_Password from '@/views/pages/administration/settings/change-password.vue'
import LeaveType from "@/views/pages/administration/settings/leave-type.vue"
import Toxbox_Setting from "@/views/pages/administration/settings/toxbox-setting.vue"
import Cron_Setting from "@/views/pages/administration/settings/cron-setting.vue"
import Knowledgebase from "@/views/pages/administration/knowledge-base.vue"
import Jobs from "@/views/pages/administration/jobs/jobs-index.vue"
import Manage_Resumes from "@/views/pages/administration/jobs/manage-resumes.vue"
import Shortlist_Candidates from "@/views/pages/administration/jobs/shortlist-candidates.vue"
import Interview_Questions from "@/views/pages/administration/jobs/interview-questions.vue"
import Experiance_Level from "@/views/pages/administration/jobs/experiance-level.vue"
import Candidates from "@/views/pages/administration/jobs/candidates-index.vue"
import Schedule_Timing from "@/views/pages/administration/jobs/schedule-timing.vue"
import Apptitude_Result from "@/views/pages/administration/jobs/apptitude-result.vue"
import Users from "@/views/pages/administration/users/users-index.vue"
import Assets from '@/views/pages/administration/assets/assets-index.vue'
import EmployeeProfile from '@/views/pages/pages/profile/employeeprofile/profile-index.vue'
import Clientprofile from '@/views/pages/pages/profile/clientprofile/index.vue'
import Estimates from '@/views/pages/sales/estimates/estimates-index.vue'
import CreateEstimate from '@/views/pages/sales/estimates/create-estimate.vue'
import EditEstimate from '@/views/pages/sales/estimates/edit-estimate.vue'
import EstimateView from '@/views/pages/sales/estimates/estimate-view.vue'
import Invoices from '@/views/pages/sales/invoices/invoices-index.vue'
import CreateInvoice from '@/views/pages/sales/invoices/create-invoice.vue'
import EditInvoice from '@/views/pages/sales/invoices/edit-invoice.vue'
import InvoiceView from '@/views/pages/sales/invoices/invoice-view.vue'
import Payments from '@/views/pages/sales/payments-index.vue'
import Expenses from '@/views/pages/sales/expenses-index.vue'
import ProvidentFund from '@/views/pages/sales/provident-fund.vue'
import Taxes from '@/views/pages/sales/taxes-index.vue'
import Categories from '@/views/pages/accounting/categories-index.vue'
import SubCategory from '@/views/pages/accounting/sub-category.vue'
import Budgets from '@/views/pages/accounting/budgets-index.vue'
import BudgetExpenses from '@/views/pages/accounting/budget-expenses.vue'
import Budgetrevenues from '@/views/pages/accounting/budget-revenues.vue'
import Salary from '@/views/pages/payroll/salary-index.vue'
import Salaryview from '@/views/pages/payroll/salary-view.vue'
import Payrollitems from '@/views/pages/payroll/payroll-items/payroll-items.vue'
import Policies from '@/views/pages/policies/policies-index.vue'
import Expensereports from '@/views/pages/reports/expense-reports.vue'
import Invoicereports from '@/views/pages/reports/invoice-reports.vue'
import Paymentsreports from '@/views/pages/reports/payments-reports.vue'
import Projectreports from '@/views/pages/reports/project-reports.vue'
import Taskreports from '@/views/pages/reports/task-reports.vue'
import Userreports from '@/views/pages/reports/user-reports.vue'
import Employeereports from '@/views/pages/reports/employee-reports.vue'
import Payslipreports from '@/views/pages/reports/payslip-reports.vue'
import Attendancereports from '@/views/pages/reports/attendance-reports.vue'
import Leavereports from '@/views/pages/reports/leave-reports.vue'
import Dailyreports from '@/views/pages/reports/daily-reports.vue'
import Clientslist from '@/views/pages/clients/clients-list.vue'
import Projects from '@/views/pages/projects/projects-index.vue'
import ProjectList from '@/views/pages/projects/project-list.vue'
import Tasks from '@/views/pages/projects/tasks/tasks-index.vue'
import TaskBoard from '@/views/pages/projects/task-board/task-board.vue'
import Leads from '@/views/pages/employees/leads/leads-index.vue'
import Tickets from '@/views/pages/employees/tickets/tickets-index.vue'
import ProjectView from '@/views/pages/projects/project-view/project-view.vue'
import Termination from '@/views/pages/performance/termination-index.vue'
import Search from '@/views/pages/pages/pages/search/search-index.vue'
import Resignation from '@/views/pages/performance/resignation-index.vue'
import Promotion from '@/views/pages/performance/promotion-index.vue'
import Training_Type from '@/views/pages/performance/training/training-type.vue'
import Trainers from '@/views/pages/performance/training/trainers-index.vue'
import TrainingList from '@/views/pages/performance/training/training-list.vue'
import Goal_Type from '@/views/pages/performance/goals/goal-type.vue'
import Goal_Tracking from '@/views/pages/performance/goals/goal-tracking.vue'
import Performance_Appraisal from '@/views/pages/performance/performance/performance-appraisal.vue'
import Performance_Indicator from '@/views/pages/performance/performance/performance-indicator.vue'
import PerformanceReview from '@/views/pages/performance/performance/performance/performance-review.vue'
import Administration from '@/views/pages/administration/administration-index.vue'
import Job from '@/views/pages/administration/jobs/job-index.vue'
import Job_Details from '@/views/pages/administration/jobs/job-details.vue'
import Job_Applicants from '@/views/pages/administration/jobs/job-applicants.vue'
import Job_List from '@/views/pages/administration/jobs/job-list.vue'
import Job_View from '@/views/pages/administration/jobs/job-view.vue'
import Assets_Details from '@/views/pages/pages/profile/employeeprofile/assets-details.vue'
import User_Asset_Details from '@/views/pages/pages/profile/employeeprofile/user-asset-details.vue'
import Performance_Setting from "@/views/pages/administration/settings/performance-setting/performance-setting.vue"
import Knowledgebase_View from "@/views/pages/administration/knowledgebase-view.vue"
import UserDashboard from '@/views/pages/administration/jobs/user-dashboard/user-dashboard.vue'
import UserAllJobs from '@/views/pages/administration/jobs/user-dashboard/user-all-jobs.vue'
import AppliedJobs from '@/views/pages/administration/jobs/user-dashboard/applied-jobs.vue'
import SavedJobs from '@/views/pages/administration/jobs/user-dashboard/saved-jobs.vue'
import VisitedJobs from '@/views/pages/administration/jobs/user-dashboard/visited-jobs.vue'
import ArchivedJobs from '@/views/pages/administration/jobs/user-dashboard/archived-jobs.vue'
import OfferedJobs from '@/views/pages/administration/jobs/user-dashboard/offered-jobs.vue'
import Offer_Approvals from "@/views/pages/administration/jobs/offer-approvals.vue"
import Interviewing from '@/views/pages/administration/jobs/user-dashboard/interviewing/interviewing-index.vue'
import JobAptitude from '@/views/pages/administration/jobs/user-dashboard/interviewing/job-aptitude.vue'
import Questions from  '@/views/pages/administration/jobs/user-dashboard/interviewing/questions-index.vue'
import JobsDashboard from '@/views/pages/administration/jobs/user-dashboard/jobs-dashboard.vue'
import Tables from '@/views/pages/uiinterface/tables/ui-tables.vue'
import Tables_Basic from '@/views/pages/uiinterface/tables/tables-basic.vue'
import Data_Tables from '@/views/pages/uiinterface/tables/data-tables.vue'
import Chartapex from '@/views/pages/uiinterface/charts/apex/chart-apex.vue'
import Chartc3 from '@/views/pages/uiinterface/charts/c3/chart-c3.vue'
import Chartflot from '@/views/pages/uiinterface/charts/flot/chart-flot.vue'
import Chartjs from '@/views/pages/uiinterface/charts/js/chart-js.vue'
import Chartmorris from '@/views/pages/uiinterface/charts/morris/chart-morris.vue'
import Charts from '@/views/pages/uiinterface/charts/ui-charts.vue'
import Forms from '@/views/pages/uiinterface/forms/ui-forms.vue'
import Formbasicinput from '@/views/pages/uiinterface/forms/form-elements/formbasic/form-basic-input.vue'
import Formcheckboxradios from '@/views/pages/uiinterface/forms/form-elements/form-checkbox-radios.vue'
import Formgridgutters from '@/views/pages/uiinterface/forms/form-elements/form-grid-gutters.vue'
import Formselect from '@/views/pages/uiinterface/forms/form-elements/form-select.vue'
import FormInput from '@/views/pages/uiinterface/forms/form-elements/forminput/form-input.vue'
import Formmask from '@/views/pages/uiinterface/forms/form-elements/formmask/formmask.vue'
import Formfileupload from '@/views/pages/uiinterface/forms/form-elements/formfile-upload.vue'
import FormHorizontal from '@/views/pages/uiinterface/forms/layouts/form-horizontal.vue'
import Formvertical from '@/views/pages/uiinterface/forms/layouts/form-vertical.vue'
import Formfloatinglabels from '@/views/pages/uiinterface/forms/layouts/form-floating-labels.vue'
import Formvalidation from '@/views/pages/uiinterface/forms/form-validation.vue'
import Formselect2 from '@/views/pages/uiinterface/forms/form-select2.vue'
import Formwizard from '@/views/pages/uiinterface/forms/form-wizard.vue'
import Advancedui from '@/views/pages/uiinterface/advancedUI/advanced-ui.vue'
import UI_Ribbon from '@/views/pages/uiinterface/advancedUI/ui-ribbon.vue'
import UI_Clipboard from '@/views/pages/uiinterface/advancedUI/ui-clipboard.vue'
import UI_Drag_Drop from '@/views/pages/uiinterface/advancedUI/ui-drag-drop.vue'
import UI_Text_Editor from '@/views/pages/uiinterface/advancedUI/ui-text-editor.vue'
import UI_Counter from '@/views/pages/uiinterface/advancedUI/ui-counter.vue'
import UI_Scrollbar from '@/views/pages/uiinterface/advancedUI/ui-scrollbar.vue'
import UI_Rating from '@/views/pages/uiinterface/advancedUI/ui-rating.vue'
import UI_Stickynote from '@/views/pages/uiinterface/advancedUI/ui-stickynote.vue'
import UI_Timeline from '@/views/pages/uiinterface/advancedUI/ui-timeline.vue'
import BaseUi from '@/views/pages/uiinterface/baseui/base-ui.vue'
import UI_Alerts from '@/views/pages/uiinterface/baseui/ui-alerts.vue'
import UI_Accordion from '@/views/pages/uiinterface/baseui/ui-accordion.vue'
import UI_Avatar from '@/views/pages/uiinterface/baseui/ui-avatar.vue'
import UI_Badges from '@/views/pages/uiinterface/baseui/ui-badges.vue'
import UI_Borders from '@/views/pages/uiinterface/baseui/ui-borders.vue'
import UI_Buttons from '@/views/pages/uiinterface/baseui/ui-buttons.vue'
import UI_Buttons_group from '@/views/pages/uiinterface/baseui/ui-buttons-group.vue'
import UI_Breadcrumb from '@/views/pages/uiinterface/baseui/ui-breadcrumb.vue'
import UI_Cards from '@/views/pages/uiinterface/baseui/ui-cards.vue'
import UI_Carousel from '@/views/pages/uiinterface/baseui/carousel/ui-carousel.vue'
import UI_Colors from '@/views/pages/uiinterface/baseui/ui-colors.vue'
import UI_Dropdowns from '@/views/pages/uiinterface/baseui/ui-dropdowns.vue'
import UI_Grid from '@/views/pages/uiinterface/baseui/ui-grid.vue'
import UI_Images from '@/views/pages/uiinterface/baseui/ui-images.vue'
import UI_Lightbox from '@/views/pages/uiinterface/baseui/lightbox/ui-lightbox.vue'
import UI_Media from '@/views/pages/uiinterface/baseui/ui-media.vue'
import UI_Modals from '@/views/pages/uiinterface/baseui/ui-modals.vue'
import UI_Offcanvas from '@/views/pages/uiinterface/baseui/ui-offcanvas.vue'
import UI_Pagination from '@/views/pages/uiinterface/baseui/ui-pagination.vue'
import UI_Popovers from '@/views/pages/uiinterface/baseui/ui-popovers.vue'
import UI_Progress from '@/views/pages/uiinterface/baseui/ui-progress.vue'
import UI_Placeholders from '@/views/pages/uiinterface/baseui/ui-placeholders.vue'
import UI_Rangeslider from '@/views/pages/uiinterface/baseui/ui-rangeslider.vue'
import UI_Spinner from '@/views/pages/uiinterface/baseui/ui-spinner.vue'
import UI_Sweetalerts from '@/views/pages/uiinterface/baseui/ui-sweetalerts.vue'
import UI_Tooltips from '@/views/pages/uiinterface/baseui/ui-tooltips.vue'
import UI_Typography from '@/views/pages/uiinterface/baseui/ui-typography.vue'
import UI_Video from '@/views/pages/uiinterface/baseui/ui-video.vue'
import Icons from '@/views/pages/uiinterface/icons/ui-icons.vue'
import UI_Iconfontawesome from "@/views/pages/uiinterface/icons/icon-fontawesome.vue";
import UI_Iconfeather from "@/views/pages/uiinterface/icons/icon-feather.vue";
import UI_Iconionic from "@/views/pages/uiinterface/icons/icon-ionic.vue";
import UI_Iconmaterial from "@/views/pages/uiinterface/icons/icon-material.vue";
import UI_Iconpe7 from "@/views/pages/uiinterface/icons/icon-pe7.vue";
import UI_Iconsimpleline from "@/views/pages/uiinterface/icons/icon-simpleline.vue";
import UI_Iconthemify from "@/views/pages/uiinterface/icons/icon-themify.vue";
import UI_Iconweather from "@/views/pages/uiinterface/icons/icon-weather.vue";
import UI_Icontypicon from "@/views/pages/uiinterface/icons/icon-typicon.vue";
import UI_Iconflag from "@/views/pages/uiinterface/icons/icon-flag.vue";
import Crm from '@/views/pages/crm/crm-index.vue'
import ContactList from '@/views/pages/crm/contact/contact-list.vue'
import CrmActivities from '@/views/pages/crm/activities-index.vue'
import Analytics from '@/views/pages/crm/analytics/analytics-index.vue'
import ContactGrid from '@/views/pages/crm/contact/contact-grid.vue'
import ContactDetails from '@/views/pages/crm/contact/contact-details.vue'
import Pipeline from '@/views/pages/crm/pipe-line.vue'
import CompaniesList from '@/views/pages/crm/companies/companies-list.vue'
import CompaniesGrid from '@/views/pages/crm/companies/companies-grid.vue'
import CompanyDetails from '@/views/pages/crm/companies/company-details.vue'
import Crm_Leads from '@/views/pages/crm/leads/leads-index.vue'
import LeadsKanban from '@/views/pages/crm/leads/leads-kanban.vue'
import LeadsDetails from '@/views/pages/crm/leads/leads-details/leads-details.vue'
import Crm_Deals from '@/views/pages/crm/deals/deals-index.vue'
import Crm_Organization from '@/views/pages/crm/organizations/organization-index.vue'
import Crm_Companies from '@/views/pages/crm/companies/companies-index.vue'
import Crm_CompanyAccounts from '@/views/pages/crm/accounts/accounts-index.vue'
import Crm_Categories from '@/views/pages/crm/categories/categories-index.vue'
import Crm_Services from '@/views/pages/crm/services/services-index.vue'
import Crm_Customers from '@/views/pages/crm/customers/customer-index.vue'
import Crm_Requisites from '@/views/pages/crm/requisites/requisite-index.vue'
import Crm_Contracts from '@/views/pages/crm/contracts/contract-index.vue'
import Crm_Invoices from '@/views/pages/crm/invoices/invoice-index.vue'
import Crm_Transactions from '@/views/pages/crm/transactions/transaction-index.vue'
import Crm_Transfers from '@/views/pages/crm/transfers/transfer-index.vue'
import Crm_Debitor from '@/views/pages/crm/reports/debitor-index.vue'
import Crm_Creditor from '@/views/pages/crm/reports/creditor-index.vue'
import Crm_Report from '@/views/pages/crm/reports/report-index.vue'
import DealsKanban from '@/views/pages/crm/deals/deals-kanban.vue'
import DealsDetails from '@/views/pages/crm/deals/deals-details/deals-details.vue'
import TicketDetails from '@/views/pages/employees/tickets/ticket-details.vue'
import Sales from '@/views/pages/sales/sales-index.vue'
import Accounting from '@/views/pages/accounting/accounting-index.vue'
import Payroll from '@/views/pages/payroll/payroll-index.vue'
import Reports from '@/views/pages/reports/reports-index.vue'
import ComingSoon from '@/views/pages/pages/pages/coming-soon.vue';
import MailView from '@/views/pages/apps/mail-view.vue';
import UnderMaintenance from '@/views/pages/apps/under-maintenance.vue'
import Performance from '@/views/pages/performance/performance-index.vue'
import Goals from '@/views/pages/performance/goals/goals-index.vue'
import Training  from '@/views/pages/performance/training/training-index.vue'
import Project from '@/views/pages/projects/project-index.vue'
import Ticket from '@/views/pages/employees/tickets/ticket-index.vue'
import Profile from '@/views/pages/pages/profile/profile-index.vue'
import Subscription from '@/views/pages/pages/subscriptions/subscription-index.vue'
import {alert} from "ionicons/icons";

// const routes = [
//   {
//     path: '/',
//     name: 'login',
//     component: Login
//   },
//   {
//     path: '/compose',
//     name: 'compose',
//     component: Compose
//   },
//   {
//     path: '/policies',
//     name: 'policies',
//     component: Policies
//   },
//   {
//     path: '/pages',
//     component: Pages,
//     children: [
//       { path: '', redirect: '/pages/search' },
//       { path: "search", component: Search },
//       { path: "faq", component: Faq },
//       { path: "terms", component: Terms },
//       { path: "privacy-policy", component: Privacy_Policy },
//       { path: "blank-page", component: Blank_Page },
//     ]
//   },
//   {
//     path: '/icons',
//     component: Icons,
//     children: [
//       { path: '', redirect: '/icons/icon-fontawesome' },
//       { path: "icon-fontawesome", component: UI_Iconfontawesome },
//       { path: "icon-feather", component: UI_Iconfeather },
//       { path: "icon-ionic", component: UI_Iconionic },
//       { path: "icon-material", component: UI_Iconmaterial },
//       { path: "icon-pe7", component: UI_Iconpe7 },
//       { path: "icon-simpleline", component: UI_Iconsimpleline },
//       { path: "icon-themify", component: UI_Iconthemify },
//       { path: "icon-weather", component: UI_Iconweather },
//       { path: "icon-typicon", component: UI_Icontypicon },
//       { path: "icon-flag", component: UI_Iconflag },
//     ]
//   },
//   {
//     path: '/baseui',
//     component: BaseUi,
//     children: [
//       { path: '', redirect: '/baseui/ui-alerts' },
//       { path: "ui-alerts", component: UI_Alerts },
//       { path: "ui-accordion", component: UI_Accordion },
//       { path: "ui-avatar", component: UI_Avatar },
//       { path: "ui-badges", component: UI_Badges },
//       { path: "ui-borders", component: UI_Borders },
//       { path: "ui-buttons", component: UI_Buttons },
//       { path: "ui-buttons-group", component: UI_Buttons_group },
//       { path: "ui-breadcrumb", component: UI_Breadcrumb },
//       { path: "ui-cards", component: UI_Cards },
//       { path: "ui-carousel", component: UI_Carousel },
//       { path: "ui-colors", component: UI_Colors },
//       { path: "ui-dropdowns", component: UI_Dropdowns },
//       { path: "ui-grid", component: UI_Grid },
//       { path: "ui-images", component: UI_Images },
//       { path: "ui-lightbox", component: UI_Lightbox},
//       { path: "ui-media", component: UI_Media},
//       { path: "ui-modals", component: UI_Modals},
//       { path: "ui-offcanvas", component: UI_Offcanvas},
//       { path: "ui-pagination", component: UI_Pagination},
//       { path: "ui-popovers", component: UI_Popovers},
//       { path: "ui-progress", component: UI_Progress},
//       { path: "ui-placeholders", component: UI_Placeholders},
//       { path: "ui-rangeslider", component: UI_Rangeslider},
//       { path: "ui-spinner", component: UI_Spinner},
//       { path: "ui-sweetalerts", component: UI_Sweetalerts},
//       { path: "ui-tooltips", component: UI_Tooltips},
//       { path: "ui-typography", component: UI_Typography},
//       { path: "ui-video", component: UI_Video},
//     ]
//   },
//   {
//     path: '/advancedui',
//     component: Advancedui,
//     children: [
//       { path: '', redirect: '/advancedui/ui-ribbon' },
//       { path: "ui-ribbon", component: UI_Ribbon },
//       { path: "ui-clipboard", component: UI_Clipboard },
//       { path: "ui-drag-drop", component: UI_Drag_Drop },
//       { path: "ui-text-editor", component: UI_Text_Editor },
//       { path: "ui-counter", component: UI_Counter },
//       { path: "ui-scrollbar", component: UI_Scrollbar },
//       { path: "ui-rating", component: UI_Rating },
//       { path: "ui-stickynote", component: UI_Stickynote },
//       { path: "ui-timeline", component: UI_Timeline }
//     ]
//   },
//   {
//     path: '/tables',
//     component: Tables,
//     children: [
//       { path: '', redirect: '/tables/data-tables' },
//       { path: "data-tables", component: Data_Tables },
//       { path: "tables-basic", component: Tables_Basic }
//     ]
//   },
//   {
//     path: '/charts',
//     component: Charts,
//     children: [
//       { path: '', redirect: '/charts/chart-apex' },
//       { path: "chart-apex", component: Chartapex },
//       { path: "chart-c3", component: Chartc3 },
//       { path: "chart-flot", component: Chartflot },
//       { path: "chart-js", component: Chartjs },
//       { path: "chart-morris", component: Chartmorris }
//     ]
//   },
//   {
//     path: '/forms',
//     component: Forms,
//     children: [
//       { path: '', redirect: '/forms/form-basic-inputs' },
//       { path: "form-basic-inputs", component: Formbasicinput },
//       { path: "form-checkbox-radios", component: Formcheckboxradios },
//       { path: "form-grid-gutters", component: Formgridgutters },
//       { path: "form-input-groups", component: FormInput },
//       { path: "form-select", component: Formselect },
//       { path: "form-mask", component: Formmask },
//       { path: "form-fileupload", component: Formfileupload },
//       { path: "form-horizontal", component: FormHorizontal },
//       { path: "form-vertical", component: Formvertical },
//       { path: "form-floating-labels", component: Formfloatinglabels },
//       { path: "form-validation", component: Formvalidation },
//       { path: "form-select2", component: Formselect2 },
//       { path: "form-wizard", component: Formwizard },
//     ]
//   },
//   {
//     path: '/administration',
//     component: Administration,
//     children: [
//       {path: '', redirect: '/administration/assets'},
//       {path: 'assets', component: Assets},
//       {path: 'knowledgebase', component: Knowledgebase},
//       {path: 'knowledgebase-view', component: Knowledgebase_View},
//       {path: 'users', component: Users},
//     ]
//   },
//   {
//     path: '/job',
//     component: Job,
//     children: [
//       {path: '', redirect: '/job/user-dashboard'},
//       {path: 'user-dashboard', component: UserDashboard},
//       {path: 'user-all-jobs', component: UserAllJobs},
//       {path: 'applied-jobs', component: AppliedJobs},
//       {path: 'saved-jobs', component: SavedJobs},
//       {path: 'visited-jobs', component: VisitedJobs},
//       {path: 'archived-jobs', component: ArchivedJobs},
//       {path: 'offered-jobs', component: OfferedJobs},
//       {path: 'offer-approvals', component: Offer_Approvals},
//       {path: 'interviewing', component: Interviewing},
//       {path: 'job-aptitude', component: JobAptitude},
//       {path: 'questions', component: Questions},
//       {path: 'jobs-dashboard', component: JobsDashboard},
//       {path: 'job-view', component: Job_View},
//       {path: 'job-list', component: Job_List},
//       {path: 'job-applicants', component: Job_Applicants},
//       {path: 'job-details', component: Job_Details},
//       {path: 'manage-resumes', component: Manage_Resumes},
//       {path: 'shortlist-candidates', component: Shortlist_Candidates},
//       {path: 'interview-questions', component: Interview_Questions},
//       {path: 'experiance-level', component: Experiance_Level},
//       {path: 'candidates', component: Candidates},
//       {path: 'jobs', component: Jobs},
//       {path: 'schedule-timing', component: Schedule_Timing},
//       {path: 'apptitude-result', component: Apptitude_Result},
//     ]
//   },
//
//   {
//     path: '/dashboard',
//     component: Dashboard,
//     children: [
//       {path: '', redirect: '/dashboard/admin-dashboard'},
//       {path: 'admin-dashboard', component: AdminDashboard,name:'dashboard',},
//       {path: 'employee-dashboard', component: EmployeeDashboard},
//       {path: 'deals-dashboard', component: DealsDashboard},
//       {path: 'leads-dashboard', component: LeadsDashboard}
//     ]
//   },
//   {
//     path: '/crm',
//     component: Crm,
//     children: [
//       {path: '', redirect: '/crm/contact-list'},
//       {path: 'contact-list', component: ContactList},
//       {path: 'analytics', component: Analytics},
//       {path: 'activities', component: CrmActivities},
//       {path: 'contact-grid', component: ContactGrid},
//       {path: 'contact-details', component: ContactDetails},
//       {path: 'pipeline', component: Pipeline},
//       {path: 'companies-list', component: CompaniesList},
//       {path: 'companies-grid', component: CompaniesGrid},
//       {path: 'company-details', component: CompanyDetails},
//       {path: 'leads', component: Crm_Leads},
//       {path: 'leads-kanban', component: LeadsKanban},
//       {path: 'leads-details', component: LeadsDetails},
//       {path: 'deals', component: Crm_Deals},
//       {path: 'deals-kanban', component: DealsKanban},
//       {path: 'deals-details', component: DealsDetails},
//     ]
//   },
//   {
//     path: '/apps',
//     component: Apps,
//     children: [
//       {path: '', redirect: '/apps/chat'},
//       {path: 'chat', component: Chat},
//       {path: 'events', component: Events},
//       {path: 'contacts', component: Contacts},
//       {path: 'inbox', component: Inbox},
//       {path: 'file-manager', component: FileManager},
//       {path: 'mail-view', component: MailView},
//     ]
//   },
//   {
//     path: '/calls',
//     component: Calls,
//     children: [
//       {path: '', redirect: '/calls/voice-call'},
//       {path: 'voice-call', component: VoiceCall},
//       {path: 'video-call', component: VideoCall},
//       {path: 'outgoing-call', component: OutgoingCall},
//       {path: 'incoming-call', component: IncomingCall},
//     ]
//   },
//   {
//     path: '/compose',
//     name: 'compose',
//     component: Compose
//   },
//   {
//     path: '/under-maintenance',
//     name: 'under-maintenance',
//     component: UnderMaintenance
//   },
//   {
//     path: '/coming-soon',
//     name: 'coming-soon',
//     component: ComingSoon
//   },
//   {
//     path: '/employee',
//     component: Employee,
//     children: [
//       {path: '', redirect: '/employee/employees'},
//       {path: 'employees', component: Employees },
//       {path: 'employee-list', component: EmployeesList },
//       {path: 'holidays', component: Holidays },
//       {path: 'leaves', component: Leaves },
//       {path: 'leaves-employee', component: LeavesEmployee },
//       {path: 'leave-settings', component: LeaveSettings },
//       {path: 'attendance', component: Attendance },
//       {path: 'attendance-employee', component: AttendanceEmployee },
//       {path: 'departments', component: Departments },
//       {path: 'designations', component: Designations },
//       {path: 'timesheet', component: Timesheet },
//       {path: 'shift-list', component: ShiftList },
//       {path: 'overtime', component: Overtime },
//       {path: 'shift-scheduling', component: ShiftScheduling}
//     ]
//   },
//   {
//     path: '/sales',
//     component: Sales,
//     children: [
//       {path: '', redirect: '/sales/estimates'},
//       {path: 'estimates', component: Estimates},
//       {path: 'edit-estimate', component: EstimateView},
//       {path: 'estimate-view', component: EditEstimate},
//       {path: 'create-estimate', component: CreateEstimate},
//       {path: 'invoices', component: Invoices},
//       {path: 'edit-invoice', component: EditInvoice},
//       {path: 'invoice-view', component: InvoiceView},
//       {path: 'create-invoice', component: CreateInvoice},
//       {path: 'payments', component: Payments},
//       {path: 'expenses', component: Expenses},
//       {path: 'provident-fund', component: ProvidentFund},
//       {path: 'taxes', component: Taxes},
//     ]
//   },
//   {
//     path: '/accounting',
//     component: Accounting,
//     children: [
//       {path: '', redirect: '/accounting/categories'},
//       {path: 'categories', component: Categories },
//       {path: 'sub-category', component: SubCategory },
//       {path: 'budgets', component: Budgets },
//       {path: 'budget-expenses', component: BudgetExpenses },
//       {path: 'budget-revenues', component: Budgetrevenues },
//     ]
//   },
//   {
//     path: '/payroll',
//     component: Payroll,
//     children: [
//       {path: '', redirect: '/payroll/salary' },
//       {path: 'salary', component: Salary },
//       {path: 'salary-view', component: Salaryview },
//       {path: 'payroll-items', component: Payrollitems },
//     ]
//   },
//   {
//     path: '/reports',
//     component: Reports,
//     children: [
//       {path: '', redirect: '/reports/expense-reports'},
//       {path: 'expense-reports', component: Expensereports },
//       {path: 'invoice-reports', component: Invoicereports },
//       {path: 'payments-reports', component: Paymentsreports },
//       {path: 'project-reports', component: Projectreports },
//       {path: 'task-reports', component: Taskreports },
//       {path: 'user-reports', component: Userreports },
//       {path: 'employee-reports', component: Employeereports },
//       {path: 'payslip-reports', component: Payslipreports },
//       {path: 'attendance-reports', component: Attendancereports },
//       {path: 'leave-reports', component: Leavereports },
//       {path: 'daily-reports', component: Dailyreports },
//     ]
//   },
//   {
//     path: '/performance',
//     component: Performance ,
//     children: [
//       {path: '', redirect: '/performance/performance-review'},
//       {path: 'performance-review', component: PerformanceReview },
//       {path: 'performance-indicator', component: Performance_Indicator },
//       {path: 'performance-appraisal', component: Performance_Appraisal }
//     ]
//   },
//   {
//     path: '/goals',
//     component: Goals,
//     children: [
//       {path: '', redirect: '/goals/goal-tracking'},
//       {path: 'goal-tracking', component: Goal_Tracking },
//       {path: 'goal-type', component: Goal_Type }
//     ]
//   },
//   {
//     path: '/training',
//     component: Training,
//     children: [
//       {path: '', redirect: '/training/training-list' },
//       {path: 'training-list', component: TrainingList },
//       {path: 'trainers', component: Trainers },
//       {path: 'training-type', component: Training_Type}
//     ]
//   },
//   {
//     path: '/project',
//     component: Project,
//     children: [
//       {path: '', redirect: '/project/projects' },
//       {path: 'projects', component: Projects},
//       {path: 'project-view', component: ProjectView},
//       {path: 'project-list', component: ProjectList},
//       {path: 'task-board', component: TaskBoard },
//       {path: 'tasks', component: Tasks }
//     ]
//   },
//   {
//     path: '/ticket',
//     component: Ticket,
//     children: [
//       {path: '', redirect: '/ticket/tickets'},
//       {path: 'tickets', component: Tickets },
//       {path: 'ticket-details', component: TicketDetails}
//     ]
//   },
//   {
//     path: '/profile',
//     component: Profile,
//     children: [
//       {path: '', redirect: '/profile/employee-profile'},
//       {path: 'employee-profile', component: EmployeeProfile },
//       {path: 'client-profile', component: Clientprofile }
//     ]
//   },
//   {
//     path: '/subscription',
//     component: Subscription,
//     children: [
//       {path: '', redirect: '/subscription/subscriptons'},
//       {path: 'subscriptions', component: Subscriptions },
//       {path: 'subscriptions-company', component: Subscriptions_Company},
//       {path: 'subscribed-companies', component: Subscribedcompanies }
//
//     ]
//   },
//   {
//     path: '/clients',
//     name: 'clients',
//     component: Clients
//   },
//   {
//     path: '/otp',
//     name: 'otp',
//     component: OTP
//   },
//   {
//     path: '/register',
//     name: 'register',
//     component: Register
//   },
//   {
//     path: '/forgot-password',
//     name: 'forgot-password',
//     component: Forgot_Password
//   },
//   {
//     path: '/lock-screen',
//     name: 'lock-screen',
//     component: Lock_Screen
//   },
//   {
//     path: '/error-500',
//     name: 'error-500',
//     component: Error_500
//   },
//   {
//     path: '/error-404',
//     name: 'error-404',
//     component: Error_404
//   },
//   {
//     path: '/settings',
//     name: 'settings',
//     component: Settings
//   },
//   {
//     path: '/localization',
//     name: 'localization',
//     component: Localization
//   },
//   {
//     path: '/theme-settings',
//     name: 'theme-settings',
//     component: Theme_Settings
//   },
//   {
//     path: '/email-settings',
//     name: 'email-settings',
//     component: Email_Settings
//   },
//   {
//     path: '/approval-setting',
//     name: 'approval-setting',
//     component: Approval_Setting
//   },
//   {
//     path: '/roles-permissions',
//     name: 'roles-permissions',
//     component: Roles_Permissions
//   },
//   {
//     path: '/invoice-settings',
//     name: 'invoice-settings',
//     component: Invoice_Settings
//   },
//   {
//     path: '/salary-settings',
//     name: 'salary-settings',
//     component: Salary_Settings
//   },
//   {
//     path: '/notifications-settings',
//     name: 'notifications-settings',
//     component: Notifications_Settings
//   },
//   {
//     path: '/change-password',
//     name: 'change-password',
//     component: Change_Password
//   },
//   {
//     path: '/leave-type',
//     name: 'leave-type',
//     component: LeaveType
//   },
//   {
//     path: '/toxbox-setting',
//     name: 'toxbox-setting',
//     component: Toxbox_Setting
//   },
//   {
//     path: '/cron-setting',
//     name: 'cron-setting',
//     component: Cron_Setting
//   },
//   {
//     path: '/clients-list',
//     name: 'clients-list',
//     component: Clientslist
//   },
//   {
//     path: '/leads',
//     name: 'leads',
//     component: Leads
//   },
//   {
//     path: '/termination',
//     name: 'termination',
//     component: Termination
//   },
//   {
//     path: '/resignation',
//     name: 'resignation',
//     component: Resignation
//   },
//   {
//     path: '/promotion',
//     name: 'promotion',
//     component: Promotion
//   },
//   {
//     path: '/assets-details',
//     name: 'assets-details',
//     component: Assets_Details
//   },
//   {
//     path: '/user-asset-details',
//     name: 'user-asset-details',
//     component: User_Asset_Details
//   },
//   {
//     path: '/performance-setting',
//     name: 'performance-setting',
//     component: Performance_Setting
//   },
// ];

const routes = [

  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: AdminDashboard
  },
  {
    path: '/crm',
    component: Crm,
    children: [
      {path: '', redirect: '/crm/contact-list'},
      {path: 'organizations', component: Crm_Organization},
      {path: 'companies', component: Crm_Companies},
      {path: 'accounts', component: Crm_CompanyAccounts},
      {path: 'categories', component: Crm_Categories},
      {path: 'services', component: Crm_Services},
      {path: 'customers-suppliers', component: Crm_Customers},
      {path: 'customers-suppliers/requisites', component: Crm_Requisites},
      {path: 'customers-suppliers/contracts', component: Crm_Contracts},
      {path: 'invoices', component: Crm_Invoices},
      {path: 'transactions', component: Crm_Transactions},
      {path: 'transfers', component: Crm_Transfers},
      {path: 'reports/debitor', component: Crm_Debitor},
      {path: 'reports/creditor', component: Crm_Creditor},
      {path: 'reports/summary', component: Crm_Report},
      {path: 'contact-list', component: ContactList},
      {path: 'analytics', component: Analytics},
      {path: 'activities', component: CrmActivities},
      {path: 'contact-grid', component: ContactGrid},
      {path: 'contact-details', component: ContactDetails},
      {path: 'pipeline', component: Pipeline},
      {path: 'companies-list', component: CompaniesList},
      {path: 'companies-grid', component: CompaniesGrid},
      {path: 'company-details', component: CompanyDetails},
      {path: 'leads', component: Crm_Leads},
      {path: 'leads-kanban', component: LeadsKanban},
      {path: 'leads-details', component: LeadsDetails},
      {path: 'deals', component: Crm_Deals},
      {path: 'deals-kanban', component: DealsKanban},
      {path: 'deals-details', component: DealsDetails},
    ]
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgot_Password
  },
  {
    path: '/error-500',
    name: 'error-500',
    component: Error_500
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: Error_404
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/theme-settings',
    name: 'theme-settings',
    component: Theme_Settings
  },
  {
    path: '/email-settings',
    name: 'email-settings',
    component: Email_Settings
  },
  {
    path: '/approval-setting',
    name: 'approval-setting',
    component: Approval_Setting
  },
  {
    path: '/roles-permissions',
    name: 'roles-permissions',
    component: Roles_Permissions
  },
  {
    path: '/invoice-settings',
    name: 'invoice-settings',
    component: Invoice_Settings
  },
  {
    path: '/salary-settings',
    name: 'salary-settings',
    component: Salary_Settings
  },
  {
    path: '/notifications-settings',
    name: 'notifications-settings',
    component: Notifications_Settings
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: Change_Password
  },
];

export const router = createRouter({
  history: createWebHistory(),
    linkActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to top smoothly on route change
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const authStore = useAuthStore();
  if (to.name === 'login') {
    if (authStore.token && authStore.token != undefined) {
      next({ name: 'dashboard' });
    } else {
      next();
    }
  } else {
    if (!authStore.token) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});
