<template>
  <!-- Add Designation Modal -->
  <div id="add_designation" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Designation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Designation Name <span class="text-danger">*</span></label
              >
              <input class="form-control" type="text" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Department <span class="text-danger">*</span></label
              >
              <vue-select
                :options="Department"
                id="marketing"
                placeholder="Select Department"
              />
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Designation Modal -->

  <!-- Edit Designation Modal -->
  <div id="edit_designation" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Designation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Designation Name <span class="text-danger">*</span></label
              >
              <input class="form-control" value="Web Developer" type="text" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Department <span class="text-danger">*</span></label
              >
              <vue-select
                :options="Department"
                id="departmentid"
                placeholder="Select Department"
              />
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Yadda saxla</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Designation Modal -->

  <!-- Delete Designation Modal -->
  <div class="modal custom-modal fade" id="delete_designation" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Designation</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Designation Modal -->
</template>

<script>
export default {
  data() {
    return {
      select2: null,
      Department: ["Select Department", "Web Development", "IT Management", "Marketing"],
    };
  },
};
</script>
