import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useServices = defineStore('services', {
    state: () => ({
        dataServices: null,
        dataService: null,
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['services'],{...payload})
                .then((res) => {
                    this.dataServices = res;

                    return res;
                }).finally(() => {

                });
        },
        async getDetail( id ){
            try {
                await axiosInstance.get(`${urlList['services']}/${id}`).then((res)=>{
                    this.dataService = res.data;
                    return res;
                });
            } catch (error) {
                throw error;
            }
        },
        async add(formData ) {
            try {
                return await axiosInstance.post(urlList['services'], formData).then( (res) => {
                    return res;
                });;
            } catch (error) {
                console.error('Error:', response.message);

                throw error;
            }
        },
        async update(formData , id ) {
            try {
                return axiosInstance.put(`${urlList['services']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData , id ) {
            try {
                return axiosInstance.put(`${urlList['services']}/update/${id}`, statusData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id ) {
            try {
                const response = await axiosInstance.delete(`${urlList['services']}/${id}`);

                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        },
    },
    getters: {}
});
