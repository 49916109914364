<template>
  <div class="tab-pane fade" id="notes">
    <div class="view-header">
      <h4>Notes</h4>
      <ul>
        <li>
          <div class="form-sort">
            <i class="las la-sort-amount-up-alt"></i>
            <vue-select
              :options="SortDateOne"
              id="sortdateone"
              placeholder="Sort By Date"
            />
          </div>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            data-bs-toggle="modal"
            data-bs-target="#add_notes"
            class="com-add"
            ><i class="las la-plus-circle me-1"></i>Add New</a
          >
        </li>
      </ul>
    </div>
    <div class="notes-activity">
      <div class="calls-box">
        <div class="caller-info">
          <div class="calls-user">
            <img src="@/assets/img/avatar/avatar-19.jpg" alt="img" />
            <div>
              <h6>Darlee Robertson</h6>
              <p>15 Sep 2023, 12:10 pm</p>
            </div>
          </div>
          <div class="calls-action">
            <div class="dropdown action-drop">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="feather-more-vertical"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);"
                  ><i class="las la-edit me-1"></i>Edit</a
                >
                <a class="dropdown-item" href="javascript:void(0);"
                  ><i class="las la-trash me-1"></i>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <h5>Notes added by Antony</h5>
        <p>
          A project review evaluates the success of an initiative and identifies areas for
          improvement. It can also evaluate a current project to determine whether it's on
          the right track. Or, it can determine the success of a completed project.
        </p>
        <ul>
          <li>
            <div class="note-download">
              <div class="note-info">
                <span class="note-icon bg-success">
                  <i class="las la-file-excel"></i>
                </span>
                <div>
                  <h6>Project Specs.xls</h6>
                  <p>365 KB</p>
                </div>
              </div>
              <a href="javascript:void(0);"><i class="las la-download"></i></a>
            </div>
          </li>
          <li>
            <div class="note-download">
              <div class="note-info">
                <span class="note-icon">
                  <img src="@/assets/img/media/media-35.jpg" alt="img" />
                </span>
                <div>
                  <h6>090224.jpg</h6>
                  <p>365 KB</p>
                </div>
              </div>
              <a href="javascript:void(0);"><i class="las la-download"></i></a>
            </div>
          </li>
        </ul>
        <div class="notes-editor">
          <div class="note-edit-wrap">
            <div class="summernote">
              Write a new comment, send your team notification by typing @ followed by
              their name
            </div>
            <div class="text-end note-btns">
              <a href="javascript:void(0);" class="btn btn-lighter add-cancel">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary">Save</a>
            </div>
          </div>
          <div class="text-end">
            <a href="javascript:void(0);" class="add-comment"
              ><i class="las la-plus-circle me-1"></i>Add Comment</a
            >
          </div>
        </div>
      </div>
      <div class="calls-box">
        <div class="caller-info">
          <div class="calls-user">
            <img src="@/assets/img/avatar/avatar-20.jpg" alt="img" />
            <div>
              <h6>Sharon Roy</h6>
              <p>18 Sep 2023, 09:52 am</p>
            </div>
          </div>
          <div class="calls-action">
            <div class="dropdown action-drop">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="feather-more-vertical"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);"
                  ><i class="las la-edit me-1"></i>Edit</a
                >
                <a class="dropdown-item" href="javascript:void(0);"
                  ><i class="las la-trash me-1"></i>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <h5>Notes added by Antony</h5>
        <p>
          A project plan typically contains a list of the essential elements of a project,
          such as stakeholders, scope, timelines, estimated cost and communication
          methods. The project manager typically lists the information based on the
          assignment.
        </p>
        <ul>
          <li>
            <div class="note-download">
              <div class="note-info">
                <span class="note-icon bg-success">
                  <i class="las la-file-excel"></i>
                </span>
                <div>
                  <h6>Andrewpass.txt</h6>
                  <p>365 KB</p>
                </div>
              </div>
              <a href="javascript:void(0);"><i class="las la-download"></i></a>
            </div>
          </li>
        </ul>
        <div class="reply-box">
          <p>
            The best way to get a project done faster is to start sooner. A goal without a
            timeline is just a dream.The goal you set must be challenging. At the same
            time, it should be realistic and attainable, not impossible to reach.
          </p>
          <p>
            Commented by <span class="text-primary">Aeron</span> on 15 Sep 2023, 11:15 pm
          </p>
        </div>
        <div class="notes-editor">
          <div class="note-edit-wrap">
            <div class="summernote">
              Write a new comment, send your team notification by typing @ followed by
              their name
            </div>
            <div class="text-end note-btns">
              <a href="javascript:void(0);" class="btn btn-lighter add-cancel">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary">Save</a>
            </div>
          </div>
          <div class="text-end">
            <a href="javascript:void(0);" class="add-comment"
              ><i class="las la-plus-circle me-1"></i>Add Comment</a
            >
          </div>
        </div>
      </div>
      <div class="calls-box">
        <div class="caller-info">
          <div class="calls-user">
            <img src="@/assets/img/avatar/avatar-21.jpg" alt="img" />
            <div>
              <h6>Vaughan</h6>
              <p>20 Sep 2023, 10:26 pm</p>
            </div>
          </div>
          <div class="calls-action">
            <div class="dropdown action-drop">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="feather-more-vertical"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);"
                  ><i class="las la-edit me-1"></i>Edit</a
                >
                <a class="dropdown-item" href="javascript:void(0);"
                  ><i class="las la-trash me-1"></i>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <p>
          Projects play a crucial role in the success of organizations, and their
          importance cannot be overstated. Whether it's launching a new product, improving
          an existing
        </p>
        <div class="notes-editor">
          <div class="note-edit-wrap">
            <div class="summernote">
              Write a new comment, send your team notification by typing @ followed by
              their name
            </div>
            <div class="text-end note-btns">
              <a href="javascript:void(0);" class="btn btn-lighter add-cancel">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary">Save</a>
            </div>
          </div>
          <div class="text-end">
            <a href="javascript:void(0);" class="add-comment"
              ><i class="las la-plus-circle me-1"></i>Add Comment</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      SortDateOne: ["Sort By Date", "Ascending", "Descending"],
    };
  },
};
</script>
