<template>
  <!-- Success Contact -->
  <div class="modal custom-modal fade" id="success_msg" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="la la-user-shield"></i>
            </div>
            <h3>Contact Created Successfully!!!</h3>
            <p>View the details of contact</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a
                href="javascript:void(0);"
                class="button btn-lights"
                data-bs-dismiss="modal"
                >Close</a
              >
              <router-link to="/crm/contact-details" class="btn btn-primary"
                >View Details</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Success Contact -->

  <!-- Delete Contact -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Contact ”Sharron Roy” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a
                href="javascript:void(0);"
                class="button btn-lights"
                data-bs-dismiss="modal"
                >Not Now</a
              >
              <router-link to="/crm/contact-list" class="btn btn-primary"
                >Okay</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Contact -->

  <!-- Export -->
  <div class="modal custom-modal fade modal-padding" id="export" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Export</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/contact-list">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <h5 class="mb-3">Export</h5>
                  <div class="status-radio-btns d-flex">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="pdf"
                        name="export-type"
                        checked
                      />
                      <label for="pdf">Person</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="excel"
                        name="export-type"
                      />
                      <label for="excel">Organization</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h4 class="mb-3">Filters</h4>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Fields <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Fields" id="addfields" placeholder="All Fields" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <input class="form-control floating datetimepicker" type="text" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <input class="form-control floating datetimepicker" type="text" />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Export Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Export -->

  <!-- Add Note -->
  <div class="modal custom-modal fade modal-padding" id="add_notes" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-header header-border align-items-center justify-content-between p-0"
        >
          <h5 class="modal-title">Add Note</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="javascript:;">
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Title <span class="text-danger"> *</span></label
              >
              <input class="form-control" type="text" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Note <span class="text-danger"> *</span></label
              >
              <textarea class="form-control" rows="4" placeholder="Add text"></textarea>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Attachment <span class="text-danger"> *</span></label
              >
              <div class="drag-upload">
                <input type="file" />
                <div class="img-upload">
                  <i class="las la-file-import"></i>
                  <p>Drag & Drop your files</p>
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Uploaded Files</label>
              <div class="upload-file">
                <h6>Projectneonals teyys.xls</h6>
                <p>4.25 MB</p>
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 25%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p>45%</p>
              </div>
              <div class="upload-file upload-list">
                <div>
                  <h6>Projectneonals teyys.xls</h6>
                  <p>4.25 MB</p>
                </div>
                <a href="javascript:void(0);" class="text-danger"
                  ><i class="las la-trash"></i
                ></a>
              </div>
            </div>
            <div class="col-lg-12 text-end form-wizard-button">
              <button class="button btn-lights reset-btn" type="reset">Reset</button>
              <button class="btn btn-primary" type="submit">Save Notes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Note -->
</template>

<script>
export default {
  data() {
    return {
      Fields: ["All Fields", "contact", "Company"],
    };
  },
};
</script>
