<template>
  <nav class="greedys sidebar-horizantal">
    <ul class="list-inline-item list-unstyled links">
      <template v-for="item in sideBarData" :key="item.tittle">
        <li class="menu-title">
          <span>{{ item.tittle }}</span>
        </li>
        <template v-for="menu in item.menu" :key="menu.menuValue">
          <li v-if="!menu.hasSubRoute && menu.menuValue === 'Clients'">
            <router-link :to="menu.route">
              <i :class="'la la-' + menu.icon"></i>
              <span>{{ menu.menuValue }}</span>
            </router-link>
          </li>
          <li
            class="submenu"
            v-else-if="
              menu.hasSubRoute &&
              (menu.menuValue === 'Dashboard' ||
                menu.menuValue === 'Apps' ||
                menu.menuValue === 'Employees' ||
                menu.menuValue === 'Inventory' ||
                menu.menuValue === 'Projects' ||
                menu.menuValue === 'CRM' ||
                menu.menuValue === 'Tickets' ||
                menu.menuValue === 'Sales')
            "
            @click="expandSubMenus(menu)"
          >
            <a href="javascript:void(0)" :class="{ subdrop: menu.showSubRoute }">
              <i :class="'la la-' + menu.icon"></i>
              <span>{{ menu.menuValue }}</span>
              <span class="menu-arrow"></span>
            </a>
            <ul :class="{ 'd-block': menu.showSubRoute, 'd-none': !menu.showSubRoute }">
              <template v-for="subMenu in menu.subMenus" :key="subMenu.menuValue">
                <li>
                  <router-link :to="subMenu.route">{{ subMenu.menuValue }}</router-link>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </template>
    </ul>

    <button @click="openMoreMenus" class="viewmoremenu">
      {{ showMoreMenu ? "Less Menu" : "More Menu" }}
    </button>
    <!-- /Settings -->
    <ul class="hidden-links" :class="{ hidden: showMoreMenu === false }">
      <template v-for="item in sideBarData" :key="item.tittle">
        <li class="menu-title">
          <span>{{ item.tittle }}</span>
        </li>
        <template v-for="menu in item.menu" :key="menu.menuValue">
          <li
            class="submenu"
            v-if="menu.hasSubRoute"
            :hidden="
              menu.menuValue === 'Employees' ||
              menu.menuValue === 'Dashboard' ||
              menu.menuValue === 'Apps' ||
              menu.menuValue === 'Employees' ||
              menu.menuValue === 'Clients' ||
              menu.menuValue === 'Projects' ||
              menu.menuValue === 'CRM' ||
              menu.menuValue === 'Tickets' ||
              menu.menuValue === 'Sales'
            "
            @click="expandSubMenus(menu)"
          >
            <a href="javascript:void(0)" :class="{ subdrop: menu.showSubRoute }">
              <i :class="'la la-' + menu.icon"></i>
              <span>{{ menu.menuValue }}</span>
              <span class="menu-arrow"></span>
            </a>
            <ul :class="{ 'd-block': menu.showSubRoute, 'd-none': !menu.showSubRoute }">
              <template v-for="subMenu in menu.subMenus" :key="subMenu.menuValue">
                <li>
                  <router-link :to="subMenu.route">{{ subMenu.menuValue }}</router-link>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </template>
    </ul>
  </nav>
</template>

<script>
import sideBarData from "@/assets/json/sidebar-menuone.json";
export default {
  data() {
    return {
      sideBarData: sideBarData,
      route_array: [],
      showMoreMenu: false,
    };
  },
  methods: {
    expandSubMenus(menu) {
      this.sideBarData.forEach((item) => {
        item.menu.forEach((subMenu) => {
          if (subMenu !== menu) {
            subMenu.showSubRoute = false;
          }
        });
      });
      menu.showSubRoute = !menu.showSubRoute;
    },
    openMoreMenus() {
      this.showMoreMenu = !this.showMoreMenu;
    },
  },
};
</script>

<style>
/* Add your styles here */
</style>
