<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="editDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Məlumatların yenilənməsi</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Valyuta </label>

                    <input
                      v-model="formData.currency"
                      class="form-control"
                      type="text"
                      name="currency"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Benefisiarın bank </label>

                    <input
                      v-model="formData.bank_name"
                      class="form-control"
                      type="text"
                      name="bank_name"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Müştəri kodu </label>
                    <input
                      v-model="formData.account_code"
                      class="form-control"
                      type="text"
                      name="account_code"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Filialın kodu </label>

                    <input
                      v-model="formData.branch_code"
                      class="form-control"
                      type="text"
                      name="branch_code"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Bank VÖEN </label>

                    <input
                      v-model="formData.bank_voen"
                      class="form-control"
                      type="text"
                      name="bank_voen"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> SWIFT kod </label>

                    <input
                      v-model="formData.swift_code"
                      class="form-control"
                      type="text"
                      name="swift_code"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Müxbir hesab </label>

                    <input
                      v-model="formData.correspondent_account"
                      class="form-control"
                      type="text"
                      name="correspondent_account"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Ünvan</label>
                    <input
                      v-model="formData.address"
                      class="form-control"
                      type="text"
                      name="address"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Telefon </label>

                    <input
                      v-model="formData.phone"
                      class="form-control"
                      type="text"
                      name="phone"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> E-mail </label>

                    <input
                      v-model="formData.email"
                      class="form-control"
                      type="email"
                      name="email"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button class="button btn-lights reset-btn" type="reset">
                    Sıfırla
                  </button>
                  <button class="btn btn-primary" type="submit">
                    Yadda saxla
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  decimal,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useCustomerRequisite } from "@/stores/modules/requisites";
import { useRoute } from "vue-router";

const props = defineProps({
  id:{
    type: Number,
    required: true
  },
});

const requisiteStore = useCustomerRequisite();
const route = useRoute();

const customerId = route.query.customerId || 0;

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const formData = reactive({
  customer_id: 0,
  currency: "",
  branch_code: "",
  bank_name: "",
  bank_voen: "",
  swift_code: "",
  correspondent_account: "",
  account_code: "",
  address: "",
  phone: "",
  email: "",
  type_id: 0,
  status: 1,
});

const rules = {
  formData: {
  },
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.customer_id = 0;
  formData.currency = "";
  formData.branch_code = "";
  formData.bank_name = "";
  formData.bank_voen = "";
  formData.voen = "";
  formData.swift_code = "";
  formData.correspondent_account = "";
  formData.account_code = "";
  formData.address = "";
  formData.phone = "";
  formData.email = "";
  formData.type_id = 0;
  formData.status = 1;
};

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  formData.customer_id = customerId;
  try {
    await requisiteStore.update(formData, props.id).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("editDataModal");
    });
  } catch (error) {
    console.error(error)
  }
};

const addLine = () => {
  formData.contact_list.push({
    full_name: null,
    email: null,
    phone_number: null,
    position: null,
  });
};

const removeLine = (index) => {
  formData.contact_list.splice(index, 1);
};

watch(() => props.id, async (newId) => {
  try {
    if (newId) {
      await requisiteStore.getDetail(newId);
      Object.assign(formData, requisiteStore.dataRequisite.data);
    }
  } catch (error) {
    console.error(error)
  }
});
</script>

  