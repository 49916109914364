<template>
  <div
    class="modal custom-modal fade modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Yeni qaimə</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-4">
                  <div class="input-block mb-3">
                    <label class="d-block">Tipi</label>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type"
                        id="customer"
                        value="0"
                        v-model="formData.type"
                        checked
                      />
                      <label class="form-check-label" for="customer"
                        >Müştəriyə</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type"
                        id="supplier"
                        value="1"
                        v-model="formData.type"
                      />
                      <label class="form-check-label" for="supplier"
                        >Təchizatçıya</label
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Şirkət <span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :options="props.companiesOptionsList"
                      name="company_id"
                      :disabled="props.companiesOptionsList.length == 0"
                      id="companyAdd"
                      v-model="formData.company_id"
                      @update:modelValue="companyChanged"
                      :placeholder="
                        props.companiesOptionsList.length > 0
                          ? ''
                          : 'Şirkət tapılmadı'
                      "
                      :class="{
                        'is-invalid': $v.formData.company_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.company_id.$error,
                      }"
                    />
                    <template
                      v-if="isSubmitForm && $v.formData.company_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Hesab <span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :options="accountsOptionsList"
                      name="account_id"
                      :disabled="accountsOptionsList.length == 0"
                      id="accountAdd"
                      v-model="formData.account_id"
                      :placeholder="
                        formData.company_id !== 0 &&
                        accountsOptionsList.length === 0
                          ? 'Hesab tapılmadı'
                          : ''
                      "
                      :class="{
                        'is-invalid': $v.formData.account_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.account_id.$error,
                      }"
                    />
                    <template
                      v-if="isSubmitForm && $v.formData.account_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      {{ formData.type == 1 ? "Təchizatçı" : "Müştəri" }}
                      <span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :options="customersOptionsList"
                      name="customer_id"
                      :disabled="customersOptionsList.length == 0"
                      id="customerAdd"
                      v-model="formData.customer_id"
                      @update:modelValue="customerChanged"
                      :placeholder="
                        formData.company_id !== 0 &&
                        customersOptionsList.length === 0
                          ? `${
                              formData.type == 1 ? 'Təchizatçı' : 'Müştəri'
                            } tapılmadı`
                          : ''
                      "
                      :class="{
                        'is-invalid': $v.formData.customer_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.customer_id.$error,
                      }"
                    />
                    <template
                      v-if="isSubmitForm && $v.formData.customer_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Müqavilə </label>

                    <vue-select
                      :options="contractOptionsList"
                      name="contract_id"
                      :disabled="contractOptionsList.length == 0"
                      id="contractAdd"
                      v-model="formData.contract_id"
                      @update:modelValue="contractChanged"
                      :placeholder="
                        formData.customer_id !== 0 &&
                        contractOptionsList.length === 0
                          ? 'Aktiv müqavilə tapılmadı'
                          : ''
                      "
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tarixi <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <flat-pickr
                          :config="dateTime"
                          placeholder="il-ay-gün"
                          v-model="formData.date"
                          :class="{
                            'is-invalid': $v.formData.date.$error,
                            'is-valid': isSubmitForm && !$v.formData.date.$error,
                          }"
                          id="dateAdd" class="form-control">
                      </flat-pickr>
                    </div>
                    <template v-if="isSubmitForm && $v.formData.date.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Qeyd </label>

                    <textarea
                      v-model="formData.note"
                      class="form-control"
                      type="text"
                      name="note"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Əlavə qeyd </label>

                    <textarea
                      v-model="formData.additional_note"
                      class="form-control"
                      type="text"
                      name="note"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <table class="table table-borderless table-auto">
                      <thead>
                        <tr>
                          <th>Xidmət <span class="text-danger">*</span></th>
                          <th>Miqdar <span class="text-danger">*</span></th>
                          <th>Məbləğ <span class="text-danger">*</span></th>
                          <th>
                            Ölçü vahidi <span class="text-danger">*</span>
                          </th>
                          <th class="mw-20r">Qeyd</th>
                          <th>Toplam</th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="gap-3 mb-4"
                          v-for="(line, index) in formData.lines"
                          :key="index"
                        >
                          <td class="mw-15r">
                            <vue-select
                              :options="servicesOptionsList"
                              :disabled="servicesOptionsList.length == 0"
                              :id="`lineService${index}`"
                              v-model="line.service_id"
                              :placeholder="
                                servicesOptionsList.length > 0
                                  ? 'Xidmət seçin'
                                  : `Xidmət tapılmadı`
                              "
                            />
                          </td>
                          <td>
                            <input
                              class="form-control"
                              :id="`quantity${index}`"
                              v-model="line.quantity"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              class="form-control"
                              :id="`price${index}`"
                              v-model="line.price"
                              type="text"
                            />
                          </td>
                          <td>
                            <vue-select
                              :options="props.measuresOptionsList"
                              :disabled="props.measuresOptionsList.length == 0"
                              :id="`lineUnit${index}`"
                              v-model="line.measure_id"
                              :placeholder="
                                props.measuresOptionsList.length > 0
                                  ? 'Seçin'
                                  : `Ölçü vahidi tapılmadı`
                              "
                            />
                          </td>
                          <td>
                            <input
                              class="form-control"
                              :id="`note${index}`"
                              v-model="line.note"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              disabled
                              class="form-control"
                              :id="`total${index}`"
                              :value="line.price * line.quantity"
                              type="text"
                            />
                          </td>
                          <td>
                            <button
                              v-if="index > 0"
                              type="button"
                              @click="removeLine(index)"
                              class="btn btn-sm btn-outline-danger"
                            >
                              <i class="las la-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-4">
                    <div>
                      <button
                        type="button"
                        @click="addLine"
                        class="btn btn-outline-success"
                      >
                        + Xidmət
                      </button>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end my-4">
                    Ümumi məbləğ: {{ calculatedTotalPrice }} azn
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button class="button btn-lights reset-btn" type="reset">
                    Sıfırla
                  </button>
                  <button class="btn btn-primary" type="submit">
                    Yadda saxla
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch, onMounted, computed } from "vue";
import {
  helpers,
  maxValue,
  minValue,
  required,
  decimal,
} from "@vuelidate/validators";
import FlatPickr from 'vue-flatpickr-component';
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useCustomersAndSuppliers } from "@/stores/modules/customerAndSuppliers";
import { useCompanyAccounts } from "@/stores/modules/companyAccounts";
import { useServices } from "@/stores/modules/services";
import { useContracts } from "@/stores/modules/contracts";
import { useInvoicesStore } from "@/stores/modules/invoices";

const invoiceStore = useInvoicesStore();
const customerStore = useCustomersAndSuppliers();
const accountStore = useCompanyAccounts();
const serviceStore = useServices();
const contractStore = useContracts();

const props = defineProps({
  companiesOptionsList: {
    type: Array,
    default: [],
  },
  measuresOptionsList: {
    type: Array,
    default: [],
  },
});

const contractOptionsList = ref([]);
const customersOptionsList = ref([]);
const accountsOptionsList = ref([]);
const servicesOptionsList = ref([]);

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const dateTime = ref({
    dateFormat: 'Y-m-d',
    allowInput: true,
    position: 'auto right'
});

const formData = reactive({
  type: 0,
  status: 1,
  contract_id: 0,
  account_id: 0,
  note: null,
  additional_note: null,
  customer_id: 0,
  company_id: 0,
  date: new Date(),
  lines: [
    {
      service_id: 0,
      quantity: null,
      price: null,
      note: null,
      measure_id: null,
    },
  ],
});

const rules = {
  formData: {
    date: { required },
    company_id: { required },
    account_id: { required },
    customer_id: { required },
    // note: { required },
    lines: {
      $each: {
        service_id: { required },
        quantity: { required, minValue: minValue(1) },
        price: { required, minValue: minValue(0.01) },
        // note: { required }
      },
    },
  },
};

const isUnique = (value) => {
  const serviceIds = new Set();

  for (const line of formData.lines) {
    if (serviceIds.has(line.service_id)) {
      return false;
    }
    serviceIds.add(line.service_id);
  }

  return true;
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  contractOptionsList.value = [];
  customersOptionsList.value = [];
  accountsOptionsList.value = [];

  formData.type = 0;
  formData.status = 1;
  formData.company_id = 0;
  formData.contract_id = 0;
  formData.customer_id = 0;
  formData.note = null;
  formData.additional_note = null;
  formData.date = new Date();
  formData.lines = [
    {
      service_id: 0,
      quantity: null,
      price: null,
      note: null,
      measure_id: null,
    },
  ];
};

const submitForm = async () => {
  isSubmitForm.value = true;

  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await invoiceStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("addDataModal");
    });
  } catch (error) {
    console.log(error);
  }
};

const addLine = () => {
  formData.lines.push({
    service_id: 0,
    quantity: null,
    price: null,
    note: null,
    measure_id: null,
  });
};

const removeLine = (index) => {
  formData.lines.splice(index, 1);
};

const companyChanged = () => {
  createAccountList();
  createCustomerList();
};

const customerChanged = () => {
  createContractList();
};

const contractChanged = () => {
  let contractObj = contractStore.dataList.data.data.map((item) => item.id === formData.contract_id);

  if(contractObj){
    formData.note = `Müqavilə №: ${contractObj.code}`
  }
}

const createAccountList = () => {
  accountStore.getList({
    params: {
      company_id: formData.company_id,
      limit: 10000,
    },
  }).then(()=>{
    accountsOptionsList.value = accountStore.dataCompanyAccounts.data.data.map(
    (item) => ({
      id: item.id,
      text: item.name,
    })
  );
  });
};


const createCustomerList = () => {
  const params = {
    active_contract: 1,
    limit: 10000,
    ...(formData.type === 1 ? { is_supplier: 1 } : { company_id: formData.company_id, is_customer: 1 }),
  };

  customerStore.getList({ params }).then(()=>{
    customersOptionsList.value = customerStore.dataList.data.data.map(item => ({
      id: item.id,
      text: item.name,
    }));
  });
};

const createServicesList = async () => {
  const params = {
    limit: 10000,
    ...(formData.type === 1 ? { is_external: 1 } : { is_internal: 1 }),
  };

  await serviceStore.getList({ params });

  servicesOptionsList.value = serviceStore.dataServices.data.data.map(
    (item) => ({
      id: item.id,
      text: item.name,
    })
  );
};

const createContractList = async () => {
  let data = customerStore.dataList.data.data;

  let customerData = await data.find((item) => 
      item.id == formData.customer_id
  );

  contractOptionsList.value = customerData.contracts.map((item) => ({
    id: item.id,
    text: item.code,
  }));
};

onMounted(() => {
  createServicesList();
});

watch(
  () => formData.type,
  async () => {
    createCustomerList();
    createServicesList();
  }
);

const calculatedTotalPrice = computed(() => {
  let totalPrice = 0;
  for (const line of formData.lines) {
    if (line.quantity !== null && line.price !== null) {
      totalPrice += line.quantity * line.price;
    }
  }
  return totalPrice;
});
</script>

<style>
.mw-15r {
  min-width: 12rem;
}

.mw-20r {
  min-width: 20rem;
}
</style>

  