<template>
  <div class="tab-pane fade" id="files">
    <div class="view-header">
      <h4>Files</h4>
    </div>
    <div class="files-activity">
      <div class="files-wrap">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="file-info">
              <h4>Manage Documents</h4>
              <p>
                Send customizable quotes, proposals and contracts to close deals faster.
              </p>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <ul class="file-action">
              <li>
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#new_file"
                  >Create Document</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="files-wrap">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="file-info">
              <h4>Collier-Turner Proposal</h4>
              <p>
                Send customizable quotes, proposals and contracts to close deals faster.
              </p>
              <div class="file-user">
                <img src="@/assets/img/avatar/avatar-21.jpg" alt="img" />
                <div>
                  <p><span>Owner</span> Vaughan</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <ul class="file-action">
              <li>
                <span class="badge badge-soft-pink">Proposal</span>
              </li>
              <li>
                <span class="badge badge-soft-grey priority-badge"
                  ><i class="fa-solid fa-circle"></i>Low</span
                >
              </li>
              <li>
                <div class="dropdown action-drop">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="feather-more-vertical"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-edit me-1"></i>Edit</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-trash me-1"></i>Delete</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-download me-1"></i>Download</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="files-wrap">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="file-info">
              <h4>Collier-Turner Proposal</h4>
              <p>
                Send customizable quotes, proposals and contracts to close deals faster.
              </p>
              <div class="file-user">
                <img src="@/assets/img/avatar/avatar-1.jpg" alt="img" />
                <div>
                  <p><span>Owner</span> Jessica</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <ul class="file-action">
              <li>
                <span class="badge badge-soft-info">Quote</span>
              </li>
              <li>
                <span class="badge badge-soft-success priority-badge"
                  ><i class="fa-solid fa-circle"></i>Sent</span
                >
              </li>
              <li>
                <div class="dropdown action-drop">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="feather-more-vertical"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-edit me-1"></i>Edit</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-trash me-1"></i>Delete</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-download me-1"></i>Download</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="files-wrap">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="file-info">
              <h4>Collier-Turner Proposal</h4>
              <p>
                Send customizable quotes, proposals and contracts to close deals faster.
              </p>
              <div class="file-user">
                <img src="@/assets/img/avatar/avatar-22.jpg" alt="img" />
                <div>
                  <p><span>Owner</span> Vaughan</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <ul class="file-action">
              <li>
                <span class="badge badge-soft-pink">Proposal</span>
              </li>
              <li>
                <span class="badge badge-soft-grey priority-badge"
                  ><i class="fa-solid fa-circle"></i>Low</span
                >
              </li>
              <li>
                <div class="dropdown action-drop">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="feather-more-vertical"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-edit me-1"></i>Edit</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-trash me-1"></i>Delete</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      ><i class="las la-download me-1"></i>Download</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
