import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';


export const useCompaniesStore = defineStore('companies', {
    state: () => ({
        dataCompanies: [],
        dataCompany : null,
        dataContracts : null
    }),

    actions: {
        async getList(payload = {}) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
            .get(urlList['companies'],{...payload})
            .then((res) => {
                    this.dataCompanies = res;
                    return res;
                }).finally(() => {

                });
        },
        async getDetail( id){
            try {
                await axiosInstance.get(`${urlList['companies']}/${id}`).then((res)=>{
                    this.dataCompany = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async getContracts( customer_id){
            try {
                await axiosInstance.get(`${urlList['companiesContracts']}`,{params:{customer_id}}).then((res)=>{
                    this.dataContracts = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }   
        },
        async add(formData) {
            try {
                return await axiosInstance.post(urlList['companies'], formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData, id) {
            try {
                return axiosInstance.put(`${urlList['companies']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData, id) {
            try {
                return axiosInstance.put(`${urlList['companies']}/update/${id}`, statusData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(companyId) {
            try {
                const response = await axiosInstance.delete(`${urlList['companies']}/${companyId}`);

                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        },
    },
    getters: {}
});
