import { createApp } from 'vue';
import { router } from '@/router';
import { createPinia } from 'pinia';
import App from "@/App.vue";
import { BootstrapVue3, BToastPlugin } from 'bootstrap-vue-3'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component';
import VueApexCharts from 'vue3-apexcharts';
import DatePicker from 'vue3-datepicker';
import FlagIcon from 'vue-flag-icon';
import VueFeather from 'vue-feather';
import Vue3Autocounter from 'vue3-autocounter';
import VueSweetalert2 from 'vue-sweetalert2' ;
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import ThemifyIcon from "vue-themify-icons";
import SimpleLineIcons from "vue-simple-line";
import VueTippy from 'vue-tippy';


import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.css'
import 'material-icons/css/material-icons.min.css'
import 'material-icons/css/material-icons.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'material-icons/iconfont/material-icons.css'
import 'material-icons/css/material-icons.min.css';
import 'material-icons/css/material-icons.css';
import 'pe7-icon/dist/dist/pe-icon-7-stroke.css';
import 'typicons.font/src/font/typicons.css';
import 'weathericons/css/weather-icons.css'
import 'ionicons-npm/css/ionicons.css';
import 'tippy.js/dist/tippy.css';
import 'flatpickr/dist/flatpickr.css';

import '@/assets/css/feather.css'
import '@/assets/css/style.css';


// *************** Components ***************
import SearchFilters from "@/components/search-filters.vue";
import Breadcrumb from "@/components/breadcrumb/breadcrumb-header.vue"
import DashboardHeader from "@/components/breadcrumb/dashboard-header.vue"
import UserDashboardHeader from "@/components/breadcrumb/user-dashboard-header.vue"
import EventHeader from "@/components/breadcrumb/event-header.vue"
import UserDashboardTabset from "@/components/user-dashboard-tabset.vue"
import JobFilter from "@/components/job-filter.vue"
import InboxHeader from '@/components/breadcrumb/inbox-header.vue'
import EmployeesHeader from '@/components/breadcrumb/employees-header.vue'
import SearchFilter from '@/components/search-filter.vue'
import HolidayHeader from '@/components/breadcrumb/holiday-header.vue'
import LeavesAdminHeader from '@/components/breadcrumb/leaves-admin-header.vue'
import Singlebreadcrumb from '@/components/breadcrumb/single-breadcrumb.vue'
import LeavesSearchFilter from '@/components/leaves-search-filter.vue'
import ShiftHeader from '@/components/breadcrumb/shift-header.vue'
import SalesFilter from '@/components/sales-filter.vue'
import SalesHeader from "@/components/breadcrumb/sales-header.vue"
import ExpensesFilter from '@/components/expenses-filter.vue'
import ExpenseReportsFilter from '@/components/expense-reports-filter.vue'
import PaymentsReportsFilter from '@/components/payments-reports-filter.vue'
import ProjectReportsFilter from '@/components/project-reports-filter.vue'
import UserReportsFilter from '@/components/user-reports-filter.vue'
import EmployeeReportsFilter from '@/components/employee-reports-filter.vue'
import PayslipReportsFilter from '@/components/payslip-reports-filter.vue'
import AttendanceReportsFilter from '@/components/attendance-reports-filter.vue'
import ClientsHeader from '@/components/breadcrumb/clients-header.vue'
import ClientsFilter from '@/components/clients-filter.vue'
import ProjectsHeader from '@/components/breadcrumb/projects-header.vue'
import ProjectsFilter from '@/components/projects-filter.vue'
import TicketsFilter from '@/components/tickets-filter.vue'
import ProjectViewHeader from '@/components/breadcrumb/project-view-header.vue'
import Jobbreadcrumb from '@/components/breadcrumb/job-breadcrumb.vue'
import AttendanceFilter from '@/components/attendance-filter.vue'
import AttendanceEmployeeFilter from '@/components/attendance-employee-filter.vue'
import UserDashboardFilter from '@/components/user-dashboard-filter.vue'
import ShiftSchedulingFilter from "@/components/shift-scheduling-filter.vue"
import SalaryFilter from "@/components/salary-filter.vue"
import ReportHeader from '@/components/breadcrumb/reports-header.vue'
import UsersFilter from '@/components/users-filter.vue'

// *************** Layout Component ***************
import Header from "@/views/layouts/layout-header.vue"
import Sidebar from "@/views/layouts/layout-sidebar.vue"
import TwoColSidebar from '@/views/layouts/two-col-sidebar.vue'
import ChatSidebar from '@/views/layouts/chat-sidebar.vue'
import InboxSidebar from '@/views/layouts/inbox-sidebar.vue'
import Settingssidebar from '@/views/layouts/settings-sidebar.vue'
import Taskssidebar from '@/views/layouts/tasks-sidebar.vue'
import JobHeader from '@/views/layouts/job-header.vue'
import SideSettings from '@/views/layouts/side-settings.vue'
import HorizontalHeader from '@/views/layouts/horizontal-header.vue'
import VerticalSidebar from '@/views/layouts/vertical-sidebar.vue'

// *************** Page Component ***************
import AdminPayments from '@/views/pages/dashboard/admin-payment.vue'
import AdminInvoices from '@/views/pages/dashboard/admin-invoices.vue'
import AdminCharts from '@/views/pages/dashboard/admin-charts.vue'
import AdminStatistics from '@/views/pages/dashboard/admin-statistics.vue'
import AdminClients from '@/views/pages/dashboard/admin-clients.vue'
import AdminRecentProject from '@/views/pages/dashboard/admin-recent-project.vue'
import ChatView from '@/views/pages/apps/chat/chat-view.vue'
import ChatRight from '@/views/pages/apps/chat/chat-right.vue'
import Annual from '@/views/pages/employees/leave-settings/annual-index.vue'
import Sick from '@/views/pages/employees/leave-settings/sick-index.vue'
import Maternity from '@/views/pages/employees/leave-settings/maternity-index.vue'
import CustomLeave from '@/views/pages/employees/leave-settings/custom-leave.vue'
import Annualsub from '@/views/pages/pages/subscriptions/sub/annual-sub.vue'
import Monthlysub from '@/views/pages/pages/subscriptions/sub/monthly-sub.vue'
import Companylist from '@/views/pages/pages/subscriptions/subscribedcompanies/company-list.vue'
import Profiletab from '@/views/pages/pages/profile/employeeprofile/profile-tab.vue'
import Banktab from '@/views/pages/pages/profile/employeeprofile/bank-tab.vue'
import Projectstab from '@/views/pages/pages/profile/employeeprofile/projects-tab.vue'
import Profilehead from '@/views/pages/pages/profile/employeeprofile/profile-head.vue'
import Clientprofilehead from '@/views/pages/pages/profile/clientprofile/client-profile-head.vue'
import Clientprofiletab from '@/views/pages/pages/profile/clientprofile/client-profile-tab.vue'
import Clienttaskstab from '@/views/pages/pages/profile/clientprofile/client-tasks-tab.vue'
import Profileassets from '@/views/pages/pages/profile/employeeprofile/employee-assets.vue'
import Resignation_Notice from '@/views/pages/administration/settings/approval-setting/resignation-notice.vue'
import Expenses_Approval from '@/views/pages/administration/settings/approval-setting/expenses-approval.vue'
import Leave_Approval from '@/views/pages/administration/settings/approval-setting/leave-approval.vue'
import Offer_Approval from '@/views/pages/administration/settings/approval-setting/offer-approval.vue'
import Add_role from '@/views/pages/administration/settings/roles-permissions/add-roles.vue'
import Modeltable from '@/views/pages/administration/settings/roles-permissions/model-table.vue'
import Assetsearch from '@/views/pages/administration/assets/asset-search.vue'
import Assetstable from '@/views/pages/administration/assets/assets-table.vue'
import Additionstab from '@/views/pages/payroll/payroll-items/additions-tab.vue'
import Overtimetab from '@/views/pages/payroll/payroll-items/overtime-tab.vue'
import Deductionstab from '@/views/pages/payroll/payroll-items/deductions-tab.vue'
import ProjectTasks from '@/views/pages/projects/project-view/project-tasks.vue'
import Projectdetails from '@/views/pages/projects/project-view/project-details.vue'
import Taskright from '@/views/pages/projects/tasks/tasks-right.vue'
import Taskleft from '@/views/pages/projects/tasks/tasks-left.vue'
import Kanbanboard from '@/views/pages/projects/task-board/kanban-board.vue'
import Clientsearch from '@/views/pages/pages/pages/search/client-search.vue'
import Usersearch from '@/views/pages/pages/pages/search/users-search.vue'
import Projectsearch from '@/views/pages/pages/pages/search/project-search.vue'
import Basicinformation from '@/views/pages/performance/performance/performance/basic-information.vue'
import Personalexcellence from '@/views/pages/performance/performance/performance/personal-excellence.vue'
import Commentsrole from '@/views/pages/performance/performance/performance/comments-role.vue'
import Appraisee from '@/views/pages/performance/performance/performance/appraisee-index.vue'
import Goals from '@/views/pages/performance/performance/performance/performance-goals.vue'
import Professional from '@/views/pages/performance/performance/performance/professional-index.vue'
import Professionalgoals from '@/views/pages/performance/performance/performance/professional-goals.vue'
import PerformanceTraining from '@/views/pages/performance/performance/performance/performance-training.vue'
import Generalcomment from '@/views/pages/performance/performance/performance/general-comment.vue'
import Ro from '@/views/pages/performance/performance/performance/ro-content.vue'
import Performancetable from '@/views/pages/performance/performance/performance/performance-table.vue'
import Okr from '@/views/pages/administration/settings/performance-setting/okr-page.vue'
import Compentency from '@/views/pages/administration/settings/performance-setting/compentency-index.vue'
import Smartgoal from '@/views/pages/administration/settings/performance-setting/smart-goal.vue'
import SubscriptionsMonthly from '@/views/pages/pages/subscriptions/subscriptions-company/subscriptions-monthly.vue'
import SubscriptionsAnnual from '@/views/pages/pages/subscriptions/subscriptions-company/subscriptions-annual.vue'
import Apptitude from "@/views/pages/administration/jobs/user-dashboard/interviewing/apptitude-table.vue"
import Schedule from '@/views/pages/administration/jobs/user-dashboard/interviewing/schedule-table.vue'
import ChatLeft from '@/views/pages/apps/chat/chat-left.vue'
import TaskView from '@/views/pages/projects/tasks/tasks-view.vue'
import VoiceCallChat from '@/views/pages/apps/calls/voice-call-chat.vue'
import VideoCallChat from '@/views/pages/apps/calls/video-call-chat.vue'
import CompanyPolicy from '@/views/pages/employee-dashboard/company-policy.vue'
import Created_Contact from '@/views/pages/crm/analytics/created-contact.vue'
import LeadsActive from '@/views/pages/crm/leads/leads-details/leads-active.vue'
import LeadsFiles from '@/views/pages/crm/leads/leads-details/leads-files.vue'
import LeadsCalls from '@/views/pages/crm/leads/leads-details/leads-calls.vue'
import LeadsEmail from '@/views/pages/crm/leads/leads-details/leads-email.vue'
import LeadsNotes from '@/views/pages/crm/leads/leads-details/leads-notes.vue'
import LeadsSidebar from '@/views/pages/crm/leads/leads-details/leads-sidebar.vue'
import DealsActive from '@/views/pages/crm/deals/deals-details/deals-active.vue'
import DealsFiles from '@/views/pages/crm/deals/deals-details/deals-files.vue'
import DealsCalls from '@/views/pages/crm/deals/deals-details/deals-calls.vue'
import DealsEmail from '@/views/pages/crm/deals/deals-details/deals-email.vue'
import DealsNotes from '@/views/pages/crm/deals/deals-details/deals-notes.vue'
import DealsSidebar from '@/views/pages/crm/deals/deals-details/deals-sidebar.vue'
import ContactTable from '@/views/pages/crm/contact/contact-table.vue'
import CompaniesTable from '@/views/pages/crm/companies/companies-table.vue'
import LeadsBoard from '@/views/pages/crm/leads/leads-board.vue'
import DealsBoard from '@/views/pages/crm/deals/deals-board.vue'
import Inputsize from '@/views/pages/uiinterface/forms/form-elements/formbasic/input-size.vue'
import Carouselthree from '@/views/pages/uiinterface/baseui/carousel/carousel-three.vue'
import Carouseltwo from '@/views/pages/uiinterface/baseui/carousel/carousel-two.vue'
import Carouselone from '@/views/pages/uiinterface/baseui/carousel/carousel-one.vue'
import LightBoxOne from '@/views/pages/uiinterface/baseui/lightbox/lightbox-one.vue'
import LightBoxTwo from '@/views/pages/uiinterface/baseui/lightbox/lightbox-two.vue'


// *************** modal Component ***************
import AddOrganizationModal from '@/components/modal/organizations/add-organization.vue'
import EditOrganizationModal from '@/components/modal/organizations/edit-organization.vue'
import Contactmodal from '@/components/modal/contacts-modal.vue'
import Employeemodal from '@/components/modal/employee-modal.vue'
import Holidaymodal from '@/components/modal/holiday-modal.vue'
import LeavesAdminmodal from '@/components/modal/leaves-admin-modal.vue'
import Attendancemodal from '@/components/modal/attendance-modal.vue'
import Designationmodal from '@/components/modal/designation-modal.vue'
import Timesheetmodal from '@/components/modal/timesheet-modal.vue'
import ShiftListmodal from '@/components/modal/shift-list-modal.vue'
import OverTimemodal from '@/components/modal/overtime-modal.vue'
import Rolemodals from '@/components/modal/role-modals.vue'
import Leavemodal from '@/components/modal/leave-modal.vue'
import Jobmodal from '@/components/modal/job-modal.vue'
import Candidatesmodal from '@/components/modal/candidates-modal.vue'
import Experiancemodal from '@/components/modal/experiance-modal.vue'
import Questionmodal from '@/components/modal/question-modal.vue'
import Schedulemodal from '@/components/modal/schedule-modal.vue'
import Assetmodal from '@/components/modal/asset-modal.vue'
import Submodals from '@/components/modal/sub-modals.vue'
import Deletemodal from '@/components/modal/delete-modal.vue'
import Expensesmodal from '@/components/modal/expenses-modal.vue'
import Providentfundmodal from '@/components/modal/providentfund-modal.vue'
import Taxesmodal from '@/components/modal/taxes-modal.vue'
import Categoriesmodal from '@/components/modal/categories-modal.vue'
import SubCategorymodal from '@/components/modal/subcategory-modal.vue'
import Budgetsmodal from '@/components/modal/budgets-modal.vue'
import Budgetexpensesmodal from '@/components/modal/budgetexpenses-modal.vue'
import Budgetrevenuesmodal from '@/components/modal/budgetrevenues-modal.vue'
import Salarymodal from '@/components/modal/salary-modal.vue'
import Payrollitemmodal from '@/components/modal/payrollitems-modal.vue'
import Policiesmodal from '@/components/modal/policies-modal.vue'
import ClientsListmodal from '@/components/modal/clients-list-modal.vue'
import ProjectListmodal from '@/components/modal/project-list-modal.vue'
import Ticketsmodal from '@/components/modal/tickets-modal.vue'
import ProjectViewmodal from '@/components/modal/project-view-modal.vue'
import Tasksmodal from '@/components/modal/tasks-modal.vue'
import Taskboardmodal from '@/components/modal/task-board-modal.vue'
import Terminationmodal from '@/components/modal/termination-modal.vue'
import Resignationmodal from '@/components/modal/resignation-modal.vue'
import Promotionmodal from '@/components/modal/promotion-modal.vue'
import Training_type_modal from '@/components/modal/training-type-modal.vue'
import Trainer_modal from '@/components/modal/trainer-modal.vue'
import Training_modal from '@/components/modal/training-modal.vue'
import Goaltype_modal from '@/components/modal/goaltype-modal.vue'
import Goaltracking_modal from '@/components/modal/goaltracking-modal.vue'
import Appraisal_Add_modal from '@/components/modal/appraisal-add-modal.vue'
import Appraisal_Edit_modal from '@/components/modal/appraisal-edit-modal.vue'
import Indicator_Add_modal from '@/components/modal/indicator-add-modal.vue'
import Indicator_Edit_modal from '@/components/modal/indicator-edit-modal.vue'
import ProfileModal from '@/components/modal/profile-modal.vue'
import ChatModal from '@/components/modal/chat-modal.vue'
import Leave_Settings_modal from '@/components/modal/leave-settings-modal.vue'
import Departmentsmodal from '@/components/modal/departments-modal.vue'
import Clientmodal from '@/components/modal/client-modal.vue'
import EditSchedulemodal from '@/components/modal/edit-schedule-modal.vue'
import Interviewing_modal from '@/components/modal/interviewing-modal.vue'
import Subscribedcompaniesmodal from '@/components/modal/subscribedcompanies-modal.vue'
import Questionsmodal from '@/components/modal/questions-modal.vue'
import usermodal from "@/components/modal/user-modal.vue"
import CrmActivitiesModal from '@/components/modal/crm-activities-modal.vue'
import AnalyticsModal from '@/components/modal/analytics-modal.vue'
import ContactListModal from '@/components/modal/contact-list-modal.vue'
import PipelineModal from '@/components/modal/pipeline-modal.vue'
import LeadsModal from '@/components/modal/leads-modal.vue'
import DealsModal from '@/components/modal/deals-modal.vue'
import EditContactListModal from '@/components/modal/edit-contact-list-modal.vue'
import AddContactListModal from '@/components/modal/add-contact-list-modal.vue'
import AddCompanyListModal from '@/components/modal/add-company-list-modal.vue'
import EditCompanyListModal from '@/components/modal/edit-company-list-modal.vue'
import CompaniesListModal from '@/components/modal/companies-list-modal.vue'
import CompanyDetailsModal from '@/components/modal/company-details-modal.vue'

import RingLoader from '@/components/ring-loader.vue';

import swal from 'sweetalert2';

window.Swal = swal;

const app = createApp(App)

const pinia = createPinia();
app.use(pinia)

// *************** Components ***************
app.component('search-area', SearchFilters);
app.component('ring-loader', RingLoader);
app.component('breadcrumb-header', Breadcrumb)
app.component('dashboard-header', DashboardHeader)
app.component('user-dashboard-header', UserDashboardHeader)
app.component('event-header', EventHeader)
app.component('inbox-header', InboxHeader)
app.component('user-dashboard-tabset', UserDashboardTabset)
app.component('job-filter', JobFilter)
app.component('inbox-sidebar', InboxSidebar)
app.component('employees-header', EmployeesHeader)
app.component('search-filter', SearchFilter)
app.component('holiday-header', HolidayHeader)
app.component('leaves-admin-header', LeavesAdminHeader)
app.component('single-breadcrumb', Singlebreadcrumb)
app.component('leaves-search-filter', LeavesSearchFilter)
app.component('shift-header', ShiftHeader)
app.component('sales-filter', SalesFilter)
app.component('sales-header', SalesHeader)
app.component('expenses-filter', ExpensesFilter)
app.component('expense-reports-filter', ExpenseReportsFilter)
app.component('payments-reports-filter', PaymentsReportsFilter)
app.component('project-reports-filter', ProjectReportsFilter)
app.component('user-reports-filter', UserReportsFilter)
app.component('employee-reports-filter', EmployeeReportsFilter)
app.component('payslip-reports-filter', PayslipReportsFilter)
app.component('attendance-reports-filter', AttendanceReportsFilter)
app.component('clients-header', ClientsHeader)
app.component('clients-filter', ClientsFilter)
app.component('projects-header', ProjectsHeader)
app.component('projects-filter', ProjectsFilter)
app.component('tickets-filter', TicketsFilter)
app.component('project-view-header', ProjectViewHeader)
app.component('job-breadcrumb', Jobbreadcrumb)
app.component('attendance-filter', AttendanceFilter)
app.component('attendance-employee-filter', AttendanceEmployeeFilter)
app.component('user-dashboard-filter', UserDashboardFilter)
app.component('shift-scheduling-filter', ShiftSchedulingFilter)
app.component('salary-filter', SalaryFilter)
app.component('reports-header', ReportHeader)
app.component('users-filter', UsersFilter)

// *************** Layout Component ***************
app.component('layout-header', Header)
app.component('layout-sidebar', Sidebar)
app.component('two-col-sidebar', TwoColSidebar)
app.component('chat-sidebar', ChatSidebar)
app.component('settings-sidebar', Settingssidebar)
app.component('tasks-sidebar', Taskssidebar)
app.component('job-header', JobHeader)
app.component('side-settings', SideSettings)
app.component('horizontal-header', HorizontalHeader)
app.component('vertical-sidebar', VerticalSidebar)

// *************** Page Component ***************
app.component('admin-payments', AdminPayments)
app.component('admin-invoices', AdminInvoices)
app.component('admin-charts', AdminCharts)
app.component('admin-statistics', AdminStatistics)
app.component('admin-clients', AdminClients)
app.component('admin-recent-project', AdminRecentProject)
app.component('chat-view', ChatView)
app.component('chat-right', ChatRight)
app.component('annual-index', Annual)
app.component('sick-index', Sick)
app.component('maternity-one', Maternity)
app.component('custom-leave', CustomLeave)
app.component('annual-sub', Annualsub)
app.component('monthly-sub', Monthlysub)
app.component('subscribed-companies-modal', Subscribedcompaniesmodal)
app.component('company-list', Companylist)
app.component('profile-tab', Profiletab)
app.component('bank-tab', Banktab)
app.component('projects-tab', Projectstab)
app.component('profile-head', Profilehead)
app.component('client-profile-head', Clientprofilehead)
app.component('client-profile-tab', Clientprofiletab)
app.component('client-tasks-tab', Clienttaskstab)
app.component('profile-assets', Profileassets)
app.component('resignation-notice', Resignation_Notice)
app.component('expenses-approval', Expenses_Approval)
app.component('leave-approval', Leave_Approval)
app.component('offer-approval', Offer_Approval)
app.component('add-role', Add_role)
app.component('model-table', Modeltable)
app.component('assets-table', Assetstable)
app.component('asset-search', Assetsearch)
app.component('additions-tab', Additionstab)
app.component('overtime-tab', Overtimetab)
app.component('deductions-tab', Deductionstab)
app.component('project-tasks', ProjectTasks)
app.component('project-details', Projectdetails)
app.component('tasks-right', Taskright)
app.component('tasks-left', Taskleft)
app.component('kanban-board', Kanbanboard)
app.component('client-search', Clientsearch)
app.component('users-search', Usersearch)
app.component('project-search', Projectsearch)
app.component('personal-excellence', Personalexcellence)
app.component('basic-information', Basicinformation)
app.component('comments-role', Commentsrole)
app.component('appraisee-index', Appraisee)
app.component('performance-goals', Goals)
app.component('professional-index', Professional)
app.component('professional-goals', Professionalgoals)
app.component('performance-training', PerformanceTraining)
app.component('general-comment', Generalcomment)
app.component('ro-content', Ro)
app.component('performance-table', Performancetable)
app.component('smart-goal', Smartgoal)
app.component('okr-page', Okr)
app.component('compentency-index', Compentency)
app.component('subscriptions-monthly', SubscriptionsMonthly)
app.component('subscriptions-annual', SubscriptionsAnnual)
app.component('apptitude-table', Apptitude)
app.component('schedule-table', Schedule)
app.component('chat-left', ChatLeft)
app.component('tasks-view', TaskView)
app.component('voice-call-chat', VoiceCallChat)
app.component('video-call-chat', VideoCallChat)
app.component('company-policy', CompanyPolicy)
app.component('created-contact',Created_Contact)
app.component('leads-active',LeadsActive)
app.component('leads-calls',LeadsCalls)
app.component('leads-email',LeadsEmail)
app.component('leads-files',LeadsFiles)
app.component('leads-notes',LeadsNotes)
app.component('leads-sidebar',LeadsSidebar)
app.component('deals-active',DealsActive)
app.component('deals-calls',DealsCalls)
app.component('deals-email',DealsEmail)
app.component('deals-files',DealsFiles)
app.component('deals-notes',DealsNotes)
app.component('deals-sidebar',DealsSidebar)
app.component('contact-table', ContactTable)
app.component('companies-table', CompaniesTable)
app.component('leads-board',LeadsBoard)
app.component('deals-board',DealsBoard)
app.component('input-size', Inputsize)
app.component('carousel-one',Carouselone)
app.component('carousel-two',Carouseltwo)
app.component('carousel-three',Carouselthree)
app.component('lightbox-one',LightBoxOne)
app.component('lightbox-two',LightBoxTwo)


// *************** modal Component ***************
app.component('add-organization-modal', AddOrganizationModal)
app.component('edit-organization-modal', EditOrganizationModal)
app.component('contacts-modal', Contactmodal)
app.component('employee-modal', Employeemodal)
app.component('holiday-modal', Holidaymodal)
app.component('leaves-admin-modal', LeavesAdminmodal)
app.component('attendance-modal', Attendancemodal)
app.component('designation-modal', Designationmodal)
app.component('timesheet-modal', Timesheetmodal)
app.component('shift-list-modal', ShiftListmodal)
app.component('overtime-modal', OverTimemodal)
app.component('role-modals', Rolemodals)
app.component('leave-modal', Leavemodal)
app.component('sub-modals', Submodals)
app.component('job-modal', Jobmodal)
app.component('asset-modal', Assetmodal)
app.component('candidates-modal', Candidatesmodal)
app.component('experiance-modal', Experiancemodal)
app.component('question-modal', Questionmodal)
app.component('schedule-modal', Schedulemodal)
app.component('delete-modal', Deletemodal)
app.component('expenses-modal', Expensesmodal)
app.component('providentfund-modal', Providentfundmodal)
app.component('taxes-modal', Taxesmodal)
app.component('categories-modal', Categoriesmodal)
app.component('subcategory-modal', SubCategorymodal)
app.component('budgets-modal', Budgetsmodal)
app.component('budgetexpenses-modal', Budgetexpensesmodal)
app.component('budgetrevenues-modal', Budgetrevenuesmodal)
app.component('salary-modal', Salarymodal)
app.component('payrollitem-modal', Payrollitemmodal)
app.component('policies-modal', Policiesmodal)
app.component('clients-list-modal', ClientsListmodal)
app.component('project-list-modal', ProjectListmodal)
app.component('tickets-modal', Ticketsmodal)
app.component('project-view-modal', ProjectViewmodal)
app.component('tasks-modal', Tasksmodal)
app.component('task-board-modal', Taskboardmodal)
app.component('termination-modal', Terminationmodal)
app.component('resignation-modal', Resignationmodal)
app.component('promotion-modal', Promotionmodal)
app.component('training-type-modal', Training_type_modal)
app.component('trainer-modal', Trainer_modal)
app.component('training-modal', Training_modal)
app.component('goaltype-modal', Goaltype_modal)
app.component('goaltracking-modal', Goaltracking_modal)
app.component('appraisal-add-modal', Appraisal_Add_modal)
app.component('appraisal-edit-modal', Appraisal_Edit_modal)
app.component('indicator-add-modal', Indicator_Add_modal)
app.component('indicator-edit-modal', Indicator_Edit_modal)
app.component('profile-modal', ProfileModal)
app.component('chat-modal', ChatModal)
app.component('leave-settings-modal', Leave_Settings_modal)
app.component('departments-modal', Departmentsmodal)
app.component('client-modal', Clientmodal)
app.component('edit-schedule-modal', EditSchedulemodal)
app.component('interviewing-modal', Interviewing_modal)
app.component('questions-modal', Questionsmodal)
app.component('crm-activities-modal', CrmActivitiesModal)
app.component('analytics-modal', AnalyticsModal)
app.component('pipeline-modal',PipelineModal)
app.component('leads-modal',LeadsModal)
app.component('deals-modal',DealsModal)
app.component('user-modal',usermodal)
app.component('contact-list-modal', ContactListModal)
app.component('edit-contact-list-modal', EditContactListModal)
app.component('add-contact-list-modal', AddContactListModal)
app.component('add-company-list-modal', AddCompanyListModal)
app.component('edit-company-list-modal', EditCompanyListModal)
app.component('companies-list-modal', CompaniesListModal)
app.component('company-details-modal', CompanyDetailsModal)

app.component('vue3-autocounter', Vue3Autocounter)
app.component('date-picker', DatePicker)
app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather);



app.use(VueSweetalert2)
app.use(VueApexCharts)
    .use(Antd)
    .use(BootstrapVue3)
    .use(BToastPlugin)
    .use(ThemifyIcon)
    .use(SimpleLineIcons)
.use(VueToast);
app.use(FlagIcon)
app.use(VueTippy,{
    directive: 'tippy',
    component: 'tippy',
});

if (process.env.NODE_ENV === 'production') {
    app.config.devtools = false;
    app.config.debug = false;
    app.config.silent = true;
}

app.use(router).mount('#app');

