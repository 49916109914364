<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Qaimələr</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard"
                  >Daşbord</router-link
                >
              </li>
              <li class="breadcrumb-item active">Qaimələr</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a
                  href="javascript:void(0);"
                  class="grid-view btn btn-link"
                  v-tippy
                  title="Filtri sıfırla"
                  @click="resetSearchFilter"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  v-tippy
                  title="Tam ekran"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-tippy
                  title="Filtr"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <!-- <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div> -->
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addDataModal"
                ><i class="la la-plus-circle"></i> Əlavə et</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <search-area
        :filter="filter"
        ref="searchComponent"
        :searchFilters="searchFilters"
        @submitSearchFilter="searchFilterSubmitHandler"
      ></search-area>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <form @submit.prevent="searchSubmit">
              <div class="search-set">
                <div class="search-input">
                  <a
                    href="javascript:void(0);"
                    @click="searchSubmit"
                    class="btn btn-searchset"
                    ><i class="las la-search"></i
                  ></a>
                  <div class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Axtar"
                        v-model="searchString"
                    /></label>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table
              class="stripped table-hover"
              :columns="columns"
              :data-source="data"
              :pagination="false"
              @change="handleTableChange"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="d-flex align-items-center gap-3">
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        @click="openShowModal(record.id)"
                        v-tippy
                        title="Baxış"
                      >
                        <i class="fa-solid fa-eye text-info"></i>
                      </a>

                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        @click="editData(record.id)"
                        v-tippy
                        title="Düzəliş et"
                        ><i class="fa-solid fa-pencil text-warning"></i
                      ></a>

                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        @click="openDeleteModal(record.id)"
                        v-tippy
                        title="Sil"
                        ><i class="fa-regular fa-trash-can text-danger"></i
                      ></a>
                    </div>
                  </div>
                </template>

                <template v-else-if="column.key === 'tax_number'">
                  <div class="d-flex flex-row gap-1">
                    <p class="text-wrap">{{ record.tax_number }}</p>
                    <button
                      class="btn btn-icon btn-outline-primary btn-sm"
                      @click="openTaxNumberModal(record)"
                    >
                      <i class="fas fa-pencil"></i>
                    </button>
                  </div>
                </template>

                

                <template v-else-if="column.key === 'pay'">
                    <button v-if="record.remaining_amount > 0" @click="openPayModal(record)"
                                v-tippy
                                 title="Ödəniş edin"
                                class="btn btn-icon btn-outline-danger btn-sm text-nowrap">
                                <i class="fas fa-credit-card"></i>
                        </button>
                        <button v-else class="btn btn-icon btn-success btn-sm"
                         v-tippy
                        title="Ödəmə tamamlanıb"><i class="fas fa-check"></i></button>
                </template>


                <template v-else-if="column.key === 'xml'">
                    <button
                      class="btn btn-icon btn-outline-dark btn-sm"
                      title="XML faylını endirin"
                      v-tippy
                      @click="downloadXml(record.id)"
                    >
                      <i class="fas fa-download"></i>
                    </button>
                </template>

                <template v-else-if="column.key === 'company_id'">
                  {{ record.company?.name }}
                </template>

                <template v-else-if="column.key === 'account_id'">
                  {{ record.account?.name }}
                </template>

                <template v-else-if="column.key === 'customer_id'">
                  {{ record.customer?.name }}
                </template>

                <template v-else-if="column.key === 'total_amount'">
                  {{
                    record.paid_amount + " ₼ / " + record.total_amount + " ₼"
                  }}
                </template>

                <template v-else-if="column.key === 'remaining_amount'">
                  {{ record.remaining_amount + " ₼" }}
                </template>

                <template v-else-if="column.key === 'type_id'">
                  {{ record.type == 1 ? "Təchizatçı" : "Müştəri" }}
                </template>
              </template>
            </a-table>
            <a-pagination
              :current="currentPage"
              :total="totalData"
              :page-size="pageSize"
              @change="handlePageChange"
              class="mt-3 text-end mb-5"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddModal 
  :companies-options-list="companiesOptionsList"
  :measures-options-list="measuresOptionsList"
  @formSubmited="addSubmit"> </AddModal>

  <EditModal 
  :id="editId"
  :companies-options-list="companiesOptionsList"
  :measures-options-list="measuresOptionsList"
  @formSubmited="editSubmit"> </EditModal>

  <delete-modal
    modalId="deleteModal"
    @deleteConfirmed="deleteSubmit"
  ></delete-modal>

  <ShowModal
    :fields="showData"
    :fieldLabels="showDataLabels"
    modal-title="Qaiməyə baxış"
    :necessary-fields="showDataFields"
  ></ShowModal>

  <div
    class="modal fade"
    tabindex="-1"
    data-bs-keyboard="false"
    aria-hidden="true"
    id="taxModal"
  >
    <!-- Scrollable modal -->
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="staticBackdropLabel2">
            Qaimə nömrəsinin yenilənməsi
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="input-block mb-3">
                <label class="col-form-label">
                  Qaimə nömrəsi <span class="text-danger">*</span>
                </label>

                <input
                  v-model="taxFormData.tax_number"
                  class="form-control"
                  type="text"
                  name="tax_number"
                  :class="{
                    'is-invalid': $vTax.taxFormData.tax_number.$error,
                    'is-valid': isTaxSubmitForm && !$vTax.taxFormData.tax_number.$error,
                  }"
                />

                <template v-if="isTaxSubmitForm && $vTax.taxFormData.tax_number.$error">
                  <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" @click="closeTaxModal" data-bs-dismiss="modal">
            Bağla
          </button>
          <button type="button" class="btn btn-primary" @click="submitTaxForm">Yadda saxla</button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    tabindex="-1"
    data-bs-keyboard="false"
    aria-hidden="true"
    id="payModal"
  >
    <!-- Scrollable modal -->
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="staticBackdropLabel2">
            Ödəniş edilməsi
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <div class="col-md-12">
              <div class="input-block mb-3">
                <label class="col-form-label">
                  Hesab 
                </label>

                <input
                  disabled
                  v-model="payFormData.account_name"
                  class="form-control"
                  type="text"
                  name="account_name"
                />
              </div>
            </div>

            <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Kateqoriya <span class="text-danger">*</span></label
                    >

                    <vue-select
                      v-if="categoryOptionsList.length > 0"
                      :options="categoryOptionsList"
                      :name="category_id"
                      id="categoryPay"
                      v-model="payFormData.category_id"
                      :class="{
                        'is-invalid': $vPay.payFormData.category_id.$error,
                        'is-valid':
                          isPaySubmitForm && !$vPay.payFormData.category_id.$error,
                      }"
                    />
                    <p v-else>Kateqoriya əlavə olunmalıdır</p>
                    <template
                      v-if="isPaySubmitForm && $vPay.payFormData.category_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

            <div class="col-md-12">
              <div class="input-block mb-3">
                <label class="col-form-label">
                  Məbləğ <span class="text-danger">*</span>
                </label>

                <input
                  v-model="payFormData.amount"
                  class="form-control"
                  type="text"
                  name="amount"
                  :class="{
                    'is-invalid': $vPay.payFormData.amount.$error,
                    'is-valid': isPaySubmitForm && !$vPay.payFormData.amount.$error,
                  }"
                />

                <template v-if="isPaySubmitForm && $vPay.payFormData.amount.$error">
                  <p
                    v-if="$vPay.payFormData.amount.required.$invalid"
                    class="text-danger mt-1"
                  >
                    {{ payRules.payFormData.amount.required.$message }}
                  </p>
                  
                  <p
                    v-else-if="$vPay.payFormData.amount.maxValue.$invalid"
                    class="text-danger mt-1"
                  >
                    {{ payRules.payFormData.amount.maxValue.$message }}
                  </p>
                  <p
                    v-else-if="$vPay.payFormData.amount.minValue.$invalid"
                    class="text-danger mt-1"
                  >
                    {{ payRules.payFormData.amount.minValue.$message }}
                  </p>
                  <p
                    v-else-if="$vPay.payFormData.amount.decimal.$invalid"
                    class="text-danger mt-1"
                  >
                    {{ payRules.payFormData.amount.decimal.$message }}
                  </p>
                </template>
              </div>
            </div>
                
            <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tarix <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <date-picker
                        v-model="payFormData.date"
                        class="form-control floating datetimepicker"
                        :editable="true"
                        :clearable="fales"
                        input-format="dd-MM-yyyy"
                        placeholder="gün-ay-il"
                        :readonly="false"
                        :class="{
                          'is-invalid': $vPay.payFormData.date.$error,
                          'is-valid':
                            isPaySubmitForm && !$vPay.payFormData.date.$error,
                        }"
                      />
                    </div>
                    <template
                      v-if="isPaySubmitForm && $vPay.payFormData.date.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

            <div class="col-md-12">
              <div class="input-block mb-3">
                <label class="col-form-label">
                  Qeyd
                </label>

                <textarea
                  v-model="payFormData.note"
                  class="form-control"
                  type="text"
                  name="note"
                  rows="4"
                  :class="{
                    'is-valid': isPaySubmitForm,
                  }"
                />
              </div>
            </div>
                
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" @click="closePayModal" data-bs-dismiss="modal">
            Bağla
          </button>
          <button type="button" class="btn btn-primary" @click="submitPayForm">Ödəniş et</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, onBeforeUnmount, watch, computed } from "vue";
import ShowModal from "@/components/modal/show-data.vue";
import AddModal from "@/components/modal/invoices/add-invoice.vue";
import EditModal from "@/components/modal/invoices/edit-invoice.vue";
import {
  helpers,
  minValue,
  maxValue,
  numeric,
  required,
  decimal,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  openModal,
  closeModal,
  handleTableSorterChange,
  toggleViewFullscreen,
  createLinkAndClickForDownload,
} from "@/utils/helper";
import { showSuccessToast, showErrorToast } from "@/utils/helper";
import { useInvoicesStore } from "@/stores/modules/invoices";
import { useCategoryStore } from "@/stores/modules/categories";
import { useUnits } from "@/stores/modules/units";
import { useCompaniesStore } from "@/stores/modules/companies";

// Store
const invoiceStore = useInvoicesStore();
const categoryStore = useCategoryStore();
const unitStore =  useUnits();
const companyStore = useCompaniesStore();

// State
const data = ref([]);

const companiesOptionsList = ref([]);

const showData = reactive({});

const showDataFields = ref([
  "id",
  "taxNumber",
  "company.name",
  "account.name",
  "customer.name",
  "total_amount",
  "paid_amount",
  "remaining_amount",
  "type.label",
  "date",
  'note',
  'additional_note',
  "created_at",
]);

const showDataLabels = reactive({
  id: "ID",
  taxNumber: "Vergi nömrəsi",
  "company.name": "Şirkət",
  "account.name": "Hesab",
  "customer.name": "Müştəri/Təchizatçı adı",
  total_amount: "Ümumi",
  paid_amount: "Ödənilən məbləğ",
  remaining_amount: "Qalan məbləğ",
  "type.label": "Tip",
  date: "Tarix",
  created_at: "Yaranma tarixi",
  note: "Qeyd",
  additional_note: "Əlavə qeyd",
});

// Pagination and Filters
const filter = ref(false);
const searchComponent = ref(null);
let searchString = ref("");
let searchFiltersValue = reactive({});
let currentPage = ref(1);
let pageSize = ref(15);
let totalData = ref(0);
let itemToDeleteId = ref(null);
const editId = ref(null);

const categoryOptionsList = ref([]);
const measuresOptionsList = ref([]);

const sortParams = ref({
  sortField: "id",
  sortOrder: "desc",
});

// Columns
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
  },
  {
    title: "Vergi nömrəsi",
    dataIndex: "tax_number",
    key: "tax_number",
    sorter: true,
  },
  {
    title: "Şirkət",
    dataIndex: "company_id",
    key: "company_id",
    sorter: false,
  },
  {
    title: "Hesab",
    dataIndex: "account_id",
    key: "account_id",
    sorter: false,
  },
  {
    title: "Müştəri",
    dataIndex: "customer_id",
    key: "customer_id",
    sorter: false,
  },
  {
    title: "Ödənilən / Ümumi",
    dataIndex: "total_amount",
    key: "total_amount",
    sorter: true,
  },

  {
    title: "Qalan məbləğ",
    dataIndex: "remaining_amount",
    key: "remaining_amount",
    sorter: false,
  },
  {
    title: "Tip",
    dataIndex: "type_id",
    key: "type_id",
    sorter: false,
  },
  {
    title: "Tarix",
    dataIndex: "date",
    sorter: true,
  },
  {
    title: "Ödəmə",
    dataIndex: "pay",
    key: "pay",
    sorter: false,
  },
  {
    title: "XML",
    dataIndex: "xml",
    key: "xml",
    sorter: false,
  },
  {
    title: "",
    dataIndex: "Action",
    key: "Action",
    sorter: false,
  },
];

//Filters
const searchFilters = [
  {
    title: "ID",
    key: "id",
    type: "text",
  },
  {
    title: "Vergi nömrəsi",
    key: "tax_number",
    type: "text",
  },
  {
    title: "Müştəri/Təchizatçı",
    key: "customer",
    type: "text",
  },
  {
    title: "Tipi",
    key: "type",
    type: "select",
    options: [
      { id: 0, text: "Müstəri" },
      { id: 1, text: "Təchizatçı" },
    ],
  },
  {
    title: "Yaranma Tarixi",
    key: "date",
    type: "date",
  },
];


// ------------------Tax Number----------------------- //

const taxFormData = reactive({
  id:null,
  tax_number:null
});

const isTaxSubmitForm = ref(false);

const taxRules = {
  taxFormData: {
    tax_number: { required }
  }
}

const $vTax = useVuelidate(taxRules, { taxFormData });

const resetTaxValidation = () => {
  isTaxSubmitForm.value = false;
  $vTax.value.taxFormData.$reset();
};


const resetTaxFormData = () => {
  taxFormData.id = null;
  taxFormData.tax_number = null;
};

const submitTaxForm = async () => {
  isTaxSubmitForm.value = true;
  $vTax.value.taxFormData.$touch();
  if ($vTax.value.taxFormData.$invalid) {
    return false;
  }
  try {
    await invoiceStore.setTaxNumber(taxFormData , taxFormData.id).then(async (response) => {
      resetTaxFormData();
      fetchListData();
      showSuccessToast(response.data.message);
      resetTaxValidation();
      closeModal("taxModal");
    });
  } catch (error) {
    console.log(error)
    showErrorToast(
      error.response?.data?.message || "Xəta baş verdi. Yenidən cəhd edin"
    );
  }
};


const openTaxNumberModal = (record) =>{
  taxFormData.tax_number = record.tax_number;
  taxFormData.id = record.id
  console.log(record)
  openModal('taxModal');
}

const closeTaxModal = () => {
  resetTaxFormData();
  resetTaxValidation();
}

// -------------------- XML --------------------------//
const downloadXml = async (id) => {
    try {
        const res = await invoiceStore.getXml(id);
        createLinkAndClickForDownload(res.data, `Qaime_Nomre_${id}.xml`);
    } catch (error) {
        console.error('Error:', error);
    }
};


// ------------------- Pay ---------------------------//
const payFormData = reactive({
  id:null,
  account_id:null,
  account_name:null,
  category_id: '',
  note: '',
  date: new Date(),
  amount: 0
});

const maxPrice = ref(10000000);
const minPrice = ref(0.01);


const maxValueMessage = computed(() => {
    return `Maksimum məbləğ:${maxPrice.value} AZN`;
});

const minValueMessage = computed(() => {
    return `Minimum məbləğ:${minPrice.value} AZN`;
});

const isPaySubmitForm = ref(false);

const payRules = {
  payFormData: {
    date: { required },
    // account_id: { required },
    category_id: { required },
    amount: {
        required: helpers.withMessage('Bu vacib məlumatdır', required),
        maxValue: helpers.withMessage(maxValueMessage, maxValue(maxPrice)),
        minValue: helpers.withMessage(minValueMessage, minValue(minPrice)),
        decimal: helpers.withMessage('Ancaq rəqəm (onluq ədədləri) qəbul edir.', decimal)
    }
  }
}

const $vPay = useVuelidate(payRules, { payFormData });


const resetPayValidation = () => {
  isPaySubmitForm.value = false;
  $vPay.value.payFormData.$reset();
};


const resetPayFormData = () => {
  payFormData.id = null;
  payFormData.account_id = null;
  payFormData.account_name = null;
  payFormData.category_id = null;
  payFormData.note = null;
  payFormData.date = new Date();
  payFormData.amount = 0;
};

const submitPayForm = async () => {
  isPaySubmitForm.value = true;
  $vPay.value.payFormData.$touch();
  
  if ($vPay.value.payFormData.$invalid) {
    return false;
  }

  try {
    await invoiceStore.submitPayForm(payFormData , payFormData.id).then(async (response) => {
      resetPayFormData();
      fetchListData();
      showSuccessToast(response.data.message);
      resetPayValidation();
      closeModal("payModal");
    });
  } catch (error) {
    console.log(error)
    // showErrorToast(
    //   error.response?.data?.message || "Xəta baş verdi. Yenidən cəhd edin"
    // );
  }
};


const openPayModal = async(record) =>{
  
  resetPayValidation();

  await categoryStore.getList({
    params:{
      type_id: record.type
    }
  });

  payFormData.id = record.id;
  payFormData.account_id = record.account?.id;
  payFormData.account_name = record.account?.name;
  payFormData.category_id = record.category?.id,
  
  createCategoryList();

  openModal('payModal');
}

const closePayModal = () => {
  resetPayFormData();
}


const createCategoryList = () => {
  categoryOptionsList.value = categoryStore.dataCategories.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}

const createUnitOptions =  ()=>{
   unitStore.getList({ limit: 10000 }).then(()=>{
    measuresOptionsList.value = unitStore.data.data.data.map((item) => ({
      id: item.id,
      text: item.name,
    }));
   });
}

/* -------------------- Methods -------------------- */

// Fetch data with pagination
const fetchListData = async (
  page = 1,
  searchString = "",
  searchFilters = searchFiltersValue
) => {
  const response = await invoiceStore.getList({
    params: {
      offset: (page - 1) * pageSize.value,
      limit: pageSize.value,
      q: searchString,
      sortField: sortParams.value.sortField,
      sortOrder: sortParams.value.sortOrder,
      ...searchFilters,
    },
  });
  const responseData = response.data;
  data.value = responseData.data;
  totalData.value = responseData.total;
};

// Add new data entry
const addSubmit = async () => {
  await fetchListData();
};

// Edit submited
const editSubmit = async () => {
  await fetchListData();
  editId.value = null;
};

// Edit existing data entry
const editData = async (id) => {
  editId.value = id;
  openModal("editDataModal");
};

// DeleteConfirmationModal
const openDeleteModal = (id) => {
  openModal("deleteModal");
  itemToDeleteId.value = id;
};

// Delete item
const deleteSubmit = async () => {
  await invoiceStore.delete(itemToDeleteId.value);
  closeModal("deleteModal");
  showSuccessToast("Uğurla silindi");
  fetchListData();
};

// Show Item
const openShowModal = async (id) => {
  await invoiceStore.getDetail(id).then(() => {
    Object.assign(showData, invoiceStore.dataInvoice.data);
  });
  openModal("showModal");
};

// Search items
const searchSubmit = async () => {
  fetchListData(1, searchString.value);
};

// Search Filters Submit
const searchFilterSubmitHandler = async (filters) => {
  await fetchListData(1, null, filters);
  searchFiltersValue = filters;
};

const resetSearchFilter = async () => {
  await fetchListData();
  searchComponent.value.resetFilters();
};

// Handle page change for pagination
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchListData(page);
};

// Handle Table Changes (Sorting)
const handleTableChange = async (pagination, filters, sorter) => {
  await handleTableSorterChange(sorter, sortParams);
  fetchListData(currentPage.value);
};

const toggleFullscreen = () => toggleViewFullscreen();


const createCompaniesOptions =  ()=>{
  companyStore.getList({
    params: {
      limit:10000
    },
  }).then(()=>{
    companiesOptionsList.value = companyStore.dataCompanies.data.data.map((company) => ({
    id: company.id,
    text: company.name,
  }));
  });
}

/* -------------------- Lifecycle Hooks -------------------- */

const debounceTime = 500;

let debounceTimeout;

watch(searchString, () => {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    searchSubmit();
  }, debounceTime);
});

// On component mount
onMounted(() => {
  fetchListData();
  createUnitOptions();
  createCompaniesOptions();
});

// Before unmount
onBeforeUnmount(() => {
  // document.removeEventListener("click", handleDocumentClick);
});
</script>

<style>
.ant-pagination-item a {
  color: black !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.stripped .ant-table-cell {
  white-space: normal;
  text-overflow: ellipsis;
  word-break: normal;
}

.ant-table-thead .ant-table-column-title {
  white-space: normal;
  text-overflow: ellipsis;
  word-break: normal;
}

.btn.btn-icon {
  height: max-content;
  padding: 2px 4px;
  line-height: initial;
}
</style>
