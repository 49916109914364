<template>
  <!-- Edit Contact -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="edit_contact"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Edit Contact</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="add-details-wizard">
            <ul id="progressbar" class="progress-bar-wizard">
              <li :class="{ active: editFieldOne }">
                <span><i class="la la-user-tie" /></span>
                <div class="multi-step-info">
                  <h6>Basic Info</h6>
                </div>
              </li>
              <li :class="{ active: editFieldTwo }">
                <span><i class="la la-map-marker" /></span>
                <div class="multi-step-info">
                  <h6>Address</h6>
                </div>
              </li>
              <li :class="{ active: editFieldThree }">
                <div class="multi-step-icon">
                  <span><i class="la la-icons" /></span>
                </div>
                <div class="multi-step-info">
                  <h6>Social Profiles</h6>
                </div>
              </li>
              <li :class="{ active: editFieldFour }">
                <div class="multi-step-icon">
                  <span><i class="la la-images" /></span>
                </div>
                <div class="multi-step-info">
                  <h6>Access</h6>
                </div>
              </li>
            </ul>
          </div>
          <div class="add-info-fieldset">
            <fieldset
              id="first-field"
              :style="{ display: editFieldOne ? 'block' : 'none' }"
            >
              <form action="/crm/contact-list">
                <div class="form-upload-profile">
                  <h6 class="">Profile Image <span> *</span></h6>
                  <div class="profile-pic-upload">
                    <div class="profile-pic">
                      <span
                        ><img
                          src="@/assets/img/avatar/avatar-19.jpg"
                          class="rounded-circle"
                          alt="Img"
                      /></span>
                    </div>
                    <div class="employee-field">
                      <div class="mb-0">
                        <div class="image-upload mb-0">
                          <input type="file" />
                          <div class="image-uploads">
                            <h4>Upload</h4>
                          </div>
                        </div>
                      </div>
                      <div class="img-reset-btn">
                        <a href="javascript:void(0);">Reset</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >First Name <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="Darlee" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Last Name <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="Robertson" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Job Title <span class="text-danger"> *</span></label
                        >
                        <input
                          class="form-control"
                          type="text"
                          value="Facility Manager"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Company Name <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Sky" id="sky" placeholder="NovaWaveLLC" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Email <span class="text-danger"> *</span></label
                          >
                          <div
                            class="status-toggle small-toggle-btn d-flex align-items-center"
                          >
                            <span class="me-2 label-text">Option</span>
                            <input type="checkbox" id="user1" class="check" checked="" />
                            <label for="user1" class="checktoggle"></label>
                          </div>
                        </div>
                        <input
                          class="form-control"
                          type="email"
                          value="Robertson@example.com"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone Number 1<span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="+1 875455453" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone Number 2<span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="+1 895455455" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Fax </label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Deals <span class="text-danger">*</span></label
                          >
                          <a href="javascript:void(0);" class="add-new"
                            ><i class="la la-plus-circle me-2"></i>Add New</a
                          >
                        </div>
                        <vue-select
                          :options="Collins"
                          id="collins"
                          placeholder="Collins"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Date of birth <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" value="02-05-1998" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Reviews <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Highest"
                          id="highest"
                          placeholder="Lowest"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Owner <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Guillory"
                          id="guillory"
                          placeholder="Hendry"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Industry <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Cuda" id="cuda" placeholder="Barry Cuda" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Currency <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Icons" id="icons" placeholder="$" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Language <span class="text-danger">*</span></label
                        >
                        <vue-select :options="French" id="french" placeholder="English" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Tags <span class="text-danger">*</span></label
                        >
                        <vue3-tags-input
                          class="input-tags form-control"
                          type="text"
                          data-role="tagsinput"
                          name="Label"
                          id="inputBox"
                          :tags="tags3"
                          value="Label, Label"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Source <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Tressa"
                          id="barrycuda"
                          placeholder="Barry Cuda"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Comments<span class="text-danger">*</span></label
                        >
                        <textarea class="form-control" rows="5"></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button
                        class="btn btn-primary wizard-next-btn"
                        type="button"
                        @click="editFieldsetTwo"
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset :style="{ display: editFieldTwo ? 'block' : 'none' }">
              <form action="/crm/contact-list">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Street Address<span class="text-danger"> *</span></label
                        >
                        <input
                          class="form-control"
                          type="text"
                          value="38 Simpson Stree"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >City <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="Rock Island" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >State / Province <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="USA" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Country <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Germany"
                          id="germany"
                          placeholder="Germany"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Zipcode <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" value="65" />
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button
                        class="btn btn-primary wizard-next-btn"
                        type="button"
                        @click="editFieldsetThree"
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset :style="{ display: editFieldThree ? 'block' : 'none' }">
              <form action="/crm/contact-list">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Facebook</label>
                        <input
                          class="form-control"
                          type="text"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Twitter</label>
                        <input
                          class="form-control"
                          type="text"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Linkedin</label>
                        <input
                          class="form-control"
                          type="text"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Skype</label>
                        <input
                          class="form-control"
                          type="text"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Whatsapp</label>
                        <input
                          class="form-control"
                          type="text"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Instagram</label>
                        <input
                          class="form-control"
                          type="text"
                          value="Darlee_Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <a href="javascript:void(0);" class="add-new"
                          ><i class="la la-plus-circle me-2"></i>Add New</a
                        >
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button
                        class="btn btn-primary wizard-next-btn"
                        type="button"
                        @click="editFieldsetFour"
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset :style="{ display: editFieldFour ? 'block' : 'none' }">
              <form action="/crm/contact-list">
                <div class="contact-input-set">
                  <div class="input-blocks add-products">
                    <label class="mb-3">Visibility</label>
                    <div class="access-info-tab">
                      <ul class="nav nav-pills" id="pills-tab2" role="tablist">
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0"
                            id="pills-public-tab2"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-public2"
                            role="tab"
                            aria-controls="pills-public2"
                            aria-selected="true"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="public"
                              checked
                            />
                            <span class="checkmark"></span> Public</span
                          >
                        </li>
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0"
                            id="pills-private-tab2"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-private2"
                            role="tab"
                            aria-controls="pills-private2"
                            aria-selected="false"
                          >
                            <input type="radio" class="form-control" name="private" />
                            <span class="checkmark"></span> Private</span
                          >
                        </li>
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0 active"
                            id="pills-select-people-tab2"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-select-people2"
                            role="tab"
                            aria-controls="pills-select-people2"
                            aria-selected="false"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="select-people"
                            />
                            <span class="checkmark"></span> Select People</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-content" id="pills-tabContent2">
                    <div
                      class="tab-pane fade"
                      id="pills-public2"
                      role="tabpanel"
                      aria-labelledby="pills-public-tab2"
                    ></div>
                    <div
                      class="tab-pane fade"
                      id="pills-private2"
                      role="tabpanel"
                      aria-labelledby="pills-private-tab2"
                    ></div>
                    <div
                      class="tab-pane fade show active"
                      id="pills-select-people2"
                      role="tabpanel"
                      aria-labelledby="pills-select-people-tab2"
                    >
                      <div class="people-select-tab">
                        <h3>Select People</h3>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-19.jpg" alt="Img" />
                              <a href="javascript:void(0);">Darlee Robertson</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-20.jpg" alt="Img" />
                              <a href="javascript:void(0);">Sharon Roy</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-21.jpg" alt="Img" />
                              <a href="javascript:void(0);">Vaughan</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-1.jpg" alt="Img" />
                              <a href="javascript:void(0);">Jessica</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-confirm-btn">
                          <a href="javascript:void(0);" class="btn danger-btn">Confirm</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="mb-3">Status</h5>
                  <div class="status-radio-btns d-flex mb-3">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="test4"
                        name="radio-group"
                        checked
                      />
                      <label for="test4">Active</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="test5"
                        name="radio-group"
                      />
                      <label for="test5">Private</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="test6"
                        name="radio-group"
                      />
                      <label for="test6">Inactive</label>
                    </div>
                  </div>
                  <div class="col-lg-12 text-end form-wizard-button">
                    <button class="button btn-lights reset-btn" type="reset">
                      Reset
                    </button>
                    <button class="btn btn-primary" type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Contact -->
</template>

<script>
import Vue3TagsInput from "vue3-tags-input";
export default {
  data() {
    return {
      Sky: ["NovaWaveLLC", "BlueSky Industries", "SilverHawk"],
      Collins: ["Collins", "Konopelski", "Adams"],
      Highest: ["Lowest", "Highest"],
      Guillory: ["Hendry", "Guillory", "Jami"],
      Cuda: ["Barry Cuda", "Tressa Wexler"],
      Icons: ["$", "€"],
      French: ["English", "French"],
      Tressa: ["Barry Cuda", "Tressa Wexler"],
      Germany: ["Germany", "USA"],
      tags3: ["Label", "Label"],
      editFieldOne: true,
      editFieldTwo: false,
      editFieldThree: false,
      editFieldFour: false,
    };
  },
  methods: {
    editFieldsetTwo() {
      this.editFieldOne = false;
      this.editFieldTwo = true;
      this.editFieldThree = false;
      this.editFieldFour = false;
    },
    editFieldsetThree() {
      this.editFieldOne = false;
      this.editFieldTwo = false;
      this.editFieldThree = true;
      this.editFieldFour = false;
    },
    editFieldsetFour() {
      this.editFieldOne = false;
      this.editFieldTwo = false;
      this.editFieldThree = false;
      this.editFieldFour = true;
    },
  },
  components: {
    Vue3TagsInput,
  },
};
</script>
