<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Müqavilə əlavə edilməsi</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Müştəri </label>
                    <input
                      v-model="customerName"
                      class="form-control"
                      type="text"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Şirkət <span class="text-danger">*</span></label
                    >

                    <vue-select
                      v-if="props.companiesOptionsList.length > 0"
                      :options="props.companiesOptionsList"
                      :name="company_id"
                      id="companyAdd"
                      v-model="formData.company_id"
                      :class="{
                        'is-invalid': $v.formData.company_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.company_id.$error,
                      }"
                    />
                    <p v-else>Şirkət əlavə olunmalıdır</p>
                    <template
                      v-if="isSubmitForm && $v.formData.company_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Rekvizit </label>
                    <vue-select
                      v-if="props.requisitesOptionsList.length > 0"
                      :options="props.requisitesOptionsList"
                      :name="requisite_id"
                      id="requisiteAdd"
                      v-model="formData.requisite_id"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                    <p v-else>Rekvizit əlavə olunmalıdır</p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Müqavilə nömrəsi <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.code"
                      class="form-control"
                      type="text"
                      name="code"
                      :disabled="disabledIsAutoCode"
                      :class="{
                        'is-invalid': $v.formData.code.$error,
                        'is-valid': isSubmitForm && !$v.formData.code.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.code.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Limit məbləği <span class="text-danger">*</span></label
                    >
                    <input
                      v-model="formData.amount"
                      class="form-control"
                      type="text"
                      name="amount"
                      :class="{
                        'is-invalid': $v.formData.amount.$error,
                        'is-valid': isSubmitForm && !$v.formData.amount.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.amount.$error">
                      <p
                        class="text-danger mt-1"
                        v-if="
                          isSubmitForm && $v.formData.amount.required.$invalid
                        "
                      >
                        Bu vacib məlumatdır
                      </p>
                      <p
                        class="text-danger mt-1"
                        v-if="
                          isSubmitForm && $v.formData.amount.numeric.$invalid
                        "
                      >
                        Sadəcə rəqəmlərdən ibarət olmalıdır
                      </p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Başlama tarixi <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <flat-pickr
                          :config="dateTime"
                          placeholder="il-ay-gün"
                          v-model="formData.start_date"
                          :class="{
                            'is-invalid': $v.formData.start_date.$error,
                            'is-valid': isSubmitForm && !$v.formData.start_date.$error,
                          }"
                          id="startDateAdd" class="form-control">
                      </flat-pickr>
                    </div>
                    <template
                      v-if="isSubmitForm && $v.formData.start_date.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Bitmə tarixi <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <flat-pickr
                          :config="dateTime"
                          placeholder="il-ay-gün"
                          v-model="formData.end_date"
                          :class="{
                            'is-invalid': $v.formData.end_date.$error,
                            'is-valid': isSubmitForm && !$v.formData.end_date.$error,
                          }"
                          id="endDateAdd" class="form-control">
                      </flat-pickr>
                      <template
                        v-if="isSubmitForm && $v.formData.end_date.$error"
                      >
                        <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                      </template>
                    </div>
                  </div>
                </div>


                <div class="col-md-6">
                  <label class="col-form-label">Fayllar</label>
                  <FileUploadCustom v-model="files" :multiple="true" ref="dropzoneUpload"/>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button class="btn btn-primary" type="submit">
                    Yadda saxla
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  decimal,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useRoute } from "vue-router";
import { useContracts } from "@/stores/modules/contracts";
import { useFileUpload } from "@/stores/modules/fileUpload";
import FileUploadCustom from "@/components/file-upload-custom.vue";
import FlatPickr from 'vue-flatpickr-component';

const contractsStore = useContracts();
const fileUplaodStore = useFileUpload();
const route = useRoute();

const props = defineProps({
  companiesOptionsList: {
    type: Array,
    required: true,
    default: [],
  },
  requisitesOptionsList: {
    type: Array,
    required: true,
    default: [],
  },
});

const customerId = route.query.customerId || 0;
const customerName = route.query.customerName || null;
let disabledIsAutoCode = ref(true);

const dropzoneUpload = ref(null);
const files = ref([]);

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const dateTime = ref({
    dateFormat: 'Y-m-d',
    allowInput: true,
    position: 'auto right'
});

const formData = reactive({
  company_id: null,
  customer_id: 0,
  requisite_id: null,
  code: "",
  start_date: "",
  end_date: "",
  amount: null,
  file_ids: [],
});

const rules = {
  formData: {
    company_id: { required },
    requisite_id: {},
    code: { required },
    amount: { required, numeric },
    start_date: { required },
    end_date: { required },
  },
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.company_id = null;
  formData.customer_id = 0;
  formData.requisite_id = null;
  formData.code = "";
  formData.start_date = "";
  formData.end_date = "";
  formData.amount = null;
  formData.file_ids = [];
  files.value = [];
  dropzoneUpload.value.clearFiles();
};

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();

  if ($v.value.formData.$invalid) {
    return false;
  }

  formData.customer_id = customerId;

  try {
    let uploadFiles = new FormData();

    files.value.forEach((item, index) => {
        uploadFiles.append(`files[${index}]`, item);
    });


    if (files.value.length > 0) {
      formData.file_ids = await fileUplaodStore.store(uploadFiles);
    }

    await contractsStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("addDataModal");
    });
  } catch (error) {
    console.error(error);
  }
};

watch(
  () => formData.company_id,
  async (newCompanyId) => {
    if (newCompanyId) {
      await contractsStore.getCurrentCode(newCompanyId);

      disabledIsAutoCode.value = contractsStore.dataCurrentCode.data.disabled;

      formData.code = contractsStore.dataCurrentCode.data.code;
    }
  }
);
</script>

  