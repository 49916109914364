<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Creditor hesabat</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard"
                  >Daşbord</router-link
                >
              </li>
              <li class="breadcrumb-item active">Creditor hesabat</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a
                  href="javascript:void(0);"
                  class="grid-view btn btn-link"
                  v-tippy
                  title="Filtri sıfırla"
                  @click="resetSearchFilter"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  v-tippy
                  title="Tam ekran"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-tippy
                  title="Filterlər"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <search-area
        :filter="filter"
        ref="searchComponent"
        :searchFilters="searchFilters"
        @submitSearchFilter="searchFilterSubmitHandler"
      ></search-area>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <form @submit.prevent="searchSubmit">
              <div class="search-set">
                <div class="search-input">
                  <a
                    href="javascript:void(0);"
                    @click="searchSubmit"
                    class="btn btn-searchset"
                    ><i class="las la-search"></i
                  ></a>
                  <div class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Axtar"
                        v-model="searchString"
                    /></label>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table
              class="stripped table-hover"
              :columns="columns"
              :data-source="data"
              :pagination="false"
              @change="handleTableChange"
            >
              <!-- <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'customer_id'">
                  {{ record.customer.name }}
                </template>
              </template> -->
            </a-table>
            <a-pagination
              :current="currentPage"
              :total="totalData"
              :page-size="pageSize"
              @change="handlePageChange"
              class="mt-3 text-end mb-5"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, onMounted, reactive, onBeforeUnmount, watch } from "vue";

import {
  handleTableSorterChange,
  showErrorToast,
  toggleViewFullscreen,
} from "@/utils/helper";
import { useReportsStore } from "@/stores/modules/reports";
import { useCustomersAndSuppliers } from "@/stores/modules/customerAndSuppliers";

// Store
const reportStore = useReportsStore();
const customerStore = useCustomersAndSuppliers();

// State
const data = ref([]);

// Pagination and Filters
const filter = ref(true);
const searchComponent = ref(null);
let searchString = ref("");
let searchFiltersValue = reactive({});
let currentPage = ref(1);
let pageSize = ref(15);
let totalData = ref(0);

const sortParams = ref({
  sortField: "id",
  sortOrder: "desc",
});

const customerOptions = ref([]);

// Columns
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
  },
  {
    title: "Təchizatçı adı ",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Dövr əvvəlinə qaliq",
    dataIndex: "total_amount_before",
    sorter: false,
  },
  {
    title: "Dövr ərzində qaimə",
    dataIndex: "total_invoices_amount_after",
    sorter: false,
  },
  {
    title: "Dövr ərzində ödəniş",
    dataIndex: "total_transactions_amount_after",
    sorter: false,
  },
  {
    title: "Dövr sonuna qalıq",
    dataIndex: "total_amount_after",
    sorter: false,
  },
];

//Filters
const searchFilters = ref([
  {
    title: "Tarix aralığı",
    key: "between_date",
    type: "date",
    class: "col-xl-4",
    required: true,
  },
  {
    title: "Müştəri",
    key: "customer_id",
    type: "select",
    options: customerOptions,
    class: "col-xl-4",
  },
]);

/* -------------------- Methods -------------------- */

// Fetch data with pagination
const fetchListData = async (
  page = 1,
  searchString = "",
  searchFilters = searchFiltersValue
) => {
  try {
    const response = await reportStore.getCreditor({
      params: {
        offset: (page - 1) * pageSize.value,
        limit: pageSize.value,
        q: searchString,
        sortField: sortParams.value.sortField,
        sortOrder: sortParams.value.sortOrder,
        ...searchFilters,
      },
    });
    const responseData = response.data;
    data.value = responseData.data;
    totalData.value = responseData.total;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Search items
const searchSubmit = async () => {
  fetchListData(1, searchString.value);
};

// Search Filters Submit
const searchFilterSubmitHandler = async (filters) => {
  await fetchListData(1, null, filters);
  searchFiltersValue = filters;
};

const resetSearchFilter = async () => {
  // await fetchListData();
  searchComponent.value.resetFilters();
  data.value = [];
};

// Handle page change for pagination
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchListData(page);
};

// Handle Table Changes (Sorting)
const handleTableChange = async (pagination, filters, sorter) => {
  await handleTableSorterChange(sorter, sortParams);
  fetchListData(currentPage.value);
};

const createCustomerOptionsList = async () => {
  await customerStore.getList({
    params: {
      limit: 100000,
      is_supplier:1
    },
  });
  customerOptions.value = customerStore.dataList.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));

  searchFilters.value = [
    {
      title: "Tarix aralığı",
      key: "between_date",
      type: "date",
      class: "col-xl-4",
      required: true,
    },
    {
      title: "Müştəri",
      key: "customer_id",
      type: "select",
      options: customerOptions,
      class: "col-xl-4",
    },
  ];
};

const toggleFullscreen = () => toggleViewFullscreen();

/* -------------------- Lifecycle Hooks -------------------- */

const debounceTime = 500;

let debounceTimeout;

watch(searchString, () => {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    searchSubmit();
  }, debounceTime);
});

// On component mount
onMounted(() => {
  createCustomerOptionsList();
});

// Before unmount
onBeforeUnmount(() => {
  // document.removeEventListener("click", handleDocumentClick);
});
</script>
  
  <style>
.ant-pagination-item a {
  color: black !important;
}

.ant-pagination-item-active a {
  color: white !important;
}
</style>
  