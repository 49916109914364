<template>
  <div
    class="modal custom-modal fade modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Yeni kateqoriya</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Kateqoriya adı <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.name"
                      class="form-control"
                      type="text"
                      name="name"
                      :class="{
                        'is-invalid': $v.formData.name.$error,
                        'is-valid': isSubmitForm && !$v.formData.name.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.name.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="d-block">Kateqoriya Tipi</label>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type_id"
                        id="income"
                        value="0"
                        v-model="formData.type_id"
                        checked
                      />
                      <label class="form-check-label" for="income">Gəlir</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type_id"
                        id="expense"
                        value="1"
                        v-model="formData.type_id"
                      />
                      <label class="form-check-label" for="expense">Xərc</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Qeyd
                    </label>

                    <textarea
                      v-model="formData.note"
                      class="form-control"
                      type="text"
                      name="note"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    @click="closeModal"
                  >
                    Sıfırla
                  </button>
                  <button class="btn btn-primary" type="submit">Yadda saxla</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useCategoryStore } from "@/stores/modules/categories";

const categoryStore = useCategoryStore();

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const formData = reactive({
  name: "",
  type_id: 0,
  note: null,
});

const rules = {
  formData: {
    name: { required },
  },
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.type_id = 0;
  formData.name = "";
  formData.note = null;
};

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await categoryStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("addDataModal");
    });
  } catch (error) {
    console.error(error)
  }
};

</script>

  