<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="editDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Xidməti yeilə</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Xidmətin adı <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.name"
                      class="form-control"
                      type="text"
                      name="name"
                      :class="{
                        'is-invalid': $v.formData.name.$error,
                        'is-valid': isSubmitForm && !$v.formData.name.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.name.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Xidmətin kodu <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.code"
                      class="form-control"
                      type="text"
                      name="code"
                      :class="{
                        'is-invalid': $v.formData.code.$error,
                        'is-valid': isSubmitForm && !$v.formData.code.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.code.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Ölçü vahidi<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      v-if="props.measuresOptionsList.length > 0"
                      :options="props.measuresOptionsList"
                      name="measure_id"
                      id="serviceEdit"
                      v-model="formData.measure_id"
                      :class="{
                        'is-invalid': $v.formData.measure_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.measure_id.$error,
                      }"
                      /> 
                    <p v-else>No measure available.</p>

                    <template
                      v-if="isSubmitForm && $v.formData.measure_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Xidmətin dəyəri (azn) <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.price"
                      class="form-control"
                      type="text"
                      name="price"
                      :class="{
                        'is-invalid': $v.formData.price.$error,
                        'is-valid': isSubmitForm && !$v.formData.price.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.price.$error">
                      <p v-if="$v.formData.price.required.$invalid" class="text-danger mt-1">{{ $v.formData.price.required.$message }}</p>
                      <p v-else-if="$v.formData.price.decimal.$invalid" class="text-danger mt-1">{{ $v.formData.price.decimal.$message }}</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Qeyd
                    </label>

                    <textarea
                      v-model="formData.note"
                      class="form-control"
                      type="text"
                      name="note"
                      rows="4"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="d-block">Xidmət növləri</label>
                    <div class="form-check form-check-inline">
                      <div class="checkbox">
                        <label for="internal" class="form-check-label"> 
                          <input
                          v-model="formData.is_internal"
                          type="checkbox" 
                          id="internal"
                          name="is_internal"
                          />
                           Daxili </label>
                      </div>
                    </div>
                    <div class="form-check form-check-inline">
                      <div class="checkbox">
                        <label for="external" class="form-check-label"> 
                          <input
                          v-model="formData.is_external"
                          type="checkbox" 
                          id="external"
                          name="is_external"/>
                           Xarici </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    @click="closeModal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Yadda saxla</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  decimal
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useServices } from '@/stores/modules/services';

const servicesStore = useServices();

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const props = defineProps({
  id:{
    type: Number,
    required: true
  },
  measuresOptionsList: {
    type: Array,
    default:[]
  }
});



const formData = reactive({
    code: null,
    name: undefined,
    measure_id: 0,
    status: 0,
    price: null,
    note: "",
    is_internal: false,
    is_external: false,
    measureObj: null
})


const rules = {
    formData: {
        name: { required },
        code: { required },
        measure_id: { required },
        price: {
            required : helpers.withMessage('Bu vacib məlumatdır', required),
            decimal : helpers.withMessage('Ancaq rəqəm (onluq ədədləri) qəbul edir.', decimal),
        },
    },
};


const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
    formData.code = null;
    formData.name = undefined;
    formData.measure_id = 0;
    formData.price = null;
    formData.is_internal = false;
    formData.is_external = false;
    formData.note = '';
};

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await servicesStore.update(formData, props.id).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("editDataModal");
    });
  } catch (error) {
    console.error(error)
  }
}

watch(() => props.id, async (newId) => {
  try {
    if (newId) {
      await servicesStore.getDetail(newId);
      Object.assign(formData, servicesStore.dataService.data);
      formData.measure_id = formData.measure?.id;
    }
  } catch (error) {
    console.error(error)
  }
});
</script>

  