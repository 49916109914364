<template>
  <a-table class="stripped table-hover" :columns="columns" :data-source="data">
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Star'">
        <div>
          <div class="set-star star-select">
            <i
              :class="[record.Star, { filled: record.isFilled }]"
              @click="toggleStar(record)"
            ></i>
          </div>
        </div>
      </template>
      <template v-else-if="column.key === 'Name'">
        <h2 class="table-avatar d-flex">
          <router-link to="/crm/contact-details" class="avatar"
            ><img :src="require(`@/assets/img/avatar/${record.Image}`)" alt="User Image"
          /></router-link>
          <router-link to="/crm/contact-details" class="profile-split d-flex flex-column"
            >{{ record.Name }} <span>{{ record.Role }}</span></router-link
          >
        </h2>
      </template>
      <template v-else-if="column.key === 'Tags'">
        <td>
          <span :class="record.TagClass">{{ record.Tags }}</span>
        </td>
      </template>
      <template v-else-if="column.key === 'Rating'">
        <div class="set-star">
          <i class="fa fa-star filled me-2"></i>{{ record.Rating }}
        </div>
      </template>
      <template v-else-if="column.key === 'Contact'">
        <ul class="social-links d-flex align-items-center">
          <li>
            <a href="javascript:void(0);"><i class="la la-envelope"></i></a>
          </li>
          <li>
            <a href="javascript:void(0);"><i class="la la-phone-volume"></i></a>
          </li>
          <li>
            <a href="javascript:void(0);"><i class="lab la-facebook-messenger"></i></a>
          </li>
          <li>
            <a href="javascript:void(0);"><i class="la la-skype"></i></a>
          </li>
          <li>
            <a href="javascript:void(0);"><i class="la la-facebook"></i></a>
          </li>
        </ul>
      </template>
      <template v-else-if="column.key === 'Status'">
        <div class="dropdown action-label">
          <a href="javascript:void(0);" :class="record.StatusClass"> {{ record.Status }} </a>
        </div>
      </template>
      <template v-else-if="column.key === 'action'">
        <div class="text-end">
          <div class="dropdown dropdown-action">
            <a
              href="javascript:void(0);"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#edit_contact"
                ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
              >
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#delete_contact"
                ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
              >
              <router-link class="dropdown-item" to="/crm/contact-details"
                ><i class="fa-regular fa-eye"></i> Preview</router-link
              >
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#add_notes"
                ><i class="la la-file-prescription"></i> Notes</a
              >
            </div>
          </div>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
const data = [
  {
    Star: "fa fa-star filled",
    Image: "avatar-19.jpg",
    Name: "Darlee Robertson",
    Role: "Facility Manager",
    Phone: "+1 875455453",
    Email: "robertson@example.com",
    Tags: "Promotion",
    TagClass: "badge badge-soft-info",
    Location: "Germany",
    Rating: "4.2",
    Owner: "Hendry",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-20.jpg",
    Name: "Sharon Roy",
    Role: "Installer",
    Phone: "+1 989757485",
    Email: "sharon@example.com",
    Tags: "Rated",
    TagClass: "badge badge-soft-warning",
    Location: "USA",
    Rating: "5",
    Owner: "Guillory",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-21.jpg",
    Name: "Vaughan",
    Role: "Senior Manager",
    Phone: "+1 546555455",
    Email: "vaughan12@example.com",
    Tags: "Promotion",
    TagClass: "badge badge-soft-info",
    Location: "Canada",
    Rating: "3.5",
    Owner: "Jami",
    Contact: "",
    Status: "Inactive",
    StatusClass: "btn btn-white btn-sm badge-outline-danger",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-1.jpg",
    Name: "Jessica",
    Role: "Test Engineer",
    Phone: "+1 454478787",
    Email: "jessica13@example.com",
    Tags: "Rated",
    TagClass: "badge badge-soft-warning",
    Location: "India",
    Rating: "4.5",
    Owner: "Theresa",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-16.jpg",
    Name: "Carol",
    Role: "ThomasUI /UX Designer",
    Phone: "+1 124547845",
    Email: "carolTho3@example.com",
    Tags: "Rejected",
    TagClass: "badge badge-soft-danger",
    Location: "China",
    Rating: "4.7",
    Owner: "Espinosa",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-22.jpg",
    Name: "Dawn Mercha",
    Role: "Technician",
    Phone: "+1 478845447",
    Email: "dawnmercha@example.com",
    Tags: "Rated",
    TagClass: "badge badge-soft-warning",
    Location: "Japan",
    Rating: "5",
    Owner: "Martin",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-23.jpg",
    Name: "Rachel Hampton",
    Role: "Software Developer",
    Phone: "+1 215544845",
    Email: "rachel@example.com",
    Tags: "Rejected",
    TagClass: "badge badge-soft-danger",
    Location: "Indonesia",
    Rating: "3.1",
    Owner: "Newell",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star filled",
    Image: "avatar-24.jpg",
    Name: "Jonelle",
    Role: "CurtissSupervisor",
    Phone: "+1 121145471",
    Email: "jonelle@example.com",
    Tags: "Rejected",
    TagClass: "badge badge-soft-danger",
    Location: "Cuba",
    Rating: "5",
    Owner: "Janet",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-25.jpg",
    Name: "Jonathan",
    Role: "Team Lead Dev",
    Phone: "+1 321454789",
    Email: "jonathan@example.com",
    Tags: "Collab",
    TagClass: "badge badge-soft-danger",
    Location: "Isreal",
    Rating: "2.7",
    Owner: "Craig",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
  {
    Star: "fa fa-star",
    Image: "avatar-26.jpg",
    Name: "Brook Team",
    Role: "Lead Dev",
    Phone: "+1 278907145",
    Email: "brook@example.com",
    Tags: "Calls",
    TagClass: "badge badge-soft-purple",
    Location: "Colombia",
    Rating: "3",
    Owner: "Daniel",
    Contact: "",
    Status: "Active",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
  },
];

const columns = [
  {
    title: "Star",
    dataIndex: "Star",
    key: "Star",
    sorter: {
      compare: (a, b) => {
        a = a.Star.toLowerCase();
        b = b.Star.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Tags",
    dataIndex: "Tags",
    key: "Tags",
    sorter: {
      compare: (a, b) => {
        a = a.Tags.toLowerCase();
        b = b.Tags.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Location",
    dataIndex: "Location",
    sorter: {
      compare: (a, b) => {
        a = a.Location.toLowerCase();
        b = b.Location.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Rating",
    dataIndex: "Rating",
    key: "Rating",
    sorter: {
      compare: (a, b) => {
        a = a.Rating.toLowerCase();
        b = b.Rating.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Owner",
    dataIndex: "Owner",
    sorter: {
      compare: (a, b) => {
        a = a.Owner.toLowerCase();
        b = b.Owner.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contact",
    dataIndex: "Contact",
    key: "Contact",
    sorter: {
      compare: (a, b) => {
        a = a.Contact.toLowerCase();
        b = b.Contact.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    sorter: true,
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
  methods: {
    toggleStar(record) {
      record.isFilled = !record.isFilled;
    },
  },
};
</script>
