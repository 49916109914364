<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <horizontal-header></horizontal-header>
          <vertical-sidebar></vertical-sidebar>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->

  <two-col-sidebar></two-col-sidebar>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
    };
  },
  methods: {
    scrollHanle() {},
    redirectReload() {
      this.$router.push({ path: "/horizontal-timeline" }).then(() => {
        this.$router.go();
      });
    },
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
