import config from './config.json';
export default {
  env: {
    dev: {
      BASE_URL: "http://127.0.0.1:8000/api/v1/",
    },
    preProd: {
      BASE_URL: "http://api-crm.datum.az/api/v1/",
    },
    prod: {
      BASE_URL: "http://api-crm.datum.az/api/v1/",
    },
  },
  mode:config.MAINTENANCE_MODE,
  getParam(param) {
    return this.env[this.mode][param];
  },
  getMode() {
    return this.mode;
  },
};
