import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';


export const useContracts = defineStore('contracts', {
    state: () => ({
        dataList : null,
        dataContract : null,
        dataCurrentCode : {},
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['contracts'],{...payload})
                .then((res) => {
                    this.dataList = res;

                    // store.showLoading(false);
                    return res;
                }).finally(() => {

                });
        },
        async getDetail( id ){
            try {
                await axiosInstance.get(`${urlList['contracts']}/${id}`).then((res)=>{
                    this.dataContract = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData ) {
            try {
                return await axiosInstance.post(urlList['contracts'], formData).then((res) => {
                    return res
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData , id ) {
            try {
                return axiosInstance.put(`${urlList['contracts']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData , id ) {
            try {
                return axiosInstance.put(`${urlList['contracts']}/update/${id}`, statusData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id) {
            try {
                return await axiosInstance.delete(`${urlList['contracts']}/${id}`).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        },
        async getCurrentCode( id ){
            try {
                await axiosInstance.get(`${urlList['contracts']}/current-code/${id}`).then((res)=>{
                    this.dataCurrentCode = res;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
    },
    getters: {}
});
