<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Leads</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Leads</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <hr />

      <div class="row">
        <!-- Contact User -->
        <div class="col-md-12">
          <div class="contact-head">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <ul class="contact-breadcrumb">
                  <li>
                    <router-link to="/crm/leads"
                      ><i class="las la-arrow-left"></i> Leads</router-link
                    >
                  </li>
                  <li>Tremblay and Rath</li>
                </ul>
              </div>
              <div class="col-sm-6 text-sm-end">
                <div class="contact-pagination">
                  <p>1 of 40</p>
                  <ul>
                    <li>
                      <router-link to="/crm/leads-details"
                        ><i class="las la-arrow-left"></i
                      ></router-link>
                    </li>
                    <li>
                      <router-link to="/crm/leads-details"
                        ><i class="las la-arrow-right"></i
                      ></router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-wrap">
            <div class="contact-profile">
              <div class="avatar company-avatar">
                <span class="text-icon">HT</span>
              </div>
              <div class="name-user">
                <h4>
                  Tremblay and Rath
                  <span class="star-icon"><i class="fa-solid fa-star"></i></span>
                </h4>
                <p><i class="las la-building"></i> SilverHawk</p>
                <p class="mb-0">
                  <i class="las la-map-marker"></i> 22, Ave Street, Newyork, USA
                </p>
              </div>
            </div>
            <div class="contacts-action">
              <span class="badge badge-light"><i class="las la-lock"></i>Private</span>
              <div class="dropdown action-drops">
                <a
                  href="javascript:void(0);"
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>Closed<i class="las la-angle-down ms-2"></i></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="javascript:void(0);"
                    ><span>Closed</span></a
                  >
                  <a class="dropdown-item" href="javascript:void(0);"
                    ><span>Opened</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Contact User -->

        <!-- Contact Sidebar -->
        <leads-sidebar></leads-sidebar>
        <!-- /Contact Sidebar -->

        <!-- Contact Details -->
        <div class="col-xl-9">
          <div class="contact-tab-wrap">
            <h4>Lead Pipeline Status</h4>
            <div class="pipeline-list">
              <ul>
                <li>
                  <a href="javascript:void(0);" class="bg-pending">Not Contacted</a>
                </li>
                <li><a href="javascript:void(0);" class="bg-info">Contacted</a></li>
                <li><a href="javascript:void(0);" class="bg-warning">Closed</a></li>
                <li><a href="javascript:void(0);" class="bg-danger">Lost</a></li>
              </ul>
            </div>
            <ul class="contact-nav nav">
              <li>
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="tab"
                  data-bs-target="#activities"
                  class="active"
                  ><i class="las la-user-clock"></i>Activities</a
                >
              </li>
              <li>
                <a href="javascript:void(0);" data-bs-toggle="tab" data-bs-target="#notes"
                  ><i class="las la-file"></i>Notes</a
                >
              </li>
              <li>
                <a href="javascript:void(0);" data-bs-toggle="tab" data-bs-target="#calls"
                  ><i class="las la-phone-volume"></i>Calls</a
                >
              </li>
              <li>
                <a href="javascript:void(0);" data-bs-toggle="tab" data-bs-target="#files"
                  ><i class="las la-file"></i>Files</a
                >
              </li>
              <li>
                <a href="javascript:void(0);" data-bs-toggle="tab" data-bs-target="#email"
                  ><i class="las la-envelope"></i>Email</a
                >
              </li>
            </ul>
          </div>

          <!-- Tab Content -->
          <div class="contact-tab-view">
            <div class="tab-content pt-0">
              <!-- Activities -->
              <leads-active></leads-active>
              <!-- /Activities -->

              <!-- Notes -->
              <leads-notes></leads-notes>
              <!-- /Notes -->

              <!-- Calls -->
              <leads-calls></leads-calls>
              <!-- /Calls -->

              <!-- Files -->
              <leads-files></leads-files>
              <!-- /Files -->

              <!-- Email -->
              <leads-email></leads-email>
              <!-- /Email -->
            </div>
          </div>
          <!-- /Tab Content -->
        </div>
        <!-- /Contact Details -->
      </div>
    </div>
  </div>

  <company-details-modal></company-details-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toggleFullscreen() {
      const elem = document.documentElement;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
  },
};
</script>
