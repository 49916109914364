<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Yeni hesab</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Şirkət <span class="text-danger">*</span>
                    </label>

                    <vue-select
                      v-if="props.companiesOptionsList.length > 0"
                      :options="props.companiesOptionsList"
                      :name="company_id"
                      id="companyAccountAdd"
                      v-model="formData.company_id"
                      :class="{
                        'is-invalid': $v.formData.company_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.company_id.$error,
                      }"
                      /> 
                    <p v-else>No companies available.</p>

                    <template
                      v-if="isSubmitForm && $v.formData.company_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="d-block">Hesab Tipi</label>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type_id"
                        id="cash"
                        value="0"
                        v-model="formData.type_id"
                        checked
                      />
                      <label class="form-check-label" for="cash">Nağd</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type_id"
                        id="bank"
                        value="1"
                        v-model="formData.type_id"
                      />
                      <label class="form-check-label" for="bank">Bank</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Hesab adı <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.name"
                      class="form-control"
                      type="text"
                      name="name"
                      :class="{
                        'is-invalid': $v.formData.name.$error,
                        'is-valid': isSubmitForm && !$v.formData.name.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.name.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Hesab kodu <span class="text-danger">*</span>
                    </label>
                    <input
                      v-model="formData.code"
                      class="form-control"
                      type="text"
                      name="code"
                      :class="{
                        'is-invalid': $v.formData.code.$error,
                        'is-valid': isSubmitForm && !$v.formData.code.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.code.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <template v-if="formData.type_id == 1">
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> Valyuta </label>
                      <input
                        v-model="formData.currency"
                        class="form-control"
                        type="text"
                        name="currency"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> Benefisiarın bank </label>
                      <input
                        v-model="formData.bank_name"
                        class="form-control"
                        type="text"
                        name="bank_name"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> VÖEN </label>
                      <input
                        v-model="formData.bank_voen"
                        class="form-control"
                        type="text"
                        name="bank_voen"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> SWIFT kod </label>
                      <input
                        v-model="formData.swift_code"
                        class="form-control"
                        type="text"
                        name="swift_code"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> Müxbir hesab </label>
                      <input
                        v-model="formData.correspondent_account"
                        class="form-control"
                        type="text"
                        name="correspondent_account"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> Ünvan </label>
                      <input
                        v-model="formData.address"
                        class="form-control"
                        type="text"
                        name="address"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> Telefon </label>
                      <input
                        v-model="formData.phone"
                        class="form-control"
                        type="text"
                        name="phone"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label"> Email </label>
                      <input
                        v-model="formData.email"
                        class="form-control"
                        type="text"
                        name="email"
                        :class="{ 'is-valid': isSubmitForm }"
                      />
                    </div>
                  </div>
                </template>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    @click="closeModal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Yadda saxla</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useCompanyAccounts } from "@/stores/modules/companyAccounts";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";

const props = defineProps({
  companiesOptionsList: {
    type: Array,
    default:[]
  }
});


const accountStore = useCompanyAccounts();

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const formData = reactive({
  code: "",
  name: "",
  type_id: 0,
  status: 1,
  company_id: 0,
  currency: null,
  address: null,
  account_code: null,
  bank_voen: null,
  branch_code: null,
  bank_name: null,
  email: null,
  correspondent_account: null,
  swift_code: null,
  phone: null,
});

const rules = {
  formData: {
    company_id: { required },
    code: { required },
    name: { required },
  },
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.code = "";
  formData.type_id = 0;
  formData.name = "";
  formData.status = 1;
  formData.company_id = 0;
  formData.currency = null;
  formData.address = null;
  formData.account_code = null;
  formData.bank_voen = null;
  formData.branch_code = null;
  formData.bank_name = null;
  formData.email = null;
  formData.correspondent_account = null;
  formData.swift_code = null;
  formData.phone = null;
};

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await accountStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
    });
  } catch (error) {
    showErrorToast(
      error.response?.data?.message || "Xəta baş verdi. Yenidən cəhd edin"
    );
  } finally {
    closeModal("addDataModal");
  }
};

watch(
  () => props.companiesOptionsList,
  (newCompaniesList) => {
    if (newCompaniesList.length > 0) {
      formData.company_id = newCompaniesList[0].id;
    }
  },
  { immediate: true }
);
</script>

  