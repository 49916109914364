<template>
  <div
    class="filter-filelds"
    :style="{ display: filter ? 'block' : 'none' }"
    id="filter_inputs"
  >
    <div class="row filter-row">
      <div
        v-for="(filter, index) in searchFilters"
        :key="index"
        :class="filter.class ?? `col-xl-2`"
      >
      
      <div v-if="filter.type === 'text'">
        <div class="input-block mb-3 form-focus" :class="getFocusClass(filter)">
            <input
            v-if="filter.type === 'text'"
            type="text"
            class="form-control floating"
            v-model="filters[filter.key]"
            @focus="onFocus(filter.key,this)"
            @blur="onBlur(filter.key)"
          />
          <label class="focus-label">{{ filter.title }} <span v-if="filter.required" class="text-danger">*</span></label>

        </div>
      </div>
      <div v-else>
        <div class="input-block mb-3 form-focus focused select-focus">

          <!-- Date Picker -->
          <div class="cal-icon" v-if="filter.type === 'singleDate'">
            <flat-pickr
                :config="dateTime"
                placeholder="il-ay-gün"
                v-model="filters[filter.key]"
                class="form-control">
            </flat-pickr>
          </div>

        <input v-if="filter.type === 'date'"
            type="text"
            class="form-control date-range bookingrange"
            ref="dateRangeInput"
        />

          <!-- Select Input -->
          <vue-select
            v-if="filter.type === 'select'"
            :options="filter.options"
            :id="`select-${filter.key}`" 
            placeholder="---Seçin---"
            v-model="filters[filter.key]"
          />

          <label class="focus-label">{{ filter.title }} <span v-if="filter.required" class="text-danger">*</span></label>
        </div>
      </div>
      </div>
      <div class="col-xl-2">
        <a
          href="javascript:void(0);"
          class="btn btn-success w-100"
          @click="searchFilterSubmited"
        >
          Axtar
        </a>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, reactive, onMounted, nextTick } from "vue";
import FlatPickr from 'vue-flatpickr-component';
import DateRangePicker from "daterangepicker"; // Ensure this is installed
import moment from "moment";

const dateTime = ref({
    dateFormat: 'Y-m-d',
    allowInput: true,
    position: 'auto right'
});

// Props
const props = defineProps({
  searchFilters: {
    type: Array,
    required: true,
  },
  filter: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['submitSearchFilter']);

// Reactive state
const filters = reactive({});
const dateRangeInput = ref(null);
const focusStates = reactive({});


// Methods
const getFocusClass = (filter) => {
  return {
    focused: focusStates[filter.key] || (filters[filter.key] !== ''),
  };
};

const onFocus = (key) => {
  focusStates[key] = true; 
};

const onBlur = (key) => {
  focusStates[key] = false; 
  if (!filters[key]) {
    filters[key] = "";
  }
};

const initDateRangePicker = () => {
  const start = moment().subtract(1, "year");
  const end = moment();

  const bookingRange = (start, end) => {
    filters[props.searchFilters.find(f => f.type === 'date').key] = 
      `${start.format("D/M/YYYY")}-${end.format("D/M/YYYY")}`;
    };

  new DateRangePicker(
    dateRangeInput.value,
    {      
      startDate: start,
      endDate: end,
      ranges: {
        "Bugün": [moment(), moment()],
        "Dünən": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Son 7 Gün": [moment().subtract(6, "days"), moment()],
        "Son 30 Gün": [moment().subtract(29, "days"), moment()],
        "Son 1 İl": [moment().subtract(1, "year"), moment()],
        "Bu Ay": [moment().startOf("month"), moment().endOf("month")],
        "Son Ay": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      locale:{
        firstDay:1,
        format:"DD/MM/YYYY",
        applyLabel:"Təsdiqlə",
        cancelLabel:"Bağla",
        customRangeLabel:"Aralıq seçin"
      }
    },
    bookingRange
  );

  // Set the initial date range in filters
  bookingRange(start, end);
};

const searchFilterSubmited = () => {
  emit("submitSearchFilter", filters); 
};

const resetFilters = () => {
  props.searchFilters.forEach((filter) => {
    if(filter.key != 'between_date'){
      filters[filter.key] = "";
    }
  });
  if (dateRangeInput.value) {
    dateRangeInput.value.value = '';
  }
};

// Initialize filters on component mount
onMounted(() => {
  props.searchFilters.forEach((filter) => {
    filters[filter.key] = ""; 
  });

  nextTick(() => {
    if (dateRangeInput.value) {
      initDateRangePicker();
    }
  });
});


defineExpose({
  resetFilters
});
</script>

<style>
.select2-container{
  display: inline;
}
</style>
  