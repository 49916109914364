<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="container">
          <!-- Account Logo -->
          <!-- <div class="account-logo">
            <router-link to="/dashboard/admin-dashboard">
              <img src="@/assets/img/logo2.png" alt="Dreams Technologies" />
            </router-link>
          </div> -->
          <!-- /Account Logo -->

          <div class="account-box">
            <div class="account-wrapper">
              <h3 class="account-title">Daxil ol</h3>
              <p class="account-subtitle">Sistemə giriş</p>

              <!-- Account Form -->
              <Form
                @submit="onSubmit"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="input-block mb-4">
                  <label class="col-form-label">Email</label>
                  <Field
                    name="email"
                    type="text"
                    autofocus
                    autocomplete="email"
                    class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback" v-show="errors.email">
                    {{ errors.email }}
                  </div>
                  <div class="invalid-feedback" v-show="errorsBag.email">
                    {{ errorsBag.email }}
                  </div>
                </div>
                <div class="input-block mb-4">
                  <div class="row align-items-center">
                    <div class="col">
                      <label class="col-form-label">Şifrə</label>
                    </div>
                    <!-- <div class="col-auto">
                      <router-link class="text-muted" to="forgot-password"
                        >Forgot password?</router-link
                      >
                     </div> -->
                  </div>
                  <div class="position-relative pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input mt-2"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="
                          showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                      ></i>
                    </span>
                    <div class="invalid-feedback" v-show="errors.password">
                      {{ errors.password }}
                    </div>
                  </div>
                </div>
                <div class="input-block mb-4 text-center">
                  <button class="btn btn-primary account-btn" type="submit">
                    Daxil ol
                  </button>
                </div>
               <!-- <div class="account-footer">
                  <p>
                    Don't have an account yet?
                    <router-link to="register">Register</router-link>
                  </p>
                </div> -->
              </Form>
              <!-- /Account Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>

<script setup>
import { Field, Form, useForm } from "vee-validate";
import * as Yup from "yup";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { useAppStore } from "@/stores";

const showPassword = ref(false);
const authStore = useAuthStore();
const router = useRouter();

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const toggleShow = () => {
  showPassword.value = !showPassword.value;
};

const { handleSubmit, errors } = useForm({ validationSchema: schema });

const errorsBag = ref({
  email: null,
  password: null,
});

const onSubmit = (values) => {
  authStore
    .login(values)
    .then((res) => {
      router.push("dashboard");
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        errorsBag.value.email = "İstifadəçi adı və ya parol səhvdir.";
      } else {
        errorsBag.value.email = "Naməlum xəta";
      }
    });
};

setTimeout(() => {
  const store = useAppStore();
  store.showLoading(false,true);
}, 300);
</script>

<style scoped>
/* Add any necessary styles here */
</style>
