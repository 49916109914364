<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    :id="modalId"
    ref="addModal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">{{ modalTitle }}</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <Form
            @submit="submitForm"
            :validation-schema="dynamicSchema"
            v-slot="{ errors }"
          >
            <div class="contact-input-set">
              <div class="row">
                <div
                  v-for="(field, index) in formFields"
                  :key="index"
                  :class="field.class"
                >
                  <div class="input-block mb-3"  v-if="field.type === 'toggle' ">
                    <label class="col-form-label">
                      {{ field.label }}
                      <span v-if="field.required" class="text-danger">*</span>
                    </label>
                    <Field
                        :name="field.name"
                        v-model="formData[field.name]"
                        type="checkbox"
                        class="custom_switch"
                        :id="`${modalId}-checkbox-${field.name}`"
                    />
                    <label
                        :for="`${modalId}-checkbox-${field.name}`"
                        class="switch-label"
                    ></label>
                    <span class="text-danger">{{
                      errors[field.name] ? errors[field.name] : ""
                    }}</span>
                  </div>
                  
                  <div class="input-block mb-3"  v-else-if="field.type === 'select' ">
                    <label class="col-form-label">
                      {{ field.label }}
                      <span v-if="field.required" class="text-danger">*</span>
                    </label>
                    
                    <vue-select
                      :options="field.options"
                      :name="field.name"
                      :id="field.name"
                      v-model="formData[field.name]"
                    />
                    
                    <span class="text-danger">{{
                      errors[field.name] ? errors[field.name] : ""
                    }}</span>
                  </div>
                  <div class="input-block mb-3" v-else>
                    <label class="col-form-label">
                      {{ field.label }}
                      <span v-if="field.required" class="text-danger">*</span>
                    </label>
                    <Field
                      :name="field.name"
                      v-model="formData[field.name]"
                      :type="field.type"
                      class="form-control"
                      :class="{ 'is-invalid': errors[field.name] }"
                    />
                    <span class="text-danger">{{
                      errors[field.name] ? errors[field.name] : ""
                    }}</span>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    @click="closeModal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Yadda saxla</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineProps, defineEmits } from "vue";
import { Field, Form, useForm } from "vee-validate";
import * as Yup from "yup";
import { Modal } from "bootstrap";


// Define props for dynamic data
const props = defineProps({
  formData: Object,
  modalTitle: {
    type: String,
    default: "Yeni məlumat yarat",
  },
  modalId: {
    type: String,
    default: 'addDataModal'
  },
  formFields: {
    type: Array,
    required: true,
  },
});

// Emit events
const emit = defineEmits(["formSubmited", "close"]);
const modalRef = ref(null);

const dynamicSchema = Yup.object(
  props.formFields.reduce((schema, field) => {
    if (field.required) {
      let validator = Yup.string().required(`Bu vacib məlumatdır`);
      if (field.name === "voen") {
        validator = validator
          .matches(/^\d+$/, "Ancaq rəqəmlərdən ibarət olmalıdır")
          .length(10, "VOEN 10 simvoldan ibarət olmalıdır");
      }
      schema[field.name] = validator;
    }
    return schema;
  }, {})
);

const { handleSubmit, errors } = useForm({ validationSchema: dynamicSchema });

const closeModal = () => Modal.getInstance(modalRef.value)?.hide();

const submitForm = (values) => {
  emit("formSubmited", values);
 
  closeModal();

  Object.keys(errors).forEach((key) => {
    errors[key] = '';
  });
};
</script>

<style scoped>
.toggle-switch {
  display: flex;
  align-items: center;
}

.custom_switch {
  display: none; /* Hide the default checkbox */
}

.switch-label {
  width: 48px;
  height: 24px;
  background-color: #ccc;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: background-color 0.3s;
}

.custom_switch:checked + .switch-label {
  background-color: #FF902F; /* Change color when checked */
}

.switch-label::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
  top: 2px;
  left: 2px;
}

.custom_switch:checked + .switch-label::before {
  transform: translateX(24px); /* Move the slider to the right when checked */
}
</style>