import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';


export const useCustomerRequisite = defineStore('customerRequisite', {
    state: () => ({
        dataList: null,
        dataRequisite: null,
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['customerRequisite'],{...payload})
                .then((res) => {
                    this.dataList = res;

                    return res;
                }).finally(() => {

                });
        },
        async getDetail( id ){
            try {
                await axiosInstance.get(`${urlList['customerRequisite']}/${id}`).then((res)=>{
                    this.dataRequisite = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData ) {
            try {
                return await axiosInstance.post(urlList['customerRequisite'], formData).then((res) => {
                    return res
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData , id ) {
            try {
                return axiosInstance.put(`${urlList['customerRequisite']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData , id ) {
            try {
                return axiosInstance.put(`${urlList['customerRequisite']}/update/${id}`, statusData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id) {
            try {
                return await axiosInstance.delete(`${urlList['customerRequisite']}/${id}`).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        }
    },
    getters: {}
});
