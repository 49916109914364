<template>
  <section class="review-section personal-excellence">
    <div class="review-header text-center">
      <h3 class="review-title">Personal Excellence</h3>
      <p class="text-muted">Lorem ipsum dollar</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered review-table mb-0">
            <thead>
              <tr>
                <th class="width-pixel">#</th>
                <th>Personal Attributes</th>
                <th>Key Indicators</th>
                <th>Weightage</th>
                <th>Percentage achieved <br />( Self Score )</th>
                <th>Points Scored <br />( Self )</th>
                <th>Percentage achieved <br />( RO's Score )</th>
                <th>Points Scored <br />( RO )</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">1</td>
                <td rowspan="2">Attendance</td>
                <td>Planned or Unplanned Leaves</td>
                <td>
                  <input type="text" class="form-control" readonly value="2" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td>Time Consciousness</td>
                <td>
                  <input type="text" class="form-control" readonly value="2" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td rowspan="2">2</td>
                <td rowspan="2">Attitude & Behavior</td>
                <td>Team Collaboration</td>
                <td>
                  <input type="text" class="form-control" readonly value="2" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td>Professionalism & Responsiveness</td>
                <td>
                  <input type="text" class="form-control" readonly value="2" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Policy & Procedures</td>
                <td>Adherence to policies and procedures</td>
                <td>
                  <input type="text" class="form-control" readonly value="2" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Initiatives</td>
                <td>Special Efforts, Suggestions,Ideas,etc.</td>
                <td>
                  <input type="text" class="form-control" readonly value="2" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Continuous Skill Improvement</td>
                <td>Preparedness to move to next level & Training utilization</td>
                <td>
                  <input type="text" class="form-control" readonly value="3" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td><input type="text" class="form-control" /></td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center">Total</td>
                <td>
                  <input type="text" class="form-control" readonly value="15" />
                </td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
                <td>
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center"><b>Total Percentage(%)</b></td>
                <td colspan="5" class="text-center">
                  <input type="text" class="form-control" readonly value="0" />
                </td>
              </tr>
              <tr>
                <td colspan="8" class="text-center">
                  <div class="grade-span">
                    <h4>Grade</h4>
                    <span class="badge bg-inverse-danger">Below 65 Poor</span>
                    <span class="badge bg-inverse-warning">65-74 Average</span>
                    <span class="badge bg-inverse-info">75-84 Satisfactory</span>
                    <span class="badge bg-inverse-purple">85-92 Good</span>
                    <span class="badge bg-inverse-success">Above 92 Excellent</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

  <section class="review-section">
    <div class="review-header text-center">
      <h3 class="review-title">
        Special Initiatives, Achievements, contributions if any
      </h3>
      <p class="text-muted">Lorem ipsum dollar</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table
            class="table table-bordered table-review review-table mb-0"
            id="table_achievements"
          >
            <thead>
              <tr>
                <th class="width-pixel">#</th>
                <th>By Self</th>
                <th>RO's Comment</th>
                <th>HOD's Comment</th>
                <th class="width-64">
                  <button class="btn btn-primary btn-add-row" @click="addRow(index)">
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody id="table_achievements_tbody">
              <tr>
                <td>1</td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td><input type="text" class="form-control" /></td>
                <td></td>
              </tr>
              <tr v-for="(row, index) in rows" :key="index">
                <td>{{ index + 6 }}</td>
                <td><input type="text" v-model="row.column1" class="form-control" /></td>
                <td>
                  <input type="text" v-model="row.column2" class="form-control" />
                </td>
                <td>
                  <input type="text" v-model="row.column3" class="form-control" />
                </td>
                <td>
                  <button type="button" class="btn btn-danger" @click="removeRow(index)">
                    <i class="fa-regular fa-trash-can"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tableId: "myTable",
      rows: [],
    };
  },
  methods: {
    addRow(index) {
      this.rows.splice(index + 1, 0, {
        column1: "",
        column2: "",
        column3: "",
      });
    },
    removeRow(index) {
      if (this.rows.length > 0) {
        this.rows.splice(index, 1);
      }
    },
  },
};
</script>
