import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';
import {useToast} from 'vue-toast-notification';

const $toast = useToast()

export const getMenuUrl = (key) => {

    let menus = {
        'organizations': '/organizations',
        'companies': '/companies',
        'accounts': '/company-accounts',
        'categories': '/categories',
        'services': '/services',
        'customers-suppliers': '/customers-and-suppliers',
        'transactions': '/transactions',
        'invoices': '/invoices',
        'transfers': '/transfer',
        'declarations': '/declarations',
        'users': '/users',
        'roles': '/roles',
        'reports_debitor': '/reports/debitor',
        'reports_creditor': '/reports/creditor',
        'reports_summary': '/reports/summary',
        'tasks': '/tasks',
    };

    return menus[key] || '/';
};

export const coloredToast = (color, title) => {
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        customClass: {
            popup: `color-${color}`
        },
        target: document.getElementById(color + '-toast')
    });
    toast.fire({
        title: title
    });
};


const defaultOptions = {
  position: 'top-right',
  duration: 3000,
  dismissable: true,
  queue: false,
};

export const showToast = (message, type = 'success', options = {}) => {
  $toast.open({
    message,
    type,
    ...defaultOptions,
    ...options
  });
};

export const showSuccessToast = (message, options = {}) => {
  showToast(message, 'success', options);
};

export const showErrorToast = (message, options = {}) => {
  showToast(message, 'error', options);
};

export const showInfoToast = (message, options = {}) => {
  showToast(message, 'info', options);
};

export const showWarningToast = (message, options = {}) => {
  showToast(message, 'warning', options);
};

export const openModal = (modalId) =>{
    const modalElement = document.getElementById(modalId);
    let modalInstance  = Modal.getInstance(modalElement);
    if(!modalInstance){
        modalInstance = new Modal(modalElement);
    }

    modalInstance.show()
}

export const closeModal = (modalId) =>{
    const modalElement = document.getElementById(modalId);
    let modalInstance  = Modal.getInstance(modalElement);
    if(!modalInstance){
        modalInstance = new Modal(modalElement);
    }

    modalInstance.hide()
}
export const createLinkAndClickForDownload = (blobData, filename) => {
  const blob = new Blob([blobData], { type: 'application/xml' });
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  const decodedFileName = decodeURIComponent((filename + '').replace(/\+/g, '%20'));

  link.href = href;
  link.download = decodedFileName;
  link.click();

  URL.revokeObjectURL(href);
};


export const formatDateToYMD= (isoDateString) => {
  let date = new Date(isoDateString);
  return date.toISOString().slice(0, 10);
}

export const handleTableSorterChange = (sorter,sortParams) => {
  if (sorter.order) {
    sortParams.value.sortField = sorter.field
    sortParams.value.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
  }else{
    sortParams.value.sortField = undefined;
    sortParams.value.sortOrder = undefined;
  }

  return sortParams
}

export const toggleViewFullscreen = () => {
  const elem = document.documentElement;
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};
