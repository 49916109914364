<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Müştəri və təchizatçının əlavə edilməsi</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Müştəri/Təchizatçı adı <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.name"
                      class="form-control"
                      type="text"
                      name="name"
                      :class="{
                        'is-invalid': $v.formData.name.$error,
                        'is-valid': isSubmitForm && !$v.formData.name.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.name.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Əlaqəli şəxsin adı <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.related_fullname"
                      class="form-control"
                      type="text"
                      name="related_fullname"
                      :class="{
                        'is-invalid': $v.formData.related_fullname.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.related_fullname.$error,
                      }"
                    />

                    <template
                      v-if="isSubmitForm && $v.formData.related_fullname.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Əlaqə nömrəsi </label>
                    <input
                      v-model="formData.phone_number"
                      class="form-control"
                      type="tel"
                      name="phone_number"
                      :class="{
                        'is-valid': isSubmitForm 
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> E-mail </label>

                    <input
                      v-model="formData.email"
                      class="form-control"
                      type="email"
                      name="email"
                      :class="{
                        'is-valid': isSubmitForm 
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Vəzifə </label>

                    <input
                      v-model="formData.position"
                      class="form-control"
                      type="text"
                      name="position"
                      :class="{
                        'is-valid':
                          isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      VÖEN <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.voen"
                      class="form-control"
                      type="text"
                      name="voen"
                      :class="{
                        'is-invalid': $v.formData.voen.$error,
                        'is-valid': isSubmitForm && !$v.formData.voen.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.voen.$error">
                      <p
                        v-if="$v.formData.voen.required.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ rules.formData.voen.required.$message }}
                      </p>
                      <p
                        v-else-if="$v.formData.voen.numeric.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ rules.formData.voen.numeric.$message }}
                      </p>
                      <p
                        v-else-if="$v.formData.voen.minLength.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ rules.formData.voen.minLength.$message }}
                      </p>
                      <p
                        v-else-if="$v.formData.voen.maxLength.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ rules.formData.voen.maxLength.$message }}
                      </p>
                    </template>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <div class="form-check form-check-inline">
                      <div class="checkbox">
                        <label for="is_customer" class="form-check-label">
                          <input
                            v-model="formData.is_customer"
                            type="checkbox"
                            id="is_customer"
                            name="is_customer"
                            checked
                          />
                          Müştəridir?
                        </label>
                      </div>
                    </div>
                    <div class="form-check form-check-inline">
                      <div class="checkbox">
                        <label for="supplier" class="form-check-label">
                          <input
                            v-model="formData.is_supplier"
                            type="checkbox"
                            id="supplier"
                            name="is_supplier"
                          />
                          Təchizatçıdır?</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h6 class="mb-4 mt-8 font-bold">
                    Digər Əlaqəli şəxslər
                  </h6>
                  <div>
                    <table  class="table table-borderless table-auto">
                      <thead>
                        <tr>
                          <th>Adı</th>
                          <th>Vəzifə</th>
                          <th>Əlaqə nömrəsi</th>
                          <th>E-mail</th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="gap-3 mb-4"
                          v-for="(line, index) in formData.contact_list"
                          :key="index"
                        >
                          <td>
                            <input
                              class="form-control"
                              :id="`full_name${index}`"
                              v-model="line.full_name"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              class="form-control"
                              :id="`position${index}`"
                              v-model="line.position"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              class="form-control"
                              :id="`phone_number${index}`"
                              v-model="line.phone_number"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              class="form-control"
                              :id="`email${index}`"
                              v-model="line.email"
                              type="text"
                            />
                          </td>
                          <td>
                            <button
                              v-if="index > 0"
                              type="button"
                              @click="removeLine(index)"
                              class="btn btn-sm btn-outline-danger"
                            >
                             <i class="las la-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-4">
                    <div>
                      <button
                        type="button"
                        @click="addLine"
                        class="btn btn-outline-success"
                      >
                        + Əlaqəli şəxs artırın
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button class="button btn-lights reset-btn" type="reset">
                    Sıfırla
                  </button>
                  <button class="btn btn-primary" type="submit">
                    Yadda saxla
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  decimal,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useCustomersAndSuppliers } from "@/stores/modules/customerAndSuppliers";

const customerStore = useCustomersAndSuppliers();

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const formData = reactive({
  voen: null,
  name: null,
  position: null,
  phone_number: null,
  email: null,
  is_customer: false,
  is_supplier: false,
  related_fullname: null,
  contact_list: [
    { full_name: null, email: null, phone_number: null, position: null },
  ],
});

const rules = {
  formData: {
    name: { required },
    related_fullname: { required },
    voen: {
      required: helpers.withMessage("Bu vacib məlumatdır", required),
      numeric: helpers.withMessage(
        "Ancaq rəqəmlərdən ibarət olmalıdır",
        numeric
      ),
      minLength: helpers.withMessage(
        "VÖEN 10 rəqəmdən ibarət olmalıdır",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "VÖEN 10 rəqəmdən ibarət olmalıdır",
        maxLength(10)
      ),
    },
  },
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.name = null;
  formData.phone_number = null;
  formData.email = null;
  formData.position = null;
  formData.related_fullname = null;
  formData.voen = null;
  formData.is_customer = false;
  formData.is_supplier = false;
};

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await customerStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("addDataModal");
    });
  } catch (error) {
    console.log(error)
  }
};

const addLine = () => {
  formData.contact_list.push({
    full_name: null,
    email: null,
    phone_number: null,
    position: null,
  });
};

const removeLine = (index) => {
  formData.contact_list.splice(index, 1);
};
</script>

  