import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useTransactionsStore = defineStore('transactions', {
    state: () => ({
        dataList: null,
        dataTransaction: null
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            try {
                const res = await axiosInstance
                    .get(urlList['transactions'], { ...payload });
                this.dataList = res;
                return res;
            } finally {
            }
        },
        async getDetail(id) {
            try {
                await axiosInstance.get(`${urlList['transactions']}/${id}`).then((res) => {
                    this.dataTransaction = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData) {
            try {
                return axiosInstance.post(urlList['transactions'], formData).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData, id) {
            try {
                return axiosInstance.put(`${urlList['transactions']}/${id}`, formData).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id) {
            try {
                const response = await axiosInstance.delete(`${urlList['transactions']}/${id}`);

                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        }
    },
    getters: {}
});
