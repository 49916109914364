
<template>
    <div class="dropzone">
        <div
            class="dropzone-wrapper" :style="{width,height}"
            @dragenter.prevent="toggleActive"
            @dragleave.prevent="toggleActive"
            @drop.prevent="drop"
            @dragover.prevent
            @mouseover="hover"
            @mouseleave="blur"
            :class="[{'dropzone-wrapper--active': active, 'dropzone-wrapper--disabled': disabled}, localState? `dropzone-wrapper--${localState}` : '']"
            ref="dropzoneWrapper"
            @click.self="openSelectFile"
            id="dropzoneWrapper"
        >
            <!--   Input   -->
            <input type="file" ref="fileInput" class="hidden" :id="id" :accept="accept" @input="inputFiles"
                   :multiple="multiple">

            <!--   Placeholder content   -->
            <template v-if="!previewUrls.length">
                <slot name="placeholder-img">
                    <svg height="250" viewBox="0 0 500 250" width="500"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
                        <linearGradient id="a">
                            <stop offset="0" stop-color="#808080" stop-opacity=".25" />
                            <stop offset=".54" stop-color="#808080" stop-opacity=".12" />
                            <stop offset="1" stop-color="#808080" stop-opacity=".1" />
                        </linearGradient>
                        <linearGradient id="b" x1="50%" x2="50%" xlink:href="#a" y1="100%" y2="0%" />
                        <linearGradient id="c" x1="0%" x2="100%" xlink:href="#a" y1="50%" y2="50%" />
                        <linearGradient id="d" x1=".006296%" x2="100.006296%" xlink:href="#a" y1="50.001807%"
                                        y2="50.001807%" />
                        <linearGradient id="e" x1="50.000222%" x2="50.000222%" y1="100.000449%" y2=".001403%">
                            <stop offset="0" stop-color="#b3b3b3" stop-opacity=".25" />
                            <stop offset=".54" stop-color="#b3b3b3" stop-opacity=".1" />
                            <stop offset="1" stop-color="#b3b3b3" stop-opacity=".05" />
                        </linearGradient>
                        <linearGradient id="f">
                            <stop offset="0" stop-opacity=".12" />
                            <stop offset=".55" stop-opacity=".09" />
                            <stop offset="1" stop-opacity=".02" />
                        </linearGradient>
                        <linearGradient id="g" x1="49.980092%" x2="49.980092%" xlink:href="#f" y1="99.948692%"
                                        y2="0%" />
                        <linearGradient id="h" x1="49.988525%" x2="49.988525%" xlink:href="#f" y1="100.005627%"
                                        y2=".007685%" />
                        <mask id="i" fill="#fff">
                            <path d="m.175034.235102h46.607793v31.650612h-46.607793z" fill="#fff" fill-rule="evenodd" />
                        </mask>
                        <mask id="j" fill="#fff">
                            <path d="m.175034.137143h46.607793v31.650612h-46.607793z" fill="#fff" fill-rule="evenodd" />
                        </mask>
                        <g fill="none" fill-rule="evenodd" transform="translate(117 1)">
                            <path d="m.047773.067214h142.821648v192.871973h-142.821648z" fill="url(#b)" opacity=".5"
                                  transform="translate(49 42)" />
                            <path d="m52 44h138v186h-138z" fill="#f2f2f2" />
                            <path d="m62 52h48v1h-48z" fill="#e0e0e0" />
                            <path d="m62 59h48v1h-48z" fill="#3157b3" opacity=".7" />
                            <g fill="#e0e0e0">
                                <path d="m116 52h48v1h-48z" />
                                <path d="m124 95h48v1h-48z" />
                                <path d="m125 99h48v1h-48z" />
                                <path d="m127 103h48v1h-48z" />
                                <path d="m124 161h48v1h-48z" />
                                <path d="m125 165h48v1h-48z" />
                                <path d="m127 169h48v1h-48z" />
                            </g>
                            <path d="m.009623.051358h50.942264v51.73037h-50.942264z" fill="url(#b)" opacity=".5"
                                  transform="translate(63 79)" />
                            <path d="m63 81h50v50h-50z" fill="#f5f5f5" />
                            <path d="m.172653.003267h46.616327v32.813762h-46.616327z" fill="url(#b)" opacity=".5"
                                  transform="translate(65 83)" />
                            <path d="m65 84h46v31h-46z" fill="#fff" />
                            <g fill="#6c63ff" mask="url(#i)" opacity=".4" transform="translate(121 103)">
                                <g transform="translate(-63.531034 -18.612245)">
                                    <path
                                        d="m1.73413793 29.5869388 11.73703447-18.3053061 4.9204138 8.0065306 10.5993104-11.05632657 9.0855862 11.05632657 7.947862-16.39510208 20.440138 32.79346938-66.24406901-2.2889796z" />
                                    <ellipse cx="10.819724" cy="3.653878" rx="3.40669" ry="3.431837" />
                                </g>
                            </g>
                            <path d="m.009623.282883h50.942264v52.401718h-50.942264z" fill="url(#b)" opacity=".5"
                                  transform="translate(63 144)" />
                            <path d="m63 146h50v50h-50z" fill="#f5f5f5" />
                            <path d="m.172653.232941h46.616327v32.492059h-46.616327z" fill="url(#b)" opacity=".5"
                                  transform="translate(65 149)" />
                            <path d="m65 149h46v31h-46z" fill="#fff" />
                            <g fill="#bdbdbd" mask="url(#j)" opacity=".4" transform="translate(121 169)">
                                <g transform="translate(-63.531034 -18.612245)">
                                    <path
                                        d="m1.73413793 29.4889796 11.73703447-18.3053061 4.9204138 8.0097959 10.5993104-11.05959185 9.0855862 11.05959185 7.947862-16.39836736 20.440138 32.79346936-66.24406901-2.2857143z" />
                                    <ellipse cx="10.819724" cy="3.559184" rx="3.40669" ry="3.431837" />
                                </g>
                            </g>
                            <path
                                d="m-7.18593005 37.2115009 192.12902205.4099529.328272 142.2708372-192.12902245-.409953z"
                                fill="url(#c)" opacity=".5"
                                transform="matrix(.19491914 -.98081932 .98081932 .19491914 36.216791 206.727871)" />
                            <path d="m66.0915479 70.2801155 186.0111601-.041629-.031096 138.7967615-186.0111602.041629z"
                                  fill="#fff"
                                  transform="matrix(.19491914 -.98081932 .98081932 .19491914 -8.905417 268.466013)" />
                            <path d="m116.257755 54.864596 98.502474.0220686.000334 1.4936417-98.502474-.0220686z"
                                  fill="#e0e0e0"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 14.016459 -31.194026)" />
                            <path d="m88.7688142 193.354237 98.5024738.022069.000334 1.493641-98.5024735-.022068z"
                                  fill="#e0e0e0"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 40.483484 -23.179579)" />
                            <path d="m87.3082715 199.595779 76.1156935.017053.000334 1.493642-76.1156932-.017053z"
                                  fill="#e0e0e0"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 41.45688 -20.593417)" />
                            <path d="m86.0954481 203.370304 28.3548849.006353.000334 1.493641-28.3548846-.006352z"
                                  fill="#3157b3" opacity=".7"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 41.710259 -15.629971)" />
                            <path d="m115.055726 58.492631 49.249639.0110339.000334 1.4936417-49.249639-.0110339z"
                                  fill="#3157b3" opacity=".7"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 14.22715 -26.090085)" />
                            <path d="m113.00671 70.7725613 88.799511.0198947.000335 1.4936416-88.799512-.0198947z"
                                  fill="#e0e0e0"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 16.961602 -29.309584)" />
                            <path d="m111.978057 72.6553713 21.63917.004848.000334 1.4936417-21.63917-.0048481z"
                                  fill="#409438" opacity=".7"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 16.663311 -22.527692)" />
                            <path d="m109.568238 88.5728644 98.502473.0220686.000334 1.4936416-98.502473-.0220685z"
                                  fill="#e0e0e0"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 20.458537 -29.243563)" />
                            <path
                                d="m24.8305151-9.68819892 50.2561139-.64239238-1.0610153 88.0864456-50.2561139.6423923z"
                                fill="url(#d)" opacity=".5"
                                transform="matrix(.19491914 -.98081932 .98081932 .19491914 114.412552 189.879983)" />
                            <path d="m113.982991 123.089179 87.307486.01956.011118 49.677179-87.307486-.019561z"
                                  fill="#fff"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 31.859547 -27.889957)" />
                            <path d="m107.895577 97.001988 98.502474.0220685.000334 1.4936417-98.502473-.0220686z"
                                  fill="#e0e0e0"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 22.069451 -28.755854)" />
                            <path d="m123.687248 124.142267 10.821182.002425.008109 36.231205-10.821183-.002425z"
                                  fill="#3157b3"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 30.205281 -22.435803)" />
                            <path d="m141.553022 139.767177 10.821183.002425.005434 24.278873-10.821183-.002424z"
                                  fill="#409438"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 32.428658 -25.732855)" />
                            <path d="m159.494995 154.652221 10.821183.002424.002925 13.071763-10.821182-.002424z"
                                  fill="#f55f44"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 34.581913 -29.051819)" />
                            <path d="m179.831224 145.119932 10.821182.002424.005936 26.520935-10.821183-.002424z"
                                  fill="#fdd835"
                                  transform="matrix(.98081932 .19491914 -.19491914 .98081932 34.424729 -33.069886)" />
                            <path
                                d="m223.88143 112.24599c-9.327021-9.647332-22.173791-15.1438091-35.630751-15.2445798-13.456959-.1007706-26.385503 5.2026918-35.857552 14.7092488-18.90462 18.953256-19.225038 49.814434-.733755 69.169189 16.772973 17.557103 43.832407 20.526774 64.051417 7.029402l36.107825 37.348876c.930917.962413 2.470008.992369 3.438078.066916l8.997318-8.603529c.967753-.92578.997875-2.456378.067287-3.419106l-36.098212-37.34569c14.287143-19.471912 12.4566-46.333859-4.341655-63.710727zm-11.246647 60.049447c-14.223912 13.598903-36.83985 13.156131-50.514549-.988968-13.674698-14.145098-13.230011-36.636252.993246-50.235832 14.223258-13.599581 36.839217-13.157885 50.514597.986562 6.567568 6.792837 10.152576 15.902112 9.966296 25.323712-.186281 9.421601-4.12859 18.383686-10.95959 24.914526z"
                                fill="url(#e)" fill-rule="nonzero" />
                            <path d="m160.998805 136c-3.99522 0-4.001592 6 0 6 4.001593 0 4.001593-6 0-6z"
                                  fill="url(#g)" />
                            <path
                                d="m189.322175 116.007838c-10.845669-.242358-20.714424 5.15649-25.962121 13.30738-1.712812 2.659562 3.149261 5.009799 4.852433 2.362992 4.344694-6.747763 12.600257-11.033676 21.530661-10.810452 9.357804.232792 17.873662 5.545539 22.382247 12.918331 1.709599 2.796687 6.192475.255114 4.498944-2.525628-5.482285-8.928987-16.099793-14.987942-27.302164-15.252623z"
                                fill="url(#h)" />
                            <path
                                d="m227.883597 110.24599c-9.327256-9.647332-22.174351-15.1438091-35.63165-15.2445798-13.457298-.1007706-26.386169 5.2026918-35.858457 14.7092488-18.905097 18.953256-19.225523 49.814434-.733774 69.169189 16.774231 17.556077 43.834266 20.524393 64.053034 7.026215l36.105532 37.352063c.93094.962413 2.47007.992369 3.438164.066916l8.997545-8.603529c.967778-.92578.9979-2.456378.067289-3.419106l-36.108736-37.348876c14.289118-19.468277 12.463995-46.327951-4.328947-63.707541zm-11.246931 60.023955c-14.22427 13.598903-36.840779 13.156131-50.515823-.988967-13.675044-14.145099-13.230345-36.636253.993271-50.235833s36.840146-13.157885 50.515871.986563c6.567734 6.792836 10.152833 15.902111 9.966547 25.323712-.186285 9.4216-4.128694 18.383685-10.959866 24.914525z"
                                fill="#3157b3" fill-rule="nonzero" />
                            <path d="m165.998805 134c-3.99522 0-4.001592 6 0 6 4.001593 0 4.001593-6 0-6z"
                                  fill="#3157b3" />
                            <path
                                d="m193.322175 115.007838c-10.845669-.242358-20.714424 5.15649-25.962121 13.30738-1.712812 2.659562 3.149261 5.009799 4.852433 2.362992 4.344694-6.747763 12.600257-11.033676 21.530661-10.810452 9.357804.232792 17.873662 5.545539 22.382247 12.918331 1.709599 2.796687 6.192475.255114 4.498944-2.525628-5.491925-8.954498-16.096579-15.013454-27.302164-15.252623z"
                                fill="#3157b3" />
                            <path d="m39 212h1v6h-1z" fill="#409438" />
                            <path d="m39.0155642 212h.9688716v6h-.9688716z" fill="#409438"
                                  transform="matrix(0 1 -1 0 254.5 175.5)" />
                            <path
                                d="m182.574839 2.53599238c-.636206-.27712698-.963286-.98804315-.760088-1.6520681.01556-.04961468.025169-.10090583.028623-.15279243.020705-.3029898-.16056-.58339623-.445173-.68865899s-.604491-.01020112-.785597.23346489c-.0306.0414095-.056219.08628326-.076327.13369337-.276875.63678448-.987146.96416206-1.650568.76077895-.049569-.01557419-.100814-.02519128-.152653-.02864858-.305161-.02205363-.587816.161483-.692077.44938684s-.004748.61009007.243656.78886845c.041372.03062757.086205.05626972.133572.07639621.636207.27712698.963287.98804314.760088 1.6520681-.01556.04961468-.025168.10090583-.028622.15279242-.024434.30733214.159454.59307609.449128.69789932.289673.10482323.613611.00284478.791183-.24907157.0306-.04140949.056219-.08628326.076327-.13369337.276875-.63678447.987146-.96416205 1.650567-.76077895.04957.01557419.100815.02519128.152654.02864858.302715.02072333.582867-.16070596.688034-.44557791.105167-.28487194.010192-.60504009-.233253-.78631102-.046346-.03177432-.096583-.05745037-.149474-.07639621z"
                                fill="#3157b3" />
                            <path
                                d="m43.5892219 49.5430932c-.6385675-.2779029-.9668617-.9908097-.7629091-1.6566939.0156178-.0497536.0252618-.1011884.0287288-.1532203.0207813-.3038381-.161156-.5850297-.4468256-.6905872s-.6067342-.0102297-.7885127.2341186c-.0307133.0415254-.0564273.0865248-.0766101.1340677-.2779029.6385675-.9908097.9668617-1.6566939.7629091-.0497536-.0156178-.1011884-.0252618-.1532203-.0287288-.3038381-.0207813-.5850297.161156-.6905872.4468256s-.0102297.6067342.2341186.7885127c.0415254.0307133.0865248.0564273.1340677.0766101.6385675.2779029.9668617.9908097.7629091 1.6566939-.0156178.0497536-.0252618.1011884-.0287288.1532203-.0207813.3038381.161156.5850297.4468256.6905872s.6067342.0102297.7885127-.2341186c.0307133-.0415254.0564273-.0865248.0766101-.1340677.2779029-.6385675.9908097-.9668617 1.6566939-.7629091.0497536.0156178.1011884.0252618.1532203.0287288.3038381.0207813.5850297-.161156.6905872-.4468256s.0102297-.6067342-.2341186-.7885127c-.0415254-.0307133-.0865248-.0564273-.1340677-.0766101z"
                                fill="#fdd835" />
                            <path
                                d="m240.589222 243.543093c-.638568-.277903-.966862-.990809-.762909-1.656694.015618-.049753.025262-.101188.028729-.15322.020781-.303838-.161156-.58503-.446826-.690587-.28567-.105558-.606734-.01023-.788513.234118-.030713.041526-.056427.086525-.07661.134068-.277903.638568-.990809.966862-1.656694.762909-.049753-.015618-.101188-.025262-.15322-.028729-.303838-.020781-.58503.161156-.690587.446826-.105558.28567-.01023.606734.234118.788513.041526.030713.086525.056427.134068.07661.638568.277903.966862.990809.762909 1.656694-.015618.049753-.025262.101188-.028729.15322-.020781.303838.161156.58503.446826.690587.28567.105558.606734.01023.788513-.234118.030713-.041526.056427-.086525.07661-.134068.277903-.638568.990809-.966862 1.656694-.762909.049753.015618.101188.025262.15322.028729.303838.020781.58503-.161156.690587-.446826.105558-.28567.01023-.606734-.234118-.788513-.041526-.030713-.086525-.056427-.134068-.07661z"
                                fill="#fdd835" />
                            <circle cx="103" cy="24" fill="#f55f44" r="2" />
                            <circle cx="263" cy="52" fill="#f55f44" r="2" />
                            <circle cx="250" cy="119" fill="#3157b3" r="2" />
                            <circle cx="2" cy="9" fill="#419538" opacity=".5" r="2" />
                        </g>
                    </svg>

                </slot>
                <slot name="title">
                    <div class="titles">
                        <h1 class="m-0">Fayllarınızı buraya sürükləyin</h1>
                    </div>
                </slot>
                <slot name="button" :fileInput="fileInput">
                    <button type="button" @click="fileInput?.click()" v-if="showSelectButton" class="select-file">
                        Fayl seçin
                    </button>
                </slot>
                <slot name="description">
                    <p class="m-0 description">
                        İcazə verilən fayl həcmi {{ maxFileSize }}MB
                        {{ accept.length ? `and in ${accept.join(', ')} formats` : '' }}</p>
                </slot>
            </template>

            <!--   Images previews   -->
            <template v-else>
                <div class="preview-container" :class="previewWrapperClasses">
                    <slot name="preview" v-for="img,key in previewUrls" :data="img">
                        <div class="preview"
                              v-bind:key="key"
                             :class="{'preview__multiple': multiple, 'preview__file': img && img.type && !img.type.includes('image/')}"
                             :style="{width: `${imgWidth} !important`, height: `${imgHeight} !important`}">
                            <img :src="img.src" :alt="img.name" v-if="img && img.type && img.type.includes('image/')">
                            <Icon :name="img.name.split('.').pop()"
                                  v-if="img && img.type && !img.type.includes('image/')" />
                            <div class="img-details" v-if="img.name || img.size">
                                <button type="button" class="img-remove" @click="removeImg(img)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                         fill="none"
                                         stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round"
                                         class="icon icon-tabler icons-tabler-outline icon-tabler-x">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M18 6l-12 12" />
                                        <path d="M6 6l12 12" />
                                    </svg>
                                </button>
                                <h2>{{ img.name }}</h2>
                                <span>{{ (img.size / 1024 / 1024).toFixed(2) }}MB</span>
                            </div>
                        </div>
                    </slot>
                </div>
            </template>
        </div>
        <div class="dropzone-wrapper__disabled" @click.prevent @drop.prevent @dragover.prevent
             v-if="disabled"></div>

        <!--   Message   -->
        <Transition name="fade-in" mode="in-out">
            <p class="m-0 message" :class="localState? `message--${localState}` : ''" v-if="localMessageState">
                {{ localMessageState }}</p>
        </Transition>
    </div>
</template>

<script setup>
    import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
    import Icon from './Icon.vue';

    const props = defineProps({
        modelValue: {
            type: Array,
            default: []
        },
        multiple: {
            type: Boolean,
            default: false
        },
        previews: {
            type: Array,
            default: []
        },
        mode: {
            type: String,
            default: 'drop',
            validator(value) {
                return ['drop', 'preview'].includes(value);
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        state: {
            type: String,
            validator(value) {
                return ['error', 'success', 'warning', 'indeterminate'].includes(value);
            }
        },
        accept: {
            type: Array,
            default: []
        },
        maxFileSize: {
            type: Number,
            default: 5
        },
        maxFiles: {
            type: Number,
            default: 5
        },
        width: [Number, String],
        height: [Number, String],
        imgWidth: [Number, String],
        imgHeight: [Number, String],
        previewWrapperClasses: String,
        blurOnClickOutside: {
            type: Boolean,
            default: true
        },
        showSelectButton: {
            type: Boolean,
            default: true
        },
        message: String
    });
    const emit = defineEmits(['drop', 'update:modelValue']);

    const fileInput = ref(null);
    const files = ref([]);
    const previewUrls = ref([]);
    const active = ref(false);
    const localMessageState = ref(props.message);
    const localState = ref(props.state);
    const dropzoneWrapper = ref(null);
    const id = computed(() => {
        if (props.id) return id;
        return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
    });

    // Manages input files
    const inputFiles = (e) => {
        const allFiles = [...e.target.files].slice(0, props.maxFiles);
        const filesAreValid = allFiles.map(item => {
            const itemSize = (item.size / 1024 / 1024).toFixed(2);
            return itemSize <= props.maxFileSize;
        });

        if (filesAreValid.every(item => item === true)) {
            files.value = allFiles.map(item => {
                return {
                    file: item,
                    id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
                };
            });
        } else {
            localState.value = 'error';
            localMessageState.value = props.multiple ? `Files are too large, maximum file size is ${props.maxFileSize}MB`
                : `File is too large, maximum file size is ${props.maxFileSize}MB`;
        }

        const generatedUrls = [];

        files.value.map((item) => {
            generatedUrls.push({
                src: URL.createObjectURL(item.file),
                name: item.file.name,
                size: item.file.size,
                type: item.file.type,
                id: item.id
            });
        });
        previewUrls.value = generatedUrls;
    };

    // Toggles active state for dropping files(styles)
    const toggleActive = () => {
        if (!props.disabled && props.mode !== 'preview') {
            active.value = !active.value;
        }
    };

    // Handles dropped files and input them
    const drop = (e) => {
        toggleActive();
        if (!props.disabled && props.mode !== 'preview') {
            const files = {
                target: {
                    files: [...e.dataTransfer.files]
                }
            };
            emit('drop', e);
            inputFiles(files);
        }
    };

    // Removes img from files list
    const removeImg = (item) => {
        previewUrls.value = previewUrls.value.filter(url => url.id !== item.id);
        files.value = files.value.filter(file => file.id !== item.id);
        emit('update:modelValue', files.value);
    };

    // Clears all images
    const clearFiles = () => {
        files.value = [];
        previewUrls.value = [];
        emit('update:modelValue', files.value);
    };

    // Hover and blur manager
    const hover = () => {
        if (!files.value.length && props.state === 'indeterminate') {
            active.value = true;
        }
    };
    const blur = () => {
        active.value = false;
    };

    // Opens os selecting file window
    const openSelectFile = (e) => {
        if (!props.disabled && props.mode === 'drop' && e.target.id === 'dropzoneWrapper') {
            fileInput.value.click();
        } else {
            e.preventDefault();
        }
    };

    // Click outside blur for clearing error state
    const useDetectOutsideClick = (component, callback) => {
        if (!component) return;
        const listener = (event) => {
            if (event.target !== component.value && event.composedPath().includes(component.value)) {
                return;
            }
            if (typeof callback === 'function') {
                callback();
            }
        };
        onMounted(() => {
            window.addEventListener('click', listener);
        });
        onBeforeUnmount(() => {
            window.removeEventListener('click', listener);
        });

        return { listener };
    };
    useDetectOutsideClick(dropzoneWrapper, () => {
        if (props.blurOnClickOutside) {
            localState.value = 'indeterminate';
            localMessageState.value = '';
        }
    });

    // Updates local preview state on previews prop change
    watchEffect(() => {
        if (props.previews && props.previews.length) {
            previewUrls.value = props.previews.map(item => {
                return {
                    src: item,
                    id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
                };
            });
        }
    });

    // Updates local message state on message prop change
    watchEffect(() => {
        if (props.message) {
            localMessageState.value = props.message;
        }
    });

    // Updates local state on state prop change
    watchEffect(() => {
        if (props.state) {
            localState.value = props.state;
        }
    });

    watchEffect(() => {
        if (files.value && files.value.length) {
            emit('update:modelValue', files.value);
        }
    });

    defineExpose({
        clearFiles
    });

</script>

<style scoped>
    * {
        font-family: sans-serif
    }

    .m-0 {
        margin: 0
    }

    .dropzone {
        --v3-dropzone--primary: 94, 112, 210;
        --v3-dropzone--border: 214, 216, 220;
        --v3-dropzone--description: 190, 191, 195;
        --v3-dropzone--overlay: 40, 44, 53;
        --v3-dropzone--overlay-opacity: .3;
        --v3-dropzone--error: 255, 76, 81;
        --v3-dropzone--success: 36, 179, 100;
        position: relative;
        display: flex;
        flex-direction: column
    }

    .hidden {
        display: none
    }

    .dropzone-wrapper {
        border: 2px dashed rgba(var(--v3-dropzone--border));
        border-radius: 12px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        height: 200px;
        transition: .3s all ease;
        justify-content: center
    }

    .dropzone-wrapper--disabled {
        opacity: .5
    }

    .dropzone-wrapper__disabled {
        position: absolute;
        top: -2px;
        inset-inline-start: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 12px;
        background: transparent;
        z-index: 2
    }

    .dropzone-wrapper--active {
        border-color: rgba(var(--v3-dropzone--primary));
        background: rgba(var(--v3-dropzone--primary), .1)
    }

    .dropzone-wrapper--error {
        border-color: rgba(var(--v3-dropzone--error)) !important
    }

    .dropzone-wrapper--success {
        border-color: rgba(var(--v3-dropzone--success)) !important
    }

    .select-file {
        background: rgba(var(--v3-dropzone--primary));
        border-radius: 10px;
        font-weight: 500;
        font-size: 12px;
        border: none;
        padding: 10px 20px;
        color: #fff;
        cursor: pointer;
        margin-bottom: 10px;
        margin-top: 10px
    }

    .description {
        font-size: 12px;
        color: rgba(var(--v3-dropzone--description))
    }

    .titles {
        text-align: center
    }

    .titles h1 {
        font-weight: 400;
        font-size: 20px
    }

    .titles h3 {
        margin-top: 30px;
        font-weight: 400
    }

    .preview-container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px
    }

    .preview {
        width: 100%;
        height: 95%;
        border-radius: 8px;
        flex-shrink: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preview__multiple {
        height: 90% !important;
        width: 90% !important
    }

    .preview__file {
        border: 1px dashed rgba(var(--v3-dropzone--primary));
    }

    .preview:hover .img-details {
        opacity: 1 !important;
        visibility: visible !important
    }

    .preview img {
        width: 100%;
        height: 100%;
        border-radius: 8px
    }

    .img-details {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--v3-dropzone--overlay), var(--v3-dropzone--overlay-opacity));
        border-radius: 8px;
        transition: all .2s linear;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        filter: grayscale(1%);
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden
    }

    .img-details h2 {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #fff;
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    @media (max-width: 400px) {
        .img-details h2 {
            max-width: 200px
        }
    }

    .img-details span {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #fff
    }

    .img-remove {
        background: rgba(var(--v3-dropzone--error));
        border-radius: 10px;
        border: none;
        padding: 5px;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        right: 10px;
        transition: all .2s linear
    }

    .img-remove:active {
        transform: scale(.9)
    }

    .img-remove:hover {
        background: rgba(var(--v3-dropzone--error), .8)
    }

    .message {
        margin-top: 10px !important;
        font-weight: 400;
        font-size: 14px;
        color: var(--v3-dropzone--overlay);
        transition: all .2s linear
    }

    .message--error {
        color: rgba(var(--v3-dropzone--error)) !important
    }

    .message--success {
        color: rgba(var(--v3-dropzone--success)) !important
    }

    .fade-in-enter-from, .fade-in-leave-to {
        opacity: 0
    }

    .fade-in-enter-active, .fade-in-leave-active {
        transition: all .2s linear
    }
</style>
