import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useCustomersAndSuppliers = defineStore('customersAndSuppliers', {
    state: () => ({
        dataList: null,
        dataCustomer: null,
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['customersAndSuppliers'],{...payload})
                .then((res) => {
                    this.dataList = res;

                    return res;
                }).finally(() => {

                });
        },
        async getDetail( id ){
            try {
                await axiosInstance.get(`${urlList['customersAndSuppliers']}/${id}`).then((res)=>{
                    this.dataCustomer = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData ) {
            try {
                return await axiosInstance.post(urlList['customersAndSuppliers'], formData).then((res) => {
                    return res
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData , id ) {
            try {
                return axiosInstance.put(`${urlList['customersAndSuppliers']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id ) {
            try {
                const response = await axiosInstance.delete(`${urlList['customersAndSuppliers']}/${id}`);

                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        },
    },
    getters: {}
});
