<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Leads</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Leads</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div>
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_leads"
                ><i class="la la-plus-circle"></i> Add Leads</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="filter-filelds"
        :style="{ display: filter ? 'block' : 'none' }"
        id="filter_inputs"
      >
        <div class="row filter-row">
          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientNameFocused }"
            >
              <input
                type="text"
                class="form-control floating"
                v-model="clientName"
                @focus="clientNameFocused = true"
                @blur="clientNameFocused = clientName !== ''"
              />
              <label class="focus-label">Lead Name</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientIdFocused }"
            >
              <input
                type="email"
                class="form-control floating"
                v-model="clientId"
                @focus="clientIdFocused = true"
                @blur="clientIdFocused = clientId !== ''"
              />
              <label class="focus-label">Email</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus focused select-focus">
              <input
                type="text"
                class="form-control date-range bookingrange"
                ref="dateRangeInput"
              />
              <label class="focus-label">From - To Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select
                :options="Leadselect"
                id="leadselect"
                placeholder="--Select--"
              />

              <label class="focus-label">Lead Status</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="LeadNova" id="leadnova" placeholder="--Select--" />

              <label class="focus-label">Company Name</label>
            </div>
          </div>
          <div class="col-xl-2">
            <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
          </div>
        </div>
      </div>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <div class="view-icons">
              <router-link to="/crm/leads" class="list-view btn btn-link active"
                ><i class="las la-list"></i
              ></router-link>
              <router-link to="/crm/leads-kanban" class="grid-view btn btn-link"
                ><i class="las la-th"></i
              ></router-link>
            </div>
          </li>
          <li>
            <div class="form-sort">
              <i class="las la-sort-alpha-up-alt"></i>
              <vue-select :options="LeadAsc" id="leadasc" placeholder="--Select--" />
            </div>
          </li>
          <li>
            <div class="form-sorts dropdown" ref="formSorts">
              <a
                href="javascript:void(0);"
                @click="toggleFilter"
                class="dropdown-toggle"
                id="table-filter"
                ><i class="las la-filter me-2"></i>Filter</a
              >
              <div class="filter-dropdown-menu">
                <div class="filter-set-view">
                  <div class="filter-set-head">
                    <h4>Filter</h4>
                  </div>
                  <div class="accordion" id="accordionExample">
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          >Rating<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse show"
                        id="collapseOne"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <span>5.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <span>4.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>3.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>2.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>1.0</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          >Owner<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse"
                        id="collapseTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Hendry</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Guillory</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Jami</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Theresa</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Espinosa</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          >Tags<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse"
                        id="collapseThree"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Promotion</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Rated</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Rejected</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Collab</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Calls</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="filter-reset-btns">
                    <a href="javascript:void(0);" class="btn btn-light">Reset</a>
                    <a href="javascript:void(0);" class="btn btn-primary">Filter</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="search-set">
              <div class="search-input">
                <a href="javascript:void(0);" class="btn btn-searchset"><i class="las la-search"></i></a>
                <div class="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="Search"
                  /></label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table class="stripped table-hover" :columns="columns" :data-source="data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Star'">
                  <div class="set-star star-select">
                    <i
                      :class="[record.Star, { filled: record.isFilled }]"
                      @click="toggleStar(record)"
                    ></i>
                  </div>
                </template>
                <template v-else-if="column.key === 'LeadName'">
                  <h2>
                    <router-link to="/crm/leads-details" class="profile-split">{{record.LeadName}}</router-link>
                  </h2>
                </template>
                <template v-else-if="column.key === 'CompanyName'">
                  <td>
                    <h2 class="table-avatar d-flex align-items-center">
                      <router-link to="/crm/company-details" class="company-img"
                        ><img
                          :src="require(`@/assets/img/icons/${record.Image}`)"
                          alt="User Image"
                      /></router-link>
                      <router-link to="/crm/company-details" class="profile-split">{{
                        record.CompanyName
                      }}</router-link>
                    </h2>
                  </td>
                </template>
                <template v-else-if="column.key === 'LeadStatus'">
                  <div>
                    <span :class="record.Class">{{ record.LeadStatus }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_leads"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_leads"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                        <router-link class="dropdown-item" to="leads-details"
                          ><i class="fa-regular fa-eye"></i> Preview</router-link
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <leads-modal></leads-modal>
</template>
<script>
const columns = [
  {
    title: "",
    dataIndex: "Star",
    key: "Star",
    sorter: false,
  },
  {
    title: "Lead Name",
    dataIndex: "LeadName",
    key: "LeadName",
    sorter: {
      compare: (a, b) => {
        a = a.LeadName.toLowerCase();
        b = b.LeadName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Company Name",
    dataIndex: "CompanyName",
    key: "CompanyName",
    sorter: {
      compare: (a, b) => {
        a = a.CompanyName.toLowerCase();
        b = b.CompanyName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Lead Status",
    dataIndex: "LeadStatus",
    key: "LeadStatus",
    sorter: {
      compare: (a, b) => {
        a = a.LeadStatus.toLowerCase();
        b = b.LeadStatus.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
    key: "CreatedDate",
    sorter: {
      compare: (a, b) => {
        a = a.CreatedDate.toLowerCase();
        b = b.CreatedDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Lead Owner",
    dataIndex: "LeadOwner",
    key: "LeadOwner",
    sorter: {
      compare: (a, b) => {
        a = a.LeadOwner.toLowerCase();
        b = b.LeadOwner.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    sorter: true,
  },
];
const data = [
  {
    Image: "company-icon-01.svg",
    LeadName: "Collins",
    CompanyName: "NovaWaveLLC",
    Phone: "+1 875455453",
    Email: "Robertson@example.com",
    LeadStatus: "Closed",
    CreatedDate: "10 Feb 2024, 09:00 am",
    Star: "fa fa-star filled",
    Class: "badge badge-soft-success",
    LeadOwner: "Hendry",
  },
  {
    Image: "company-icon-02.svg",
    LeadName: "Konopelski",
    CompanyName: "BlueSky Industries",
    Phone: "+1 989757485",
    Email: "sharon@example.com",
    LeadStatus: "Closed",
    CreatedDate: "24 Feb 2024, 12:20 pm",
    Star: "fa fa-star",
    Class: "badge badge-soft-success",
    LeadOwner: "Guillory",
  },
  {
    Image: "company-icon-03.svg",
    LeadName: "Adams",
    CompanyName: "SilverHawk",
    Phone: "+1 546555455",
    Email: "Vaughan12@example.com",
    LeadStatus: "Not Contacted",
    CreatedDate: "5 Mar 2024, 10:00 am",
    Star: "fa fa-star filled",
    Class: "badge badge-soft-info",
    LeadOwner: "Jami",
  },
  {
    Image: "company-icon-04.svg",
    LeadName: "Schumm",
    CompanyName: "SummitPeak",
    Phone: "+1 454478787",
    Email: "Jessica13@example.com",
    LeadStatus: "Contacted",
    CreatedDate: "17 Mar 2024, 02:00 pm",
    Star: "fa fa-star filled",
    Class: "badge badge-soft-warning ",
    LeadOwner: "Theresa",
  },
  {
    Image: "company-icon-05.svg",
    LeadName: "Wisozk",
    CompanyName: "RiverStone Ventur",
    Phone: "+1 124547845",
    Email: "CarolTho3@example.com",
    LeadStatus: "Closed",
    CreatedDate: "8 Apr 2024, 11:00 am",
    Star: "fa fa-star",
    Class: "badge badge-soft-success",
    LeadOwner: "Espinosa",
  },
  {
    Image: "company-icon-06.svg",
    LeadName: "Heller",
    CompanyName: "Bright Bridge Grp",
    Phone: "+1 478845447",
    Email: "Dawnmercha@example.com",
    LeadStatus: "Closed",
    CreatedDate: "20 Apr 2024, 03:15 pm",
    Star: "fa fa-star",
    Class: "badge badge-soft-success",
    LeadOwner: "Martin",
  },
  {
    Image: "company-icon-07.svg",
    LeadName: "Gutkowski",
    CompanyName: "CoastalStar Co.",
    Phone: "+1 215544845",
    Email: "Rachel@example.com",
    LeadStatus: "Closed",
    CreatedDate: "12 May 2024, 08:30 am",
    Star: "fa fa-star",
    Class: "badge badge-soft-success",
    LeadOwner: "Newell",
  },
  {
    Image: "company-icon-08.svg",
    LeadName: "Walter",
    CompanyName: "HarborView",
    Phone: "+1 121145471",
    Email: "Jonelle@example.com",
    LeadStatus: "Closed",
    CreatedDate: "26 May 2024, 04:10 pm",
    Star: "fa fa-star filled",
    Class: "badge badge-soft-success",
    LeadOwner: "Janet",
  },
  {
    Image: "company-icon-09.svg",
    LeadName: "Hansen",
    CompanyName: "Golden Gate Ltd",
    Phone: "+1 321454789",
    Email: "Jonathan@example.com",
    LeadStatus: "Closed",
    CreatedDate: "13 June 2024, 10:30 am",
    Star: "fa fa-star",
    Class: "badge badge-soft-success",
    LeadOwner: "Craig",
  },
  {
    Image: "company-icon-10.svg",
    LeadName: "Leuschke",
    CompanyName: "Redwood Inc",
    Phone: "+1 278907145",
    Email: "brook@example.com",
    LeadStatus: "Lost",
    CreatedDate: "28 June 2024, 05:40 pm",
    Star: "fa fa-star",
    Class: "badge badge-soft-danger",
    LeadOwner: "Daniel",
  },
];
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
export default {
  data() {
    return {
      LeadAsc: [
        "Sort By Alphabet",
        "Ascending",
        "Descending",
        "Recently Viewed",
        "Recently Added",
      ],
      LeadNova: [
        "--Select--",
        "NovaWaveLLC",
        "SilverHawk",
        "SummitPeak",
        "HarborView",
        "Redwood Inc",
      ],
      Leadselect: ["--Select--", "Not Contacted", "Contacted", "Lost"],
      data,
      columns,
      records: [
        { Star: "fa fa-star", isFilled: false }, 
      ],
      filter: false,
      clientName: "",
      clientId: "",
      clientIdFocused: false,
      clientNameFocused: false,
    };
  },
  setup() {
    const dateRangeInput = ref(null);
    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        function booking_range(start, end) {
          // Move the function declaration to the root level of the function body
          function formatDate(date) {
            return date.format("M/D/YYYY");
          }

          return formatDate(start) + " - " + formatDate(end);
        }

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });
    return {
      dateRangeInput,
    };
  },
  methods: {
    toggleStar(record) {
      record.isFilled = !record.isFilled;
    },
    toggleFullscreen() {
      const elem = document.documentElement;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    toggleFilter(event) {
      const formSorts = this.$refs.formSorts;

      if (formSorts.classList.contains("table-filter-show")) {
        formSorts.classList.remove("table-filter-show");
      } else {
        formSorts.classList.add("table-filter-show");
      }

      event.stopPropagation();
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
};
</script>
