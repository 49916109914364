<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Xidmətlər</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard"
                  >Daşbord</router-link
                >
              </li>
              <li class="breadcrumb-item active">Xidmətlər</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                v-tippy
                title="Filtri sıfırla"
                @click="resetSearchFilter"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  v-tippy
                title="Tam ekran"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-tippy
                title="Filtr"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <!-- <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div> -->
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addDataModal"
                ><i class="la la-plus-circle"></i> Əlavə et</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <search-area :filter="filter" 
      ref="searchComponent"
      :searchFilters="searchFilters"
       @submitSearchFilter="searchFilterSubmitHandler"></search-area>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <form @submit.prevent="searchSubmit">
              <div class="search-set">
                <div class="search-input">
                  <a
                    href="javascript:void(0);"
                    @click="searchSubmit"
                    class="btn btn-searchset"
                    ><i class="las la-search"></i
                  ></a>
                  <div class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Axtar"
                        v-model="searchString"
                    /></label>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table
              class="stripped table-hover"
              :columns="columns"
              :data-source="data"
              :pagination="false"
              @change="handleTableChange"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Status'">
                  <StatusChange
                    :data="record"
                    @statusChange="handleChangeStatus"
                  />
                </template>
                <template v-else-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="editData(record.id)"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Düzəliş et</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="openDeleteModal(record.id)"
                          ><i class="fa-regular fa-trash-can m-r-5"></i>
                          Sil</a
                        >
                        
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="openShowModal(record.id)"
                          ><i class="fa-regular fa-eye m-r-5"></i>
                          Baxış</a
                        >
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else-if="column.key === 'measure_id'">
                    {{ record.measure.name }}
                </template>

                <template v-else-if="column.key === 'is_internal'">
                    {{ record.is_internal == 1 ? 'Bəli' : 'Xeyr' }}
                </template>
                <template v-else-if="column.key === 'is_external'">
                    {{ record.is_external == 1 ? 'Bəli' : 'Xeyr' }}
                </template>
                
              </template>
            </a-table>
            <a-pagination
              :current="currentPage"
              :total="totalData"
              :page-size="pageSize"
              @change="handlePageChange"
              class="mt-3 text-end mb-5"
            />
          </div>
        </div>
      </div>
    </div>
  </div>


  <AddModal 
    :measuresOptionsList="measuresOptionsList"
    @formSubmited="addSubmit">
  </AddModal>

  <EditModal 
    :id="editId"
    :measuresOptionsList="measuresOptionsList"
    @formSubmited="editSubmit">
  </EditModal>

  <delete-modal
    modalId="deleteModal"
    @deleteConfirmed="deleteSubmit"
  ></delete-modal>

  <ShowModal :fields="showData" :fieldLabels="showDataLabels" modal-title="Xidmətə baxış" :necessary-fields="showDataFields"></ShowModal>
</template>

<script setup>
import {
  ref,
  onMounted,
  reactive,
  onBeforeUnmount,
  watch,
} from "vue";
import StatusChange from "@/components/status-change.vue";
import ShowModal from "@/components/modal/show-data.vue";
import AddModal from "@/components/modal/services/add-service.vue";
import EditModal from "@/components/modal/services/edit-service.vue";

import { openModal, closeModal, handleTableSorterChange, toggleViewFullscreen } from "@/utils/helper";
import { showSuccessToast, showErrorToast } from "@/utils/helper";
import { useServices } from "@/stores/modules/services";
import { useUnits } from "@/stores/modules/units";

// Store
const serviceStore = useServices();
const unitStore = useUnits();

// State
const data = ref([]);

const showData = reactive({});

const showDataFields = ref(['id', 'name', 'code','unit.label','price','balance','note','is_internal','is_external','created_at']);
const showDataLabels = reactive({
  id:'ID',
  name:'Xidmət adı',
  code:'Xidmət Kodu',
  'unit.label':'Ölçü vahidi',
  price:'Qiymət (azn)',
  note:'Qeyd',
  is_internal:'Daxili',
  is_external:'Xarici',
  created_at:'Yaranma tarixi',
})

// Pagination and Filters
const filter = ref(false);
const searchComponent = ref(null);
let searchString = ref("");
let searchFiltersValue = reactive({});
let currentPage = ref(1);
let pageSize = ref(15);
let totalData = ref(0);
let itemToDeleteId = ref(null);
const editId = ref(null);

const sortParams = ref({
  sortField: 'id',
  sortOrder: 'desc',
})

const measuresOptionsList = ref([]);


// Columns
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
  },
  {
    title: "Ad",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Kod",
    dataIndex: "code",
    sorter: true,
  },
  {
    title: "Ölçü vahidi",
    dataIndex: "measure_id",
    key: "measure_id",
    sorter: true,
  },
  {
    title: "Qiymət",
    dataIndex: "price",
    sorter: false,
  },
  {
    title: "Daxili",
    dataIndex: "is_internal",
    key:"is_internal",
    sorter: true,
  },
  {
    title: "Xarici",
    dataIndex: "is_external",
    key:"is_external",
    sorter: true,
  },
  {
    title: "Yaranma Tarixi",
    dataIndex: "created_at",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "Status",
    sorter: false,
  },
  {
    title: "",
    dataIndex: "Action",
    key: "Action",
    sorter: false,
  },
];

//Filters
const searchFilters = [
  {
    title: "ID",
    key: "id",
    type: "text"
  },
  {
    title: "Xidmət adı",
    key: "name",
    type: "text"
  },
  {
    title: "Xidmət kodu",
    key: 'code',
    type: "text"
  },
  {
    title: "Xidmət növü",
    key: "type_id",
    type: "select",
    options:[
      {id:0,text:'Daxili'},
      {id:1,text:'Xarici'}
    ]
  },
  {
    title: "Yaranma Tarixi",
    key: "created_at",
    type: "date"
  }
];

/* -------------------- Methods -------------------- */

// Fetch data with pagination
const fetchListData = async (page = 1, searchString = "",searchFilters = searchFiltersValue) => {
  const response = await serviceStore.getList({
    params: {
      offset: (page - 1) * pageSize.value,
      limit: pageSize.value,
      q: searchString,
      sortField: sortParams.value.sortField,
      sortOrder: sortParams.value.sortOrder,
      ...searchFilters
    },
  });
  const responseData = response.data;
  data.value = responseData.data;
  totalData.value = responseData.total;
};


// Fetch Units Data and set it Select options
const createUnitOptions = async ()=>{
  await unitStore.getList({ limit: 10000 });
  measuresOptionsList.value = unitStore.data.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}


// Add new data entry
const addSubmit = async () => {
  await fetchListData();
};

// Edit submited
const editSubmit = async () => {
  await fetchListData();
  editId.value = null;
};


// Edit existing data entry
const editData = async (id) => {
  editId.value = id;
  openModal("editDataModal");
};

// DeleteConfirmationModal
const openDeleteModal = (id) => {
  openModal("deleteModal");
  itemToDeleteId.value = id;
};

// Delete item
const deleteSubmit = async () => {
  await serviceStore.delete(itemToDeleteId.value);
  closeModal("deleteModal");
  showSuccessToast("Uğurla silindi");
  fetchListData();
};

// Show Item
const openShowModal = async (id) => {
  await serviceStore.getDetail(id).then(()=>{
    Object.assign(showData, serviceStore.dataService  .data);
  });
  openModal('showModal')
}


// Search items
const searchSubmit = async () => {
  fetchListData(1, searchString.value);
};

// Search Filters Submit
const searchFilterSubmitHandler = async (filters) =>{
  await fetchListData(1,null,filters);
  searchFiltersValue = filters;
}

const resetSearchFilter = async () => {
  await fetchListData()
  searchComponent.value.resetFilters()
}

const handleChangeStatus = (id) => {
  const index = data.value.findIndex((item) => item.id === id);

  if (index !== -1) {
    changeStatus(!data.value[index].status, id);
    data.value[index].status =
      !data.value[index].status;
  }
};

const changeStatus = async (statusValue , id) => {
  try {
    await serviceStore.updateStatus({ status: statusValue }, id);
    showSuccessToast('Status yenilendi')
  } catch (error) {
    console.error("Error:", error);
  }
};

// Handle page change for pagination
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchListData(page);
};


// Handle Table Changes (Sorting)
const handleTableChange = async (pagination, filters, sorter) => {
  await handleTableSorterChange(sorter,sortParams);
  fetchListData(currentPage.value)
}


const toggleFullscreen = () => toggleViewFullscreen()
    
/* -------------------- Lifecycle Hooks -------------------- */

const debounceTime = 500;

let debounceTimeout;

watch(searchString, () => {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    searchSubmit();
  }, debounceTime);
});

// On component mount
onMounted(() => {
  fetchListData();
  createUnitOptions();
});

// Before unmount
onBeforeUnmount(() => {
  // document.removeEventListener("click", handleDocumentClick);
});
</script>

<style>
.ant-pagination-item a{
  color: black !important;
}

.ant-pagination-item-active a{
  color: white !important;
}
</style>
