<template>
    <a-table class="stripped table-hover" :columns="columns" :data-source="data">
        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'Star'">
                <div>
                    <div class="set-star star-select">
                        <i
                            :class="[record.Star, { filled: record.isFilled }]"
                            @click="toggleStar(record)"
                        ></i>
                    </div>
                </div>
            </template>
            <template v-else-if="column.key === 'Name'">
                <h2 class="table-avatar d-flex align-items-center">
                    <router-link to="/crm/company-details" class="company-img"><img :src="require(`@/assets/img/icons/${record.Image}`)"
                            alt="User Image"></router-link>
                    <router-link to="/crm/company-details" class="profile-split">{{ record.Name }}</router-link>
                </h2>
            </template>
            <template v-else-if="column.key === 'Tags'">
                <div><span :class="record.TagsClass">{{ record.Tags }}</span></div>
            </template>
            <template v-else-if="column.key === 'Rating'">
                <div class="set-star">
                    <i class="fa fa-star filled me-2"></i>{{ record.Rating }}
                </div>
            </template>
            <template v-else-if="column.key === 'Contact'">
                <ul class="social-links d-flex align-items-center">
                    <li>
                        <a href="javascript:void(0);"><i class="la la-envelope"></i></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);"><i class="la la-phone-volume"></i></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);"><i class="lab la-facebook-messenger"></i></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);"><i class="la la-skype"></i></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);"><i class="la la-facebook "></i></a>
                    </li>
                </ul>
            </template>
            <template v-else-if="column.key === 'Status'">
                <div class="dropdown action-label">
                    <a href="javascript:void(0);" :class="record.StatusClass"> {{ record.Status }} </a>
                </div>
            </template>
            <template v-else-if="column.key === 'action'">
                <div class="text-end">
                    <div class="dropdown dropdown-action">
                        <a href="javascript:void(0);" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#edit_company"><i
                                    class="fa-solid fa-pencil m-r-5"></i>
                                Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#delete_company"><i
                                    class="fa-regular fa-trash-can m-r-5"></i> Delete</a>
                            <router-link class="dropdown-item" to="/crm/company-details"><i class="fa-regular fa-eye"></i>
                                Preview</router-link>
                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#add_notes"><i class="la la-file-prescription"></i> Notes</a>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </a-table>
</template>

<script>
const data = [
    {
        "Star": "fa fa-star",
        "Image": "company-icon-01.svg",
        "Name": "NovaWaveLLC",
        "Phone": "+1 875455453",
        "Email": "robertson@example.com",
        "Tags": "Promotion",
        "TagsClass": "badge badge-soft-info",
        "Location": "Germany",
        "Rating": "4.2",
        "Owner": "Hendry",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star filled",
        "Image": "company-icon-02.svg",
        "Name": "BlueSky Industries",
        "Phone": "+1 989757485",
        "Email": "sharon@example.com",
        "Tags": "Rated",
        "TagsClass": "badge badge-soft-warning",
        "Location": "USA",
        "Rating": "5",
        "Owner": "Guillory",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star",
        "Image": "company-icon-03.svg",
        "Name": "SilverHawk",
        "Phone": "+1 546555455",
        "Email": "vaughan12@example.com",
        "Tags": "Promotion",
        "TagsClass": "badge badge-soft-info",
        "Location": "Canada",
        "Rating": "3.5",
        "Owner": "Jami",
        "Contact": "",
        "Status": "Inactive",
        "StatusClass": "btn btn-white btn-sm badge-outline-danger"
    },
    {
        "Star": "fa fa-star",
        "Image": "company-icon-04.svg",
        "Name": "SummitPeak",
        "Phone": "+1 454478787",
        "Email": "jessica13@example.com",
        "Tags": "Rated",
        "TagsClass": "badge badge-soft-warning",
        "Location": "India",
        "Rating": "4.5",
        "Owner": "Theresa",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star",
        "Image": "company-icon-05.svg",
        "Name": "RiverStone Ventur",
        "Phone": "+1 124547845",
        "Email": "carolTho3@example.com",
        "Tags": "Rejected",
        "TagsClass": "badge badge-soft-danger",
        "Location": "China",
        "Rating": "4.7",
        "Owner": "Espinosa",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star",
        "Image": "company-icon-06.svg",
        "Name": "Bright Bridge Grp",
        "Phone": "+1 478845447",
        "Email": "dawnmercha@example.com",
        "Tags": "Rated",
        "TagsClass": "badge badge-soft-warning",
        "Location": "Japan",
        "Rating": "5",
        "Owner": "Martin",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star filled",
        "Image": "company-icon-07.svg",
        "Name": "CoastalStar Co.",
        "Phone": "+1 215544845",
        "Email": "rachel@example.com",
        "Tags": "Rejected",
        "TagsClass": "badge badge-soft-danger",
        "Location": "Indonesia",
        "Rating": "3.1",
        "Owner": "Newell",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star filled",
        "Image": "company-icon-08.svg",
        "Name": "HarborView",
        "Phone": "+1 121145471",
        "Email": "jonelle@example.com",
        "Tags": "Rejected",
        "TagsClass": "badge badge-soft-danger",
        "Location": "Cuba",
        "Rating": "5",
        "Owner": "Janet",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star",
        "Image": "company-icon-09.svg",
        "Name": "Golden Gate Ltd",
        "Phone": "+1 321454789",
        "Email": "jonathan@example.com",
        "Tags": "CollabCalls",
        "TagsClass": "badge badge-soft-danger",
        "Location": "Isreal",
        "Rating": "2.7",
        "Owner": "Craig",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    },
    {
        "Star": "fa fa-star",
        "Image": "company-icon-10.svg",
        "Name": "Redwood Inc",
        "Phone": "+1 278907145",
        "Email": "brook@example.com",
        "Tags": "Calls",
        "TagsClass": "badge badge-soft-purple",
        "Location": "Colombia",
        "Rating": "3",
        "Owner": "Daniel",
        "Contact": "",
        "Status": "Active",
        "StatusClass": "btn btn-white btn-sm badge-outline-success"
    }
]

const columns = [
    {
        title: "Star",
        dataIndex: "Star",
        key: "Star",
        sorter: {
            compare: (a, b) => {
                a = a.Star.toLowerCase();
                b = b.Star.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: {
            compare: (a, b) => {
                a = a.Name.toLowerCase();
                b = b.Name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Phone",
        dataIndex: "Phone",
        sorter: {
            compare: (a, b) => {
                a = a.Phone.toLowerCase();
                b = b.Phone.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Email",
        dataIndex: "Email",
        sorter: {
            compare: (a, b) => {
                a = a.Email.toLowerCase();
                b = b.Email.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Tags",
        dataIndex: "Tags",
        key: "Tags",
        sorter: {
            compare: (a, b) => {
                a = a.Tags.toLowerCase();
                b = b.Tags.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Location",
        dataIndex: "Location",
        sorter: {
            compare: (a, b) => {
                a = a.Location.toLowerCase();
                b = b.Location.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Rating",
        dataIndex: "Rating",
        key: "Rating",
        sorter: {
            compare: (a, b) => {
                a = a.Rating.toLowerCase();
                b = b.Rating.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Owner",
        dataIndex: "Owner",
        sorter: {
            compare: (a, b) => {
                a = a.Owner.toLowerCase();
                b = b.Owner.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Contact",
        dataIndex: "Contact",
        key: "Contact",
        sorter: {
            compare: (a, b) => {
                a = a.Contact.toLowerCase();
                b = b.Contact.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        sorter: {
            compare: (a, b) => {
                a = a.Status.toLowerCase();
                b = b.Status.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Action",
        dataIndex: "Action",
        key: "action",
        sorter: true,
    },
]

export default {
    data() {
        return {
            columns,
            data
        }
    },
    methods: {
        toggleStar(record) {
            record.isFilled = !record.isFilled;
        },
    },
}
</script>