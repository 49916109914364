<template>
    <div
      class="modal custom-modal fade custom-modal-two modal-padding"
      id="add_organization"
      ref="addModal"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header header-border justify-content-between p-0">
            <h5 class="modal-title">Yeni təşkilat</h5>
            <button
              type="button"
              class="btn-close position-static"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <Form @submit="submitForm" :validation-schema="schema" v-slot="{ errors }">
              <div class="contact-input-set">
                <div class="row">
                  <div class="col-md-12">
                    <div class="input-block mb-3">
                      <label class="col-form-label">
                        Ad <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="name"
                        v-model="formData.name"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.name }"
                      />
                      <span class="text-danger">{{ errors.name ? errors.name : '' }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">
                        VÖEN <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="voen"
                        v-model="formData.voen"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.voen }"
                      />
                      <span class="text-danger">{{ errors.voen ? errors.voen : '' }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block mb-3">
                      <label class="col-form-label">
                        Əlaqəli şəxsin adı <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="related_fullname"
                        v-model="formData.related_fullname"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.related_fullname }"
                      />
                      <span class="text-danger">{{ errors.related_fullname ? errors.related_fullname : '' }}</span>
                    </div>
                  </div>
                  <div class="col-lg-12 text-end form-wizard-button">
                    <button
                      class="button btn-lights reset-btn"
                      type="reset"
                      @click="closeModal"
                    >
                      Sıfırla
                    </button>
                    <button class="btn btn-primary" type="submit">Yadda saxla</button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, defineProps, defineEmits } from "vue";
  import { Field, Form, useForm } from 'vee-validate';
  import * as Yup from 'yup';
  import { Modal } from 'bootstrap';
  
  const props = defineProps(['formData']);
  const emit = defineEmits(['formSubmited', 'close']);
  const modalRef = ref(null);

  const schema = Yup.object().shape({
    name: Yup.string().required("Məlumatın daxil edilməsi məcburidir"),
    voen: Yup.string().matches(/^\d+$/, "Ancaq rəqəmlərdən ibarət olmalıdır").length(10, "VÖEN 10 simvoldan ibarət olmalıdır").required("Məlumatın daxil edilməsi məcburidir"),
    related_fullname: Yup.string().required("Məlumatın daxil edilməsi məcburidir"),
  });
  
  const { handleSubmit, errors } = useForm({ validationSchema: schema });
  
  const closeModal = () => Modal.getInstance(modalRef.value)?.hide();

  const submitForm = (values) => {
    emit('formSubmited', values); 
    closeModal()
  };
  </script>

  