import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useUnits = defineStore('units', {
    state: () => ({
        data: null,
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['measurement'],{...payload})
                .then((res) => {
                    this.data = res;

                    return res;
                }).finally(() => {

                });
        },
    },
    getters: {}
});
