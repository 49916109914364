<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Deals</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Deals</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div>
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_deals"
                ><i class="la la-plus-circle"></i> Add Deals</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="filter-filelds"
        :style="{ display: filter ? 'block' : 'none' }"
        id="filter_inputs"
      >
        <div class="row filter-row">
          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientIdFocused }"
            >
              <input
                type="text"
                class="form-control floating"
                v-model="clientId"
                @focus="clientIdFocused = true"
                @blur="clientIdFocused = clientId !== ''"
              />
              <label class="focus-label">Deal Name</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus focused select-focus">
              <input
                type="text"
                class="form-control date-range bookingrange"
                ref="dateRangeInput"
              />
              <label class="focus-label">From - To Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdate"
                  placeholder="From"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Expected Closed Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="ConDeal" id="condeal" placeholder="--Select--" />

              <label class="focus-label">Stage</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="DealGer" id="dealger" placeholder="--Select--" />

              <label class="focus-label">Location</label>
            </div>
          </div>
          <div class="col-xl-2">
            <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
          </div>
        </div>
      </div>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <div class="view-icons">
              <router-link to="/crm/deals" class="list-view btn btn-link active"
                ><i class="las la-list"></i
              ></router-link>
              <router-link to="/crm/deals-kanban" class="grid-view btn btn-link"
                ><i class="las la-th"></i
              ></router-link>
            </div>
          </li>
          <li>
            <div class="form-sort">
              <i class="las la-sort-alpha-up-alt"></i>
              <vue-select
                :options="DealsSort"
                id="dealssort"
                placeholder="Sort By Alphabet"
              />
            </div>
          </li>
          <li>
            <div class="form-sorts dropdown" ref="formSorts">
              <a
                href="javascript:void(0);"
                @click="toggleFilter"
                class="dropdown-toggle"
                id="table-filter"
                ><i class="las la-filter me-2"></i>Filter</a
              >
              <div class="filter-dropdown-menu">
                <div class="filter-set-view">
                  <div class="filter-set-head">
                    <h4>Filter</h4>
                  </div>
                  <div class="accordion" id="accordionExample">
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          >Rating<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse show"
                        id="collapseOne"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <span>5.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <span>4.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>3.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>2.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>1.0</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          >Owner<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse"
                        id="collapseTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Hendry</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Guillory</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Jami</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Theresa</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Espinosa</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          >Tags<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse"
                        id="collapseThree"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Promotion</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Rated</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Rejected</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Collab</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Calls</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="filter-reset-btns">
                    <a href="javascript:void(0);" class="btn btn-light">Reset</a>
                    <a href="javascript:void(0);" class="btn btn-primary">Filter</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="search-set">
              <div class="search-input">
                <a href="javascript:void(0);" class="btn btn-searchset"
                  ><i class="las la-search"></i
                ></a>
                <div class="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="Search"
                  /></label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table class="stripped table-hover" :columns="columns" :data-source="data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Star'">
                  <div class="set-star star-select">
                    <i
                      :class="[record.Star, { filled: record.isFilled }]"
                      @click="toggleStar(record)"
                    ></i>
                  </div>
                </template>
                <template v-else-if="column.key === 'DealName'">
                  <h2>
                    <router-link to="/crm/deals-details" class="profile-split">{{
                      record.DealName
                    }}</router-link>
                  </h2>
                </template>
                <template v-else-if="column.key === 'Tags'">
                  <span :class="record.TagsClass">{{ record.Tags }}</span>
                  <span :class="record.Tagclass1">{{ record.Tags1 }}</span>
                </template>
                <template v-else-if="column.key === 'Status'">
                  <span :class="record.StatusClass">{{ record.Status }}</span>
                </template>
                <template v-else-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_deals"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_deals"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                        <router-link class="dropdown-item" to="/crm/deals-details"
                          ><i class="fa-regular fa-eye"></i> Preview</router-link
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <deals-modal></deals-modal>
</template>
<script>
const columns = [
  {
    title: "",
    dataIndex: "Star",
    key: "Star",
    sorter: false,
  },
  {
    title: "Deal Name",
    dataIndex: "DealName",
    key: "DealName",
    sorter: {
      compare: (a, b) => {
        a = a.DealName.toLowerCase();
        b = b.DealName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Stage",
    dataIndex: "Stage",
    sorter: {
      compare: (a, b) => {
        a = a.Stage.toLowerCase();
        b = b.Stage.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Deal Value",
    dataIndex: "DealValue",
    sorter: {
      compare: (a, b) => {
        a = a.DealValue.toLowerCase();
        b = b.DealValue.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Tags",
    dataIndex: "Tags",
    key: "Tags",
    sorter: {
      compare: (a, b) => {
        a = a.Tags.toLowerCase();
        b = b.Tags.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Expected Closed Date",
    dataIndex: "ExpectedClosedDate",
    sorter: {
      compare: (a, b) => {
        a = a.ExpectedClosedDate.toLowerCase();
        b = b.ExpectedClosedDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Owner",
    dataIndex: "Owner",
    sorter: {
      compare: (a, b) => {
        a = a.Owner.toLowerCase();
        b = b.Owner.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Probability",
    dataIndex: "Probability",
    sorter: {
      compare: (a, b) => {
        a = a.Probability.toLowerCase();
        b = b.Probability.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    sorter: true,
  },
];
const data = [
  {
    DealName: "Collins",
    Stage: "Conversation",
    DealValue: "$04,51,000",
    Tags: "Promotion",
    Tags1: "",
    TagsClass: "badge badge-soft-info",
    Tagclass1: "",
    ExpectedClosedDate: "10 Feb 2024",
    Owner: "Hendry",
    Probability: "85%",
    Star: "fa fa-star filled",
    StatusClass: "badge badge-soft-info",
    Status: "Open",
  },
  {
    DealName: "Konopelski",
    Stage: "Pipeline",
    DealValue: "$03,12,500",
    Tags: "Rated",
    Tags1: "",
    TagsClass: "badge badge-soft-warning",
    Tagclass1: "",
    ExpectedClosedDate: "24 Feb 2024",
    Owner: "Guillory",
    Probability: "15%",
    Star: "fa fa-star",
    StatusClass: "badge badge-soft-danger",
    Status: "Lost",
  },
  {
    DealName: "Adams",
    Stage: "Won",
    DealValue: "$04,14,400",
    Tags: "Promotion",
    Tags1: "",
    TagsClass: "badge badge-soft-info",
    Tagclass1: "",
    ExpectedClosedDate: "5 Mar 2024",
    Owner: "Jami",
    Probability: "95%",
    Star: "fa fa-star filled",
    StatusClass: "badge badge-soft-success",
    Status: "Won",
  },
  {
    DealName: "Schumm",
    Stage: "Lost",
    DealValue: "$09,14,400",
    Tags: "Rated",
    Tags1: "",
    TagsClass: "badge badge-soft-warning",
    Tagclass1: "",
    ExpectedClosedDate: "17 Mar 2024",
    Owner: "Theresa",
    Probability: "47%",
    Star: "fa fa-star filled",
    StatusClass: "badge badge-soft-success",
    Status: "Won",
  },
  {
    DealName: "Wisozk",
    Stage: "Follow Up",
    DealValue: "$11,14,400",
    Tags: "Rejected",
    Tags1: "",
    TagsClass: "badge badge-soft-danger",
    Tagclass1: "",
    ExpectedClosedDate: "08 Apr 2024",
    Owner: "Espinosa",
    Probability: "98%",
    Star: "fa fa-star",
    StatusClass: "badge badge-soft-success",
    Status: "Won",
  },
  {
    DealName: "Heller",
    Stage: "Pipeline",
    DealValue: "$16,11,400",
    Tags: "Rated",
    Tags1: "",
    TagsClass: "badge badge-soft-warning",
    Tagclass1: "",
    ExpectedClosedDate: "20 Apr 2024",
    Owner: "Martin",
    Probability: "14%",
    Star: "fa fa-star",
    StatusClass: "badge badge-soft-success",
    Status: "Won",
  },
  {
    DealName: "Gutkowski",
    Stage: "Pipeline",
    DealValue: "$78,11,800",
    Tags: "Rejected",
    Tags1: "",
    TagsClass: "badge badge-soft-danger",
    Tagclass1: "",
    ExpectedClosedDate: "12 May 2024",
    Owner: "Newell",
    Probability: "78%",
    Star: "fa fa-star",
    StatusClass: "badge badge-soft-info",
    Status: "Open",
  },
  {
    DealName: "Walter",
    Stage: "Pipeline",
    DealValue: "$09,05,947",
    Tags: "Rejected",
    Tags1: "",
    TagsClass: "badge badge-soft-danger",
    Tagclass1: "",
    ExpectedClosedDate: "26 May 2024",
    Owner: "Janet",
    Probability: "47%",
    Star: "fa fa-star filled",
    StatusClass: "badge badge-soft-danger",
    Status: "Lost",
  },
  {
    DealName: "Hansen",
    Stage: "Schedule",
    DealValue: "$04,51,000",
    Tags: "Collab",
    Tags1: "Calls",
    TagsClass: "badge badge-soft-danger me-2",
    Tagclass1: "badge badge-soft-purple",
    ExpectedClosedDate: "13 June 2024",
    Owner: "Craig",
    Probability: "25%",
    Star: "fa fa-star",
    StatusClass: "badge badge-soft-success",
    Status: "Won",
  },
  {
    DealName: "Leuschke",
    Stage: "Conversation",
    DealValue: "$72,14,078",
    Tags: "Calls",
    Tags1: "",
    TagsClass: "badge badge-soft-info",
    Tagclass1: "",
    ExpectedClosedDate: "28 June 2024",
    Owner: "Daniel",
    Probability: "69%",
    Star: "fa fa-star",
    StatusClass: "badge badge-soft-success",
    Status: "Won",
  },
];
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      ConDeal: ["--Select--", "Conversation", "Pipeline", "Won", "Lost", "Follow Up"],
      DealGer: ["--Select--", "Germany", "USA", "Canada", "India", "China"],
      DealsSort: [
        "Sort By Alphabet",
        "Ascending",
        "Descending",
        "Recently Viewed",
        "Recently Added",
      ],
      data,
      columns,
      filter: false,
      clientId: "",
      clientIdFocused: false,
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
    };
  },
  setup() {
    const dateRangeInput = ref(null);
    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        function booking_range(start, end) {
          // Move the function declaration to the root level of the function body
          function formatDate(date) {
            return date.format("M/D/YYYY");
          }

          return formatDate(start) + " - " + formatDate(end);
        }

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });
    return {
      dateRangeInput,
    };
  },
  methods: {
    toggleStar(record) {
      record.isFilled = !record.isFilled;
    },
    toggleFullscreen() {
      const elem = document.documentElement;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    toggleFilter(event) {
      const formSorts = this.$refs.formSorts;

      if (formSorts.classList.contains("table-filter-show")) {
        formSorts.classList.remove("table-filter-show");
      } else {
        formSorts.classList.add("table-filter-show");
      }

      event.stopPropagation();
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
};
</script>
