<template>
  <!-- Add Activity -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_activity"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Activity</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/activities">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3 activity-date-picker">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <date-picker
                        v-model="startdate"
                        placeholder="Date"
                        class="form-control floating datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="activity-date-picker input-block mb-3">
                    <label class="col-form-label"
                      >Time <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon time-icon">
                      <input type="text" class="form-control timepicker" />
                      <span class="cus-icon"><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="activity-date-picker input-block mb-3">
                    <label class="col-form-label"
                      >Reminder <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon reminder">
                      <input type="text" class="form-control" />
                      <span class="cus-icon"><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="input-block mb-3 d-flex align-items-center">
                    <div class="w-100">
                      <vue-select :options="MinAna" id="minana" placeholder="Minutes" />
                    </div>
                    <span class="use-before">Before Use</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Owner <span class="text-danger">*</span></label
                    >
                    <vue-select :options="MinSel" id="minsel" placeholder="Select" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Guests <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox3"
                      :tags="tags3"
                      value="Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-md-12 deals-add-col">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Deals <span class="text-danger">*</span></label
                          >
                          <a href="javascript:void(0);" class="add-new add-new-deal"
                            ><i class="la la-plus-circle me-2"></i>Add New</a
                          >
                        </div>
                        <vue-select
                          :options="MinCollins"
                          id="mincollins"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 contact-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Contacts <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-contact"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select
                      :options="MinDarlee"
                      id="mindarlee"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-md-12 company-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Company <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-company"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select :options="MinNov" id="minnov" placeholder="Select" />
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Activity</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Activity -->

  <!-- Add Company -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_company"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Company</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="add-details-wizard">
            <ul id="progressbar" class="progress-bar-wizard">
              <li class="active">
                <span><i class="la la-user-tie"></i></span>
                <div class="multi-step-info">
                  <h6>Basic Info</h6>
                </div>
              </li>
              <li>
                <span><i class="la la-map-marker"></i></span>
                <div class="multi-step-info">
                  <h6>Address</h6>
                </div>
              </li>
              <li>
                <div class="multi-step-icon">
                  <span><i class="la la-icons"></i></span>
                </div>
                <div class="multi-step-info">
                  <h6>Social Profiles</h6>
                </div>
              </li>
              <li>
                <div class="multi-step-icon">
                  <span><i class="la la-images"></i></span>
                </div>
                <div class="multi-step-info">
                  <h6>Access</h6>
                </div>
              </li>
            </ul>
          </div>
          <div class="add-info-fieldset">
            <fieldset id="first-field">
              <form action="/company">
                <div class="form-upload-profile">
                  <h6 class="">Profile Image <span> *</span></h6>
                  <div class="profile-pic-upload">
                    <div class="profile-pic">
                      <span
                        ><img src="@/assets/img/icons/profile-upload-img.svg" alt="Img"
                      /></span>
                    </div>
                    <div class="employee-field">
                      <div class="mb-0">
                        <div class="image-upload mb-0">
                          <input type="file" />
                          <div class="image-uploads">
                            <h4>Upload</h4>
                          </div>
                        </div>
                      </div>
                      <div class="img-reset-btn">
                        <a href="javascript:void(0);">Reset</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Company Name <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Email <span class="text-danger"> *</span></label
                          >
                          <div
                            class="status-toggle small-toggle-btn d-flex align-items-center"
                          >
                            <span class="me-2 label-text">Option</span>
                            <input type="checkbox" id="user2" class="check" checked="" />
                            <label for="user2" class="checktoggle"></label>
                          </div>
                        </div>
                        <input class="form-control" type="email" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone Number 1<span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone Number 2 <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Fax <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Website</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Reviews <span class="text-danger">*</span></label
                        >
                        <vue-select :options="LowAna" id="lowana" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Owner <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="LowHendry"
                          id="lowhendry"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Tags <span class="text-danger">*</span></label
                        >
                        <vue3-tags-input
                          class="input-tags form-control"
                          type="text"
                          data-role="tagsinput"
                          name="Label"
                          id="inputBox"
                          :tags="tags"
                          value="Label, Label"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Deals <span class="text-danger">*</span></label
                          >
                          <a href="javascript:void(0);" class="add-new"
                            ><i class="la la-plus-circle me-2"></i>Add New</a
                          >
                        </div>
                        <vue-select
                          :options="MinCollinsOne"
                          id="mincollinsone"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Industry <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="MinBarry"
                          id="minbarry"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Source <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="MinBarryOne"
                          id="minbarryone"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Contact <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="MinEmail"
                          id="minemail"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Currency <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="MinselOne"
                          id="minselone"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Language <span class="text-danger">*</span></label
                        >
                        <vue-select :options="MinEng" id="mineng" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >About Company<span class="text-danger">*</span></label
                        >
                        <textarea class="form-control" rows="5"></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button class="btn btn-primary wizard-next-btn" type="button">
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset>
              <form action="/company">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Street Address<span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >City <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >State / Province <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Country <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="SelectGer"
                          id="selectger"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Zipcode <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button class="btn btn-primary wizard-next-btn" type="button">
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset>
              <form action="/company">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Facebook</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Twitter</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Linkedin</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Skype</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Whatsapp</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label">Instagram</label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <a href="javascript:void(0);" class="add-new"
                          ><i class="la la-plus-circle me-2"></i>Add New</a
                        >
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button class="btn btn-primary wizard-next-btn" type="button">
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset>
              <form action="/company">
                <div class="contact-input-set">
                  <div class="input-blocks add-products">
                    <label class="mb-3">Visibility</label>
                    <div class="access-info-tab">
                      <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0"
                            id="pills-public-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-public"
                            role="tab"
                            aria-controls="pills-public"
                            aria-selected="true"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="public"
                              checked
                            />
                            <span class="checkmark"></span> Public</span
                          >
                        </li>
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0"
                            id="pills-private-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-private"
                            role="tab"
                            aria-controls="pills-private"
                            aria-selected="false"
                          >
                            <input type="radio" class="form-control" name="private" />
                            <span class="checkmark"></span> Private</span
                          >
                        </li>
                        <li class="nav-item" role="presentation">
                          <span
                            class="custom_radio mb-0 active"
                            id="pills-select-people-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-select-people"
                            role="tab"
                            aria-controls="pills-select-people"
                            aria-selected="false"
                          >
                            <input
                              type="radio"
                              class="form-control"
                              name="select-people"
                            />
                            <span class="checkmark"></span> Select People</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade"
                      id="pills-public"
                      role="tabpanel"
                      aria-labelledby="pills-public-tab"
                    ></div>
                    <div
                      class="tab-pane fade"
                      id="pills-private"
                      role="tabpanel"
                      aria-labelledby="pills-private-tab"
                    ></div>
                    <div
                      class="tab-pane fade show active"
                      id="pills-select-people"
                      role="tabpanel"
                      aria-labelledby="pills-select-people-tab"
                    >
                      <div class="people-select-tab">
                        <h3>Select People</h3>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-19.jpg" alt="Img" />
                              <a href="javascript:void(0);">Darlee Robertson</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-20.jpg" alt="Img" />
                              <a href="javascript:void(0);">Sharon Roy</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-21.jpg" alt="Img" />
                              <a href="javascript:void(0);">Vaughan</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-people-checkbox">
                          <label class="custom_check">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                            <span class="people-profile">
                              <img src="@/assets/img/avatar/avatar-1.jpg" alt="Img" />
                              <a href="javascript:void(0);">Jessica</a>
                            </span>
                          </label>
                        </div>
                        <div class="select-confirm-btn">
                          <a href="javascript:void(0);" class="btn danger-btn">Confirm</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="mb-3">Status</h5>
                  <div class="status-radio-btns d-flex mb-3">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="test1"
                        name="radio-group"
                        checked
                      />
                      <label for="test1">Active</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="test2"
                        name="radio-group"
                      />
                      <label for="test2">Private</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="test3"
                        name="radio-group"
                      />
                      <label for="test3">Inactive</label>
                    </div>
                  </div>
                  <div class="col-lg-12 text-end form-wizard-button">
                    <button class="button btn-lights reset-btn" type="reset">
                      Reset
                    </button>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#success_msg"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Company -->

  <!-- Export -->
  <div class="modal custom-modal fade modal-padding" id="export" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Export</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/analytics">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <h5 class="mb-3">Export</h5>
                  <div class="status-radio-btns d-flex">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="pdf"
                        name="export-type"
                        checked
                      />
                      <label for="pdf">Person</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="excel"
                        name="export-type"
                      />
                      <label for="excel">Organization</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h4 class="mb-3">Filters</h4>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Fields <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Anaall" id="anaall" placeholder="All Fields" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      placeholder="Date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdateOne"
                      placeholder="Date"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Export Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Export -->
</template>
<script>
import Vue3TagsInput from "vue3-tags-input";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      Anaall: ["All Fields", "Contact", "Company"],
      SelectGer: ["Select", "Germany", "USA"],
      MinEng: ["Select", "English", "French"],
      MinselOne: ["Select", "$", "€"],
      MinEmail: ["Select", "Email", "Call", "Skype"],
      MinBarry: ["Select", "Barry Cuda", "Tressa Wexler"],
      MinBarryOne: ["Select", "Barry Cuda", "Tressa Wexler"],
      MinCollins: ["Select", "Collins", "Konopelski", "Adams"],
      MinCollinsOne: ["Select", "Collins", "Konopelski", "Adams"],
      LowHendry: ["Select", "Hendry", "Guillory", "Jami"],
      LowAna: ["Select", "Lowest", "Highest"],
      MinNov: ["Select", "NovaWaveLLC", "SilverHawk", "SummitPeak"],
      MinDarlee: ["Select", "Darlee Robertson", "Sharon Roy", "Vaughan"],
      MinSel: ["Select", "Monty Beer", "Itzel", "Andrew"],
      MinAna: ["Minutes", "Hours"],
      tags3: ["Darlee Robertson"],
      tags: ["Label", "Label"],
    };
  },
  components: {
    Vue3TagsInput,
  },
};
</script>
