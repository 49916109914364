<template>
  <div id="app">
    <ring-loader :loading="store.loading"></ring-loader>
    
    <div class="main-wrapper">
      <!-- <AtomSpinner v-if="store.loading"></AtomSpinner> -->
      <router-view />
    </div>
  </div>
</template>
<script setup>
import { useAppStore } from '@/stores';
// import AtomSpinner from './components/atom-spinner.vue';

const store = useAppStore();
</script>
