<template>
  <div class="tab-pane fade active show" id="monthly">
    <div class="row mb-30 equal-height-cards">
      <div class="col-md-4">
        <div class="card pricing-box">
          <div class="card-body d-flex flex-column">
            <div class="mb-4">
              <h3>Free</h3>
              <span class="display-4">$0</span>
            </div>
            <ul>
              <li><i class="fa-solid fa-check"></i> <b>1 User</b></li>
              <li><i class="fa-solid fa-check"></i> 5 Projects</li>
              <li><i class="fa-solid fa-check"></i> 5 GB Storage</li>
              <li><i class="fa-solid fa-check"></i> Unlimited Message</li>
            </ul>
            <a href="javascript:;" class="btn btn-lg btn-success mt-auto">Current Plan</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card pricing-box">
          <div class="card-body d-flex flex-column">
            <div class="mb-4">
              <h3>Professional</h3>
              <span class="display-4">$21</span>
              <span>/mo</span>
            </div>
            <ul>
              <li><i class="fa-solid fa-check"></i> <b>30 Users</b></li>
              <li><i class="fa-solid fa-check"></i> 50 Projects</li>
              <li><i class="fa-solid fa-check"></i> 100 GB Storage</li>
              <li><i class="fa-solid fa-check"></i> Unlimited Message</li>
              <li><i class="fa-solid fa-check"></i> 24/7 Customer Support</li>
            </ul>
            <a href="javascript:;" class="btn btn-lg btn-outline-secondary mt-auto"
              >Upgrade</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card pricing-box">
          <div class="card-body d-flex flex-column">
            <div class="mb-4">
              <h3>Enterprise</h3>
              <span class="display-4">$38</span>
              <span>/mo</span>
            </div>
            <ul>
              <li><i class="fa-solid fa-check"></i> <b>Unlimited Users </b></li>
              <li><i class="fa-solid fa-check"></i> Unlimited Projects</li>
              <li><i class="fa-solid fa-check"></i> 500 GB Storage</li>
              <li><i class="fa-solid fa-check"></i> Unlimited Message</li>
              <li><i class="fa-solid fa-check"></i> Voice and Video Call</li>
              <li><i class="fa-solid fa-check"></i> 24/7 Customer Support</li>
            </ul>
            <a href="javascript:;" class="btn btn-lg btn-outline-secondary mt-auto"
              >Upgrade</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
