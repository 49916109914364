import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';


export const useFileUpload = defineStore('fileUpload', {
    state: () => ({}),

    actions: {
        async store(formData) {
            try {
                return await axiosInstance.post(urlList['fileUpload'], formData,{
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }).then((res) => {
                    return res.data;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        }
    },
    getters: {}
});
