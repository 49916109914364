<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Analytics</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Analytics</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  @click="toggleFullscreen"
                  id="collapse-header"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <!-- Search Filter -->
      <div
        class="filter-filelds"
        :style="{ display: filter ? 'block' : 'none' }"
        id="filter_inputs"
      >
        <div class="row filter-row">
          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientIdFocused }"
            >
              <input
                type="text"
                class="form-control floating"
                v-model="clientId"
                @focus="clientIdFocused = true"
                @blur="clientIdFocused = clientId !== ''"
              />
              <label class="focus-label">Activity</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientNameFocused }"
            >
              <input
                type="email"
                class="form-control floating"
                v-model="clientName"
                @focus="clientNameFocused = true"
                @blur="clientNameFocused = clientName !== ''"
              />
              <label class="focus-label">Owner</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdate"
                  placeholder="Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Due Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdateOne"
                  placeholder="Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Created at</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="CrmAna" id="crmana" placeholder="--Select--" />

              <label class="focus-label">Activity Type</label>
            </div>
          </div>
          <div class="col-xl-2">
            <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <created-contact></created-contact>
        <div class="col-xl-6">
          <div class="card analytics-card">
            <div
              class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
            >
              <h3>Deals By Stage</h3>
              <div class="d-flex flex-wrap row-gap-3">
                <div class="input-block mb-0 me-3">
                  <vue-select
                    :options="CrmMarkThree"
                    id="crmmarkthree"
                    placeholder="Sales Pipeline"
                  />
                </div>
                <div class="input-block mb-0">
                  <vue-select
                    :options="CrmSeldayFive"
                    id="crmseldayfive"
                    placeholder="Last 30 days"
                  />
                </div>
              </div>
            </div>
            <div class="card-body">
              <apexchart
                type="bar"
                height="270"
                :options="dealsChart.chart"
                :series="dealsChart.series"
              >
              </apexchart>
            </div>
          </div>
          <div class="card analytics-card">
            <div
              class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
            >
              <h3>
                Activities
                <a href="javascript:void(0);"><i class="la la-link"></i></a>
              </h3>
              <div class="title-head d-flex">
                <div class="input-block mb-0">
                  <vue-select
                    :options="CrmSeldaySix"
                    id="crmseldaysix"
                    placeholder="Last 30 days"
                  />
                </div>
                <a
                  href="javascript:void(0);"
                  class="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add_activity"
                  ><i class="la la-plus-circle"></i> Add Activity</a
                >
              </div>
            </div>
            <div class="card-body">
              <div class="activities-list">
                <ul>
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <div class="activity-name">
                          <h5>We scheduled a meeting for next week</h5>
                          <p>Due Date : 10 Feb 2024, 09:00 am</p>
                          <span class="badge activity-badge badge-bg-violet"
                            ><i class="la la-user-tie"></i>Meeting</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="user-activity">
                          <span>
                            <img src="@/assets/img/avatar/avatar-19.jpg" alt="Img" />
                          </span>
                          <h6>Darlee Robertson</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-block mb-0">
                          <vue-select
                            :options="CrmIn"
                            id="crmin"
                            placeholder="Inprogress"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <div class="activity-name">
                          <h5>Built landing pages</h5>
                          <p>Due Date : 12 Mar 2024, 08:30 am</p>
                          <span class="badge activity-badge badge-bg-warning"
                            ><i class="la la-envelope"></i>Email</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="user-activity">
                          <span>
                            <img src="@/assets/img/avatar/avatar-16.jpg" alt="Img" />
                          </span>
                          <h6>Carol Thomas</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-block mb-0">
                          <vue-select
                            :options="CrmInOne"
                            id="crminone"
                            placeholder="Inprogress"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <div class="activity-name">
                          <h5>Call John and discuss about project</h5>
                          <p>Due Date : 20 Apr 2024, 03:15 pm</p>
                          <span class="badge activity-badge badge-bg-success"
                            ><i class="la la-phone"></i>Calls</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="user-activity">
                          <span>
                            <img src="@/assets/img/avatar/avatar-20.jpg" alt="Img" />
                          </span>
                          <h6>Sharon Roy</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-block mb-0">
                          <vue-select
                            :options="CrmInTwo"
                            id="crmintwo"
                            placeholder="Inprogress"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <div class="activity-name">
                          <h5>Store and manage contact data</h5>
                          <p>Due Date : 13 May 2024, 10:30 am</p>
                          <span class="badge activity-badge badge-bg-info"
                            ><i class="la la-tasks"></i>Task</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="user-activity">
                          <span>
                            <img src="@/assets/img/avatar/avatar-22.jpg" alt="Img" />
                          </span>
                          <h6>Dawn Mercha</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-block mb-0">
                          <vue-select
                            :options="CrmInThree"
                            id="crminthree"
                            placeholder="Inprogress"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <div class="activity-name">
                          <h5>Regarding latest updates in project</h5>
                          <p>Due Date : 25 May 2024, 02:10 pm</p>
                          <span class="badge activity-badge badge-bg-violet"
                            ><i class="la la-user-tie"></i>Meeting</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="user-activity">
                          <span>
                            <img src="@/assets/img/avatar/avatar-24.jpg" alt="Img" />
                          </span>
                          <h6>Jonelle Curtiss</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-block mb-0">
                          <vue-select
                            :options="CrmInFour"
                            id="crminfour"
                            placeholder="Inprogress"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <div class="activity-name">
                          <h5>Discussed budget proposal with Edwin</h5>
                          <p>Due Date : 05 June 2024, 05:40 pm</p>
                          <span class="badge activity-badge badge-bg-success"
                            ><i class="la la-phone"></i>Calls</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="user-activity">
                          <span>
                            <img src="@/assets/img/avatar/avatar-25.jpg" alt="Img" />
                          </span>
                          <h6>Jonathan</h6>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-block mb-0">
                          <vue-select
                            :options="CrmInFive"
                            id="crminfive"
                            placeholder="Inprogress"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card analytics-card">
            <div
              class="card-header d-flex justify-content-between card-selectsview flex-wrap row-gap-3 mb-0"
            >
              <h3 class="card-title mb-0">Lost Leads Stage</h3>
              <div class="card-select">
                <ul>
                  <li>
                    <vue-select
                      :options="CrmOne"
                      id="crmone"
                      placeholder="Marketing Pipeline"
                    />
                  </li>
                  <li>
                    <vue-select
                      :options="CrmSeldaySeven"
                      id="crmseldayseven"
                      placeholder="Last 30 days"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body pt-0">
              <apexchart
                type="bar"
                height="150"
                :options="analyticsFour.anachartsFour"
                :series="analyticsFour.series"
              ></apexchart>
            </div>
          </div>
          <div class="card analytics-card">
            <div
              class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
            >
              <h3>
                Recently Created Leads
                <a href="javascript:void(0);"><i class="la la-link"></i></a>
              </h3>
              <div class="input-block mb-0">
                <vue-select
                  :options="CrmSeldayEight"
                  id="crmseldayeight"
                  placeholder="Last 30 days"
                />
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-nowrap custom-table mb-0">
                  <thead>
                    <tr>
                      <th>Lead Name</th>
                      <th>Company Name</th>
                      <th>Phone</th>
                      <th>Lead Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <router-link to="/crm/leads-details">Collins</router-link>
                      </td>
                      <td>
                        <h2 class="table-avatar d-flex align-items-center">
                          <router-link to="/crm/company-details" class="company-img"
                            ><img
                              src="@/assets/img/icons/company-icon-01.svg"
                              alt="User Image"
                          /></router-link>
                          <router-link to="/crm/company-details" class="profile-split"
                            >NovaWaveLLC
                            <span class="d-block">Newyork, USA</span></router-link
                          >
                        </h2>
                      </td>
                      <td>+1 875455453</td>
                      <td>
                        <span class="badge badge-soft-success">Closed</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link to="/crm/leads-details">Konopelski</router-link>
                      </td>
                      <td>
                        <h2 class="table-avatar d-flex align-items-center">
                          <router-link to="/crm/company-details" class="company-img"
                            ><img
                              src="@/assets/img/icons/company-icon-02.svg"
                              alt="User Image"
                          /></router-link>
                          <router-link to="/crm/company-details" class="profile-split"
                            >BlueSky Industries
                            <span class="d-block">Winchester, KY</span></router-link
                          >
                        </h2>
                      </td>
                      <td>+1 989757485</td>
                      <td>
                        <span class="badge badge-soft-success">Closed</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link to="/crm/leads-details">Adams</router-link>
                      </td>
                      <td>
                        <h2 class="table-avatar d-flex align-items-center">
                          <router-link to="/crm/company-details" class="company-img"
                            ><img
                              src="@/assets/img/icons/company-icon-03.svg"
                              alt="User Image"
                          /></router-link>
                          <router-link to="/crm/company-details" class="profile-split"
                            >SilverHawk
                            <span class="d-block">Jamestown, NY</span></router-link
                          >
                        </h2>
                      </td>
                      <td>+1 546555455</td>
                      <td>
                        <span class="badge badge-soft-info">Not Contacted</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link to="/crm/leads-details">Schumm</router-link>
                      </td>
                      <td>
                        <h2 class="table-avatar d-flex align-items-center">
                          <router-link to="/crm/company-details" class="company-img"
                            ><img
                              src="@/assets/img/icons/company-icon-04.svg"
                              alt="User Image"
                          /></router-link>
                          <router-link to="/crm/company-details" class="profile-split"
                            >SummitPeak
                            <span class="d-block">Compton, RI</span></router-link
                          >
                        </h2>
                      </td>
                      <td>+1 454478787</td>
                      <td>
                        <span class="badge badge-soft-warning">Contacted</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link to="/crm/leads-details">Wisozk</router-link>
                      </td>
                      <td>
                        <h2 class="table-avatar d-flex align-items-center">
                          <router-link to="/crm/company-details" class="company-img"
                            ><img
                              src="@/assets/img/icons/company-icon-05.svg"
                              alt="User Image"
                          /></router-link>
                          <router-link to="/crm/company-details" class="profile-split"
                            >RiverStone Ventur<span class="d-block"
                              >Dayton, OH</span
                            ></router-link
                          >
                        </h2>
                      </td>
                      <td>+1 124547845</td>
                      <td>
                        <span class="badge badge-soft-success">Closed</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link to="/crm/leads-details">Heller</router-link>
                      </td>
                      <td>
                        <h2 class="table-avatar d-flex align-items-center">
                          <router-link to="/crm/company-details" class="company-img"
                            ><img
                              src="@/assets/img/icons/company-icon-06.svg"
                              alt="User Image"
                          /></router-link>
                          <router-link to="/crm/company-details" class="profile-split"
                            >Bright Bridge Grp<span class="d-block"
                              >Saybrook, IL</span
                            ></router-link
                          >
                        </h2>
                      </td>
                      <td>+1 478845447</td>
                      <td>
                        <span class="badge badge-soft-success">Closed</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card analytics-card">
            <div
              class="card-header d-flex justify-content-between card-selectsview flex-wrap row-gap-3 mb-0"
            >
              <h3 class="card-title mb-0">Won Leads Stage</h3>
              <div class="card-select">
                <ul>
                  <li>
                    <vue-select
                      :options="CrmTwo"
                      id="crmTwo"
                      placeholder="Marketing Pipeline"
                    />
                  </li>
                  <li>
                    <vue-select
                      :options="CrmSeldayNine"
                      id="crmseldaynine"
                      placeholder="Last 30 days"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body pt-0">
              <apexchart
                type="bar"
                height="150"
                :options="analytics.anacharts"
                :series="analytics.series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <analytics-modal></analytics-modal>
    <!-- /Page Content -->
  </div>
</template>
<script>
import { analyticsFour, dealsChart } from "./data";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import { analytics } from "./data";
export default {
  data() {
    return {
      analyticsFour: analyticsFour,
      dealsChart: dealsChart,
      filter: false,
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      clientName: "",
      clientId: "",
      clientIdFocused: false,
      clientNameFocused: false,
      analytics: analytics,
      CrmMarkThree: ["Sales Pipeline", "Marketing Pipeline"],
      CrmSeldayFive: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldaySix: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldaySeven: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldayEight: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldayNine: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmIn: ["Inprogress", "Completed", "Todo"],
      CrmInOne: ["Inprogress", "Completed", "Todo"],
      CrmInTwo: ["Inprogress", "Completed", "Todo"],
      CrmInThree: ["Inprogress", "Completed", "Todo"],
      CrmInFour: ["Inprogress", "Completed", "Todo"],
      CrmInFive: ["Inprogress", "Completed", "Todo"],
      CrmOne: ["Marketing Pipeline", "Pipeline"],
      CrmTwo: ["Marketing Pipeline", "Pipeline"],
      CrmAna: ["--Select--", "Meeting", "Calls", "Email", "Task"],
    };
  },
  toggleFullscreen() {
    const elem = document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  },
};
</script>
