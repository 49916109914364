<template>
  <ul class="sidebar-vertical">
    <template v-for="item in sideBarData" :key="item.tittle">
      <li class="menu-title">
        <span>{{ item.tittle }}</span>
        <small class="newly-added-features" v-if="item.tittle === 'CRM'">New</small>
      </li>

      <template v-for="menu in item.menu" :key="menu.menuValue">
        <li v-if="!menu.hasSubRoute" class="custom-style" :class="{ 'active': isMenuActive(menu) }">
          <router-link v-if="menu.route" :to="menu.route">
            <i :class="'la la-' + menu.icon"></i>
            <span>{{ menu.menuValue }} </span>
          </router-link>
        </li>
        <li v-else class="submenu">
          <a href="javascript:void(0)" @click="expandSubMenus(menu)" @keydown.enter="expandSubMenus(item)"
            :tabindex="menu.hasSubRoute ? '0' : undefined"
            :class="{ subdrop: menu.showSubRoute, 'noti-dot': menu.dot, active: route_array[1] === menu.active_link }">
            <i :class="'la la-' + menu.icon"></i>
            <span>{{ menu.menuValue }} </span>
            <span class="menu-arrow"></span>
          </a>
          <ul :class="{ 'd-block': menu.showSubRoute, 'd-none': !menu.showSubRoute, }">
            <li v-for="(subMenu, index) in menu.subMenus" :key="index">
              <router-link :to="subMenu.route">
                <span v-if="subMenu.currentActive" class="badge rounded-pill bg-primary float-end">1</span>
                {{ subMenu.menuValue }}
              </router-link>
            </li>
          </ul>
        </li>

        <li v-if="menu.hasSubRouteTwo" class="submenu">
          <a href="javascript:void(0);" @click="openMenu(menu)"
            :class="{ subdrop: openMenuItem === menu, active: route_array[1] === menu.active_link }">
            <i :class="'la la-' + menu.icon"></i>
            <span>{{ menu.menuValue }}</span>
            <span class="menu-arrow"></span>
          </a>

          <ul :class="{ 'd-block': openMenuItem === menu, 'd-none': openMenuItem !== menu }">
            <li v-for="subMenus in menu.subMenus" :key="subMenus.menuValue">
              <template v-if="!subMenus.customSubmenuTwo">
                <router-link :to="subMenus.route" router-link-active="active">{{
      subMenus.menuValue
    }}</router-link>
              </template>

              <template v-else-if="subMenus.customSubmenuTwo">
            <li class="submenu">
              <a href="javascript:void(0);" @click="openSubmenuOne(subMenus)"
                :class="{ subdrop: openSubmenuOneItem === subMenus }">
                {{ subMenus.menuValue }}
                <span class="menu-arrow"></span>
              </a>
              <ul :class="{
      'd-block': openSubmenuOneItem === subMenus,
      'd-none': openSubmenuOneItem !== subMenus,
    }">
                <li v-for="subMenuTwo in subMenus.subMenusTwo" :key="subMenuTwo.menuValue">
                  <router-link :to="subMenuTwo.route">{{
      subMenuTwo.menuValue
    }}</router-link>
                </li>
              </ul>
            </li>
      </template>
      </li>
  </ul>
  </li>
</template>
</template>
</ul>
</template>

<script>
import sideBarData from "@/assets/json/sidebar-menu.json";
export default {
  data() {
    return {
      sideBarData: sideBarData,
      base: "",
      page: "",
      openMenuItem: null,
      openSubmenuOneItem: null,
      route_array: [],
      multilevel: [false, false, false],
    };
  },
  computed: {
    isMenuActive() {
      return (menu) => {
        return this.$route.path === menu.route ||
          this.$route.path === menu.active_link ||
          this.$route.path === menu.active_link1 ||
          this.$route.path === menu.active_link2;
      };
    },
  },
  methods: {
    expandSubMenus(menu) {
      this.sideBarData.forEach((item) => {
        item.menu.forEach((subMenu) => {
          if (subMenu !== menu) {
            subMenu.showSubRoute = false;
          }
        });
      });
      menu.showSubRoute = !menu.showSubRoute;
    },
    openMenu(menu) {
      this.openMenuItem = this.openMenuItem === menu ? null : menu;
    },
    openSubmenuOne(subMenus) {
      this.openSubmenuOneItem = this.openSubmenuOneItem === subMenus ? null : subMenus;
    },
  },
};
</script>
