<template>
  <div class="tab-pane fade" id="email">
    <div class="view-header">
      <h4>Email</h4>
      <ul>
        <li>
          <a
            href="javascript:void(0);"
            class="com-add create-mail"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            data-bs-custom-class="tooltip-dark"
            data-bs-original-title="There are no email accounts configured, Please configured your email account in order to Send/ Create EMails"
            ><i class="las la-plus-circle me-1"></i>Create Email</a
          >
        </li>
      </ul>
    </div>
    <div class="files-activity">
      <div class="files-wrap">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="file-info">
              <h4>Manage Emails</h4>
              <p>You can send and reply to emails directly via this section.</p>
            </div>
          </div>
          <div class="col-md-4 text-md-end">
            <ul class="file-action">
              <li>
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#create_email"
                  >Connect Account</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="files-wrap">
        <div class="email-header">
          <div class="row">
            <div class="col top-action-left">
              <div class="float-start d-none d-sm-block">
                <input
                  type="text"
                  placeholder="Search Messages"
                  class="form-control search-message"
                />
              </div>
            </div>
            <div class="col-auto top-action-right">
              <div class="text-end">
                <button
                  type="button"
                  title="Refresh"
                  data-bs-toggle="tooltip"
                  class="btn btn-white d-none d-md-inline-block"
                >
                  <i class="fa-solid fa-rotate"></i>
                </button>
                <div class="btn-group">
                  <a class="btn btn-white"><i class="fa-solid fa-angle-left"></i></a>
                  <a class="btn btn-white"><i class="fa-solid fa-angle-right"></i></a>
                </div>
              </div>
              <div class="text-end">
                <span class="text-muted d-none d-md-inline-block"
                  >Showing 10 of 112
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="email-content">
          <div class="table-responsive">
            <table class="table table-inbox table-hover">
              <thead>
                <tr>
                  <th colspan="6" class="ps-2">
                    <input type="checkbox" class="checkbox-all" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="unread clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa fa-star starred"></i></span>
                  </td>
                  <td class="name">John Doe</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td><i class="fa-solid fa-paperclip"></i></td>
                  <td class="mail-date">13:14</td>
                </tr>
                <tr class="unread clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">Envato Account</td>
                  <td class="subject">Important account security update from Envato</td>
                  <td></td>
                  <td class="mail-date">8:42</td>
                </tr>
                <tr class="clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">Twitter</td>
                  <td class="subject">HRMS Bootstrap Admin Template</td>
                  <td></td>
                  <td class="mail-date">30 Nov</td>
                </tr>
                <tr class="unread clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">Richard Parker</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td></td>
                  <td class="mail-date">18 Sep</td>
                </tr>
                <tr class="clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">John Smith</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td></td>
                  <td class="mail-date">21 Aug</td>
                </tr>
                <tr class="clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">me, Robert Smith (3)</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td></td>
                  <td class="mail-date">1 Aug</td>
                </tr>
                <tr class="unread clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">Codecanyon</td>
                  <td class="subject">Welcome To Codecanyon</td>
                  <td></td>
                  <td class="mail-date">Jul 13</td>
                </tr>
                <tr class="clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">Richard Miles</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td><i class="fa-solid fa-paperclip"></i></td>
                  <td class="mail-date">May 14</td>
                </tr>
                <tr class="unread clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa-regular fa-star"></i></span>
                  </td>
                  <td class="name">John Smith</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td></td>
                  <td class="mail-date">11/11/16</td>
                </tr>
                <tr class="clickable-row" data-href="/mail-view">
                  <td>
                    <input type="checkbox" class="checkmail" />
                  </td>
                  <td>
                    <span class="mail-important"><i class="fa fa-star starred"></i></span>
                  </td>
                  <td class="name">Mike Litorus</td>
                  <td class="subject">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                  </td>
                  <td></td>
                  <td class="mail-date">10/31/16</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
