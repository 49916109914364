import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';


export const useOrganizationStore = defineStore('organization', {
    state: () => ({
        dataOrganizations: null,
        dataOrganization : null
    }),

    actions: {
        async getList(payload = {}) {

            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['organizations'],{...payload})
                .then((res) => {
                    this.dataOrganizations = res;
                    return res;
                });
        },
        async getDetail( id ){
            try {
                await axiosInstance.get(`${urlList['organizations']}/${id}`).then((res)=>{
                    this.dataOrganization = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData ) {
            try {
                return await axiosInstance.post(urlList['organizations'], formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData , id ) {
            try {
                return axiosInstance.put(`${urlList['organizations']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData , id ) {
            try {
                return axiosInstance.put(`${urlList['organizations']}/update/${id}`, statusData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id ) {
            try {
                const response = await axiosInstance.delete(`${urlList['organizations']}/${id}`);

                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        },
    },
    getters: {}
});
