<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Yeni Transfer</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Şirkət<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      v-if="props.companiesList.length > 0"
                      :options="props.companiesList"
                      name="company_id"
                      id="companySelectAdd"
                      v-model="formData.company_id"
                      @update:modelValue="companyChanged"
                      :class="{
                        'is-invalid': $v.formData.company_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.company_id.$error,
                      }"
                    />

                    <template
                      v-if="isSubmitForm && $v.formData.company_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Göndərən Hesab<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :disabled="accountsList.length <= 0"
                      :options="accountsList"
                      name="from_account_id"
                      id="fromAccountSelectAdd"
                      v-model="formData.from_account_id"
                      :class="{
                        'is-invalid': $v.formData.from_account_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.from_account_id.$error,
                      }"
                    />
                    <template
                      v-if="isSubmitForm && $v.formData.from_account_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Qəbul edən hesab<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :disabled="accountsList.length <= 0"
                      :options="accountsList"
                      name="to_account_id"
                      id="toAccountSelectAdd"
                      v-model="formData.to_account_id"
                      :class="{
                        'is-invalid': $v.formData.to_account_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.to_account_id.$error,
                      }"
                    />
                    <template
                      v-if="isSubmitForm && $v.formData.to_account_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Məbləğ <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.amount"
                      class="form-control"
                      type="text"
                      name="amount"
                      :class="{
                        'is-invalid': $v.formData.amount.$error,
                        'is-valid': isSubmitForm && !$v.formData.amount.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.amount.$error">
                      <p
                        v-if="$v.formData.amount.required.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ $v.formData.amount.required.$message }}
                      </p>
                      <p
                        v-else-if="$v.formData.amount.decimal.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ $v.formData.amount.decimal.$message }}
                      </p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tarix <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <flat-pickr
                          :config="dateTime"
                          placeholder="il-ay-gün"
                          v-model="formData.date"
                          :class="{
                            'is-invalid': $v.formData.date.$error,
                            'is-valid': isSubmitForm && !$v.formData.date.$error,
                          }"
                          id="transferDateAdd" class="form-control">
                      </flat-pickr>
                    </div>
                    <template v-if="isSubmitForm && $v.formData.date.$error">
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Qeyd </label>

                    <textarea
                      v-model="formData.note"
                      class="form-control"
                      type="text"
                      name="note"
                      rows="4"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button class="button btn-lights reset-btn" type="reset">
                    Sıfırla
                  </button>
                  <button class="btn btn-primary" type="submit">
                    Yadda saxla
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  decimal,
} from "@vuelidate/validators";
import FlatPickr from 'vue-flatpickr-component';
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useTransfersStore } from "@/stores/modules/transfes";
import { useCompanyAccounts } from "@/stores/modules/companyAccounts";

const transferStore = useTransfersStore();
const accountStore = useCompanyAccounts();

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const dateTime = ref({
    dateFormat: 'Y-m-d',
    allowInput: true,
    position: 'auto right'
});

const props = defineProps({
  companiesList: {
    type: Array,
    default:[]
  }
});

const accountsList = ref([]);

const formData = reactive({
  note: null,
  amount: null,
  company_id: 0,
  date: new Date(),
  to_account_id: 0,
  from_account_id: 0,
});

const rules = {
  formData: {
    company_id: { required },
    from_account_id: { required },
    to_account_id: { required },
    date: { required },
    amount: {
      required: helpers.withMessage("Bu vacib məlumatdır", required),
      decimal: helpers.withMessage(
        "Ancaq rəqəm (onluq ədədləri) qəbul edir.",
        decimal
      ),
    },
  },
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.note = null;
  formData.amount = null;
  formData.from_account_id = 0;
  formData.to_account_id = 0;
  formData.date = new Date();
};

const companyChanged = async () =>{
  await accountStore.getList({
    params:{ 
    company_id: formData.company_id,
    limit: 10000
   }
  });
  accountsList.value = accountStore.dataCompanyAccounts.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}

const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await transferStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("addDataModal");
    });
  } catch (error) {
    console.error(error);
  }
};

</script>

  