<template>
  <div class="col-xl-6">
    <div class="card analytics-card">
      <div
        class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
      >
        <h3>
          Recently Created Contacts
          <a href="javascript:void(0);"><i class="la la-link"></i></a>
        </h3>
        <div class="input-block mb-0">
          <vue-select :options="CrmDays" id="crmdays" placeholder="Last 30 days" />
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-nowrap custom-table mb-0">
            <thead>
              <tr>
                <th>Contact</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in Created_Contact" :key="record.id">
                <td>
                  <h2 class="table-avatar d-flex">
                    <router-link to="/crm/contact-details" class="avatar"
                      ><img
                        :src="require(`@/assets/img/avatar/${record.Image}`)"
                        alt="User Image"
                    /></router-link>
                    <router-link
                      to="/crm/contact-details"
                      class="profile-split d-flex flex-column"
                      >{{ record.Contact }}<span>{{ record.Work }}</span></router-link
                    >
                  </h2>
                </td>
                <td>{{ record.Email }}</td>
                <td>{{ record.Phone }}</td>
                <td>{{ record.Createdat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card analytics-card">
      <div
        class="card-header d-flex justify-content-between card-selectsview flex-wrap row-gap-3 mb-0"
      >
        <h3 class="card-title mb-0">Won Deals Stage</h3>
        <div class="card-select">
          <ul>
            <li>
              <vue-select
                :options="CrmMark"
                id="crmmark"
                placeholder="Marketing Pipeline"
              />
            </li>
            <li>
              <vue-select
                :options="CrmSelday"
                id="crmselday"
                placeholder="Last 30 days"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body pt-0">
        <apexchart
          type="bar"
          height="150"
          :options="analyticsOne.anachartsOne"
          :series="analyticsOne.series"
        ></apexchart>
      </div>
    </div>
    <div class="card analytics-card">
      <div
        class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
      >
        <h3>
          Recently Created Deals
          <a href="javascript:void(0);"><i class="la la-link"></i></a>
        </h3>
        <div class="input-block mb-0">
          <vue-select
            :options="CrmSeldayOne"
            id="crmseldayone"
            placeholder="Last 30 days"
          />
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-nowrap custom-table mb-0">
            <thead>
              <tr>
                <th>Deal Name</th>
                <th>Stage</th>
                <th>Deal Value</th>
                <th>Probability</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in Created_Deals" :key="record.id">
                <td>
                  <router-link to="/crm/deals-details">{{ record.DealName }}</router-link>
                </td>
                <td>{{ record.Stage }}</td>
                <td>{{ record.DealValue }}</td>
                <td>{{ record.Probability }}</td>
                <td>
                  <span :class="record.Class">{{ record.Status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card analytics-card">
      <div
        class="card-header d-flex justify-content-between card-selectsview mb-0 flex-wrap row-gap-3"
      >
        <h3 class="card-title mb-0">Lost Leads Stage</h3>
        <div class="card-select">
          <ul>
            <li>
              <vue-select
                :options="CrmMarkOneO"
                id="crmmarkoneo"
                placeholder="Marketing Pipeline"
              />
            </li>
            <li>
              <vue-select
                :options="CrmSeldayTwo"
                id="crmseldaytwo"
                placeholder="Last 30 days"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body pt-0">
        <apexchart
          type="bar"
          height="150"
          :options="analyticsTwo.anachartsTwo"
          :series="analyticsTwo.series"
        ></apexchart>
      </div>
    </div>
    <div class="card analytics-card">
      <div
        class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
      >
        <h3>Leads By Stage</h3>
        <div class="d-flex flex-wrap row-gap-3">
          <div class="input-block mb-0 me-3">
            <vue-select
              :options="CrmMarkTwo"
              id="crmmarktwo"
              placeholder="Sales Pipeline"
            />
          </div>
          <div class="input-block mb-0">
            <vue-select
              :options="CrmSeldayThree"
              id="crmseldaythree"
              placeholder="Last 30 days"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <apexchart
          type="bar"
          height="270"
          :options="dealsChartOne.chart"
          :series="dealsChartOne.series"
        >
        </apexchart>
      </div>
    </div>
    <div class="card analytics-card">
      <div
        class="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3"
      >
        <h3>
          Recently Added Companies
          <a href="javascript:void(0);"><i class="la la-link"></i></a>
        </h3>
        <div class="title-head d-flex">
          <div class="input-block mb-0">
            <vue-select
              :options="CrmSeldayFour"
              id="crmseldayfour"
              placeholder="Last 30 days"
            />
          </div>
          <a
            href="javascript:void(0);"
            class="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target="#add_company"
            ><i class="la la-plus-circle"></i> Add Company</a
          >
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-nowrap custom-table mb-0">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in Added_Companies" :key="record.id">
                <td>
                  <h2 class="table-avatar d-flex align-items-center">
                    <router-link to="/crm/company-details" class="company-img"
                      ><img
                        :src="require(`@/assets/img/icons/${record.Image}`)"
                        alt="User Image"
                    /></router-link>
                    <router-link to="/crm/company-details" class="profile-split">{{
                      record.CompanyName
                    }}</router-link>
                  </h2>
                </td>
                <td>{{ record.Email }}</td>
                <td>{{ record.Phone }}</td>
                <td>{{ record.Createdat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { analyticsOne, analyticsTwo, dealsChartOne } from "./data";
import Created_Contact from "@/assets/json/created-contact.json";
import Created_Deals from "@/assets/json/created-deals.json";
import Added_Companies from "@/assets/json/added-companies.json";
export default {
  data() {
    return {
      analyticsOne: analyticsOne,
      analyticsTwo: analyticsTwo,
      dealsChartOne: dealsChartOne,
      Created_Contact: Created_Contact,
      Created_Deals: Created_Deals,
      Added_Companies: Added_Companies,
      CrmDays: ["Last 30 days", "Last 3 Months", "Last 6 Months"],
      CrmMark: ["Marketing Pipeline", "Pipeline"],
      CrmMarkOneO: ["Marketing Pipeline", "Pipeline"],
      CrmMarkTwo: ["Sales Pipeline", "Marketing Pipeline"],
      CrmSelday: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldayOne: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldayTwo: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldayThree: ["Last 30 days", "Last 15 days", "Last 7 days"],
      CrmSeldayFour: ["Last 30 days", "Last 15 days", "Last 7 days"],
    };
  },
};
</script>
