<template>
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
        <!-- Page Content -->
        <div class="content container-fluid">

            <!-- Page Header -->
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col-md-4">
                        <h3 class="page-title">Şirkətlər</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/dashboard/">Daşbord</router-link></li>
                            <li class="breadcrumb-item active">Şirkətlər</li>
                        </ul>
                    </div>
                    <div class="col-md-8 float-end ms-auto">
                        <div class="d-flex title-head">
                            <div class="view-icons">
                                <a href="javascript:void(0);" class="grid-view btn btn-link"><i class="las la-redo-alt"></i></a>
                                <a href="javascript:void(0);" class="list-view btn btn-link" @click="toggleFullscreen" id="collapse-header"><i
                                        class="las la-expand-arrows-alt"></i></a>
                                <a href="javascript:void(0);" class="list-view btn btn-link" v-on:click="filter = !filter"
                                    :class="{ setclose: filter }" id="filter_search"><i class="las la-filter"></i></a>
                            </div>
                            <!-- <div class="form-sort">
                                <a href="javascript:void(0);" class="list-view btn btn-link" data-bs-toggle="modal"
                                    data-bs-target="#export"><i class="las la-file-export"></i>Export</a>
                            </div> -->
                            <a href="javascript:void(0);" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_company"><i
                                    class="la la-plus-circle"></i> Add Company</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Page Header -->

            <!-- Search Filter -->
            <div class="filter-filelds" :style="{ display: filter ? 'block' : 'none' }" id="filter_inputs">
                <div class="row filter-row">
                    <div class="col-xl-2">
                        <div class="input-block mb-3 form-focus" :class="{ focused: contactIdFocused }">
                            <input type="text" v-model="contactId" @focus="contactIdFocused = true"
                                @blur="contactIdFocused = contactId !== ''" class="form-control floating">
                            <label class="focus-label">Contact Name</label>
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-block mb-3 form-focus" :class="{ focused: emailIdFocused }">
                            <input type="text" v-model="emailId" @focus="emailIdFocused = true"
                                @blur="emailIdFocused = emailId !== ''" class="form-control floating">
                            <label class="focus-label">Email</label>
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-block mb-3 form-focus" :class="{ focused: phoneNumberFocused }">
                            <input type="text" v-model="phoneNumber" @focus="phoneNumberFocused = true"
                                @blur="phoneNumberFocused = phoneNumber !== ''" class="form-control floating">
                            <label class="focus-label">Phone Number</label>
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-block mb-3 form-focus focused">
                            <input type="text" class="form-control  date-range bookingrange" ref="dateRangeInput">
                            <label class="focus-label">From - To Date</label>
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-block mb-3 form-focus select-focus">
                            <vue-select :options="Country" id="india" placeholder="--Select--" />
                            <label class="focus-label">Location</label>
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
                    </div>
                </div>
            </div>
            <hr>
            <!-- /Search Filter -->
			<div class="filter-section mb-4">
				<ul>
					<li>
						<div class="view-icons">
                            <router-link to="/crm/companies-list" class="list-view btn btn-link active"><i class="las la-list"></i></router-link>
                            <router-link to="/crm/companies-grid" class="grid-view btn btn-link"><i class="las la-th"></i></router-link>
                        </div>
					</li>
					<li>
						<div class="form-sort">
							<i class="las la-sort-alpha-up-alt"></i>
							<vue-select :options="Alphabet" id="alphabetcompany" placeholder="Sort By Alphabet" />
						</div>
					</li>
					<li>
						<div class="form-sorts dropdown" ref="formSorts">
							<a href="javascript:void(0);" @click="toggleFilter" class="dropdown-toggle" id="table-filter"><i
									class="las la-filter me-2"></i>Filter</a>
							<div class="filter-dropdown-menu">
								<div class="filter-set-view">
									<div class="filter-set-head">
										<h4>Filter</h4>
									</div>
									<div class="accordion" id="accordionExample">
										<div class="filter-set-content">
											<div class="filter-set-content-head">
												<a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseOne"
													aria-expanded="true" aria-controls="collapseOne">Rating<i
														class="las la-angle-right"></i></a>
											</div>
											<div class="filter-set-contents accordion-collapse collapse show"
												id="collapseOne" data-bs-parent="#accordionExample">
												<ul>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox" checked>
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="rating">
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<span>5.0</span>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="rating">
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star"></i>
															<span>4.0</span>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="rating">
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star"></i>
															<i class="fa fa-star"></i>
															<span>3.0</span>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="rating">
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star"></i>
															<i class="fa fa-star"></i>
															<i class="fa fa-star"></i>
															<span>2.0</span>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="rating">
															<i class="fa fa-star filled"></i>
															<i class="fa fa-star"></i>
															<i class="fa fa-star"></i>
															<i class="fa fa-star"></i>
															<i class="fa fa-star"></i>
															<span>1.0</span>
														</div>
													</li>
												</ul>
											</div>
										</div>
										<div class="filter-set-content">
											<div class="filter-set-content-head">
												<a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
													aria-expanded="false" aria-controls="collapseTwo">Owner<i
														class="las la-angle-right"></i></a>
											</div>
											<div class="filter-set-contents accordion-collapse collapse" id="collapseTwo"
												data-bs-parent="#accordionExample">
												<ul>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox" checked>
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Hendry</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Guillory</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Jami</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Theresa</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Espinosa</h5>
														</div>
													</li>
												</ul>
											</div>
										</div>
										<div class="filter-set-content">
											<div class="filter-set-content-head">
												<a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseThree"
													aria-expanded="false" aria-controls="collapseThree">Tags<i
														class="las la-angle-right"></i></a>
											</div>
											<div class="filter-set-contents accordion-collapse collapse" id="collapseThree"
												data-bs-parent="#accordionExample">
												<ul>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox" checked>
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Promotion</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Rated</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Rejected</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Collab</h5>
														</div>
													</li>
													<li>
														<div class="filter-checks">
															<label class="checkboxs">
																<input type="checkbox">
																<span class="checkmarks"></span>
															</label>
														</div>
														<div class="collapse-inside-text">
															<h5>Calls</h5>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>

									<div class="filter-reset-btns">
										<a href="javascript:void(0);" class="btn btn-light">Reset</a>
										<a href="javascript:void(0);" class="btn btn-primary">Filter</a>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="search-set">
							<div class="search-input">
								<a href="javascript:void(0);" class="btn btn-searchset"><i class="las la-search"></i></a>
								<div class="dataTables_filter">
									<label> <input type="search" class="form-control form-control-sm"
											placeholder="Search"></label>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive contact-table-list">
                        <companies-table></companies-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Page Content -->

	<companies-list-modal></companies-list-modal>
	<add-company-list-modal></add-company-list-modal>
	<edit-company-list-modal></edit-company-list-modal>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
    data() {
        return {
            Alphabet: ["Sort By Alphabet", "Ascending", "Descending", "Recently Viewed", "Recently Added"],
            Country: ["--Select--", "Germany", "USA", "Canada", "India", "China"],
            filter: false,
            contactId: "",
            emailId: "",
            phoneNumber: "",
            contactIdFocused: false,
            emailIdFocused: false,
            phoneNumberFocused: false,
        }
    },
    methods: {
        toggleFullscreen() {
            const elem = document.documentElement;
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement
            ) {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        },
        toggleFilter(event) {
            const formSorts = this.$refs.formSorts;

            if (formSorts.classList.contains("table-filter-show")) {
                formSorts.classList.remove("table-filter-show");
            } else {
                formSorts.classList.add("table-filter-show");
            }

            event.stopPropagation();
        },

        handleDocumentClick(event) {
            const formSorts = this.$refs.formSorts;

            if (formSorts && !event.target.classList.contains("form-sorts")) {
                formSorts.classList.remove("table-filter-show");
            }
        }

    },
    mounted() {
        document.addEventListener("click", this.handleDocumentClick);
    },

    beforeUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    },
    setup() {
        const dateRangeInput = ref(null);
        onMounted(() => {
            if (dateRangeInput.value) {
                const start = moment().subtract(6, "days");
                const end = moment();

                function booking_range(start, end) {
                    start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
                }

                new DateRangePicker(
                    dateRangeInput.value,
                    {
                        startDate: start,
                        endDate: end,
                        ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "Last 30 Days": [moment().subtract(29, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment().subtract(1, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                            ],
                        },
                    },
                    booking_range
                );

                booking_range(start, end);
            }
        });
        return {
            dateRangeInput,
        };
    },
}
</script>