<template>
  <!-- Add Note -->
  <div class="modal custom-modal fade modal-padding" id="add_notes" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-header header-border align-items-center justify-content-between p-0"
        >
          <h5 class="modal-title">Add Note</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="javascript:void(0);">
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Title <span class="text-danger"> *</span></label
              >
              <input class="form-control" type="text" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Note <span class="text-danger"> *</span></label
              >
              <textarea class="form-control" rows="4" placeholder="Add text"></textarea>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Attachment <span class="text-danger"> *</span></label
              >
              <div class="drag-upload">
                <input type="file" />
                <div class="img-upload">
                  <i class="las la-file-import"></i>
                  <p>Drag & Drop your files</p>
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label">Uploaded Files</label>
              <div class="upload-file">
                <h6>Projectneonals teyys.xls</h6>
                <p>4.25 MB</p>
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 25%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p>45%</p>
              </div>
              <div class="upload-file upload-list">
                <div>
                  <h6>Projectneonals teyys.xls</h6>
                  <p>4.25 MB</p>
                </div>
                <a href="javascript:void(0);" class="text-danger"
                  ><i class="las la-trash"></i
                ></a>
              </div>
            </div>
            <div class="col-lg-12 text-end form-wizard-button">
              <button class="button btn-lights reset-btn" type="reset">Reset</button>
              <button class="btn btn-primary" type="submit">Save Notes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Note -->

  <!-- Create Call Log -->
  <div class="modal custom-modal fade modal-padding" id="create_call" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-header header-border align-items-center justify-content-between p-0"
        >
          <h5 class="modal-title">Create Call Log</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="javascript:void(0);">
            <div class="row">
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Status <span class="text-danger"> *</span></label
                  >
                  <vue-select :options="Busy" id="busy" placeholder="Busy" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Follow-up Date <span class="text-danger"> *</span></label
                  >
                  <input class="form-control datetimepicker" type="text" />
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Note <span class="text-danger"> *</span></label
              >
              <textarea class="form-control" rows="4" placeholder="Add text"></textarea>
            </div>
            <div class="input-block mb-3">
              <label class="custom_check check-box mb-0">
                <input type="checkbox" />
                <span class="checkmark"></span> Create a Follow up task
              </label>
            </div>
            <div class="col-lg-12 text-end form-wizard-button">
              <button class="button btn-lights reset-btn" type="reset">Reset</button>
              <button class="btn btn-primary" type="submit">Save Call</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Call Log -->

  <!-- Connect Account -->
  <div class="modal custom-modal fade modal-padding" id="create_email" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-header header-border align-items-center justify-content-between p-0"
        >
          <h5 class="modal-title">Connect Account</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="input-block mb-3">
            <label class="col-form-label"
              >Account type <span class="text-danger"> *</span></label
            >
            <vue-select :options="Outlook" id="outlook" placeholder="Gmail" />
          </div>
          <div class="input-block mb-3">
            <h5 class="mb-3">Sync emails from</h5>
            <div class="sync-radio">
              <div class="radio-item">
                <input
                  type="radio"
                  class="status-radio"
                  id="test1"
                  name="radio-group"
                  checked=""
                />
                <label for="test1">Now</label>
              </div>
              <div class="radio-item">
                <input type="radio" class="status-radio" id="test2" name="radio-group" />
                <label for="test2">1 Month Ago</label>
              </div>
              <div class="radio-item">
                <input type="radio" class="status-radio" id="test3" name="radio-group" />
                <label for="test3">3 Month Ago</label>
              </div>
              <div class="radio-item">
                <input type="radio" class="status-radio" id="test4" name="radio-group" />
                <label for="test4">6 Month Ago</label>
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-end form-wizard-button">
            <button
              class="button btn-lights reset-btn"
              data-bs-dismiss="modal"
              type="reset"
            >
              Reset
            </button>
            <button
              class="btn btn-primary wizard-next-btn"
              data-bs-target="#success_mail"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              type="button"
            >
              Connect Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Call Log -->

  <!-- Success Contact -->
  <div class="modal custom-modal fade" id="success_mail" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="la la-envelope-open"></i>
            </div>
            <h3>Email Connected Successfully!!!</h3>
            <p>
              Email Account is configured with “example@example.com”. Now you can access
              email.
            </p>
            <div class="col-lg-12 text-center form-wizard-button">
              <router-link to="/crm/company-details" class="btn btn-primary"
                >Go to email</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Success Contact -->

  <!-- Add Company -->
  <add-contact-list-modal></add-contact-list-modal>
  <!-- /Add Company -->

  <!-- Add File -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="new_file"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Create New File</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="add-info-fieldset">
            <div class="add-details-wizard">
              <ul class="progress-bar-wizard">
                <li class="active">
                  <span><i class="la la-file"></i></span>
                  <div class="multi-step-info">
                    <h6>Basic Info</h6>
                  </div>
                </li>
                <li>
                  <span><i class="la la-plus-circle"></i></span>
                  <div class="multi-step-info">
                    <h6>Add Recipient</h6>
                  </div>
                </li>
              </ul>
            </div>
            <fieldset id="first-field-file">
              <form action="javascript:void(0);">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Choose Deal <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Walter" id="walter" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Document Type <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Quote" id="quote" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Owner <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Daniel" id="daniel" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Title <span class="text-danger"> *</span></label
                        >
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Locale <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Locale" id="locale" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signature-wrap">
                        <h4>Signature</h4>
                        <ul class="nav sign-item">
                          <li class="nav-item">
                            <span
                              class="mb-0"
                              data-bs-toggle="tab"
                              data-bs-target="#nosign"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="sign1"
                                name="email"
                              />
                              <label for="sign1"
                                ><span class="sign-title">No Signature</span>This document
                                does not require a signature before acceptance.</label
                              >
                            </span>
                          </li>
                          <li class="nav-item">
                            <span
                              class="active mb-0"
                              data-bs-toggle="tab"
                              data-bs-target="#use-esign"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="sign2"
                                name="email"
                                checked
                              />
                              <label for="sign2"
                                ><span class="sign-title">Use e-signature</span>This
                                document require e-signature before acceptance.</label
                              >
                            </span>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div class="tab-pane fade" id="nosign"></div>
                          <div class="tab-pane show active" id="use-esign">
                            <div class="input-block mb-0">
                              <label class="col-form-label"
                                >Document Signers
                                <span class="text-danger">*</span></label
                              >
                            </div>
                            <div class="sign-content">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="input-block mb-3">
                                    <input
                                      class="form-control"
                                      type="text"
                                      placeholder="Enter Name"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="d-flex align-items-center">
                                    <div class="input-block float-none mb-3 me-3">
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Email Address"
                                      />
                                    </div>
                                    <div class="input-btn mb-3">
                                      <a href="javascript:void(0);" class="add-sign"
                                        ><i class="las la-plus-circle"></i
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Content <span class="text-danger"> *</span></label
                        >
                        <textarea
                          class="form-control"
                          rows="3"
                          placeholder="Add Content"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button class="btn btn-primary wizard-next-btn" type="button">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset>
              <form action="javascript:void(0);">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="signature-wrap">
                        <h4 class="mb-2">Send the document to following signers</h4>
                        <p>In order to send the document to the signers</p>
                        <div class="input-block mb-0">
                          <label class="col-form-label"
                            >Recipients (Additional recipients)</label
                          >
                        </div>
                        <div class="sign-content">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="input-block mb-3">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Enter Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="d-flex align-items-center">
                                <div class="input-block float-none mb-3 me-3">
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Email Address"
                                  />
                                </div>
                                <div class="input-btn mb-3">
                                  <a href="javascript:void(0);" class="add-sign"
                                    ><i class="las la-plus-circle"></i
                                  ></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Message Subject <span class="text-danger"> *</span></label
                        >
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter Subject"
                        />
                      </div>
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Message Text <span class="text-danger"> *</span></label
                        >
                        <textarea
                          class="form-control"
                          rows="3"
                          placeholder="Your document is ready"
                        ></textarea>
                      </div>
                      <button class="btn btn-lighter mb-3">Send Now</button>
                      <div class="send-success">
                        <p>
                          <i class="las la-check-circle"></i> Document Sent successfully
                          to the Selected Recipients
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button">
                      <button class="button btn-lights reset-btn" type="reset">
                        Reset
                      </button>
                      <button
                        class="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add File -->

  <!-- Add Deals -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_deals"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Deals</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="javascript:void(0);">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Deal Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6 pipeline-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Pipeline <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-pipeline-btn"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select :options="Pipeline" id="linepipes" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Status <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="Status"
                      id="statusdetail"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Deal Value<span class="text-danger"> *</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Currency <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="Currency"
                      id="dangercurrency"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Period <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Period Value <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Contact <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox5"
                      :tags="tags1"
                      value="James, Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Project <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox6"
                      :tags="tags2"
                      value="Divine dran"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Expected Closing Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Assignee <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox3"
                      :tags="tags3"
                      value="James"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tags <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox4"
                      :tags="tags4"
                      value="Label, Label"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Follow-up date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Source <span class="text-danger">*</span></label
                    >
                    <vue-select :options="Cuda" id="cudaselect" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Priority <span class="text-danger">*</span></label
                    >
                    <vue-select :options="Low" id="lowdetails" placeholder="Select" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Deal</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Deals -->

  <!-- Add Compose -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_compose"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add Compose</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="javascript:void(0);">
            <div class="input-block mb-3">
              <input type="email" placeholder="To" class="form-control" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <input type="email" placeholder="Cc" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <input type="email" placeholder="Bcc" class="form-control" />
                </div>
              </div>
            </div>
            <div class="input-block mb-3">
              <input type="text" placeholder="Subject" class="form-control" />
            </div>
            <div class="input-block mb-3">
              <editor v-model="content" />
            </div>
            <div class="input-block mb-3 mb-0">
              <div class="text-center">
                <button class="btn btn-primary me-2">
                  <span>Send</span> <i class="fa-solid fa-paper-plane m-l-5"></i>
                </button>
                <button class="btn btn-success m-l-5 me-2" type="button">
                  <span>Draft</span> <i class="fa-regular fa-floppy-disk m-l-5"></i>
                </button>
                <button class="btn btn-success m-l-5 me-2" type="button">
                  <span>Delete</span> <i class="fa-regular fa-trash-can m-l-5"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Compose -->

  <!-- Edit Company -->
  <edit-company-list-modal></edit-company-list-modal>
  <!-- /Edit Company -->

  <!-- Delete Contact -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Company ”NovaWaveLLC” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/crm/company-details" class="btn btn-primary"
                >Okay</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Contact -->

  <!-- Success Company -->
  <div class="modal custom-modal fade" id="success_msg" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="success-message text-center">
                        <div class="success-popup-icon">
                            <i class="la la-building"></i>
                        </div>
                        <h3>Company Created Successfully!!!</h3>
                        <p>View the details of Company</p>
                        <div class="col-lg-12 text-center form-wizard-button">
                            <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Close</a>
                            <router-link to="/crm/company-details" class="btn btn-primary">View Details</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Success Company -->
  
  <edit-contact-list-modal></edit-contact-list-modal>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import Vue3TagsInput from "vue3-tags-input";
export default {
  components: {
    editor: Editor,
    Vue3TagsInput,
  },
  data() {
    return {
      content: "Description",
      Busy: [
        "Busy",
        "Unavailable",
        "No Answer",
        "Wrong Number",
        "Left Voice Message",
        "Moving Forward",
      ],
      Outlook: ["Gmail", "Outlook", "Imap"],
      Company: ["SilverHawk", "NovaWaveLLC"],
      Bluesky: ["Select", "NovaWaveLLC", "BlueSky Industries", "SilverHawk"],
      Collins: ["Select", "Collins", "Konopelski", "Adams"],
      Review: ["Select", "Lowest", "Highest"],
      Guillory: ["Select", "Hendry", "Guillory", "Jami"],
      Cuda: ["Select", "Barry Cuda", "Tressa Wexler"],
      Currency: ["Select", "$", "€"],
      Country: ["Germany", "USA"],
      Lang: ["Select", "English", "French"],
      Barry: ["Select", "Barry Cuda", "Tressa Wexler"],
      Countrys: ["Select", "Germany", "USA"],
      Walter: ["Select", "Collins", "Wisozk", "Walter"],
      Quote: ["Select", "Contract", "Proposal", "Quote"],
      Daniel: ["Select", "Admin", "Jackson Daniel"],
      Locale: ["Select", "en", "es", "ru"],
      Pipeline: ["Select", "Sales", "Marketing", "Calls"],
      Status: ["Select", "Open", "Lost", "Won"],
      Low: ["Select", "Highy", "Low", "Medium"],
      Jami: ["Hendry", "Guillory", "Jami"],
      Contact: ["Email", "Call", "Skype"],
      tags5: ["Label", "Label"],
      tags4: ["Label", "Label"],
      tags3: ["James"],
      tags2: ["Divine dran"],
      tags1: ["James, Darlee Robertson"],
      tags7: ["Label", "Label"],
    };
  },
};
</script>
