<template>
    <settings-sidebar></settings-sidebar>
    <layout-header></layout-header>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <!-- Page Header -->
            <single-breadcrumb :title="title" />
            <!-- /Page Header -->

            <form>
              <div class="input-block mb-3 row">
                <label class="col-lg-3 col-form-label">Website Name</label>
                <div class="col-lg-9">
                  <input
                    name="website_name"
                    class="form-control"
                    value="Dreams Technologies"
                    type="text"
                  />
                </div>
              </div>
              <div class="input-block mb-3 row">
                <label class="col-lg-3 col-form-label">Light Logo</label>
                <div class="col-lg-7">
                  <input type="file" class="form-control" />
                  <span class="form-text text-muted"
                    >Recommended image size is 40px x 40px</span
                  >
                </div>
                <div class="col-lg-2">
                  <div class="img-thumbnail float-end">
                    <img src="@/assets/img/logo2.png" alt="Logo" width="40" height="40" />
                  </div>
                </div>
              </div>
              <div class="input-block mb-3 row">
                <label class="col-lg-3 col-form-label">Favicon</label>
                <div class="col-lg-7">
                  <input type="file" class="form-control" />
                  <span class="form-text text-muted"
                    >Recommended image size is 16px x 16px</span
                  >
                </div>
                <div class="col-lg-2">
                  <div class="settings-image img-thumbnail float-end">
                    <img
                      src="@/assets/img/logo2.png"
                      class="img-fluid"
                      width="16"
                      height="16"
                      alt="Logo"
                    />
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn">Yadda saxla</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Theme Settings",
    };
  },
};
</script>
