<template>
  <div class="row">
    <div class="col-md-12">
      <div class="kanban-wrapper">
        <div class="kanban-list-items" v-for="(module, color) in modules" :key="color">
          <div class="kanban-list-head">
            <div class="d-flex justify-content-between align-items-center">
              <div :class="module.DotClass">
                <h5>{{ module.Title }}</h5>
                <span>{{ module.Count }}</span>
              </div>
              <div class="kanban-action-btns d-flex align-items-center">
                <a href="javascript:void(0);" class="plus-btn"><i class="la la-plus"></i></a>
                <div class="dropdown dropdown-action">
                  <a
                    href="javascript:void(0);"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="material-icons">more_vert</i></a
                  >
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_deals"
                      ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_deals"
                      ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="kanban-drag-wrap">
            <draggable
              :list="module.Cards"
              :group="{ name: 'kanban', pull: true, put: true }"
              @end="onDragEnd(color)"
            >
              <li v-for="Card in module.Cards" :key="Card.id">
                <div class="kanban-card">
                  <div class="kanban-card-head">
                    <span :class="Card.CardClass"></span>
                    <div class="kanban-card-title">
                      <span>{{ Card.Logo }}</span>
                      <h6>{{ Card.CompanyName }}</h6>
                    </div>
                  </div>
                  <div class="kanban-card-body">
                    <ul>
                      <li>
                        <i class="la la-dollar-sign"></i>
                        {{ Card.Amount }}
                      </li>
                      <li>
                        <i class="la la-envelope"></i>
                        {{ Card.Email }}
                      </li>
                      <li>
                        <i class="la la-phone"></i>
                        {{ Card.MobileNo }}
                      </li>
                      <li>
                        <i class="la la-map-marker-alt"></i>
                        {{ Card.Location }}
                      </li>
                    </ul>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="card-user-info">
                        <router-link to="/crm/contact-details" class="avatar"
                          ><img
                            :src="require(`@/assets/img/avatar/${Card.Image}`)"
                            alt="Img"
                        /></router-link>
                        <router-link to="/crm/contact-details" class="user-name">{{
                          Card.Name
                        }}</router-link>
                      </div>
                      <span class="badge badge-soft-info">{{ Card.Percentage }}</span>
                    </div>
                  </div>
                  <div
                    class="kanban-card-footer d-flex align-items-center justify-content-between"
                  >
                    <span><i class="la la-calendar"></i> {{ Card.Date }}</span>
                    <ul class="icons-social">
                      <li>
                        <a href="javascript:void(0);"><i class="la la-phone-volume"></i></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);"><i class="lab la-facebook-messenger"></i></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);"><i class="la la-swatchbook"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </draggable>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
export default {
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      modules: {
        Qualify: {
          DotClass: "kanban-title-head dot-success",
          Title: "Not Contacted",
          Count: "45 Leads - $15,44,540",
          Cards: [
            {
              CardClass: "bar-design bg-purple",
              Logo: "HT",
              CompanyName: "Howell, Tremblay and Rath",
              Amount: "$03,50,000",
              Email: "darleeo@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Newyork, United States",
              Image: "avatar-19.jpg",
              Name: "Darlee Robertson",
              Percentage: "85%",
              Date: "10 Jan 2024",
            },
            {
              CardClass: "bar-design bg-warning",
              Logo: "RJ",
              CompanyName: "Robert, John and Carlos",
              Amount: "$02,10,000",
              Email: "sheron@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Exeter, United States",
              Image: "avatar-20.jpg",
              Name: "Darlee Robertson",
              Percentage: "15%",
              Date: "12 Jan 2024",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "WS",
              CompanyName: "Wendy, Star and David",
              Amount: "$04,22,000",
              Email: "vau@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Phoenix, United States",
              Image: "avatar-21.jpg",
              Name: "Darlee Robertson",
              Percentage: "95%",
              Date: "14 Jan 2024",
            },
          ],
        },
        Contact: {
          DotClass: "kanban-title-head dot-info",
          Title: "Contact Made",
          Count: "30 Leads - $19,94,938",
          Cards: [
            {
              CardClass: "bar-design bg-success",
              Logo: "BR",
              CompanyName: "Byron, Roman and Bailey",
              Amount: "$02,45,000",
              Email: "jessica13@example.com",
              MobileNo: "+1 89351-90346",
              Location: "Chester, United States",
              Image: "avatar-1.jpg",
              Name: "Jessica",
              Percentage: "47%",
              Date: "06 Feb 2024",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "CH",
              CompanyName: "Robert, John and Carlos",
              Amount: "$01,17,000",
              Email: "caroltho3@example.com",
              MobileNo: "+1 78982-09163",
              Location: "Charlotte, United States",
              Image: "avatar-16.jpg",
              Name: "Carol Thomas",
              Percentage: "98%",
              Date: "15 Feb 2024",
            },
            {
              CardClass: "bar-design bg-danger",
              Logo: "IC",
              CompanyName: "Irene, Charles and Wilston",
              Amount: "$02,12,000",
              Email: "dawnmercha@example.com",
              MobileNo: "+1 27691-89246",
              Location: "Bristol, United States",
              Image: "avatar-22.jpg",
              Name: "Dawn Mercha",
              Percentage: "78%",
              Date: "25 Jan 2024",
            },
          ],
        },
        Presentation: {
          DotClass: "kanban-title-head dot-warning",
          Title: "Presentation",
          Count: "25 Leads - $10,36.390",
          Cards: [
            {
              CardClass: "bar-design bg-success",
              Logo: "HT",
              CompanyName: "Jody, Powell and Cecil",
              Amount: "$01,84,043",
              Email: "rachel@example.com",
              MobileNo: "+1 17839-93617",
              Location: "Baltimore, United States",
              Image: "avatar-23.jpg",
              Name: "Rachel Hampton",
              Percentage: "25%",
              Date: "18 Mar 2024",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "BL",
              CompanyName: "Bonnie, Linda and Mullin",
              Amount: "$09,35,189",
              Email: "jonelle@example.com",
              MobileNo: "+1 16739-47193",
              Location: "Coventry, United States",
              Image: "avatar-24.jpg",
              Name: "Jonelle Curtiss",
              Percentage: "70%",
              Date: "15 Feb 2024",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "CJ",
              CompanyName: "Carlos, Jones and Jim",
              Amount: "$04,27,940",
              Email: "jonathan@example.com",
              MobileNo: "+1 18390-37153",
              Location: "Seattle",
              Image: "avatar-25.jpg",
              Name: "Jonathan",
              Percentage: "45%",
              Date: "30 Jan 2024",
            },
          ],
        },
        Proposal: {
          DotClass: "kanban-title-head dot-purple",
          Title: "Proposal Made",
          Count: "50 Leads - $18,83,013",
          Cards: [
            {
              CardClass: "bar-design bg-danger",
              Logo: "FJ",
              CompanyName: "Freda,Jennfier and Thompson",
              Amount: "$04,17,593",
              Email: "sidney@example.com",
              MobileNo: "+1 11739-38135",
              Location: "London, United States",
              Image: "avatar-17.jpg",
              Name: "Sidney Franks",
              Percentage: "59%",
              Date: "11 Apr 2024",
            },
            {
              CardClass: "bar-design bg-danger",
              Logo: "BF",
              CompanyName: "Bruce, Faulkner and Lela",
              Amount: "$08,81,389",
              Email: "brook@example.com",
              MobileNo: "+1 19302-91043",
              Location: "Detroit, United States",
              Image: "avatar-26.jpg",
              Name: "Brook",
              Percentage: "72%",
              Date: "17 Apr 2024",
            },
            {
              CardClass: "bar-design bg-danger",
              Logo: "LP",
              CompanyName: "Lawrence, Patrick and Vandorn",
              Amount: "$09,27,193",
              Email: "mickey@example.com",
              MobileNo: "+1 17280-92016",
              Location: "Manchester, United States",
              Image: "avatar-15.jpg",
              Name: "Mickey",
              Percentage: "20%",
              Date: "10 Feb 2024",
            },
          ],
        },
      },
    };
  },
  methods: {
    onDragEnd() {
    },
    onModuleDragEnd() {
    },
  },
};
</script>
