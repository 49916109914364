<template>
  <!-- Add Leads -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_leads"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Lead</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/leads">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Lead Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <h5 class="mb-3">Lead Type</h5>
                    <div class="status-radio-btns d-flex">
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test1"
                          name="radio-group"
                          checked
                        />
                        <label for="test1">Person</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test2"
                          name="radio-group"
                        />
                        <label for="test2">Organization</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 company-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Company <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-company"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select :options="LeadWa" id="leadwa" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Value<span class="text-danger"> *</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Currency <span class="text-danger">*</span></label
                    >
                    <vue-select :options="LeadDa" id="leadda" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-12 lead-phno-col del-phno-col">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone Number <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-lg-4 d-flex align-items-end">
                      <div class="input-block mb-3 d-flex align-items-center">
                        <div class="w-100">
                          <vue-select
                            :options="LeadWork"
                            id="leadwork"
                            placeholder="Work"
                          />
                        </div>
                        <a href="javascript:void(0);" class="add-modal-row add-lead-phno"
                          ><i class="la la-plus-circle"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 lead-email-col del-email-col">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Email <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="email" />
                      </div>
                    </div>
                    <div class="col-lg-4 d-flex align-items-end">
                      <div class="input-block mb-3 d-flex align-items-center">
                        <div class="w-100">
                          <vue-select
                            :options="LeadWorkOne"
                            id="leadworkone"
                            placeholder="Work"
                          />
                        </div>
                        <a href="javascript:void(0);" class="add-modal-row add-lead-email"
                          ><i class="la la-plus-circle"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Source <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Industry <span class="text-danger">*</span></label
                    >
                    <vue-select :options="LeadHigh" id="leadhigh" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Owner <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox3"
                      :tags="tags3"
                      value="James"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tags <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox4"
                      :tags="tags4"
                      value="Label, Label"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <h5 class="mb-3">Visibility</h5>
                    <div class="status-radio-btns d-flex">
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test3"
                          name="radio-group2"
                          checked
                        />
                        <label for="test3">Pubilc</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test4"
                          name="radio-group2"
                        />
                        <label for="test4">Private</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test5"
                          name="radio-group2"
                        />
                        <label for="test5">Select People</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <h5 class="mb-3">Status</h5>
                    <div class="status-radio-btns d-flex">
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test6"
                          name="radio-group3"
                          checked
                        />
                        <label for="test6">Active</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test7"
                          name="radio-group3"
                        />
                        <label for="test7">Inactive</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Lead</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Leads -->

  <!-- Edit Leads -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="edit_leads"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Edit Lead</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/leads">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Lead Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="Adams" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <h5 class="mb-3">Lead Type</h5>
                    <div class="status-radio-btns d-flex">
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test8"
                          name="radio-group"
                          checked
                        />
                        <label for="test8">Person</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="test9"
                          name="radio-group"
                        />
                        <label for="test9">Organization</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 company-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Company <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-company"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select
                      :options="LeadSil"
                      id="leadsil"
                      placeholder="NovWavellv"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Value<span class="text-danger"> *</span></label
                    >
                    <input class="form-control" type="text" value="10" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Currency <span class="text-danger">*</span></label
                    >
                    <vue-select :options="LeadRs" id="leadrs" placeholder="$" />
                  </div>
                </div>
                <div class="col-md-12 lead-phno-col del-phno-col">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Phone Number <span class="text-danger">*</span></label
                        >
                        <input class="form-control" type="text" value="+1 546555455" />
                      </div>
                    </div>
                    <div class="col-lg-4 d-flex align-items-end">
                      <div class="input-block mb-3 d-flex align-items-center">
                        <div class="w-100">
                          <vue-select
                            :options="LeadHome"
                            id="leadhome"
                            placeholder="Work"
                          />
                        </div>
                        <a href="javascript:void(0);" class="add-modal-row add-lead-phno"
                          ><i class="la la-plus-circle"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 lead-email-col del-email-col">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Email <span class="text-danger">*</span></label
                        >
                        <input
                          class="form-control"
                          type="email"
                          value="Vaughan12@example.com"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 d-flex align-items-end">
                      <div class="input-block mb-3 d-flex align-items-center">
                        <div class="w-100">
                          <vue-select
                            :options="LeadHomeOne"
                            id="leadhomeone"
                            placeholder="Work"
                          />
                        </div>
                        <a href="javascript:void(0);" class="add-modal-row add-lead-email"
                          ><i class="la la-plus-circle"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Source <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="Lost" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Industry <span class="text-danger">*</span></label
                    >
                    <vue-select :options="LeadHighy" id="leadhighy" placeholder="Highy" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Owner <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox1"
                      :tags="tags1"
                      value="James"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tags <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox2"
                      :tags="tags2"
                      value="Label, Label"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <h5 class="mb-3">Visibility</h5>
                    <div class="status-radio-btns d-flex">
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="tests3"
                          name="radio-group2"
                          checked
                        />
                        <label for="tests3">Pubilc</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="tests4"
                          name="radio-group2"
                        />
                        <label for="tests4">Private</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="tests5"
                          name="radio-group2"
                        />
                        <label for="tests5">Select People</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <h5 class="mb-3">Status</h5>
                    <div class="status-radio-btns d-flex">
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="tests6"
                          name="radio-group3"
                          checked
                        />
                        <label for="tests6">Active</label>
                      </div>
                      <div class="people-status-radio">
                        <input
                          type="radio"
                          class="status-radio"
                          id="tests7"
                          name="radio-group3"
                        />
                        <label for="tests7">Inactive</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Lead</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Leads -->

  <!-- Delete Leads -->
  <div class="modal custom-modal fade" id="delete_leads" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Lead ”Collins” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/crm/leads" class="btn btn-primary">Okay</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Leads -->

  <!-- Export -->
  <div class="modal custom-modal fade modal-padding" id="export" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Export</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/leads">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <h5 class="mb-3">Export</h5>
                  <div class="status-radio-btns d-flex">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="pdf"
                        name="export-type"
                        checked
                      />
                      <label for="pdf">Person</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="excel"
                        name="export-type"
                      />
                      <label for="excel">Organization</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h4 class="mb-3">Filters</h4>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Fields <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="LeadCompany"
                    id="leadcompany"
                    placeholder="All Fields"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdateOne"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Export Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Export -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
export default {
  data() {
    return {
      LeadCompany: ["All Fields", "Contact", "Company"],
      LeadHighy: ["Highy", "Low", "Medium"],
      LeadHome: ["Work", "Home"],
      LeadHomeOne: ["Work", "Home"],
      LeadRs: ["$", "€"],
      LeadSil: ["NovaWaveLLC", "SilverHawk", "SummitPeak"],
      LeadHigh: ["Select", "Highy", "Low", "Medium"],
      LeadWork: ["Work", "Home"],
      LeadWorkOne: ["Work", "Home"],
      LeadDa: ["Select", "$", "€"],
      LeadWa: ["Select", "NovaWaveLLC", "SilverHawk", "SummitPeak"],
      tags2: ["Label", "Label"],
      tags3: ["James"],
      tags4: ["Label", "Label"],
      tags1: ["James"],
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      startdateOne: currentDateOne,
    };
  },
  components: {
    Vue3TagsInput,
  },
};
</script>
