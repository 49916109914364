<template>
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row align-items-center">
            <div class="col-md-4">
              <h3 class="page-title">Transferlər</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard/admin-dashboard"
                    >Daşbord</router-link
                  >
                </li>
                <li class="breadcrumb-item active">
                  Transferlər
                </li>
              </ul>
            </div>
            <div class="col-md-8 float-end ms-auto">
              <div class="d-flex title-head">
                <div class="view-icons">
                  <a
                    href="javascript:void(0);"
                    class="grid-view btn btn-link"
                    v-tippy
                    title="Filtri sıfırla"  
                    @click="resetSearchFilter"
                    ><i class="las la-redo-alt"></i
                  ></a>
                  <a
                    href="javascript:void(0);"
                    class="list-view btn btn-link"
                    id="collapse-header"
                    v-tippy
                    title="Tam ekran"
                    @click="toggleFullscreen"
                    ><i class="las la-expand-arrows-alt"></i
                  ></a>
                  <a
                    href="javascript:void(0);"
                    class="list-view btn btn-link"
                    id="filter_search"
                    v-tippy
                    title="Filtr"  
                    v-on:click="filter = !filter"
                    :class="{ setclose: filter }"
                    ><i class="las la-filter"></i
                  ></a>
                </div>
                <!-- <div class="form-sort">
                  <a
                    href="javascript:void(0);"
                    class="list-view btn btn-link"
                    data-bs-toggle="modal"
                    data-bs-target="#export"
                    ><i class="las la-file-export"></i>Export</a
                  >
                </div> -->
                <a
                  href="javascript:void(0);"
                  class="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#addDataModal"
                  ><i class="la la-plus-circle"></i> Əlavə et</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
  
        <!-- Search Filter -->
        <search-area
          :filter="filter"
          ref="searchComponent"
          :searchFilters="searchFilters"
          @submitSearchFilter="searchFilterSubmitHandler"
        ></search-area>
        <hr />
        <!-- /Search Filter -->
        <div class="filter-section mb-3">
          <ul>
            <li>
              <form @submit.prevent="searchSubmit">
                <div class="search-set">
                  <div class="search-input">
                    <a
                      href="javascript:void(0);"
                      @click="searchSubmit"
                      class="btn btn-searchset"
                      ><i class="las la-search"></i
                    ></a>
                    <div class="dataTables_filter">
                      <label>
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder="Axtar"
                          v-model="searchString"
                      /></label>
                    </div>
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
  
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
                :pagination="false"
                @change="handleTableChange"
                :locale="{ emptyText: 'Məlumat tapılmadı' }"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Action'">
                    <div class="text-end">
                      <div class="d-flex align-items-center gap-3">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="openShowModal(record.id)"
                          v-tippy title="Baxış"
                          >
                          <i class="fa-solid fa-eye   text-info"></i>
                        </a>
  
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="editData(record.id)"
                          v-tippy title="Düzəliş et"
                          ><i class="fa-solid fa-pencil  text-warning"></i></a
                        >
  
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="openDeleteModal(record.id)"
                          v-tippy title="Sil"
                          ><i class="fa-regular fa-trash-can  text-danger"></i></a
                        >
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'from_account'">
                    {{ record.from_account?.name }}
                  </template>
                  
                  <template v-if="column.key === 'to_account'">
                    {{ record.to_account?.name }}
                  </template>
                </template>
              </a-table>
              <a-pagination
                :current="currentPage"
                :total="totalData"
                :page-size="pageSize"
                @change="handlePageChange"
                class="mt-3 text-end mb-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <AddModal
    :companiesList="companiesList"
    @formSubmited="addSubmit"> </AddModal>
  
    <EditModal 
    :id="editId" 
    @formSubmited="editSubmit"
    :companiesList="companiesList"
    > </EditModal>
  
    <delete-modal
      modalId="deleteModal"
      @deleteConfirmed="deleteSubmit"
    ></delete-modal>
  
    <ShowModal
      :fields="showData"
      :fieldLabels="showDataLabels"
      modal-title="Transfərə baxış"
      :necessary-fields="showDataFields"
    ></ShowModal>
  </template>
  
  <script setup>
  import { ref, onMounted, reactive, onBeforeUnmount, watch } from "vue";
  import ShowModal from "@/components/modal/show-data.vue";
  import AddModal from "@/components/modal/transfers/add-transfer.vue";
  import EditModal from "@/components/modal/transfers/edit-transfer.vue";
  
  import {
    openModal,
    closeModal,
    handleTableSorterChange,
    toggleViewFullscreen,
  } from "@/utils/helper";
  import { showSuccessToast, showErrorToast } from "@/utils/helper";
import { useCompaniesStore } from "@/stores/modules/companies";
import { useTransfersStore } from "@/stores/modules/transfes";
  
  // Store
  const transferStore = useTransfersStore();
  const companyStore = useCompaniesStore();
  
  // State
  const data = ref([]);
  
  const showData = reactive({});
  
  const showDataFields = ref([
    "id",
    "company.name",
    "from_account.name",
    "to_account.name",
    "amount",
    "date",
    "note",
    "created_at",
  ]);
  const showDataLabels = reactive({
    id: "ID",
    "company.name": "Şirkət",
    "from_account.name": "Göndərən Hesab",
    "to_account.name": "Qəbul edən hesab",
    amount: "Məbləğ",
    date: "Tarix",
    note: "Qeyd",
    created_at: "Yaranma tarixi",
  });
  
  // Pagination and Filters
  const filter = ref(false);
  const searchComponent = ref(null);
  let searchString = ref("");
  let searchFiltersValue = reactive({});
  let currentPage = ref(1);
  let pageSize = ref(15);
  let totalData = ref(0);
  let itemToDeleteId = ref(null);
  const editId = ref(null);

  const companiesList = ref([]);
  
  const sortParams = ref({
    sortField: "id",
    sortOrder: "desc",
  });
  
  // Columns
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Göndərən Hesab",
      dataIndex: "from_account_name",
      key:"from_account",
      sorter: false,
    },
    {
      title: "Qəbul edən hesab ",
      dataIndex: "to_account_name",
      key:"to_account",
      sorter: false,
    },
    {
      title: "Məbləğ",
      dataIndex: "amount",
      sorter: true,
    },
    {
      title: "Tarix",
      dataIndex: "date",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "Action",
      key: "Action",
      sorter: false,
    },
  ];
  
  //Filters
  const searchFilters = [
    {
      title: "ID",
      key: "id",
      type: "text",
    },
    {
      title: "Tarix",
      key: "date_between",
      type: "date",
    },
  ];
  
  /* -------------------- Methods -------------------- */
  
  // Fetch data with pagination
  const fetchListData = async (
    page = 1,
    searchString = "",
    searchFilters = searchFiltersValue
  ) => {
    const response = await transferStore.getList({
      params: {
        offset: (page - 1) * pageSize.value,
        limit: pageSize.value,
        q: searchString,
        sortField: sortParams.value.sortField,
        sortOrder: sortParams.value.sortOrder,
        ...searchFilters,
      },
    });
    const responseData = response.data;
    data.value = responseData.data;
    totalData.value = responseData.total;
  };
  
  // Add new data entry
  const addSubmit = async () => {
    await fetchListData();
  };
  
  // Edit submited
  const editSubmit = async () => {
    await fetchListData();
    editId.value = null;
  };
  
  // Edit existing data entry
  const editData = async (id) => {
    editId.value = id;
    openModal("editDataModal");
  };
  
  // DeleteConfirmationModal
  const openDeleteModal = (id) => {
    openModal("deleteModal");
    itemToDeleteId.value = id;
  };
  
  // Delete item
  const deleteSubmit = async () => {
    await transferStore.delete(itemToDeleteId.value);
    closeModal("deleteModal");
    showSuccessToast("Uğurla silindi");
    fetchListData();
  };
  
  // Show Item
  const openShowModal = async (id) => {
    await transferStore.getDetail(id).then(() => {
      Object.assign(showData, transferStore.dataTransfer.data);
    });
    openModal("showModal");
  };
  
  // Search items
  const searchSubmit = async () => {
    fetchListData(1, searchString.value);
  };
  
  // Search Filters Submit
  const searchFilterSubmitHandler = async (filters) => {
    await fetchListData(1, null, filters);
    searchFiltersValue = filters;
  };
  
  const resetSearchFilter = async () => {
    await fetchListData();
    searchComponent.value.resetFilters();
  };
  
  // Handle page change for pagination
  const handlePageChange = (page) => {
    currentPage.value = page;
    fetchListData(page);
  };
  
  // Handle Table Changes (Sorting)
  const handleTableChange = async (pagination, filters, sorter) => {
    await handleTableSorterChange(sorter, sortParams);
    fetchListData(currentPage.value);
  };

  const createCompaniesList = async ()=>{
  await companyStore.getList({ limit: 10000 });
  companiesList.value = companyStore.dataCompanies.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}
  
  const toggleFullscreen = () => toggleViewFullscreen();
  
  /* -------------------- Lifecycle Hooks -------------------- */
  
  const debounceTime = 500;
  
  let debounceTimeout;
  
  watch(searchString, () => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
  
    debounceTimeout = setTimeout(() => {
      searchSubmit();
    }, debounceTime);
  });
  
  // On component mount
  onMounted(() => {
    fetchListData();
    createCompaniesList();
  });
  
  // Before unmount
  onBeforeUnmount(() => {
    // document.removeEventListener("click", handleDocumentClick);
  });
  </script>
  
  <style>
  .ant-pagination-item a {
    color: black !important;
  }
  
  .ant-pagination-item-active a {
    color: white !important;
  }
  
  .stripped .ant-table-cell {
    white-space: normal;
    text-overflow: ellipsis;
    word-break: normal;
  }
  </style>
  