import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useCategoryStore = defineStore('categories', {
    state: () => ({
        dataCategories: null,
        dataCategory: null,
    }),

    actions: {
        async getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['categories'],{...payload})
                .then((res) => {
                    this.dataCategories = res;
                    return res;
                }).finally(() => {

                });
        },
        async getDetail( id ){
            try {
                await axiosInstance.get(`${urlList['categories']}/${id}`).then((res)=>{
                    this.dataCategory = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData ) {
            try {
                return await axiosInstance.post(urlList['categories'], formData).then( (res) => {
                    return res;
                });;
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData , id ) {
            try {
                return axiosInstance.put(`${urlList['categories']}/${id}`, formData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id) {
            try {
                const response = await axiosInstance.delete(`${urlList['categories']}/${id}`);

                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        },
    },
    getters: {}
});
