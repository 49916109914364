<template>
    <div class="company-slider owl-carousel">

        <!-- Company Grid -->
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="item in CompanyPolicy" :key="item.id">
                <div :class="item.Class" class="company-policys">
                    <div class="company-top ">
                        <div class="company-icon">
                            <span :class="item.LogoClass">{{ item.Logo }}</span>
                        </div>
                        <div class="company-link">
                            <router-link to="/crm/companies-list">{{ item.Title }}</router-link>
                        </div>
                    </div>
                    <div class="company-bottom d-flex">
                        <ul>
                            <li>{{ item.Policy }}</li>
                            <li>{{ item.Updated }}</li>
                        </ul>
                        <div class="company-bottom-links">
                            <a href="javascript:void(0);"><i class="la la-download"></i></a>
                            <a href="javascript:void(0);"><i class="la la-eye"></i></a>
                        </div>
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
        <!-- Company Grid -->

    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import CompanyPolicy from '@/assets/json/company-policy.json'
export default {
    data() {
        return {
            CompanyPolicy: CompanyPolicy,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 2,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation
    },
}
</script>