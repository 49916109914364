<template>
  <div class="card mb-0">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="profile-view">
            <div class="profile-img-wrap">
              <div class="profile-img">
                <a href="javascript:;">
                  <img
                    src="@/assets/img/profiles/avatar-19.jpg"
                    alt="User Image"
                  />
                </a>
              </div>
            </div>
            <div class="profile-basic">
              <div class="row">
                <div class="col-md-5">
                  <div class="profile-info-left">
                    <h3 class="user-name m-t-0">Global Technologies</h3>
                    <h5 class="company-role m-t-0 mb-0">Barry Cuda</h5>
                    <small class="text-muted">CEO</small>
                    <div class="staff-id">Employee ID : CLT-0001</div>
                    <div class="staff-msg">
                      <router-link to="/apps/chat" class="btn btn-custom"
                        >Send Message</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-7">
                  <ul class="personal-info">
                    <li>
                      <span class="title">Phone:</span>
                      <span class="text"><a href="javascript:;">9876543210</a></span>
                    </li>
                    <li>
                      <span class="title">Email:</span>
                      <span class="text"
                        ><a href="javascript:;">barrycuda@example.com</a></span
                      >
                    </li>
                    <li>
                      <span class="title">Birthday:</span>
                      <span class="text">2nd August</span>
                    </li>
                    <li>
                      <span class="title">Address:</span>
                      <span class="text"
                        >5754 Airport Rd, Coosada, AL, 36020</span
                      >
                    </li>
                    <li>
                      <span class="title">Gender:</span>
                      <span class="text">Male</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
