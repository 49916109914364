import axios from 'axios';
import urls from '@/configs/urls';
import { router } from './router';
import { useAppStore } from '@/stores';
import { showErrorToast } from "@/utils/helper";


const axiosInstance = axios.create({
  baseURL: urls.getParam('BASE_URL'),
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
});

const getAccessToken = () => {
  return localStorage.getItem('token');
};

const refreshAccessToken = async () => {
  try {
    const response = await axiosInstance.post('/auth/refresh', {
      token: getAccessToken()
    });
    const newAccessToken = response.data.accessToken;
    localStorage.setItem('token', newAccessToken);
    return newAccessToken;
  } catch (error) {
    localStorage.removeItem('token')
  }
};

axiosInstance.interceptors.request.use(
  config => {
    const store = useAppStore();
    store.showLoading(true);

    if (!config.noAuth) {
      const token = getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => {
    const store = useAppStore();
    store.showLoading(false);

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    const store = useAppStore();
    store.showLoading(false);

    return response;
  },
  async error => {
    const store = useAppStore();
    store.showLoading(false);

    const originalRequest = error.config;

    if (!error.response) {
      console.error('Network Error:', error.message);
      showErrorToast(`Xəta meydana gəldi: ${error.message}`);
      localStorage.clear('token');
      router.push({ name: 'login' });
      return Promise.reject(error);
    }

    if(error.response.status === 422 || error.response.status === 400){
      showErrorToast(error.response.data.message);
    }

    // Check for 401 Unauthorized error
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshAccessToken();
        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        localStorage.clear('token');
        router.push({ name: 'login' });
        return Promise.reject(refreshError);
      }
    }

    if (originalRequest.noAuth) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
