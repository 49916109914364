import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useCompanyAccounts = defineStore('companyAccounts', {
    state: () => ({
        dataCompanyAccounts: null,
        dataCompanyAccount: null
    }),

    actions: {
        getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['companyAccounts'],{...payload})
                .then((res) => {
                    this.dataCompanyAccounts = res;
                    return res;
                }).finally(() => {

                });
        },
        async getDetail(id) {
            try {
                await axiosInstance.get(`${urlList['companyAccounts']}/${id}`).then((res) => {
                    this.dataCompanyAccount = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData) {
            try {
                return await axiosInstance.post(urlList['companyAccounts'], formData).then(async (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData, id) {
            try {
                return axiosInstance.put(`${urlList['companyAccounts']}/${id}`, formData).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData , id ) {
            try {
                return axiosInstance.put(`${urlList['companyAccounts']}/update/${id}`, statusData).then( (res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id) {
            try {
                return await axiosInstance.delete(`${urlList['companyAccounts']}/${id}`).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        }
    },
    getters: {}
});
