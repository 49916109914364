<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Təşkilatlar</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard"
                  >    Daşbord
                  </router-link
                >
              </li>
              <li class="breadcrumb-item active">Təşkilatlar</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link" 
                  @click="resetSearchFilter"
                  v-tippy
                  title="Filtri sıfırla"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  @click="toggleFullscreen"
                  v-tippy
                  title="Tam ekran"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-tippy
                  title="Filtr"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <!-- <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div> -->
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_organization"
                ><i class="la la-plus-circle"></i> Əlavə et</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <search-area  ref="searchComponent" :filter="filter" :searchFilters="searchFilters" @submitSearchFilter="searchFilterSubmitHandler"></search-area>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <form @submit.prevent="searchSubmit">
              <div class="search-set">
                <div class="search-input">
                  <a
                    href="javascript:void(0);"
                    @click="searchSubmit"
                    class="btn btn-searchset"
                    ><i class="las la-search"></i
                  ></a>
                  <div class="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Axtar"
                        v-model="searchString"
                    /></label>
                  </div>
                </div>
              </div>
            </form>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table
              class="stripped table-hover"
              :columns="columns"
              :data-source="data"
              :pagination="false"
              @change="handleTableChange"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Status'">
                  <StatusChange
                    :data="record"
                    @statusChange="handleChangeStatus"
                  />
                </template>
                <template v-else-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="editData(record.id)"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Düzəliş et</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="openDeleteModal(record.id)"
                          ><i class="fa-regular fa-trash-can m-r-5"></i>
                          Sil</a
                        >
                        
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="openShowModal(record.id)"
                          ><i class="fa-regular fa-eye m-r-5"></i>
                          Baxış</a
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
            <a-pagination
              :current="currentPage"
              :total="totalData"
              :page-size="pageSize"
              @change="handlePageChange"
              class="mt-3 text-end mb-5"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <add-organization-modal
    :formData="createFormData"
    @formSubmited="addSubmit"
  ></add-organization-modal>

  <edit-organization-modal
    :formData="editFormData"
    @formSubmited="editSubmit"
  ></edit-organization-modal>

  <delete-modal
    modalId="deleteOrganization"
    @deleteConfirmed="deleteSubmit"
  ></delete-modal>

  <ShowOrganization :fields="showData"></ShowOrganization>
</template>

<script setup>
import {
  ref,
  onMounted,
  reactive,
  onBeforeUnmount,
  watch,
} from "vue";
import StatusChange from "@/components/status-change.vue";
import ShowOrganization from "@/components/modal/organizations/show-organization.vue";
import { useOrganizationStore } from "@/stores/modules/organization";
import { openModal, closeModal, handleTableSorterChange, toggleViewFullscreen} from "@/utils/helper";
import { showSuccessToast, showErrorToast } from "@/utils/helper";

// Store
const organizationStore = useOrganizationStore();

// State
const data = ref([]);

// Form data
const createFormData = reactive({
  name: "",
  related_fullname: "",
  voen: "",
});

const editFormData = reactive({
  id: null,
  name: "",
  related_fullname: "",
  voen: "",
});

const showData = reactive({});

// Pagination and Filters
const filter = ref(false);
let searchString = ref("");
let searchFiltersValue = reactive({});
let currentPage = ref(1);
let pageSize = ref(15);
let totalData = ref(0);
let itemToDeleteId = ref(null);
const searchComponent = ref(null);

const sortParams = ref({
  sortField: 'id',
  sortOrder: 'desc',
})


// Columns
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
  },
  {
    title: "Təşkilatın adı",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Əlaqəli şəxs",
    dataIndex: "related_fullname",
    sorter: true,
  },
  {
    title: "VÖEN",
    dataIndex: "voen",
    sorter: true,
  },
  {
    title: "Yaranma Tarixi",
    dataIndex: "created_at",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "Status",
    sorter: false,
  },
  {
    title: "",
    dataIndex: "Action",
    key: "Action",
    sorter: false,
  },
];

//Filters
const searchFilters = [
  // {
  //   title: "ID",
  //   key: "id",
  //   type: "text"
  // },
  {
    title: "Təşkilatın adı",
    key: "name",
    type: "text"
  },
  {
    title: "Əlaqəli şəxs",
    key: 'related_fullname',
    type: "text"
  },
  {
    title: "VÖEN",
    key: "voen",
    type: "text"
  },
  {
    title: "Status",
    key: "status",
    type: "select",
    options:[
      {id:1,text:'Aktiv'},
      {id:2,text:'Pasif'}
    ]
  },
  {
    title: "Yaranma tarixi",
    key: "created_at",
    type: "date"
  }
];

/* -------------------- Methods -------------------- */

// Fetch data with pagination
const fetchListData = async (page = 1, searchString = "",searchFilters = {}) => {
  const response = await organizationStore.getList({
    params: {
      offset: (page - 1) * pageSize.value,
      limit: pageSize.value,
      q: searchString,
      sortField: sortParams.value.sortField,
      sortOrder: sortParams.value.sortOrder,
      ...searchFilters
    },
  });
  const responseData = response.data;
  data.value = responseData.data;
  totalData.value = responseData.total;
};

// Fetch single data item
const fetchData = async (id) => {
  await organizationStore.getDetail(id);
  Object.assign(editFormData, organizationStore.dataOrganization.data);
};

// Add new data entry
const addSubmit = async () => {
  try {
    const response = await organizationStore.add(createFormData);
    showSuccessToast(response.data.message);
    resetFormData();
    fetchListData();
  } catch (error) {
    showErrorToast(
      error.response.data.message || "Xəta baş verdi. Yenidən cəhd edin"
    );
  } finally {
    closeModal("add_organization");
  }
};

// Edit form submited
const editSubmit = async () => {
  try {
    const response = await organizationStore.update(
      editFormData,
      editFormData.id
    );
    showSuccessToast(response.data.message);
    resetFormData();
    fetchListData();
    closeModal("add_organization");
  } catch (error) {
    console.error(error)
  }
};

// Edit existing data entry
const editData = async (id) => {
  await fetchData(id);
  openModal("edit_organization");
};

// DeleteConfirmationModal
const openDeleteModal = (id) => {
  openModal("deleteOrganization");
  itemToDeleteId.value = id;
};

// Delete item
const deleteSubmit = async () => {
  await organizationStore.delete(itemToDeleteId.value);
  closeModal("deleteOrganization");
  showSuccessToast("Uğurla silindi");
  fetchListData();
};

// Show Item
const openShowModal = async (id) => {
  await organizationStore.getDetail(id).then(()=>{
    Object.assign(showData, organizationStore.dataOrganization.data);
  });
  openModal('showOrganization')
}

// Reset form data
const resetFormData = () => {
  createFormData.name = "";
  createFormData.related_fullname = "";
  createFormData.voen = "";
};

// Search items
const searchSubmit = async () => {
  fetchListData(1, searchString.value);
};

// Search Filters Submit

const searchFilterSubmitHandler = async (filters) =>{
  await fetchListData(1,null,filters);
  searchFiltersValue = filters;
}


const resetSearchFilter = async () => {
  await fetchListData()
  searchComponent.value.resetFilters()
}

const handleChangeStatus = (id) => {
  const index = data.value.findIndex((item) => item.id === id);

  if (index !== -1) {
    changeStatus(!data.value[index].status, id);
    data.value[index].status =
      !data.value[index].status;
  }
};

const changeStatus = async (statusValue , id) => {
  try {
    await organizationStore.updateStatus({ status: statusValue }, id);
    showSuccessToast('Status yenilendi')
  } catch (error) {
    console.error("Error:", error);
  }
};

// Handle page change for pagination
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchListData(page);
};


// Handle Table Changes (Sorting)
const handleTableChange = async (pagination, filters, sorter) => {
  await handleTableSorterChange(sorter,sortParams);
  fetchListData(currentPage.value)
}


const toggleFullscreen = () => toggleViewFullscreen()

/* -------------------- Lifecycle Hooks -------------------- */

const debounceTime = 500;

let debounceTimeout;

watch(searchString, () => {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    searchSubmit();
  }, debounceTime);
});

// On component mount
onMounted(() => {
  fetchListData();
});

// Before unmount
onBeforeUnmount(() => {
  // document.removeEventListener("click", handleDocumentClick);
});
</script>

<style>
.ant-pagination-item a{
  color: black !important;
}

.ant-pagination-item-active a{
  color: white !important;
}
</style>
