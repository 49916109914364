<template>
  <!-- Add Activity -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_activity"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Activity</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/activities">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3 activity-date-picker">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <date-picker
                        v-model="startdate"
                        placeholder="From"
                        class="form-control floating datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="activity-date-picker input-block mb-3">
                    <label class="col-form-label"
                      >Time <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon time-icon">
                      <a-time-picker
                        v-model:value="value17"
                        class="form-control timepicker"
                        placeholder="Time"
                        use12-hours
                        format="h:mm:ss a"
                      />
                      <span class="cus-icon"><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="activity-date-picker input-block mb-3">
                    <label class="col-form-label"
                      >Reminder <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon reminder">
                      <input type="text" class="form-control" />
                      <span class="cus-icon"><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="input-block mb-3 d-flex align-items-center">
                    <div class="w-100">
                      <vue-select :options="CrmMin" id="crmmin" placeholder="Select" />
                    </div>
                    <span class="use-before">Before Use</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Owner <span class="text-danger">*</span></label
                    >
                    <vue-select :options="CrmBeer" id="crmbeer" placeholder="Select" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Guests <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox4"
                      :tags="tags4"
                      value="Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-md-12 deals-add-col">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Deals <span class="text-danger">*</span></label
                          >
                          <a href="javascript:void(0);" class="add-new add-new-deal"
                            ><i class="la la-plus-circle me-2"></i>Add New</a
                          >
                        </div>
                        <vue-select
                          :options="CrmCollins"
                          id="crmcollins"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 contact-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Contacts <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-contact"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select
                      :options="CrmDarlee"
                      id="crmdarlee"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-md-12 company-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Company <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-company"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select :options="CrmNov" id="crmnov" placeholder="Select" />
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Activity</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Activity -->

  <!-- Edit Activity -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="edit_activity"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Edit Activity</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/activities">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      type="text"
                      value="We scheduled a meeting for next week"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3 activity-date-picker">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <date-picker
                        v-model="startdate"
                        placeholder="From"
                        class="form-control floating datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="activity-date-picker input-block mb-3">
                    <label class="col-form-label"
                      >Time <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon time-icon">
                      <a-time-picker
                        v-model:value="value18"
                        class="form-control timepicker"
                        placeholder="Time"
                        use12-hours
                        format="h:mm:ss a"
                      />
                      <span class="cus-icon"><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="activity-date-picker input-block mb-3">
                    <label class="col-form-label"
                      >Reminder <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon reminder">
                      <input type="text" class="form-control" />
                      <span class="cus-icon"><i class="feather-clock"></i></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="input-block mb-3 d-flex align-items-center">
                    <div class="w-100">
                      <vue-select
                        :options="EditCrmMin"
                        id="editcrmmin"
                        placeholder="Minutes"
                      />
                    </div>
                    <span class="use-before">Before Use</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Owner <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="EditCrmBeer"
                      id="editcrmbeer"
                      placeholder="Monty Beer"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Guests <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox3"
                      :tags="tags3"
                      value="Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-md-12 deals-add-col">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-block mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Deals <span class="text-danger">*</span></label
                          >
                          <a href="javascript:void(0);" class="add-new add-new-deal"
                            ><i class="la la-plus-circle me-2"></i>Add New</a
                          >
                        </div>
                        <vue-select
                          :options="EditCrmCollins"
                          id="editcrmcollins"
                          placeholder="Collins"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 contact-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Contacts <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-contact"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select
                      :options="EditCrmDarlee"
                      id="editcrmdarlee"
                      placeholder="Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-md-12 company-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Company <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-new-company"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select
                      :options="EditCrmNov"
                      id="editcrmnov"
                      placeholder="NovaWaveLLC"
                    />
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Activity</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Activity -->

  <!-- Delete Activity -->
  <div class="modal custom-modal fade" id="delete_activity" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Selected Activity from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/pipeline" class="btn btn-primary">Okay</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Activity -->

  <!-- Delete Pipeline -->
  <div class="modal custom-modal fade" id="delete_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Stage ”Inpipeline” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/crm/activities" class="btn btn-primary">Okay</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Pipeline -->

  <!-- Export -->
  <div class="modal custom-modal fade modal-padding" id="export" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Export</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/activities">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <h5 class="mb-3">Export</h5>
                  <div class="status-radio-btns d-flex">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="pdf"
                        name="export-type"
                        checked
                      />
                      <label for="pdf">Person</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="excel"
                        name="export-type"
                      />
                      <label for="excel">Organization</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h4 class="mb-3">Filters</h4>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Fields <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="CrmFields"
                    id="crmfields"
                    placeholder="All Fields"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdateOne"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Export Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Export -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
export default {
  data() {
    return {
      CrmFields: ["All Fields", "Contact", "Company"],
      CrmMin: ["Minutes", "Hours"],
      EditCrmMin: ["Minutes", "Hours"],
      CrmBeer: ["Select", "Monty Beer", "Itzel", "Andrew"],
      EditCrmBeer: ["Monty Beer", "Itzel", "Andrew"],
      CrmCollins: ["Select", "Collins", "Konopelski", "Adams"],
      EditCrmCollins: ["Collins", "Konopelski", "Adams"],
      CrmDarlee: ["Select", "Darlee Robertson", "Sharon Roy", "Vaughan"],
      EditCrmDarlee: ["Darlee Robertson", "Sharon Roy", "Vaughan"],
      CrmNov: ["Select", "NovaWaveLLC", "SilverHawk", "SummitPeak"],
      EditCrmNov: ["NovaWaveLLC", "SilverHawk", "SummitPeak"],
      tags3: ["Darlee Robertson"],
      tags4: ["Darlee Robertson"],
      value17: null,
      value18: null,
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      startdateOne: currentDateOne,
    };
  },
  components: {
    Vue3TagsInput,
  },
};
</script>
