<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="addDataModal"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Yeni Tranzaksiya</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="contact-input-set">
              <div class="row">

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="d-block">Tranzaksiya Tipi</label>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type_id"
                        id="income"
                        value="0"
                        v-model="formData.type_id"
                        checked
                         @change="typeChanged"
                      />
                      <label class="form-check-label" for="income">Mədaxil</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="type_id"
                        id="expense"
                        value="1"
                        v-model="formData.type_id"
                        @change="typeChanged"
                      />
                      <label class="form-check-label" for="expense">Məxaric</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Şirkət<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      v-if="props.companiesOptionsList.length > 0"
                      :options="props.companiesOptionsList"
                      name="company_id"
                      id="companySelectAdd"
                      v-model="formData.company_id"
                      @update:modelValue="companyChanged"
                      :class="{
                        'is-invalid': $v.formData.company_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.company_id.$error,
                      }"
                    />

                    <template
                      v-if="isSubmitForm && $v.formData.company_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Hesab<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :disabled="accountsList.length <= 0"
                      :options="accountsList"
                      name="account_id"
                      id="accountSelectAdd"
                      v-model="formData.account_id"
                      :class="{
                        'is-invalid': $v.formData.account_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.account_id.$error,
                      }"
                    />
                    <template
                      v-if="isSubmitForm && $v.formData.account_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      {{ formData.type_id == 1 ? `Təchizatçı` : `Müştəri` }}
                      <span class="text-danger">*</span>
                    </label>

                    <vue-select
                      :disabled="customerList.length <= 0"
                      :options="customerList"
                      name="customer_id"
                      id="customerSelectAdd"
                      v-model="formData.customer_id"
                      :placeholder="customerList.length <= 0 ? 
                      `Ödənməmiş qaiməsi olan ${formData.type_id == 1 ? 'təchizatçı' : 'müştəri'} tapılmadı` : null"
                      @update:modelValue="customerChanged"
                      :class="{
                        'is-invalid': $v.formData.customer_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.customer_id.$error,
                      }"
                    />

                    <template
                      v-if="isSubmitForm && $v.formData.customer_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Kateqoriya<span class="text-danger">*</span>
                    </label>

                    <vue-select
                      v-if="categoryList.length > 0"
                      :options="categoryList"
                      name="category_id"
                      id="categorySelectAdd"
                      v-model="formData.category_id"
                      :class="{
                        'is-invalid': $v.formData.category_id.$error,
                        'is-valid':
                          isSubmitForm && !$v.formData.category_id.$error,
                      }"
                    />

                    <template
                      v-if="isSubmitForm && $v.formData.category_id.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Qaimələr
                    </label>

                    <vue-select
                      :disabled="invoiceList.length <= 0"
                      :options="invoiceList"
                      name="invoice_id"
                      id="invoiceSelectAdd"
                      @update:modelValue="invoiceChanged"
                      v-model="formData.invoice_id"
                    />
                  </div>
                </div>
                

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">
                      Məbləğ <span class="text-danger">*</span>
                    </label>

                    <input
                      v-model="formData.amount"
                      class="form-control"
                      type="text"
                      name="amount"
                      :class="{
                        'is-invalid': $v.formData.amount.$error,
                        'is-valid': isSubmitForm && !$v.formData.amount.$error,
                      }"
                    />

                    <template v-if="isSubmitForm && $v.formData.amount.$error">
                      <p
                        v-if="$v.formData.amount.required.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ $v.formData.amount.required.$message }}
                      </p>
                      <p
                        v-else-if="$v.formData.amount.decimal.$invalid"
                        class="text-danger mt-1"
                      >
                        {{ $v.formData.amount.decimal.$message }}
                      </p>
                    </template>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"> Qeyd </label>

                    <textarea
                      v-model="formData.note"
                      class="form-control"
                      type="text"
                      name="note"
                      rows="4"
                      :class="{
                        'is-valid': isSubmitForm,
                      }"
                    />
                  </div>
                </div>

                <div class="col-lg-12 text-end form-wizard-button">
                  <button class="button btn-lights reset-btn" type="reset">
                    Sıfırla
                  </button>
                  <button class="btn btn-primary" type="submit">
                    Yadda saxla
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, onMounted } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  maxValue,
  required,
  decimal,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useCompanyAccounts } from "@/stores/modules/companyAccounts";
import { useCustomersAndSuppliers } from "@/stores/modules/customerAndSuppliers";
import { useCategoryStore } from "@/stores/modules/categories";
import { useTransactionsStore } from "@/stores/modules/transactions";
import { useInvoicesStore } from "@/stores/modules/invoices";

const transactionStore = useTransactionsStore()
const accountStore = useCompanyAccounts();
const customerStore = useCustomersAndSuppliers();
const categoryStore = useCategoryStore();
const invoiceStore = useInvoicesStore();

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const props = defineProps({
  companiesOptionsList: {
    type: Array,
    default:[]
  }
});

const accountsList = ref([]);
const customerList = ref([]);
const categoryList = ref([]);
const invoiceList = ref([]);

const maxAmount = ref(1000000000);
const maxValueMessage = `Maksimum məbləğ:${maxAmount.value} AZN`;

const formData = reactive({
    note: '',
    type_id: 0,
    amount: undefined,
    customer_id: undefined,
    company_id: undefined,
    category_id: undefined,
    invoice_id: undefined,
    account_id: undefined,
});

const rules = {
  formData: {
      company_id: { required },
      category_id: {},
      customer_id: { required },
      account_id: { required },
      amount: {
          required: helpers.withMessage('Bu vacib məlumatdır', required),
          maxValue: helpers.withMessage(maxValueMessage, maxValue(maxAmount)),
          decimal: helpers.withMessage('Ancaq rəqəm (onluq ədədləri) qəbul edir.', decimal)
      }
  }
};

const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
    formData.note = '';
    formData.type_id = 0;
    formData.amount = undefined;
    formData.customer_id = undefined;
    formData.category_id = undefined;
    formData.account_id = undefined;
    formData.invoice_id = undefined;

    accountsList.value = [];
    customerList.value = [];
    invoiceList.value = [];
};


const companyChanged = async () => {
  createAccountList();
  createCustomerList();
}

const customerChanged = async () => {
  createInvoiceList();
}

const invoiceChanged = async () => {
  const invoice = invoiceStore.dataList.find(item => item.id === formData.invoice_id);
  if (invoice) {
    maxAmount.value = invoice.remaining_amount;
  }
};
const createAccountList = async () => {
  await accountStore.getList({
    params:{ 
      company_id: formData.company_id,
      limit: 10000
   }
  });

  accountsList.value = accountStore.dataCompanyAccounts.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}


const createCustomerList = async () => {
  if (formData.type_id == 1) {
    await customerStore.getList({
      params:{ 
        company_id: formData.company_id, 
        invoice_status: 0, 
        is_supplier: 1,
        limit: 10000
      }
    });
  }else{
    await customerStore.getList({
      params:{ 
        company_id: formData.company_id, 
        invoice_status: 0, 
        is_customer: 1,
        limit: 10000
      }
    });
  }

  customerList.value = customerStore.dataList.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}


const createCategoryList = async () => {
  await categoryStore.getList({
    params:{ 
      type_id: formData.type_id,
      limit: 10000
   }
  });

  categoryList.value = categoryStore.dataCategories.data.data.map((item) => ({
    id: item.id,
    text: item.name,
  }));
}

const createInvoiceList = async () => {
  await invoiceStore.getList({
    params:{ 
      type_id: formData.type_id,
      offset: 0,
      limit: 1000,
      customer_id: formData.customer_id,
      status: 0
   }
  });

  invoiceList.value = invoiceStore.dataList.data.data.map((item) => ({
    id: item.id,
    text: `${item.tax_number || 'nömrə yoxdur'} - ${item.paid_amount}/${item.total_amount} AZN - Qalıq: ${item.remaining_amount} AZN`,
  }));
}


const typeChanged = async () =>{
  createCategoryList();
  createCustomerList();
}


const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }
  try {
    await transactionStore.add(formData).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("addDataModal");
    });
  } catch (error) {
    console.log(error);
  }
};

onMounted(()=>{
  createCategoryList();
})

</script>

  