<template>
  <div class="tab-pane fade" id="calls">
    <div class="view-header">
      <h4>Calls</h4>
      <ul>
        <li>
          <a
            href="javascript:void(0);"
            data-bs-toggle="modal"
            data-bs-target="#create_call"
            class="com-add"
            ><i class="las la-plus-circle me-1"></i>Add New</a
          >
        </li>
      </ul>
    </div>
    <div class="calls-activity">
      <div class="calls-box">
        <div class="caller-info">
          <div class="calls-user">
            <img src="@/assets/img/avatar/avatar-19.jpg" alt="img" />
            <p><span>Darlee Robertson</span> logged a call on 23 Jul 2023, 10:00 pm</p>
          </div>
          <div class="calls-action">
            <div class="dropdown call-drop">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Busy<i class="las la-angle-down ms-1"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);">Busy</a>
                <a class="dropdown-item" href="javascript:void(0);">No Answer</a>
                <a class="dropdown-item" href="javascript:void(0);">Unavailable</a>
                <a class="dropdown-item" href="javascript:void(0);">Wrong Number</a>
                <a class="dropdown-item" href="javascript:void(0);">Left Voice Message</a>
                <a class="dropdown-item" href="javascript:void(0);">Moving Forward</a>
              </div>
            </div>
            <div class="dropdown">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="feather-more-vertical"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);">Delete</a>
              </div>
            </div>
          </div>
        </div>
        <p>
          A project review evaluates the success of an initiative and identifies areas for
          improvement. It can also evaluate a current project to determine whether it's on
          the right track. Or, it can determine the success of a completed project.
        </p>
      </div>
      <div class="calls-box">
        <div class="caller-info">
          <div class="calls-user">
            <img src="@/assets/img/avatar/avatar-20.jpg" alt="img" />
            <p><span>Sharon Roy</span> logged a call on 28 Jul 2023, 09:00 pm</p>
          </div>
          <div class="calls-action">
            <div class="dropdown call-drop">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle bg-light-pending"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >No Answer<i class="las la-angle-down ms-1"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);">Busy</a>
                <a class="dropdown-item" href="javascript:void(0);">No Answer</a>
                <a class="dropdown-item" href="javascript:void(0);">Unavailable</a>
                <a class="dropdown-item" href="javascript:void(0);">Wrong Number</a>
                <a class="dropdown-item" href="javascript:void(0);">Left Voice Message</a>
                <a class="dropdown-item" href="javascript:void(0);">Moving Forward</a>
              </div>
            </div>
            <div class="dropdown">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="feather-more-vertical"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);">Delete</a>
              </div>
            </div>
          </div>
        </div>
        <p>
          A project plan typically contains a list of the essential elements of a project,
          such as stakeholders, scope, timelines, estimated cost and communication
          methods. The project manager typically lists the information based on the
          assignment.
        </p>
      </div>
      <div class="calls-box">
        <div class="caller-info">
          <div class="calls-user">
            <img src="@/assets/img/avatar/avatar-21.jpg" alt="img" />
            <p><span>Vaughan</span> logged a call on 30 Jul 2023, 08:00 pm</p>
          </div>
          <div class="calls-action">
            <div class="dropdown call-drop">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle bg-light-pending"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >No Answer<i class="las la-angle-down ms-1"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);">Busy</a>
                <a class="dropdown-item" href="javascript:void(0);">No Answer</a>
                <a class="dropdown-item" href="javascript:void(0);">Unavailable</a>
                <a class="dropdown-item" href="javascript:void(0);">Wrong Number</a>
                <a class="dropdown-item" href="javascript:void(0);">Left Voice Message</a>
                <a class="dropdown-item" href="javascript:void(0);">Moving Forward</a>
              </div>
            </div>
            <div class="dropdown">
              <a
                href="javascript:void(0);"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="feather-more-vertical"></i
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:void(0);">Delete</a>
              </div>
            </div>
          </div>
        </div>
        <p>
          Projects play a crucial role in the success of organizations, and their
          importance cannot be overstated. Whether it's launching a new product, improving
          an existing
        </p>
      </div>
    </div>
  </div>
</template>
