<template>
  <!-- Add Deals -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="add_deals"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Add New Deals</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/deals">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Deal Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6 pipeline-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Pipeline <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-pipeline-btn"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select
                      :options="DealSales"
                      id="dealsales"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Status <span class="text-danger">*</span></label
                    >
                    <vue-select :options="DealOpen" id="dealopen" placeholder="Select" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Deal Value<span class="text-danger"> *</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Currency <span class="text-danger">*</span></label
                    >
                    <vue-select :options="DealRu" id="dealru" placeholder="Select" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Period <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Period Value <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Contact <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox"
                      :tags="tags"
                      value="James, Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Project <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox2"
                      :tags="tags2"
                      value="Divine dran"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Expected Closing Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Assignee <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox3"
                      :tags="tags3"
                      value="James"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tags <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox4"
                      :tags="tags4"
                      value="Label, Label"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Follow-up date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Source <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="SelectBarry"
                      id="selectbarry"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Priority <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="DealHighy"
                      id="dealhighy"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Save Deal</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Deals -->

  <!-- Edit Deals -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="edit_deals"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Edit Deals</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="deals.html">
            <div class="contact-input-set">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Deal Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="Adams" />
                  </div>
                </div>
                <div class="col-md-6 pipeline-add-col">
                  <div class="input-block mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="col-form-label"
                        >Pipeline <span class="text-danger">*</span></label
                      >
                      <a href="javascript:void(0);" class="add-new add-pipeline-btn"
                        ><i class="la la-plus-circle me-2"></i>Add New</a
                      >
                    </div>
                    <vue-select :options="Dealmark" id="dealmark" placeholder="Sales" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Status <span class="text-danger">*</span></label
                    >
                    <vue-select :options="DealWon" id="dealwon" placeholder="Open" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Deal Value<span class="text-danger"> *</span></label
                    >
                    <input class="form-control" type="text" value="5" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Currency <span class="text-danger">*</span></label
                    >
                    <vue-select :options="Dealam" id="dealam" placeholder="$" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Period <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Period Value <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="10" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Contact <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox5"
                      :tags="tags5"
                      value="James, Darlee Robertson"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Project <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox6"
                      :tags="tags6"
                      value="Divine dran"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="5 Mar 2024" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Expected Closing Date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="10 Mar 2024" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block tag-with-img mb-3">
                    <label class="col-form-label"
                      >Assignee <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox7"
                      :tags="tags7"
                      value="James"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Tags <span class="text-danger">*</span></label
                    >
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      id="inputBox8"
                      :tags="tags8"
                      value="Label, Label"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Follow-up date <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" value="5 Mar 2024" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Source <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="DealBarry"
                      id="dealbarry"
                      placeholder="Barry Cuda"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Priority <span class="text-danger">*</span></label
                    >
                    <vue-select :options="DealLow" id="deallow" placeholder="Highy" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
                <div class="col-lg-12 text-end form-wizard-button">
                  <button
                    class="button btn-lights reset-btn"
                    type="reset"
                    data-bs-dismiss="modal"
                  >
                    Bağla
                  </button>
                  <button class="btn btn-primary" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Deals -->

  <!-- Delete Deals -->
  <div class="modal custom-modal fade" id="delete_deals" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-danger">
              <i class="la la-trash-restore"></i>
            </div>
            <h3>Are you sure, You want to delete</h3>
            <p>Deal ”Collins” from your Account</p>
            <div class="col-lg-12 text-center form-wizard-button">
              <a href="javascript:void(0);" class="button btn-lights" data-bs-dismiss="modal">Not Now</a>
              <router-link to="/crm/deals" class="btn btn-primary">Okay</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Deals -->

  <!-- Export -->
  <div class="modal custom-modal fade modal-padding" id="export" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Export</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="/crm/deals">
            <div class="row">
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <h5 class="mb-3">Export</h5>
                  <div class="status-radio-btns d-flex">
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="pdf"
                        name="export-type"
                        checked
                      />
                      <label for="pdf">Person</label>
                    </div>
                    <div class="people-status-radio">
                      <input
                        type="radio"
                        class="status-radio"
                        id="excel"
                        name="export-type"
                      />
                      <label for="excel">Organization</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h4 class="mb-3">Filters</h4>
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Fields <span class="text-danger">*</span></label
                  >
                  <vue-select :options="DealCon" id="dealcon" placeholder="All Fields" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >From Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >To Date <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdateOne"
                      placeholder="From"
                      class="form-control floating datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 text-end form-wizard-button">
                <button
                  class="button btn-lights reset-btn"
                  type="reset"
                  data-bs-dismiss="modal"
                >
                  Reset
                </button>
                <button class="btn btn-primary" type="submit">Export Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Export -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
export default {
  data() {
    return {
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      startdateOne: currentDateOne,
      DealCon: ["All Fields", "Contact", "Company"],
      DealLow: ["Highy", "Low", "Medium"],
      DealBarry: ["Barry Cuda", "Tressa Wexler"],
      Dealam: ["$", "€"],
      DealRu: ["Select", "$", "€"],
      DealWon: ["Open", "Lost", "Won"],
      Dealmark: ["Sales", "Marketing", "Calls"],
      DealHighy: ["Select", "Highy", "Low", "Medium"],
      SelectBarry: ["Select", "Barry Cuda", "Tressa Wexler"],
      DealOpen: ["Select", "Open", "Lost", "Won"],
      DealSales: ["Select", "Sales", "Marketing", "Calls"],
      tags: ["James", "Darlee Robertson"],
      tags8: ["Label", "Label"],
      tags7: ["James"],
      tags6: ["Divine dran"],
      tags5: ["James", "Darlee Robertson"],
      tags4: ["Label", "Label"],
      tags3: ["James"],
      tags2: ["Divine dran"],
    };
  },
  components: {
    Vue3TagsInput,
  },
};
</script>
