<template>
  <div class="tab-pane active show" id="activities">
    <div class="view-header">
      <h4>Activities</h4>
      <ul>
        <li>
          <div class="form-sort">
            <i class="las la-sort-amount-up-alt"></i>

            <vue-select
              :options="DealSortDate"
              id="dealsortdate"
              placeholder="Sort By Date"
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="contact-activity">
      <div class="badge-day"><i class="fa-regular fa-calendar-check"></i>15 Feb 2024</div>
      <ul>
        <li class="activity-wrap">
          <span class="activity-icon bg-info">
            <i class="las la-comment-alt"></i>
          </span>
          <div class="activity-info">
            <h6>You sent 1 Message to the contact.</h6>
            <p>10:25 pm</p>
          </div>
        </li>
        <li class="activity-wrap">
          <span class="activity-icon bg-success">
            <i class="las la-phone"></i>
          </span>
          <div class="activity-info">
            <h6>
              Denwar responded to your appointment schedule question by call at 09:30pm.
            </h6>
            <p>09:25 pm</p>
          </div>
        </li>
        <li class="activity-wrap">
          <span class="activity-icon bg-warning">
            <i class="las la-file-alt"></i>
          </span>
          <div class="activity-info">
            <h6>Notes added by Antony</h6>
            <p>
              Please accept my apologies for the inconvenience caused. It would be much
              appreciated if it's possible to reschedule to 6:00 PM, or any other day that
              week.
            </p>
            <p>10.00 pm</p>
          </div>
        </li>
      </ul>
      <div class="badge-day"><i class="fa-regular fa-calendar-check"></i>15 Feb 2024</div>
      <ul>
        <li class="activity-wrap">
          <span class="activity-icon bg-pending">
            <i class="las la-user-tie"></i>
          </span>
          <div class="activity-info">
            <h6>
              Meeting With
              <span class="avatar-xs"
                ><img src="@/assets/img/avatar/avatar-19.jpg" alt="img"
              /></span>
              Abraham
            </h6>
            <p>Schedueled on 05:00 pm</p>
          </div>
        </li>
        <li class="activity-wrap">
          <span class="activity-icon bg-success">
            <i class="las la-phone"></i>
          </span>
          <div class="activity-info">
            <h6>Drain responded to your appointment schedule question.</h6>
            <p>09:25 pm</p>
          </div>
        </li>
      </ul>
      <div class="badge-day">
        <i class="fa-regular fa-calendar-check"></i>Upcoming Activity
      </div>
      <ul>
        <li class="activity-wrap">
          <span class="activity-icon bg-pending">
            <i class="las la-user-tie"></i>
          </span>
          <div class="activity-info">
            <h6>Product Meeting</h6>
            <p>
              A product team meeting is a gathering of the cross-functional product team —
              ideally including team members from product, engineering, marketing, and
              customer support.
            </p>
            <p>25 Jul 2023, 05:00 pm</p>
            <div class="upcoming-info">
              <div class="row">
                <div class="col-sm-4">
                  <p>Reminder</p>
                  <div class="dropdown">
                    <a
                      href="javascript:void(0);"
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="las la-clock me-1"></i>Reminder<i
                        class="las la-angle-down ms-1"
                      ></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="javascript:void(0);">Remainder</a>
                      <a class="dropdown-item" href="javascript:void(0);">1 hr</a>
                      <a class="dropdown-item" href="javascript:void(0);">10 hr</a>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <p>Task Priority</p>
                  <div class="dropdown">
                    <a
                      href="javascript:void(0);"
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fa-solid fa-circle me-1 text-danger circle"></i>High<i
                        class="las la-angle-down ms-1"
                      ></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><i class="fa-solid fa-circle me-1 text-danger circle"></i>High</a
                      >
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><i class="fa-solid fa-circle me-1 text-success circle"></i>Low</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <p>Assigned to</p>
                  <div class="dropdown">
                    <a
                      href="javascript:void(0);"
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><img
                        src="@/assets/img/avatar/avatar-19.jpg"
                        alt="img"
                        class="me-1 avatar-xs" />John<i class="las la-angle-down ms-1"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><img
                          src="@/assets/img/avatar/avatar-19.jpg"
                          alt="img"
                          class="me-1 avatar-xs"
                        />John</a
                      >
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><img
                          src="@/assets/img/avatar/avatar-19.jpg"
                          alt="img"
                          class="me-1 avatar-xs"
                        />Peter</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      DealSortDate: ["Sort By Date", "Ascending", "Descending"],
    };
  },
};
</script>
