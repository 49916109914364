const analytics = {
  anacharts: {
    chart: {
      type: "bar",
      height: 150,
    },
    plotOptions: {
      bar: {
        // borderRadius: 10,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#77D882"],
    xaxis: {
      categories: ["Conversation", "Follow Up", "Inpipeline"],
    },
  },
  series: [
    {
      data: [400, 430, 448],
    },
  ],
};

const analyticsOne = {
  anachartsOne: {
    chart: {
      type: "bar",
      height: 150,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#77D882"],
    xaxis: {
      categories: ["Conversation", "Follow Up", "Inpipeline"],
    },
  },
  series: [
    {
      data: [400, 122, 250],
    },
  ],
};

const analyticsTwo = {
  anachartsTwo: {
    chart: {
      type: "bar",
      height: 150,
    },

    plotOptions: {
      bar: {
        // borderRadius: 50,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    borderRadius: 50,
    colors: ["#F96C85"],
    xaxis: {
      categories: ["Conversation", "Follow Up", "Inpipeline"],
    },
  },
  series: [
    {
      data: [400, 430, 448],
    },
  ],
};

const dealsChartOne = {
  chart: {
    type: "bar",
    height: 250,
    plotOptions: {
      bar: {
        // borderRadius: 50,
        borderRadiusApplication: "around",
      },
    },
    colors: ["#C8B2F4"],
    xaxis: {
      type: "category",
      group: {
        style: {
          fontSize: "7px",
          fontWeight: 700,
        },
        groups: [
          { title: "2019", cols: 4 },
          { title: "2020", cols: 4 },
        ],
      },
    },
  },
  series: [
    {
      name: "sales",
      colors: ["#FFC38F"],
      data: [
        {
          x: "Inpipeline",
          y: 400,
        },
        {
          x: "Follow Up",
          y: 30,
        },
        {
          x: "Schedule",
          y: 248,
        },
        {
          x: "Conversation",
          y: 470,
        },
        {
          x: "Won",
          y: 470,
        },
        {
          x: "Lost",
          y: 180,
        },
      ],
    },
  ],
};

const analyticsFour = {
  anachartsFour: {
    chart: {
      type: "bar",
      height: 150,
    },

    plotOptions: {
      bar: {
        // borderRadius: 50,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    borderRadius: 50,
    colors: ["#F96C85"],
    xaxis: {
      categories: ["Conversation", "Follow Up", "Inpipeline"],
    },
  },
  series: [
    {
      data: [400, 220, 448],
    },
  ],
};

const dealsChart = {
  chart: {
    type: "bar",
    height: 250,
    plotOptions: {
      bar: {
        // borderRadius: 50,
        borderRadiusApplication: "around",
      },
    },
    colors: ["#FFC38F"],
    xaxis: {
      type: "category",
      group: {
        style: {
          fontSize: "7px",
          fontWeight: 700,
        },
        groups: [
          { title: "2019", cols: 4 },
          { title: "2020", cols: 4 },
        ],
      },
    },
  },
  series: [
    {
      name: "sales",
      colors: ["#FFC38F"],
      data: [
        {
          x: "Inpipeline",
          y: 400,
        },
        {
          x: "Follow Up",
          y: 30,
        },
        {
          x: "Schedule",
          y: 248,
        },
        {
          x: "Conversation",
          y: 470,
        },
        {
          x: "Won",
          y: 470,
        },
        {
          x: "Lost",
          y: 180,
        },
      ],
    },
  ],
};

export { analytics, analyticsOne, analyticsTwo, dealsChartOne, analyticsFour,dealsChart };
