<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Deals</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Deals</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="collapse-header"
                  @click="toggleFullscreen"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div>
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_deals"
                ><i class="la la-plus-circle"></i> Add Deals</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="filter-filelds"
        :style="{ display: filter ? 'block' : 'none' }"
        id="filter_inputs"
      >
        <div class="row filter-row">
          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientNameFocused }"
            >
              <input
                type="text"
                class="form-control floating"
                v-model="clientName"
                @focus="clientNameFocused = true"
                @blur="clientNameFocused = clientName !== ''"
              />
              <label class="focus-label">Deal Name</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus focused select-focus">
              <input
                type="text"
                class="form-control date-range bookingrange"
                ref="dateRangeInput"
              />
              <label class="focus-label">From - To Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdate"
                  placeholder="From"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Expected Closed Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="KanbanPip" id="kanbanpip" placeholder="--Select--" />

              <label class="focus-label">Stage</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="KanbanIn" id="kanbanin" placeholder="--Select--" />

              <label class="focus-label">Location</label>
            </div>
          </div>
          <div class="col-xl-2">
            <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
          </div>
        </div>
      </div>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section">
        <ul>
          <li>
            <div class="view-icons">
              <router-link to="/crm/deals" class="list-view btn btn-link"
                ><i class="las la-list"></i
              ></router-link>
              <router-link to="/crm/deals-kanban" class="grid-view btn btn-link active"
                ><i class="las la-th"></i
              ></router-link>
            </div>
          </li>
          <li>
            <div class="form-sort">
              <i class="las la-sort-amount-up-alt"></i>
              <vue-select
                :options="MarketKanban"
                id="marketkanban"
                placeholder="Marketing Pipeline"
              />
            </div>
          </li>
          <li>
            <div class="form-sorts dropdown" ref="formSorts">
              <a
                href="javascript:void(0);"
                @click="toggleFilter"
                class="dropdown-toggle"
                id="table-filter"
                ><i class="las la-filter me-2"></i>Filter</a
              >
              <div class="filter-dropdown-menu">
                <div class="filter-set-view">
                  <div class="filter-set-head">
                    <h4>Filter</h4>
                  </div>
                  <div class="accordion" id="accordionExample">
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          >Rating<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse show"
                        id="collapseOne"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <span>5.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <span>4.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>3.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>2.0</span>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star filled"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>1.0</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          >Owner<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse"
                        id="collapseTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Hendry</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Guillory</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Jami</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Theresa</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Espinosa</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-set-content">
                      <div class="filter-set-content-head">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          >Tags<i class="las la-angle-right"></i
                        ></a>
                      </div>
                      <div
                        class="filter-set-contents accordion-collapse collapse"
                        id="collapseThree"
                        data-bs-parent="#accordionExample"
                      >
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Promotion</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Rated</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Rejected</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Collab</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Calls</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="filter-reset-btns">
                    <a href="javascript:void(0);" class="btn btn-light">Reset</a>
                    <a href="javascript:void(0);" class="btn btn-primary">Filter</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="search-set">
              <div class="search-input">
                <a href="javascript:void(0);" class="btn btn-searchset"><i class="las la-search"></i></a>
                <div class="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="Search"
                  /></label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <deals-board></deals-board>
    </div>
  </div>
  <deals-modal></deals-modal>
</template>
<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      KanbanPip: ["--Select--", "Conversation", "Pipeline", "Won", "Lost", "Follow Up"],
      KanbanIn: ["--Select--", "Germany", "USA", "Canda", "India", "China"],
      MarketKanban: ["Marketing Pipeline", "Sales Pipeline", "Calls Pipeline"],
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      filter: false,
      clientNameFocused: false,
      clientName: "",
    };
  },
  setup() {
    const dateRangeInput = ref(null);
    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        function booking_range(start, end) {
          // Move the function declaration to the root level of the function body
          function formatDate(date) {
            return date.format("M/D/YYYY");
          }

          return formatDate(start) + " - " + formatDate(end);
        }

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });
    return {
      dateRangeInput,
    };
  },
  methods: {
    toggleFullscreen() {
      const elem = document.documentElement;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    toggleFilter(event) {
      const formSorts = this.$refs.formSorts;

      if (formSorts.classList.contains("table-filter-show")) {
        formSorts.classList.remove("table-filter-show");
      } else {
        formSorts.classList.add("table-filter-show");
      }

      event.stopPropagation();
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
};
</script>
