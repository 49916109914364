const workingChart = {
  chart: {
    type: "bar",
    height: 210,
    stacked: true,
    zoom: {
      enabled: true,
    },
    colors: ["#FC133D", "#55CE63"],
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            // offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      min: -200,
      max: 300,
      tickAmount: 5,
    },
    xaxis: {
      categories: [" S ", "M", "T", "W", "T", "F", "S"],
    },
    legend: { show: false },
    fill: {
      opacity: 1,
    },
  },
  series: [
    {
      name: "Break",
      data: [-50, -120, -80, -180, -80, -70, -100],
    },
    {
      name: "Hours",
      data: [200, 250, 200, 290, 220, 300, 250],
    },
  ],
};

export { workingChart }