import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useUserStore = defineStore('user', {
    state: () => ({
        userData : {
            id : null,
            email : null,
            name : null
        }
    }),

    actions: {
        getUserData(payload = null) {
            axiosInstance.defaults.headers.common["Authorization"] =
                `Bearer ${window.localStorage.getItem("token")}` || "";
            return axiosInstance
                .get(urlList["userData"] )
                .then((res) => {
                    this.userData = res.data.data.user;
                    return res;
            })
        }
    },
    getters: {},
});
