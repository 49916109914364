<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">

    <!-- Page Content -->
    <div class="content container-fluid pb-0">

      <!-- Leave Alert -->
      <div class="row">
        <div class="col-md-12">
          <div class="employee-alert-box">
            <div class="alert alert-outline-success alert-dismissible fade show">
              <div class="employee-alert-request">
                <i class="far fa-circle-question"></i>
                Your Leave Request on <span>“24th April 2024”</span> has been Approved!!!
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><i
                  class="fas fa-xmark"></i></button>
            </div>
          </div>
        </div>
      </div>
      <!-- /Leave Alert -->

      <div class="row">
        <div class="col-xxl-8 col-lg-12 col-md-12">
          <div class="row">

            <!-- Employee Details -->
            <div class="col-lg-6 col-md-12">
              <div class="card employee-welcome-card flex-fill">
                <div class="card-body">
                  <div class="welcome-info">
                    <div class="welcome-content">
                      <h4>Welcome Back, Darlee</h4>
                      <p>You have <span>4 meetings</span> today,</p>
                    </div>
                    <div class="welcome-img">
                      <img src="@/assets/img/avatar/avatar-19.jpg" class="img-fluid" alt="User">
                    </div>
                  </div>
                  <div class="welcome-btn">
                    <router-link to="/profile/employee-profile" class="btn">View Profile</router-link>
                  </div>
                </div>
              </div>
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4>Statistics</h4>
                    <div class="dropdown statistic-dropdown">
                      <a class="dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);">
                        Today
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a href="javascript:void(0);" class="dropdown-item">
                          Week
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          Month
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          Year
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="clock-in-info">
                    <div class="clock-in-content">
                      <p>Work Time</p>
                      <h4>6 Hrs : 54 Min</h4>
                    </div>
                    <div class="clock-in-btn">
                      <a href="javascript:void(0);" class="btn btn-primary">
                        <img src="@/assets/img/icons/clock-in.svg" alt="Icon"> Clock-In
                      </a>
                    </div>
                  </div>
                  <div class="clock-in-list">
                    <ul class="nav">
                      <li>
                        <p>Remaining</p>
                        <h6>2 Hrs 36 Min</h6>
                      </li>
                      <li>
                        <p>Overtime</p>
                        <h6>0 Hrs 00 Min</h6>
                      </li>
                      <li>
                        <p>Break</p>
                        <h6>1 Hrs 20 Min</h6>
                      </li>
                    </ul>
                  </div>
                  <div class="view-attendance">
                    <router-link to="/employee/attendance">
                      View Attendance <i class="fe fe-arrow-right-circle"></i>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="card info-card flex-fill">
                <div class="card-body">
                  <h4>Upcoming Holidays</h4>
                  <div class="holiday-details">
                    <div class="holiday-calendar">
                      <div class="holiday-calendar-icon">
                        <img src="@/assets/img/icons/holiday-calendar.svg" alt="Icon">
                      </div>
                      <div class="holiday-calendar-content">
                        <h6>Ramzan</h6>
                        <p>Mon 20 May 2024</p>
                      </div>
                    </div>
                    <div class="holiday-btn">
                      <router-link to="/employee/holidays" class="btn">View All</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Employee Details -->

            <!-- Attendance & Leaves -->
            <div class="col-lg-6 col-md-12">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4>Attendance & Leaves</h4>
                    <div class="dropdown statistic-dropdown">
                      <a class="dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);">
                        2024
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a href="javascript:void(0);" class="dropdown-item">
                          2025
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          2026
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          2027
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="attendance-list">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="attendance-details">
                          <h4 class="text-primary">9</h4>
                          <p>Total Leaves</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="attendance-details">
                          <h4 class="text-pink">5.5</h4>
                          <p>Leaves Taken</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="attendance-details">
                          <h4 class="text-success">04</h4>
                          <p>Leaves Absent</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="attendance-details">
                          <h4 class="text-purple">0</h4>
                          <p>Pending Approval</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="attendance-details">
                          <h4 class="text-info">214</h4>
                          <p>Working Days</p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="attendance-details">
                          <h4 class="text-danger">2</h4>
                          <p>Loss of Pay</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="view-attendance">
                    <router-link to="/employee/leaves-employee">
                      Apply Leave <i class="fe fe-arrow-right-circle"></i>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4>Working hours</h4>
                    <div class="dropdown statistic-dropdown">
                      <a class="dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);">
                        This Week
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a href="javascript:void(0);" class="dropdown-item">
                          Last Week
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          This Month
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item">
                          Last 30 Days
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="working-hour-info">
                    <div id="working_chart"></div>
                    <apexchart type="bar" height="270" :options="workingChart.chart" :series="workingChart.series">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Attendance & Leaves -->

          </div>
        </div>

        <!-- Employee Notifications -->
        <div class="col-xxl-4 col-lg-12 col-md-12 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <div class="statistic-header">
                <h4>Important</h4>
                <div class="important-notification">
                  <router-link to="/crm/activities">
                    View All <i class="fe fe-arrow-right-circle"></i>
                  </router-link>
                </div>
              </div>
              <div class="notification-tab">
                <ul class="nav nav-tabs">
                  <li>
                    <a href="javascript:void(0);" class="active" data-bs-toggle="tab" data-bs-target="#notification_tab">
                      <i class="la la-bell"></i> Notifications
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" data-bs-toggle="tab" data-bs-target="#schedule_tab">
                      <i class="la la-list-alt"></i> Schedules
                    </a>
                  </li>
                </ul>
                <div class="tab-content">

                  <div class="tab-pane active" id="notification_tab">
                    <div class="employee-noti-content">
                      <ul class="employee-notification-list">
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-danger rounded-circle">HR</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Your leave request has been
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>02:10 PM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-info rounded-circle">ER</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                You’re enrolled in upcom....
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>12:40 PM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-warning rounded-circle">SM</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Your annual compliance trai
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>11:00 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="rounded-circle">
                                <img src="@/assets/img/avatar/avatar-1.jpg" class="img-fluid rounded-circle" alt="User">
                              </span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Jessica has requested feedba
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>10:30 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-warning rounded-circle">DT</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Gentle remainder about train
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>09:00 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-danger rounded-circle">AU</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Our HR system will be down
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>11:50 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="schedule_tab">
                    <div class="employee-noti-content">
                      <ul class="employee-notification-list">
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="rounded-circle">
                                <img src="@/assets/img/avatar/avatar-2.jpg" class="img-fluid rounded-circle" alt="User">
                              </span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                John has requested feedba
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>10:30 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-danger rounded-circle">HR</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Your leave request has been
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>02:10 PM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-info rounded-circle">ER</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                You’re enrolled in upcom....
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>12:40 PM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-warning rounded-circle">SM</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Your annual compliance trai
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>11:00 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-warning rounded-circle">DT</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Gentle remainder about train
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>09:00 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                        <li class="employee-notification-grid">
                          <div class="employee-notification-icon">
                            <router-link to="/crm/activities">
                              <span class="badge-soft-danger rounded-circle">AU</span>
                            </router-link>
                          </div>
                          <div class="employee-notification-content">
                            <h6>
                              <router-link to="/crm/activities">
                                Our HR system will be down
                              </router-link>
                            </h6>
                            <ul class="nav">
                              <li>11:50 AM</li>
                              <li>21 Apr 2024</li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Employee Notifications -->

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-sm-8">
                  <div class="statistic-header">
                    <h4>On Going Projects</h4>
                  </div>
                </div>
                <div class="col-sm-4 text-sm-end">
                  <div class="owl-nav project-nav nav-control"></div>
                </div>
              </div>
              <div class="project-slider owl-carousel">

                <!-- Project Grid -->
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                  <Slide v-for="item in ProjectGrid" :key="item.id">
                    <div class="project-grid text-start">
                      <div class="project-top">
                        <h6>
                          <router-link to="/project/project-view">{{item.Badge}}</router-link>
                        </h6>
                        <h5>
                          <router-link to="/project/project-view">{{item.Title}}</router-link>
                        </h5>
                        <p>{{item.Content}}</p>
                      </div>
                      <div class="project-middle">
                        <ul class="nav">
                          <li>
                            <div class="project-tasks">
                              <h4>{{item.Tasks}}</h4>
                              <p>Total Tasks</p>
                            </div>
                          </li>
                          <li>
                            <div class="project-tasks">
                              <h4>{{item.Completed}}</h4>
                              <p>Total Completed</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="project-bottom">
                        <div class="project-leader">
                          <ul class="nav">
                            <li>Project Leader :</li>
                            <li>
                              <router-link to="/project/project-view" data-bs-toggle="tooltip" aria-label="Catherine Manseau"
                                data-bs-original-title="Catherine Manseau">
                                <img :src="require(`@/assets/img/avatar/${item.Avatar}`)" alt="User">
                              </router-link>
                            </li>
                          </ul>
                        </div>
                        <div class="project-leader">
                          <ul class="nav">
                            <li>Members :</li>
                            <li>
                              <router-link to="/project/project-view" data-bs-toggle="tooltip" aria-label="Richard Miles"
                                data-bs-original-title="Richard Miles">
                                <img src="@/assets/img/avatar/avatar-21.jpg" alt="User">
                              </router-link>
                            </li>
                            <li>
                              <router-link to="/project/project-view" data-bs-toggle="tooltip" aria-label="Jeffery Lalor"
                                data-bs-original-title="Jeffery Lalor">
                                <img src="@/assets/img/avatar/avatar-16.jpg" alt="User">
                              </router-link>
                            </li>
                            <li>
                              <router-link to="/project/project-view" data-bs-toggle="tooltip" aria-label="Lesley Grauer"
                                data-bs-original-title="Lesley Grauer">
                                <img src="@/assets/img/avatar/avatar-20.jpg" alt="User">
                              </router-link>
                            </li>
                            <li>
                              <router-link to="/project/project-view" data-bs-toggle="tooltip" aria-label="Loren Gatlin"
                                data-bs-original-title="Loren Gatlin">
                                <img src="@/assets/img/avatar/avatar-1.jpg" alt="User">
                              </router-link>
                            </li>
                            <li>
                              <router-link to="/project/project-view" data-bs-toggle="tooltip" aria-label="Tarah Shropshire"
                                data-bs-original-title="Tarah Shropshire">
                                <img src="@/assets/img/avatar/avatar-23.jpg" alt="User">
                              </router-link>
                            </li>
                            <li>
                              <a href="javascript:void(0);" class="more-team-members">{{ item.Member }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Slide>
                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>
                <!-- /Project Grid -->

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <!-- Employee Month -->
        <div class="col-xl-6 col-md-12 d-flex">
          <div class="card employee-month-card flex-fill">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-9 col-md-12">
                  <div class="employee-month-details">
                    <h4>Employee of the month</h4>
                    <p>We are really proud of the difference you have made which gives everybody the reason to applaud &
                      appreciate</p>
                  </div>
                  <div class="employee-month-content">
                    <h6>Congrats, Hanna</h6>
                    <p>UI/UX Team Lead</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="employee-month-img">
                    <img src="@/assets/img/employee-img.png" class="img-fluid" alt="User">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Employee Month -->

        <!-- Company Policy -->
        <div class="col-xl-6 col-md-12 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-sm-8">
                  <div class="statistic-header">
                    <h4>Company Policy</h4>
                  </div>
                </div>
                <div class="col-sm-4 text-sm-end">
                  <div class="owl-nav company-nav nav-control"></div>
                </div>
              </div>
              <company-policy></company-policy>
            </div>
          </div>
        </div>
        <!-- /Company Policy -->

      </div>
    </div>
    <!-- /Page Content -->

  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import { workingChart } from "./data";

import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import ProjectGrid from '@/assets/json/project-grid.json'

export default {
  data() {
    return {
      workingChart: workingChart,
      ProjectGrid: ProjectGrid,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation
  },
}
</script>