<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Pipeline</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Pipeline</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  @click="toggleFullscreen"
                  id="collapse-header"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div>
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_pipeline"
                ><i class="la la-plus-circle"></i> Add Pipeline</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="filter-filelds"
        :style="{ display: filter ? 'block' : 'none' }"
        id="filter_inputs"
      >
        <div class="row filter-row custom-filter-row">
          <div class="custom-col">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientIdFocused }"
            >
              <input
                type="text"
                class="form-control floating"
                v-model="clientId"
                @focus="clientIdFocused = true"
                @blur="clientIdFocused = clientId !== ''"
              />
              <label class="focus-label">Pipeline Name</label>
            </div>
          </div>

          <div class="custom-col">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientNameFocused }"
            >
              <input
                type="email"
                class="form-control floating"
                v-model="clientName"
                @focus="clientNameFocused = true"
                @blur="clientNameFocused = clientName !== ''"
              />
              <label class="focus-label">Pipeline Name</label>
            </div>
          </div>
          <div class="custom-col">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdate"
                  placeholder="Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Created Date</label>
            </div>
          </div>

          <div class="custom-col">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select :options="WinSelect" id="winselect" placeholder="--Select--" />

              <label class="focus-label">Stages</label>
            </div>
          </div>
          <div class="custom-col">
            <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
          </div>
        </div>
      </div>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section mb-3">
        <ul>
          <li>
            <div class="form-sort">
              <i class="las la-sort-alpha-up-alt"></i>
              <vue-select
                :options="SortSele"
                id="sorysele"
                placeholder="Sort By Alphabet"
              />
            </div>
          </li>
          <li>
            <div class="search-set">
              <div class="search-input">
                <a href="javascript:void(0);" class="btn btn-searchset"><i class="las la-search"></i></a>
                <div class="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="Search"
                  /></label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive contact-table-list">
            <a-table class="stripped table-hover" :columns="columns" :data-source="data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Stages'">
                  <div class="pipeline-progress d-flex align-items-center">
                    <div class="progress">
                      <div :class="record.Class" role="progressbar"></div>
                    </div>
                    <span>{{ record.Stages }}</span>
                  </div>
                </template>
                <template v-else-if="column.key === 'Status'">
                  <div class="dropdown action-label">
                    <a href="javascript:void(0);" :class="record.StatusClass"> {{ record.Status }} </a>
                </div>
                </template>
                <template v-else-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_pipeline"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_pipeline"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                        <router-link class="dropdown-item" to="/crm/contact-details"
                          ><i class="fa-regular fa-eye"></i> Preview</router-link
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pipeline-modal></pipeline-modal>
</template>
<script>
const columns = [
  {
    title: "Pipeline Name",
    dataIndex: "PipelineName",
    sorter: {
      compare: (a, b) => {
        a = a.PipelineName.toLowerCase();
        b = b.PipelineName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Deal Value",
    dataIndex: "TotalDealValue",
    sorter: {
      compare: (a, b) => {
        a = a.TotalDealValue.toLowerCase();
        b = b.TotalDealValue.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "No of Deals",
    dataIndex: "NoofDeals",
    sorter: {
      compare: (a, b) => {
        a = a.NoofDeals.toLowerCase();
        b = b.NoofDeals.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Stages",
    dataIndex: "Stages",
    key: "Stages",
    sorter: {
      compare: (a, b) => {
        a = a.Stages.toLowerCase();
        b = b.Stages.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
    sorter: {
      compare: (a, b) => {
        a = a.CreatedDate.toLowerCase();
        b = b.CreatedDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    sorter: true,
  },
];
const data = [
  {
    PipelineName: "Calls",
    TotalDealValue: "$04,14,400",
    NoofDeals: "654",
    Class: "progress-bar progress-bar-violet",
    Stages: "In Pipeline",
    CreatedDate: "05 Mar 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Chats",
    TotalDealValue: "$11,14,400",
    NoofDeals: "787",
    Class: "progress-bar progress-bar-success",
    Stages: "Win",
    CreatedDate: "08 Apr 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Collabrative",
    TotalDealValue: "$78,11,800",
    NoofDeals: "315",
    Class: "progress-bar progress-bar-warning",
    Stages: "Folllow Up",
    CreatedDate: "12 May 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Differentiate",
    TotalDealValue: "$09,05,947",
    NoofDeals: "478",
    Class: "progress-bar progress-bar-pink",
    Stages: "Schedule Service",
    CreatedDate: "26 May 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-danger",
    Status: "Inactive",
  },
  {
    PipelineName: "Email",
    TotalDealValue: "$09,14,400",
    NoofDeals: "545",
    Class: "progress-bar progress-bar-success",
    Stages: "Win",
    CreatedDate: "17 Mar 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Identify",
    TotalDealValue: "$72,14,078",
    NoofDeals: "128",
    Class: "progress-bar progress-bar-danger",
    Stages: "Lost",
    CreatedDate: "28 June 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Interact",
    TotalDealValue: "$04,51,000",
    NoofDeals: "664",
    Class: "progress-bar progress-bar-success",
    Stages: "Win",
    CreatedDate: "13 June 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },

  {
    PipelineName: "Marketing",
    TotalDealValue: "$03,12,500",
    NoofDeals: "447",
    Class: "progress-bar progress-bar-success",
    Stages: "Win",
    CreatedDate: "24 Feb 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Operational",
    TotalDealValue: "$16,11,400",
    NoofDeals: "142",
    Class: "progress-bar progress-bar-info",
    Stages: "Conversation",
    CreatedDate: "20 Apr 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
  {
    PipelineName: "Sales",
    TotalDealValue: "$04,51,000",
    NoofDeals: "315",
    Class: "progress-bar progress-bar-success",
    Stages: "Win",
    CreatedDate: "10 Feb 2024",
    StatusClass: "btn btn-white btn-sm badge-outline-success",
    Status: "Active",
  },
];
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      filter: false,
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      clientName: "",
      clientId: "",
      clientIdFocused: false,
      clientNameFocused: false,
      SortSele: [
        "Sort By Alphabet",
        "Ascending",
        "Descending",
        "Recently Viewed",
        "Recently Added",
      ],
      WinSelect: ["Win", "In Pipeline", "Conversation", "Follow Up", "Lost"],
      data,
      columns,
    };
  },
  methods: {
    toggleFullscreen() {
      const elem = document.documentElement;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
  },
};
</script>
