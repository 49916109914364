<template>
  <div class="col-xl-3">
    <div class="card contact-sidebar">
      <h5>Lead Information</h5>
      <ul class="other-info">
        <li>
          <span class="other-title">Date Created</span><span>10 Jan 2024, 10:00 am</span>
        </li>
        <li><span class="other-title">Value</span><span>$25,11,145</span></li>
        <li>
          <span class="other-title">Due Date</span><span>20 Jan 2024, 10:00 am</span>
        </li>
        <li><span class="other-title">Follow Up</span><span>20 Jan 2024</span></li>
        <li><span class="other-title">Source</span><span>Google</span></li>
      </ul>
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h5>Owner</h5>
      </div>
      <ul class="deals-info">
        <li>
          <span>
            <img src="@/assets/img/avatar/avatar-21.jpg" alt="Image" />
          </span>
          <div>
            <p>Vaughan</p>
          </div>
        </li>
      </ul>
      <h5>Tags</h5>
      <ul class="tag-info">
        <li>
          <a href="javascript:void(0);" class="bg-success-light">Collab</a>
        </li>
        <li>
          <a href="javascript:void(0);" class="bg-warning-light">Rated</a>
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h5>Priority</h5>
      </div>
      <ul class="priority-info">
        <li>
          <div class="dropdown">
            <a
              href="javascript:void(0);"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><span><i class="fa-solid fa-circle me-1 text-danger circle"></i>High</span
              ><i class="las la-angle-down ms-1"></i
            ></a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="javascript:void(0);">
                <span
                  ><i class="fa-solid fa-circle me-1 text-danger circle"></i>High</span
                >
              </a>
              <a class="dropdown-item" href="javascript:void(0);">
                <span
                  ><i class="fa-solid fa-circle me-1 text-success circle"></i>Low</span
                >
              </a>
            </div>
          </div>
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h5>Projects</h5>
      </div>
      <ul class="projects-info">
        <li>
          <a href="javascript:void(0);" class="badge badge-light">Devops Design</a>
        </li>
        <li>
          <a href="javascript:void(0);" class="badge badge-light">Margrate Design</a>
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h5>Contacts</h5>
        <a
          href="javascript:void(0);"
          class="com-add"
          data-bs-toggle="modal"
          data-bs-target="#add_contact"
          ><i class="las la-plus-circle me-1"></i>Add New</a
        >
      </div>
      <ul class="deals-info">
        <li>
          <span>
            <img src="@/assets/img/avatar/avatar-1.jpg" alt="Image" />
          </span>
          <div>
            <p>Jessica</p>
          </div>
        </li>
      </ul>
      <ul class="other-info">
        <li>
          <span class="other-title">Last Modified</span><span>10 Jan 2024, 10:00 am</span>
        </li>
        <li>
          <span class="other-title">Modified By</span>
          <span
            ><img
              src="@/assets/img/avatar/avatar-19.jpg"
              class="avatar-xs rounded-circle"
              alt="img"
            />
            Darlee Robertson</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>
