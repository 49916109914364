<template>
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="editDataModal"
    role="dialog"
    ref="editModal"
  >
  <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-border justify-content-between p-0">
          <h5 class="modal-title">Müqaviləni yenilə</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            @click="closeEditModal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="contact-input-set">
            <div class="row">
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"> Müştəri </label>
                  <input
                    v-model="customerName"
                    class="form-control"
                    type="text"
                    disabled
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Şirkət <span class="text-danger">*</span></label
                  >

                  <vue-select
                    v-if="props.companiesOptionsList.length > 0"
                    :options="props.companiesOptionsList"
                    :name="company_id"
                    id="companyEdit"
                    v-model="formData.company_id"
                    :class="{
                      'is-invalid': $v.formData.company_id.$error,
                      'is-valid':
                        isSubmitForm && !$v.formData.company_id.$error,
                    }"
                  />
                  <p v-else>Şirkət əlavə olunmalıdır</p>
                  <template
                    v-if="isSubmitForm && $v.formData.company_id.$error"
                  >
                    <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                  </template>
                </div>
              </div>

              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"> Rekvizit </label>
                  <vue-select
                    v-if="props.requisitesOptionsList.length > 0"
                    :options="props.requisitesOptionsList"
                    :name="requisite_id"
                    id="requisiteEdit"
                    v-model="formData.requisite_id"
                    :class="{
                      'is-valid': isSubmitForm,
                    }"
                  />
                  <p v-else>Rekvizit əlavə olunmalıdır</p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">
                    Müqavilə nömrəsi <span class="text-danger">*</span>
                  </label>

                  <input
                    v-model="formData.code"
                    class="form-control"
                    type="text"
                    name="code"
                    :class="{
                      'is-invalid': $v.formData.code.$error,
                      'is-valid': isSubmitForm && !$v.formData.code.$error,
                    }"
                  />

                  <template v-if="isSubmitForm && $v.formData.code.$error">
                    <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                  </template>
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Limit məbləği <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="formData.amount"
                    class="form-control"
                    type="text"
                    name="amount"
                    :class="{
                      'is-invalid': $v.formData.amount.$error,
                      'is-valid': isSubmitForm && !$v.formData.amount.$error,
                    }"
                  />

                  <template v-if="isSubmitForm && $v.formData.amount.$error">
                    <p
                      class="text-danger mt-1"
                      v-if="
                        isSubmitForm && $v.formData.amount.required.$invalid
                      "
                    >
                      Bu vacib məlumatdır
                    </p>
                    <p
                      class="text-danger mt-1"
                      v-if="
                        isSubmitForm && $v.formData.amount.numeric.$invalid
                      "
                    >
                      Sadəcə rəqəmlərdən ibarət olmalıdır
                    </p>
                  </template>
                </div>
              </div>

              <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Başlama tarixi <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <flat-pickr
                          :config="dateTime"
                          placeholder="il-ay-gün"
                          v-model="formData.start_date"
                          :class="{
                            'is-invalid': $v.formData.start_date.$error,
                            'is-valid': isSubmitForm && !$v.formData.start_date.$error,
                          }"
                          id="startDateEdit" class="form-control">
                      </flat-pickr>
                    </div>
                    <template
                      v-if="isSubmitForm && $v.formData.start_date.$error"
                    >
                      <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                    </template>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Bitmə tarixi <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <flat-pickr
                          :config="dateTime"
                          placeholder="il-ay-gün"
                          v-model="formData.end_date"
                          :class="{
                            'is-invalid': $v.formData.end_date.$error,
                            'is-valid': isSubmitForm && !$v.formData.end_date.$error,
                          }"
                          id="endDateEdit" class="form-control">
                      </flat-pickr>
                      <template
                        v-if="isSubmitForm && $v.formData.end_date.$error"
                      >
                        <p class="text-danger mt-1">Bu vacib məlumatdır</p>
                      </template>
                    </div>
                  </div>
                </div>


              <div class="col-md-6">
                <label class="col-form-label">Fayllar</label>
                <FileUploadCustom v-model="files" :multiple="true" ref="dropzoneUpload"/>
              </div>

              <div class="col-md-6" v-if="formData.files.length > 0">
                  <p class="fw-bold">Əlavə edilmiş müqavilə faylları</p>
                  <div class="list-group">
                      <div
                          class="list-group-item d-flex justify-content-between align-items-center border border-dashed"
                          v-for="(file, index) in formData.files"
                          :key="index"
                      >
                          <span>{{ file.id }}</span>
                          <div class="btn-group">
                              <a
                                  :href="file.url"
                                  target="_blank"
                                  class="btn btn-primary"
                              >
                                  Bax
                              </a>
                              <button
                                  @click="formData.files.splice(index,1)"
                                  class="btn btn-danger"
                              >
                                  Sil
                              </button>
                          </div>
                      </div>
                  </div>
              </div>


              <div class="col-lg-12 text-end form-wizard-button">
                <button class="btn btn-primary" @click="submitForm">
                  Yadda saxla
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits, reactive, watch,onMounted,onBeforeUnmount } from "vue";
import {
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  decimal,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { showErrorToast, showSuccessToast, closeModal } from "@/utils/helper";
import { useRoute } from "vue-router";
import { useContracts } from "@/stores/modules/contracts";
import { useFileUpload } from "@/stores/modules/fileUpload";
import FileUploadCustom from "@/components/file-upload-custom.vue";
import FlatPickr from 'vue-flatpickr-component';
import dayjs from "dayjs";

const props = defineProps({
  id:{
    type: Number,
    default:null
  },
  companiesOptionsList: {
    type: Array,
    required: true,
    default: [],
  },
  requisitesOptionsList: {
    type: Array,
    required: true,
    default: [],
  },
});

const contractsStore = useContracts();
const fileUplaodStore = useFileUpload();
const route = useRoute();

const customerId = route.query.customerId || 0;
const customerName = route.query.customerName || null;

const files = ref([]);
const editModal = ref(null);

const dropzoneUpload = ref(null);

const emit = defineEmits(["formSubmited", "close"]);
const isSubmitForm = ref(false);

const dateTime = ref({
    dateFormat: 'Y-m-d',
    allowInput: true,
    position: 'auto right'
});

const formData = reactive({
  company_id: null,
  customer_id: 0,
  requisite_id: null,
  code: "",
  start_date: "",
  end_date: "",
  amount: null,
  file_ids: [],
  new_file_ids: [],
  files:[]
});

const rules = {
  formData: {
    company_id: { required },
    requisite_id: {},
    code: { required },
    amount: { required, numeric },
    start_date: { required },
    end_date: { required },
  },
};


const $v = useVuelidate(rules, { formData });

const resetValidation = () => {
  isSubmitForm.value = false;
  $v.value.formData.$reset();
};

const resetFormData = () => {
  formData.company_id = null;
  formData.customer_id = 0;
  formData.requisite_id = null;
  formData.code = "";
  formData.start_date = null;
  formData.end_date = null;
  formData.amount = null;
  formData.file_ids = [];
  formData.new_file_ids = [];
  formData.files = [];
  files.value = [];
  dropzoneUpload.value.clearFiles();
};

const closeEditModal = ()=>{
  resetFormData();
}


const submitForm = async () => {
  isSubmitForm.value = true;
  $v.value.formData.$touch();
  if ($v.value.formData.$invalid) {
    return false;
  }

  formData.customer_id = customerId;

  try {
    let uploadFiles = new FormData();

    files.value.forEach((item, index) => {
        uploadFiles.append(`files[${index}]`, item.file);
    });

    if (files.value.length > 0) {
        formData.new_file_ids = await fileUplaodStore.store(uploadFiles);
    }

    formData.file_ids = formData.files?.map(file => file.id) || [];
    
    await contractsStore.update(formData, props.id).then(async (response) => {
      resetFormData();
      showSuccessToast(response.data.message);
      resetValidation();
      emit("formSubmited", formData);
      closeModal("editDataModal");
    });
  } catch (error) {
    console.error(error);
  }
};

const onModalClosed = () => {
  resetFormData();
  emit("editModalClosed");
};

watch(() => props.id, async (newId) => {
  try {
    if (newId) {
      await contractsStore.getDetail(newId);
      formData.code = contractsStore.dataContract?.data?.code;
      formData.amount = contractsStore.dataContract?.data?.amount;
      formData.files = contractsStore.dataContract?.data?.files || [];
      formData.company_id = contractsStore.dataContract?.data?.company.id;
      formData.requisite_id = contractsStore.dataContract?.data?.requisite.id;
      files.value = [];

      if (contractsStore.dataContract?.data?.start_date) {
          formData.start_date = dayjs(contractsStore.dataContract?.data?.start_date).toDate();
        } else {
          formData.start_date = null;
        }

        if (contractsStore.dataContract?.data?.end_date) {
          formData.end_date = dayjs(contractsStore.dataContract?.data?.end_date).toDate();
        } else {
          formData.end_date = null;
        }
    }
  } catch (error) {
    console.log(error)
  }
});


onMounted(() => {
  const modalElement = editModal.value;
  modalElement.addEventListener('hidden.bs.modal', onModalClosed);
});

onBeforeUnmount(() => {
  const modalElement = editModal.value;
  modalElement.removeEventListener('hidden.bs.modal', onModalClosed);
});
</script>

  