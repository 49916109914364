<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="page-title">Activities</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/dashboard/admin-dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Activities</li>
            </ul>
          </div>
          <div class="col-md-8 float-end ms-auto">
            <div class="d-flex title-head">
              <div class="view-icons">
                <a href="javascript:void(0);" class="grid-view btn btn-link"
                  ><i class="las la-redo-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  @click="toggleFullscreen"
                  id="collapse-header"
                  ><i class="las la-expand-arrows-alt"></i
                ></a>
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  id="filter_search"
                  v-on:click="filter = !filter"
                  :class="{ setclose: filter }"
                  ><i class="las la-filter"></i
                ></a>
              </div>
              <div class="form-sort">
                <a
                  href="javascript:void(0);"
                  class="list-view btn btn-link"
                  data-bs-toggle="modal"
                  data-bs-target="#export"
                  ><i class="las la-file-export"></i>Export</a
                >
              </div>
              <a
                href="javascript:void(0);"
                class="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_activity"
                ><i class="la la-plus-circle"></i>Add Activity</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="filter-filelds"
        :style="{ display: filter ? 'block' : 'none' }"
        id="filter_inputs"
      >
        <div class="row filter-row">
          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientIdFocused }"
            >
              <input
                type="text"
                class="form-control floating"
                v-model="clientId"
                @focus="clientIdFocused = true"
                @blur="clientIdFocused = clientId !== ''"
              />
              <label class="focus-label">Activity</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div
              class="input-block mb-3 form-focus"
              :class="{ focused: clientNameFocused }"
            >
              <input
                type="email"
                class="form-control floating"
                v-model="clientName"
                @focus="clientNameFocused = true"
                @blur="clientNameFocused = clientName !== ''"
              />
              <label class="focus-label">Owner</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdate"
                  placeholder="Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Due Date</label>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <div class="cal-icon">
                <date-picker
                  v-model="startdateOne"
                  placeholder="Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
              <label class="focus-label">Created at</label>
            </div>
          </div>

          <div class="col-xl-2">
            <div class="input-block mb-3 form-focus select-focus">
              <vue-select
                :options="CrmActivities"
                id="crmactivities"
                placeholder="--Select--"
              />

              <label class="focus-label">Activity Type</label>
            </div>
          </div>
          <div class="col-xl-2">
            <a href="javascript:void(0);" class="btn btn-success w-100"> Search </a>
          </div>
        </div>
      </div>
      <hr />
      <!-- /Search Filter -->
      <div class="filter-section activity-filter-head">
        <div class="all-activity-head">
          <h5>All Activity</h5>
          <ul class="activity-list">
            <li>
              <a href="javascript:void(0);"><i class="la la-phone"></i></a>
            </li>
            <li>
              <a href="javascript:void(0);"><i class="la la-envelope"></i></a>
            </li>
            <li>
              <a href="javascript:void(0);"><i class="la la-tasks"></i></a>
            </li>
            <li>
              <a href="javascript:void(0);"><i class="la la-user-tie"></i></a>
            </li>
          </ul>
        </div>
        <ul>
          <li>
            <div class="form-sort">
              <i class="las la-sort-alpha-up-alt"></i>
              <vue-select
                :options="SortbyCrm"
                id="sortbycrm"
                placeholder="Sort By Alphabet"
              />
            </div>
          </li>
          <li>
            <div class="search-set">
              <div class="search-input">
                <a href="javascript:void(0);" class="btn btn-searchset"><i class="las la-search"></i></a>
                <div class="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="Search"
                  /></label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <a-table class="stripped table-hover" :columns="columns" :data-source="data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'ActivityType'">
                  <span :class="record.Class"
                    ><i class="la la-user-tie"></i>{{ record.ActivityType }}</span
                  >
                </template>
                <template v-else-if="column.key === 'Action'">
                  <div class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0);"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit_activity"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_activity"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                        <router-link class="dropdown-item" to="/crm/contact-details"
                          ><i class="fa-regular fa-eye"></i> Preview</router-link
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <crm-activities-modal></crm-activities-modal>
</template>
<script>
const columns = [
  {
    title: "Title",
    dataIndex: "Title",
    sorter: {
      compare: (a, b) => {
        a = a.Title.toLowerCase();
        b = b.Title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Activity Type",
    dataIndex: "ActivityType",
    key: "ActivityType",
    sorter: {
      compare: (a, b) => {
        a = a.ActivityType.toLowerCase();
        b = b.ActivityType.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due Date",
    dataIndex: "DueDate",
    sorter: {
      compare: (a, b) => {
        a = a.DueDate.toLowerCase();
        b = b.DueDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Owner",
    dataIndex: "Owner",
    sorter: {
      compare: (a, b) => {
        a = a.Owner.toLowerCase();
        b = b.Owner.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created At",
    dataIndex: "CreatedAt",
    sorter: {
      compare: (a, b) => {
        a = a.CreatedAt.toLowerCase();
        b = b.CreatedAt.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    sorter: true,
  },
];
const data = [
  {
    Title: "We scheduled a meeting for next week",
    ActivityType: "Meeting",
    DueDate: "10 Feb 2024, 09:00 am",
    Owner: "Hendry",
    Class: "badge activity-badge badge-bg-violet",
    CreatedAt: "08 Feb 2024, 10:00 am",
  },
  {
    Title: "Had conversation with Fred regarding task",
    ActivityType: "Calls",
    DueDate: "24 Feb 2024, 12:20 pm",
    Owner: "Monty Beer",
    Class: "badge activity-badge badge-bg-success",
    CreatedAt: "21 Feb 2024, 09:00 am",
  },
  {
    Title: "Analysing latest time estimation for new project",
    ActivityType: "Email",
    DueDate: "05 Mar 2024, 10:00 am",
    Owner: "Bradtke",
    Class: "badge activity-badge badge-bg-warning",
    CreatedAt: "03 Mar 2024, 11:00 am",
  },
  {
    Title: "Store and manage contact data",
    ActivityType: "Task",
    DueDate: "17 Mar 2024, 02:00 pm",
    Owner: "Swaniawski",
    Class: "badge activity-badge badge-bg-info",
    CreatedAt: "15 Mar 2024, 12:00 pm",
  },
  {
    Title: "Call John and discuss about project",
    ActivityType: "Calls",
    DueDate: "20 Apr 2024, 03:15 pm",
    Owner: "Itzel",
    Class: "badge activity-badge badge-bg-success",
    CreatedAt: "18 Apr 2024, 01:15 pm",
  },
  {
    Title: "Will have a meeting before project start",
    ActivityType: "Meeting",
    DueDate: "08 Apr 2024, 11:00 am",
    Owner: "Sally",
    Class: "badge activity-badge badge-bg-violet",
    CreatedAt: "05 Apr 2024, 03:20 pm",
  },
  {
    Title: "Built landing pages",
    ActivityType: "Email",
    DueDate: "12 May 2024, 08:30 am",
    Owner: "Danny",
    Class: "badge activity-badge badge-bg-warning",
    CreatedAt: "10 May 2024, 11:30 am",
  },
  {
    Title: "Regarding latest updates in project",
    ActivityType: "Meeting",
    DueDate: "26 May 2024, 04:10 pm",
    Owner: "Lynch",
    Class: "badge activity-badge badge-bg-violet",
    CreatedAt: "23 May 2024, 05:00 pm",
  },
  {
    Title: "Attach final proposal for upcoming project",
    ActivityType: "Task",
    DueDate: "13 June 2024, 10:30 am",
    Owner: "Merwin",
    Class: "badge activity-badge badge-bg-info",
    CreatedAt: "11 June 2024, 04:00 pm",
  },
  {
    Title: "Discussed budget proposal with Edwin",
    ActivityType: "Calls",
    DueDate: "28 June 2024, 05:40 pm",
    Owner: "Andrew",
    Class: "badge activity-badge badge-bg-success",
    CreatedAt: "26 June 2024, 08:50 am",
  },
];
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      filter: false,
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      clientName: "",
      clientId: "",
      clientIdFocused: false,
      clientNameFocused: false,
      SortbyCrm: [
        "Sort By Alphabet",
        "Ascending",
        "Descending",
        "Recently Viewed",
        "Recently Added",
      ],
      CrmActivities: ["--Select--", "Meeting", "Calls", "Email", "Task"],
      columns,
      data,
    };
  },
  methods: {
    toggleFullscreen() {
      const elem = document.documentElement;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
  },
};
</script>
