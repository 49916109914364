import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import urlList from '../../utils/apis.json';

export const useInvoicesStore = defineStore('invoices', {
    state: () => ({
        dataList: null,
        dataInvoice: null
    }),

    actions: {
        getList(payload = null) {
            axiosInstance.defaults.headers.common['Authorization'] =
                `Bearer ${window.localStorage.getItem('token')}` || '';
            return axiosInstance
                .get(urlList['invoices'], { ...payload })
                .then((res) => {
                    this.dataList = res;
                    return res;
                });
        },
        async getDetail(id) {
            try {
                await axiosInstance.get(`${urlList['invoices']}/${id}`).then((res) => {
                    this.dataInvoice = res.data;
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async getXml(id) {
            try {
                return await axiosInstance.get(`${urlList['getInvoiceXml']}/${id}`, { responseType: 'blob' }).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async add(formData) {
            try {
                return await axiosInstance.post(urlList['invoices'], formData);
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async update(formData, id) {
            try {
                return axiosInstance.put(`${urlList['invoices']}/${id}`, formData).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async setTaxNumber(formData, id) {
            try {
                return axiosInstance.put(`${urlList['invoiceSetTaxNumber']}/${id}`, formData).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async submitPayForm(payFormData, id) {
            try {
                return await axiosInstance.post(`${urlList['invoices']}/pay/${id}`, payFormData);
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async updateStatus(statusData, id) {
            try {
                return axiosInstance.put(`${urlList['invoices']}/update/${id}`, statusData).then((res) => {
                    return res;
                });
            } catch (error) {
                console.error('Error :', error);
                throw error;
            }
        },
        async delete(id) {
            try {
                const response = await axiosInstance.delete(`${urlList['invoices']}/${id}`);
                return response;
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        }
    },
    getters: {}
});
