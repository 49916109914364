<template>
  <!-- Profile Info Tab -->
  <div id="emp_profile" class="pro-overview tab-pane fade show active">
    <div class="row">
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Personal Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <ul class="personal-info">
              <li>
                <div class="title">Passport No.</div>
                <div class="text">9876543210</div>
              </li>
              <li>
                <div class="title">Passport Exp Date.</div>
                <div class="text">9876543210</div>
              </li>
              <li>
                <div class="title">Tel</div>
                <div class="text"><a href="javascript:;">9876543210</a></div>
              </li>
              <li>
                <div class="title">Nationality</div>
                <div class="text">Indian</div>
              </li>
              <li>
                <div class="title">Religion</div>
                <div class="text">Christian</div>
              </li>
              <li>
                <div class="title">Marital status</div>
                <div class="text">Married</div>
              </li>
              <li>
                <div class="title">Employment of spouse</div>
                <div class="text">No</div>
              </li>
              <li>
                <div class="title">No. of children</div>
                <div class="text">2</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Emergency Contact
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#emergency_contact_modal"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <h5 class="section-title">Primary</h5>
            <ul class="personal-info">
              <li>
                <div class="title">Name</div>
                <div class="text">John Doe</div>
              </li>
              <li>
                <div class="title">Relationship</div>
                <div class="text">Father</div>
              </li>
              <li>
                <div class="title">Phone</div>
                <div class="text">9876543210, 9876543210</div>
              </li>
            </ul>
            <hr />
            <h5 class="section-title">Secondary</h5>
            <ul class="personal-info">
              <li>
                <div class="title">Name</div>
                <div class="text">Karen Wills</div>
              </li>
              <li>
                <div class="title">Relationship</div>
                <div class="text">Brother</div>
              </li>
              <li>
                <div class="title">Phone</div>
                <div class="text">9876543210, 9876543210</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">Bank information</h3>
            <ul class="personal-info">
              <li>
                <div class="title">Bank name</div>
                <div class="text">ICICI Bank</div>
              </li>
              <li>
                <div class="title">Bank account No.</div>
                <div class="text">159843014641</div>
              </li>
              <li>
                <div class="title">IFSC Code</div>
                <div class="text">ICI24504</div>
              </li>
              <li>
                <div class="title">PAN No</div>
                <div class="text">TC000Y56</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Family Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#family_info_modal"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <div class="table-responsive">
              <table class="table table-nowrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Date of Birth</th>
                    <th>Phone</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Leo</td>
                    <td>Brother</td>
                    <td>Feb 16th, 2023</td>
                    <td>9876543210</td>
                    <td class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          aria-expanded="false"
                          data-bs-toggle="dropdown"
                          class="action-icon dropdown-toggle"
                          href="javascript:;"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="javascript:;" class="dropdown-item"
                            ><i class="fa fa-pencil m-r-5"></i> Edit</a
                          >
                          <a href="javascript:;" class="dropdown-item"
                            ><i class="fa fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Education Informations
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#education_info"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <div class="experience-box">
              <ul class="experience-list">
                <li>
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name"
                        >International College of Arts and Science (UG)</a
                      >
                      <div>Bsc Computer Science</div>
                      <span class="time">2000 - 2003</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name"
                        >International College of Arts and Science (PG)</a
                      >
                      <div>Msc Computer Science</div>
                      <span class="time">2000 - 2003</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card profile-box flex-fill">
          <div class="card-body">
            <h3 class="card-title">
              Experience
              <a
                href="javascript:;"
                class="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#experience_info"
                ><i class="fas fa-pencil-alt"></i
              ></a>
            </h3>
            <div class="experience-box">
              <ul class="experience-list">
                <li>
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name"
                        >Web Designer at Zen Corporation</a
                      >
                      <span class="time"
                        >Jan 2023 - Present (5 years 2 months)</span
                      >
                    </div>
                  </div>
                </li>
                <li>
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name">Web Designer at Ron-tech</a>
                      <span class="time"
                        >Jan 2023 - Present (5 years 2 months)</span
                      >
                    </div>
                  </div>
                </li>
                <li>
                  <div class="experience-user">
                    <div class="before-circle"></div>
                  </div>
                  <div class="experience-content">
                    <div class="timeline-content">
                      <a href="#/" class="name"
                        >Web Designer at Dalt Technology</a
                      >
                      <span class="time"
                        >Jan 2023 - Present (5 years 2 months)</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Profile Info Tab -->
</template>
