<template>
    <div
      :id="modalId"
      class="modal fade custom-modal modal-padding"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header header-border justify-content-between p-0">
            <h5 class="modal-title">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close position-static"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="row">
              <div v-for="(value, key) in transformedFields" :key="key" class="col-12">
                <div class="mb-3 d-flex align-items-center">
                  <label :for="key" class="fw-bold">{{ fieldLabels[key] }} :</label>
                  <p :id="key">{{ value }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger btn-sm btn-cancel waves-effect"
              data-bs-dismiss="modal"
            >
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
import { computed } from 'vue';

  const props = defineProps({
    fields: {
      type: Object,
      default: () => ({}),
    },
    modalId: {
      type: String,
      default: 'showModal',
    },
    modalTitle: {
      type: String,
      default: 'Baxış',
    },
    necessaryFields: {
        type:Array,
        default:['name', 'related_fullname', 'voen', 'created_at','auto_contract','contract_prefix']
    },
    fieldLabels: {
      type: Object,
      default: () => ({
        id: 'ID',
        name: 'Ad',
        related_fullname: 'Əlaqəli şəxs',
        voen: 'VÖEN',
        created_at: 'Yaranma tarixi',
        auto_contract: 'Auto Müqavilə nömrəsi',
        contract_prefix: 'Müqavilə prefix-i',
      }),
    },
  });


const getNecessaryFields = (fields) => {
  let result = {};
  props.necessaryFields.forEach((field) => {
    if (field.includes('.')) {

      const fieldParts = field.split('.');
      if (fieldParts[0] in fields && typeof fields[fieldParts[0]] === 'object') {
          result[field] = fields[fieldParts[0]] ?  fields[fieldParts[0]][fieldParts[1]] : null;
      }
    } else {
      if (field in fields) {
        result[field] = fields[field];
      }
    }
    
  });
  return result;
};

const transformedFields = computed(() => {
const filteredFields = getNecessaryFields(props.fields);


  return {
    ...filteredFields,
    ...(filteredFields.auto_contract !== undefined && {
        auto_contract: filteredFields.auto_contract === 1 ? 'Bəli' : 'Xeyr',
    }),
    ...(filteredFields.is_internal !== undefined && {
        is_internal: filteredFields.is_internal === 1 ? 'Bəli' : 'Xeyr',
    }),
    ...(filteredFields.is_external !== undefined && {
        is_external: filteredFields.is_external === 1 ? 'Bəli' : 'Xeyr',
    }),
    ...(filteredFields.is_customer !== undefined && {
        is_customer: filteredFields.is_customer === 1 ? 'Bəli' : 'Xeyr',
    }),
    ...(filteredFields.is_supplier !== undefined && {
        is_supplier: filteredFields.is_supplier === 1 ? 'Bəli' : 'Xeyr',
    }),
  };
});
  
  </script>
  
  <style scoped>
  .col-12 p {
    flex-grow: 1;
    text-align: right;
    display: flex;
  }
  
  .col-12 p::before {
    content: '';
    background: repeating-linear-gradient(
      to right,
      currentColor,
      currentColor 1px,
      transparent 2px,
      transparent 5px
    );
    height: 1px;
    flex-grow: 1;
    display: inline-block;
    margin-top: 1em;
  }
  </style>
  