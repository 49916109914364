<template>
  <div class="col-md-12">
    <div class="kanban-wrapper leads-kanban-wrapper">
      <div class="kanban-list-items" v-for="(module, color) in modules" :key="color">
        <div class="kanban-list-head">
          <div class="d-flex justify-content-between align-items-center">
            <div :class="module.DotClass">
              <h5>{{ module.Title }}</h5>
              <span>{{ module.Count }}</span>
            </div>
            <div class="kanban-action-btns d-flex align-items-center">
              <a href="javascript:void(0);" class="plus-btn"><i class="la la-plus"></i></a>
              <div class="dropdown dropdown-action">
                <a
                  href="javascript:void(0);"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_leads"
                    ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                  >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_leads"
                    ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="kanban-drag-wrap">
          <draggable
            :list="module.Cards"
            :group="{ name: 'kanban', pull: true, put: true }"
            @end="onDragEnd(color)"
          >
            <li v-for="Card in module.Cards" :key="Card.id">
              <div class="kanban-card">
                <div class="kanban-card-head">
                  <span class="bar-design bg-warning"></span>
                  <div class="kanban-card-title">
                    <span>{{ Card.Logo }}</span>
                    <h6>{{ Card.CompanyName }}</h6>
                  </div>
                </div>
                <div class="kanban-card-body">
                  <ul>
                    <li>
                      <i class="la la-dollar-sign"></i>
                      {{ Card.Amount }}
                    </li>
                    <li>
                      <i class="la la-envelope"></i>
                      {{ Card.Email }}
                    </li>
                    <li>
                      <i class="la la-phone"></i>
                      {{ Card.MobileNo }}
                    </li>
                    <li>
                      <i class="la la-map-marker-alt"></i>
                      {{ Card.Location }}
                    </li>
                  </ul>
                </div>
                <div
                  class="kanban-card-footer d-flex align-items-center justify-content-between"
                >
                  <span
                    ><img :src="require(`@/assets/img/icons/${Card.Image}`)" alt="Img"
                  /></span>
                  <ul class="icons-social">
                    <li>
                      <a href="javascript:void(0);"><i class="la la-phone-volume"></i></a>
                    </li>
                    <li>
                      <a href="javascript:void(0);"><i class="lab la-facebook-messenger"></i></a>
                    </li>
                    <li>
                      <a href="javascript:void(0);"><i class="la la-swatchbook"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </draggable>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
export default {
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      modules: {
        NotContacted: {
          DotClass: "kanban-title-head dot-purple",
          Title: "Not Contacted",
          Count: "13 Leads - $19,38,092",
          Cards: [
            {
              CardClass: "bar-design bg-warning",
              Logo: "SM",
              CompanyName: "Schumm",
              Amount: "$03,50,000",
              Email: "darleeo@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Newyork, United States",
              Image: "company-icon-09.svg",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "CS",
              CompanyName: "Collins",
              Amount: "$02,10,000",
              Email: "sheron@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Exeter, United States",
              Image: "company-icon-01.svg",
            },
            {
              CardClass: "bar-design bg-danger",
              Logo: "SM",
              CompanyName: "Schumm",
              Amount: "$04,22,000",
              Email: "vau@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Phoenix, United States",
              Image: "company-icon-02.svg",
            },
            {
              CardClass: "bar-design bg-purple",
              Logo: "SM",
              CompanyName: "Schumm",
              Amount: "$03,50,000",
              Email: "darleeo@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Newyork, United States",
              Image: "company-icon-09.svg",
            },
          ],
        },
        Contacted: {
          DotClass: "kanban-title-head dot-warning",
          Title: "Contacted",
          Count: "20 Leads - $12,48,924",
          Cards: [
            {
              CardClass: "bar-design bg-purple",
              Logo: "AS",
              CompanyName: "Adams",
              Amount: "$02,45,000",
              Email: "jessica13@example.com",
              MobileNo: "+1 89351-90346",
              Location: "Chester, United States",
              Image: "company-icon-03.svg",
            },
            {
              CardClass: "bar-design bg-warning",
              Logo: "WK",
              CompanyName: "Wizosk",
              Amount: "$01,17,000",
              Email: "caroltho3@example.com",
              MobileNo: "+1 78982-09163",
              Location: "Charlotte, United States",
              Image: "company-icon-04.svg",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "HR",
              CompanyName: "Heller",
              Amount: "$02,12,000",
              Email: "dawnmercha@example.com",
              MobileNo: "+1 27691-89246",
              Location: "Bristol, United States",
              Image: "company-icon-05.svg",
            },
            {
              CardClass: "bar-design bg-danger",
              Logo: "SM",
              CompanyName: "Schumm",
              Amount: "$03,50,000",
              Email: "darleeo@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Newyork, United States",
              Image: "company-icon-01.svg",
            },
          ],
        },
        Closed: {
          DotClass: "kanban-title-head dot-success",
          Title: "Contacted",
          Count: "18 Leads - $16,39,093",
          Cards: [
            {
              CardClass: "bar-design bg-success",
              Logo: "GI",
              CompanyName: "Gutkowsi",
              Amount: "$01,84,043",
              Email: "rachel@example.com",
              MobileNo: "+1 17839-93617",
              Location: "Baltimore, United States",
              Image: "company-icon-06.svg",
            },
            {
              CardClass: "bar-design bg-danger",
              Logo: "WR",
              CompanyName: "Walter",
              Amount: "$09,35,189",
              Email: "jonelle@example.com",
              MobileNo: "+1 16739-47193",
              Location: "Coventry, United States",
              Image: "company-icon-07.svg",
            },
            {
              CardClass: "bar-design bg-purple",
              Logo: "HN",
              CompanyName: "Hansen",
              Amount: "$04,27,940",
              Email: "jonathan@example.com",
              MobileNo: "+1 18390-37153",
              Location: "Seattle",
              Image: "company-icon-08.svg",
            },
            {
              CardClass: "bar-design bg-warning",
              Logo: "SM",
              CompanyName: "Schumm",
              Amount: "$03,50,000",
              Email: "darleeo@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Newyork, United States",
              Image: "company-icon-03.svg",
            },
          ],
        },
        Lost: {
          DotClass: "kanban-title-head dot-danger",
          Title: "Lost",
          Count: "15 Leads - $14,89,543",
          Cards: [
            {
              CardClass: "bar-design bg-danger",
              Logo: "SE",
              CompanyName: "Steve",
              Amount: "$04,17,593",
              Email: "sidney@example.com",
              MobileNo: "+1 11739-38135",
              Location: "London, United States",
              Image: "company-icon-09.svg",
            },
            {
              CardClass: "bar-design bg-success",
              Logo: "LE",
              CompanyName: "Leuschke",
              Amount: "$08,81,389",
              Email: "brook@example.com",
              MobileNo: "+1 19302-91043",
              Location: "Detroit, United States",
              Image: "company-icon-10.svg",
            },
            {
              CardClass: "bar-design bg-warning",
              Logo: "AY",
              CompanyName: "Anthony",
              Amount: "$09,27,193",
              Email: "mickey@example.com",
              MobileNo: "+1 17280-92016",
              Location: "Manchester, United States",
              Image: "company-icon-01.svg",
            },
            {
              CardClass: "bar-design bg-purple",
              Logo: "CS",
              CompanyName: "Collins",
              Amount: "$03,50,000",
              Email: "darleeo@example.com",
              MobileNo: "+1 12445-47878",
              Location: "Newyork, United States",
              Image: "company-icon-10.svg",
            },
          ],
        },
      },
    };
  },
  methods: {
    onDragEnd() {
      // Handle module drops here
    },
    onModuleDragEnd() {
    },
  },
};
</script>
